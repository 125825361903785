import { DEFAULT_LOCALE } from '../constants/defaults';

import fetcher from './fetcher';

export async function useSearchTrip({ search, filterIds }) {
  if (!search) return Promise.resolve({ data: [] });
  return fetcher({
    url: `/api/trips/search?s=${search}&limit=30&idNin=[${filterIds}]`,
    method: 'GET'
  });
}

export async function useGetTrips({ page, search, locale = DEFAULT_LOCALE }) {
  const queryParams = search || ``;
  const urlSearchParams = new URLSearchParams(`${queryParams}`);
  const s = urlSearchParams.get('search');
  const guide = urlSearchParams.get('guide');
  const status = urlSearchParams.get('status');

  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $addFields: {
          sortField: {
            $cond: {
              if: { $ne: ['$updatedAt', null] },
              then: '$updatedAt',
              else: '$dato_last_updated'
            }
          }
        }
      },
      {
        $match: {
          ...(s
            ? {
                [`i18n.${locale}.title`]: {
                  $regex: s,
                  $options: 'i'
                }
              }
            : {}),
          ...(guide ? { guide: { $eq: Number(guide) } } : {}),
          _id: {
            $gt: 0
          },
          [`i18n.${locale}`]: {
            $exists: true
          },
          ...(status
            ? {
                trip_status: {
                  $eq: status
                }
              }
            : {})
        }
      },
      {
        $sort: {
          sortField: -1
        }
      },
      { $skip: 20 * page },
      { $limit: 20 },
      {
        $lookup: {
          from: 'guidesv2',
          localField: 'guide',
          foreignField: '_id',
          as: 'guide'
        }
      },
      {
        $unwind: {
          path: '$guide',
          preserveNullAndEmptyArrays: true
        }
      }
    ])
  );

  return fetcher({
    url: `/api/trip/aggregation?query=${urlSearchParams.get('query').toString()}`,
    method: 'GET'
  });
}

export async function createTrip(data) {
  return fetcher({
    url: `/api/v1/dashboard/trips/new`,
    method: 'POST',
    data
  });
}

const useGetPendingApprovalRecords = async () => {
  return fetcher({
    url: `/api/v1/dashboard/trips/pendingApproval`,
    method: 'GET'
  });
};

const useGetRecentlyUpdated = async () => {
  return fetcher({
    url: `/api/v1/dashboard/trips/recentlyUpdated`,
    method: 'GET'
  });
};

const useGetRecentlyPublished = async () => {
  return fetcher({
    url: `/api/v1/dashboard/trips/recentlyPublished`,
    method: 'GET'
  });
};

const useGetTripsByGuide = async (guideID) => {
  return fetcher({
    url: `/api/v1/dashboard/guides/trips/${guideID}`,
    method: 'GET'
  });
};

const useGetTripById = async (tripId) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/populated/${tripId}`,
    method: 'GET'
  });
};

const getWhatsIncluded = async () => {
  return fetcher({
    url: `/api/related_items/aggregation?query=${JSON.stringify([
      { $match: { type: 'whats_included' } }
    ])}`,
    method: 'GET'
  });
};

const getToBring = async () => {
  return fetcher({
    url: `/api/related_items/aggregation?query=${JSON.stringify([
      { $match: { type: 'to_bring' } }
    ])}`,
    method: 'GET'
  });
};

const usePatchTrip = async (data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${data._id}`,
    method: 'PATCH',
    data
  });
};

const getTripHotels = async (tripId) => {
  return fetcher({
    url: `/api/hotels/aggregation?query=${JSON.stringify([{ $match: { trip: Number(tripId) } }])}`,
    method: 'GET'
  });
};

const deleteHotel = async (hotelId) => {
  return fetcher({
    url: `/api/hotels/${hotelId}`,
    method: 'DELETE'
  });
};

const createHotel = async (data) => {
  return fetcher({
    url: `/api/hotels`,
    method: 'POST',
    data
  });
};

const updateHotel = async (data) => {
  return fetcher({
    url: `/api/hotels/${data._id}`,
    method: 'PUT',
    data
  });
};

// FAQ
const usePatchAllFAQ = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/FAQ/all`,
    method: 'PATCH',
    data
  });
};

const usePatchFAQ = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/FAQ/${data._id}`,
    method: 'PATCH',
    data
  });
};

const useDeleteFAQ = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/FAQ/${data._id}`,
    method: 'DELETE',
    data
  });
};

const usePostFAQ = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/FAQ`,
    method: 'POST',
    data
  });
};

const useGetFAQByTrip = async (id) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${id}/FAQ`,
    method: 'GET'
  });
};

// What's Included

const usePatchWhatsIncluded = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/whatsIncluded`,
    method: 'PATCH',
    data
  });
};

// next Departures

const usePatchNextDepartures = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/nextDeparture/${data._id}`,
    method: 'PATCH',
    data
  });
};

const useDeleteNextDepartures = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/nextDeparture/${data._id}`,
    method: 'DELETE',
    data
  });
};

const usePostNextDepartures = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${tripId}/nextDeparture`,
    method: 'POST',
    data
  });
};

const useGetNextDeparturesByTrip = async (id) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/${id}/nextDeparture`,
    method: 'GET'
  });
};

const usePostChangePrice = async (tripId, data) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/change-price/${tripId}`,
    method: 'POST',
    data
  });
};

const postGeolocationChange = async (tripId, data, locale) => {
  return fetcher({
    url: `/api/v1/dashboard/trips/geolocation/${tripId}?locale=${locale}`,
    method: 'POST',
    data
  });
};

export {
  useGetTripsByGuide,
  usePatchTrip,
  usePatchFAQ,
  usePostFAQ,
  useGetFAQByTrip,
  useDeleteFAQ,
  useGetTripById,
  usePatchWhatsIncluded,
  usePatchNextDepartures,
  useDeleteNextDepartures,
  usePostNextDepartures,
  useGetNextDeparturesByTrip,
  useGetPendingApprovalRecords,
  useGetRecentlyUpdated,
  usePatchAllFAQ,
  usePostChangePrice,
  postGeolocationChange,
  useGetRecentlyPublished,
  getWhatsIncluded,
  getToBring,
  getTripHotels,
  deleteHotel,
  createHotel,
  updateHotel
};
