const ZERO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF'
];

export default function formatMoney(amount, currency) {
  if (amount === null || amount === undefined) return null;
  const isZeroDecimal = ZERO_DECIMAL_CURRENCIES.includes(currency);
  const formatted = (isZeroDecimal ? Math.ceil(amount) : amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return isZeroDecimal ? formatted.replace(/\.\d\d$/, '') : formatted;
}
