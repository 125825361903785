import { useQuery } from '@tanstack/react-query';
import { Box, List, ListItemButton, Typography } from '@mui/joy';

import { Link } from 'react-router-dom';

import MainLayout from '../../components/Layouts/MainLayout';
import { getMails } from '../../lib/mails';
import Loading from '../../components/Loading/Loading';

function upperFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const mapTo = {
  '{{lead.email}}': 'Lead Email',
  '{{guide.email}}': 'Guide Email',
  '{{user.email}}': 'User Email'
};

export default function Mails() {
  const { data, isLoading } = useQuery(['allMails'], () => getMails());

  if (isLoading) {
    return (
      <MainLayout title="Mails">
        <Loading />
      </MainLayout>
    );
  }

  const mails = data?.data;

  return (
    <MainLayout title="Mails">
      {mails?.length ? (
        <Box>
          <List>
            {mails.map((mail) => (
              <ListItemButton
                key={mail._id}
                component={Link}
                to={`/messaging/mails/${mail._id}`}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '1px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  {upperFirstLetter(mail.mail_name)} -
                  <Typography level="body-xs">To: {mapTo[mail.to]}</Typography>
                </Box>
                <Typography level="body-xs">{mail.comments}</Typography>
              </ListItemButton>
            ))}
          </List>
        </Box>
      ) : (
        <Box>No mails found</Box>
      )}
    </MainLayout>
  );
}
