import { Box, Typography, Checkbox } from '@mui/joy';
import { Select } from '@esui/lib.formik.select';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Input } from '@esui/lib.formik.input';

import {
  getAllActivities,
  getAllCountries,
  getAllLocations,
  getAllMountainRanges,
  getAllPeriods,
  getAllRegions
} from '../../../lib/taxonomies';
import MainLayout from '../../../components/Layouts/MainLayout';
import Loading from '../../../components/Loading/Loading';
import TripEditLayout from '../TripEditLayout';
import { useGetCurrenciesAvailable } from '../../../lib/currencies';
import ExpandableContent from '../ExpandableContent';
import { usePatchTrip } from '../../../lib/trip';
import Guide from '../Guide';
import ArrayField from '../../../components/ArrayField';

export default function GeneralInfo() {
  const currenciesRequest = useQuery(['currenciesAvailable'], () => useGetCurrenciesAvailable());

  const { isLoading } = currenciesRequest;

  if (isLoading)
    return (
      <MainLayout title="General Info">
        <Loading />
      </MainLayout>
    );

  const handleMutation = async (tripId, newTrip) => {
    const newData = {
      ...newTrip,
      guide: newTrip.guide?._id,
      activities: newTrip.activities.map((v) => v._id),
      countries: newTrip.countries.map((v) => v._id),
      locations: newTrip.locations.map((v) => v._id),
      regions: newTrip.regions.map((v) => v._id),
      main_activity: (() => {
        if (!Array.isArray(newTrip.main_activity)) return newTrip.main_activity?._id;
        return newTrip.main_activity?.[0] ? newTrip.main_activity[0]._id : null;
      })(),
      activity_group: (() => {
        if (!Array.isArray(newTrip.main_activity)) return newTrip.main_activity?.group;
        return newTrip.main_activity?.[0] ? newTrip.main_activity[0].group : null;
      })(),
      mountain_ranges: newTrip.mountain_ranges.map((v) => v._id),
      periods: newTrip.periods.map((v) => v._id),
      enabled: newTrip.trip_status === 'Active',
      followMetaTag: newTrip.trip_status === 'Active' ? 'None' : 'noindex-follow'
    };
    await toast.promise(
      usePatchTrip({
        _id: tripId,
        ...newData
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  return (
    <TripEditLayout title="General Info" handleMutation={handleMutation}>
      {(
        {
          activities,
          countries,
          locations,
          regions,
          main_activity,
          mountain_ranges,
          periods,
          guide,
          explore_share_selection
        },
        setFieldValue
      ) => {
        return (
          <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
            <ExpandableContent title="Guide">
              <Guide guide={guide} setFieldValue={setFieldValue} />
            </ExpandableContent>

            <ExpandableContent title="Periods">
              <ArrayField
                valueKey="periods"
                currentValues={periods}
                name="periods"
                fn={getAllPeriods}
              />
            </ExpandableContent>

            <ExpandableContent title="Duration">
              <Box>
                <Input label="Min duration" name="duration.0" type="number" />
              </Box>
              <Box>
                <Input label="Max duration" name="duration.1" type="number" />
              </Box>
              <Box>
                <Input label="Duration in days" name="duration_in_days" />
              </Box>
            </ExpandableContent>

            <ExpandableContent title="Settings">
              <Checkbox
                label="Explore-Share selection tag"
                defaultChecked={explore_share_selection}
                onChange={(e) => setFieldValue('explore_share_selection', e.target.checked)}
              />
              <Box>
                <Input label="Guide ratio" name="guide_ratio" />
              </Box>
              <Box>
                <Select
                  label="Trip status"
                  name="trip_status"
                  items={[
                    { value: 'Active', label: 'Active' },
                    { value: 'Inactive', label: 'Inactive' },
                    { value: 'Draft', label: 'Draft' }
                  ]}
                />
              </Box>
              <Box>
                <Select
                  label="Trip type"
                  name="trip_type"
                  items={[
                    { value: 'Group', label: 'Group' },
                    { value: 'Private', label: 'Private' },
                    { value: 'Private and Groups', label: 'Private and Groups' },
                    { value: 'Not specified', label: 'Not specified' }
                  ]}
                />
              </Box>
            </ExpandableContent>

            <ExpandableContent title="Levels">
              <Box>
                <Typography level="title-md">Fitness level</Typography>
                <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
                  <Select
                    name="fitness_level"
                    items={[
                      { label: 'Extremely fit', value: 'extremely-fit' },
                      { label: 'Occasional', value: 'occasional' },
                      { label: 'Fit', value: 'fit' },
                      { label: 'Open to everyone', value: 'open-to-everyone' }
                    ]}
                  />
                </Box>
              </Box>
              <Box>
                <Typography level="title-md">Trip level</Typography>
                <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
                  <Select
                    name="level"
                    items={[
                      { label: 'Easy', value: 'easy' },
                      { label: 'Intermediate', value: 'intermediate' },
                      { label: 'Sustained', value: 'sustained' },
                      { label: 'Hard', value: 'hard' }
                    ]}
                  />
                </Box>
              </Box>
              <Box>
                <Typography level="title-md">Skill level</Typography>
                <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
                  <Select
                    name="skill_level"
                    items={[
                      { label: 'First time', value: 'first-time' },
                      { label: 'Beginner', value: 'beginner' },
                      { label: 'Intermediate', value: 'intermediate' },
                      { label: 'Advanced', value: 'advanced' },
                      { label: 'All levels', value: 'all-levels' }
                    ]}
                  />
                </Box>
              </Box>
            </ExpandableContent>

            <ExpandableContent title="Activities">
              <ArrayField valueKey="activities" currentValues={activities} fn={getAllActivities} />
            </ExpandableContent>

            <ExpandableContent title="Countries">
              <ArrayField valueKey="countries" currentValues={countries} fn={getAllCountries} />
            </ExpandableContent>

            <ExpandableContent title="Locations">
              <ArrayField
                remoteQuery
                valueKey="locations"
                currentValues={locations}
                fn={getAllLocations}
              />
            </ExpandableContent>

            <ExpandableContent title="Regions">
              <ArrayField
                remoteQuery
                valueKey="regions"
                currentValues={regions}
                fn={getAllRegions}
              />
            </ExpandableContent>

            <ExpandableContent title="Mountain ranges">
              <ArrayField
                valueKey="mountain_ranges"
                currentValues={mountain_ranges}
                fn={getAllMountainRanges}
              />
            </ExpandableContent>

            <ExpandableContent title="Main activity">
              <ArrayField
                valueKey="main_activity"
                currentValues={(() => {
                  if (Array.isArray(main_activity)) return main_activity;
                  return main_activity ? [main_activity] : [];
                })()}
                fn={getAllActivities}
              />
            </ExpandableContent>
          </Box>
        );
      }}
    </TripEditLayout>
  );
}
