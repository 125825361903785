import fetcher from './fetcher';

const apiUrl = import.meta.env.VITE_API;

export async function useStats({ startDate, endDate }) {
  console.log({ startDate, endDate });
  const statsURL = new URL(`${apiUrl}/api/booking/aggregation`);

  statsURL.searchParams.set(
    'query',
    JSON.stringify([
      {
        $facet: {
          // total: [
          //   {
          //     $group: {
          //       _id: '$state',
          //       count: {
          //         $sum: 1
          //       }
          //     }
          //   }
          // ],
          // byDateRange: [
          //   {
          //     $match: {
          //       created: {
          //         $gte: `new Date("${startDate}")`,
          //         $lt: `new Date("${endDate}")`
          //       }
          //     }
          //   },
          //   {
          //     $group: {
          //       _id: '$state',
          //       count: {
          //         $sum: 1
          //       }
          //     }
          //   }
          // ],
          bookingsAndActivities: [
            {
              $match: {
                created: {
                  $gte: `new Date("${startDate}")`,
                  $lt: `new Date("${endDate}")`
                }
              }
            },
            {
              $project: {
                _id: 1
              }
            },
            {
              $lookup: {
                from: 'booking_activities',
                // localField: '_id',
                // foreignField: 'booking',
                as: 'activities',
                let: { bookingId: '$_id' },
                pipeline: [
                  {
                    $match: {
                      $expr: { $eq: ['$$bookingId', '$booking'] },
                      prev_state: { $exists: true }
                    }
                  },
                  {
                    $project: {
                      booking: 1,
                      timestamp: 1,
                      prev_state: 1,
                      prev_guide_state: 1,
                      state: 1,
                      guide_state: 1,
                      state_changed: 1,
                      guide_state_changed: 1,
                      action: 1
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ])
  );

  return fetcher({
    url: `${statsURL.toString()}`,
    method: 'GET'
  });
}

export async function useStatsByBookings(bookings) {
  const statsURL = new URL(`${apiUrl}/api/booking/aggregation`);
  console.log(bookings);
  statsURL.searchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          _id: { $in: bookings }
        }
      },
      {
        $lookup: {
          from: 'booking_activities',
          // localField: '_id',
          // foreignField: 'booking',
          as: 'activities',
          let: { bookingId: '$_id' },
          pipeline: [
            {
              $match: {
                $expr: { $eq: ['$$bookingId', '$booking'] },
                prev_state: { $exists: true }
              }
            },
            {
              $project: {
                booking: 1,
                timestamp: 1,
                prev_state: 1,
                prev_guide_state: 1,
                state: 1,
                guide_state: 1,
                state_changed: 1,
                guide_state_changed: 1,
                action: 1
              }
            }
          ]
        }
      },
      {
        $lookup: {
          from: 'messages',

          as: 'messages',
          let: { bookingId: '$_id' },
          pipeline: [
            {
              $match: {
                $expr: { $eq: ['$$bookingId', '$booking'] }
              }
            },
            {
              $project: {
                sender: 1,
                ts: 1,
                content: 1,
                booking: 1
              }
            }
          ]
        }
      }
    ])
  );

  return fetcher({
    url: `${statsURL.toString()}`,
    method: 'GET'
  });
}
