import { useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useParams, Link } from 'react-router-dom';

import utc from 'dayjs/plugin/utc';

import MainLayout from '../../components/Layouts/MainLayout';
import useIsIframe from '../../hooks/useIsIframe';

import { useGetBlocksDates } from '../../lib/unavailability';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Datepicker } from '@esui/lib.formik.datepicker';
import { Formik, Form } from 'formik';
import ButtonLoading from '../../components/ButtonLoading';

import { CalendarMonth } from '@mui/icons-material';

dayjs.extend(utc);

export default function BlocksByDates() {
  const isIframe = useIsIframe();

  const [dates, setDates] = useState({
    start: dayjs().add(-1, 'week'),
    end: dayjs()
  });

  const { blocks, isFetching, isLoading } = useGetBlocksDates(
    dates.start.format('YYYY-MM-DD'),
    dates.end.format('YYYY-MM-DD')
  );

  const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

  return (
    <MainLayout back={isIframe ? null : '/'} title="Unavailability">
      <Formik
        initialValues={dates}
        onSubmit={(values) => {
          setDates({
            start: dayjs(values.start),
            end: dayjs(values.end)
          });
        }}>
        {({ values }) => (
          <Form>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'flex-end' }}>
              <Datepicker
                name="start"
                label="Start"
                autoComplete="off"
                endDecorator={
                  <IconButton>
                    <CalendarMonth />
                  </IconButton>
                }
                sx={{
                  '--joy-palette-neutral-plainDisabledColor': '#414141',
                  '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                }}
                // calendarProps={{
                //   locale: 'en-GB',
                //   ...(values.endDate && { maxDate: new Date(values.endDate) })
                // }}
              />

              <Datepicker
                name="end"
                label="End"
                autoComplete="off"
                endDecorator={
                  <IconButton>
                    <CalendarMonth />
                  </IconButton>
                }
                sx={{
                  '--joy-palette-neutral-plainDisabledColor': '#414141',
                  '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                }}
                // calendarProps={{
                //   locale: 'en-GB',
                //   ...(values.endDate && { minDate: new Date(values.endDate) })
                // }}
              />

              <ButtonLoading size="sm" type="submit" isLoading={isLoading}>
                Search
              </ButtonLoading>
            </Box>
          </Form>
        )}
      </Formik>

      {!isFetching && !blocks?.length ? (
        <Typography level="body-sm" fontStyle="italic">
          No blocked dates
        </Typography>
      ) : (
        <>
          <Box>
            <Typography level="h5">Blocks:</Typography>
            {blocks?.length ? (
              <Box sx={{ marginTop: '10px' }}>
                {blocks.map(({ startAt, endAt, type, guide, trip, createdAt, comment }, i) => {
                  return (
                    <Box key={i} sx={{ padding: '5px', alignItems: 'center', gap: '5px' }}>
                      <Typography level="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        {formatDate(startAt)} <NavigateNextIcon /> {formatDate(endAt)}{' '}
                        {type === 'trip' ? trip?.title : 'All trips'}
                      </Typography>
                      <Typography level="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link to={`/guide/${guide?._id}`}>
                          {guide?.first_name} {guide?.last_name}{' '}
                        </Link>
                      </Typography>
                      <Typography level="body-sm">
                        Created at: {formatDate(createdAt)}{' '}
                        {comment && <Chip size="sm">{comment}</Chip>}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </MainLayout>
  );
}
