import { useState } from 'react';

import revalidatePage from '../lib/revalidatePage';

export default function useRevalidatePage() {
  const [revalidating, setRevalidating] = useState(false);

  const handleRevalidatePage = async (link) => {
    setRevalidating(true);

    const mobileUrl = link.replace(
      'https://www.explore-share.com',
      'https://www.explore-share.com/mobile'
    );

    try {
      await revalidatePage({
        absolute: true,
        url: link
      });
      await revalidatePage({
        absolute: true,
        url: mobileUrl
      });
    } catch (err) {
      console.error('Failed to revalidate page', err);
    } finally {
      setRevalidating(false);
    }
  };

  return [revalidating, handleRevalidatePage];
}
