import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import { useState } from 'react';

export default function ExpandSectionLayout({ children, title }) {
  const [showSection, setShowSection] = useState(false);
  return (
    <Box
      sx={{
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '20px'
        }}
        onClick={() => setShowSection((prev) => !prev)}>
        <Typography level="h4">{title}</Typography>
        {showSection ? (
          <ExpandLess sx={{ color: 'black' }} />
        ) : (
          <ExpandMore sx={{ color: 'black' }} />
        )}
      </Box>
      {showSection ? <Box sx={{ padding: '20px' }}>{children}</Box> : null}
    </Box>
  );
}
