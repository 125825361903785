import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import MainLayout from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import Loading from '../../components/Loading/Loading';
import { getGuidesWithAggregation } from '../../lib/guide';

export default function GuideEditLayout({ children, title, handleMutation, customActionButton }) {
  const { guideId } = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading: isLoadingTrip } = useQuery(
    ['guide', guideId],
    () =>
      getGuidesWithAggregation([
        {
          $match: {
            _id: {
              $eq: Number(guideId)
            }
          }
        },
        {
          $lookup: {
            from: 'countriesv2',
            localField: 'guided_countries',
            foreignField: '_id',
            as: 'guided_countries'
          }
        }
      ]),
    {
      staleTime: Infinity
    }
  );

  const mutation = useMutation(
    (values) => {
      return handleMutation(guideId, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['guide']);
      }
    }
  );

  if (isLoadingTrip)
    return (
      <MainLayout title={title}>
        <Loading />
      </MainLayout>
    );

  const [guide] = data.data || [{}];
  const guideName = guide.display_name || `${guide.first_name} ${guide.last_name}`;

  return (
    <Formik initialValues={guide} validateOnChange={false} onSubmit={mutation.mutate}>
      {({ values, setFieldValue }) => (
        <Form>
          <MainLayout
            title={title}
            topTitle={guideName}
            options={
              customActionButton || (
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Save
                </ButtonLoading>
              )
            }
            back={`/guide/${guideId}`}>
            {children(values, setFieldValue)}
          </MainLayout>
        </Form>
      )}
    </Formik>
  );
}
