import { Formik, Form, FieldArray } from 'formik';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, Button, List, ListItem, Typography } from '@mui/joy';
import { Textarea } from '@esui/lib.formik.textarea';
import { useState } from 'react';
import toast from 'react-hot-toast';

import MainLayout from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import {
  createHotel,
  deleteHotel,
  getTripHotels,
  updateHotel,
  useGetTripById
} from '../../lib/trip';
import Loading from '../../components/Loading/Loading';
import { useLocale } from '../../hooks/useLocale';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import ButtonAsker from '../../components/ButtonAsker/ButtonAsker';
import { DEFAULT_LOCALE } from '../../constants/defaults';
import useRevalidatePage from '../../hooks/useRevalidatePage';

export default function EditHotels() {
  const { tripId } = useParams();
  const [locale] = useLocale();
  const [revalidating, handleRevalidate] = useRevalidatePage();

  const { data: tripData, isLoading: isLoadingTrip } = useQuery(
    ['trip', tripId],
    async () => {
      const trip = await useGetTripById(tripId);
      const hotels = await getTripHotels(tripId);
      return {
        trip,
        hotels
      };
    },
    {
      staleTime: Infinity
    }
  );

  const [deletedHotels, setDeletedHotels] = useState([]);

  if (isLoadingTrip)
    return (
      <MainLayout title="FAQs">
        <Loading />
      </MainLayout>
    );

  const locales = Object.keys(tripData.trip.i18n);

  const updateHotels = async (values) => {
    const newHotels = values.hotels.filter((hotel) => hotel.isNew);
    const updatedHotels = values.hotels.filter((hotel) => !hotel.isNew);

    const promises = [];

    for (const hotel of newHotels) {
      promises.push(createHotel(hotel));
    }

    for (const hotel of updatedHotels) {
      promises.push(updateHotel(hotel));
    }

    for (const hotelId of deletedHotels) {
      promises.push(deleteHotel(hotelId));
    }

    await toast.promise(Promise.all(promises), {
      loading: 'Saving...',
      success: 'Saved',
      error: 'Error'
    });

    const allTripLinks = Object.entries(tripData.trip.i18n)
      .flatMap(([localeKey, data]) => {
        if (localeKey === DEFAULT_LOCALE) {
          return [
            data.link,
            data.link.replace(
              'https://www.explore-share.com',
              'https://www.explore-share.com/mobile'
            )
          ];
        }
        const lang = localeKey.split('_')[0];
        return [
          data.link,
          data.link.replace(
            `https://www.explore-share.com/${lang}`,
            `https://www.explore-share.com/${lang}/mobile`
          )
        ];
      })
      .filter(Boolean);

    for (const link of allTripLinks) {
      handleRevalidate(link);
    }
  };

  return (
    <Formik
      initialValues={{ hotels: tripData.hotels.data }}
      validateOnChange={false}
      onSubmit={(values) => {
        updateHotels(values);
      }}>
      {({ values }) => {
        return (
          <Form>
            <MainLayout
              title="FAQs"
              topTitle={tripData?.title}
              options={
                <ButtonLoading size="sm" type="submit">
                  Save
                </ButtonLoading>
              }
              back={`/trip/${tripId}/options`}>
              {locales.length > 1 ? <LocaleSelector /> : null}

              <FieldArray name="hotels">
                {({ push, remove }) => (
                  <List>
                    <ListItem>
                      <Typography level="h4">Hotels</Typography>
                    </ListItem>

                    {values.hotels.length > 0 &&
                      values.hotels.map(({ i18n, _id }, index) => {
                        return (
                          <ListItem
                            key={_id}
                            sx={{
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              marginTop: '30px'
                            }}>
                            <Box sx={{ width: '100%' }}>
                              <Textarea
                                name={`hotels.${index}.i18n.${locale}.title`}
                                label="Title"
                              />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                              <Textarea name={`hotels.${index}.source`} label="Link" />
                            </Box>
                            <Box
                              sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                              <ButtonAsker
                                size="sm"
                                ask={`Are you sure remove "${i18n[locale].title}"?`}
                                color="danger"
                                variant="plain"
                                onClick={() => {
                                  setDeletedHotels((prev) => [...prev, _id]);
                                  remove(index);
                                }}>
                                Remove this question
                              </ButtonAsker>
                            </Box>
                          </ListItem>
                        );
                      })}

                    <ListItem sx={{ marginTop: '30px' }}>
                      <Button
                        onClick={() =>
                          push({
                            type: 'BOOKING',
                            trip: Number(tripId),
                            source: '',
                            i18n: { [locale]: { title: '' } },
                            isNew: true
                          })
                        }>
                        Add Hotel
                      </Button>
                    </ListItem>
                  </List>
                )}
              </FieldArray>
            </MainLayout>
          </Form>
        );
      }}
    </Formik>
  );
}
