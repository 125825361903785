import { Input, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';

dayjs.extend(utc);

const validDateRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/;

export default function Dates({ defaultDate, handleNewDate, title }) {
  const [newDate, setNewDate] = useState(null);

  const handleDate = (date) => {
    if (date.match(validDateRegex)) {
      const [day, month, year] = date.split('-');
      const d = new Date(year, month - 1, day);
      handleNewDate(d);
    }
    setNewDate(date);
  };

  return (
    <>
      <Typography level="body-sm">{title ?? 'New date'} (DD-MM-YYYY)</Typography>
      <Input
        type="text"
        defaultValue={defaultDate ? defaultDate.split('T')[0].split('-').reverse().join('-') : ''}
        value={newDate}
        onChange={(e) => handleDate(e.target.value)}
      />
    </>
  );
}
