export const ALL_CERTIFICATES = [
  { title: '', certificate: 'DMLA', link: '' },
  { title: '', certificate: 'CAM', link: '' },
  { title: '', certificate: 'SNAPEC', link: '' },
  { title: '', certificate: 'DEJEPS', link: '' },
  {
    title: 'Accompagnatori di Media Montagna',
    certificate: 'AGAI-AMM',
    link: 'https://www.guidealpine.it/accompagnatore-di-media-montagna.html'
  },
  {
    title: 'American Mountain Guides Association',
    certificate: 'AMGA',
    link: 'http://www.amga.com/'
  },
  {
    title: 'Asociación Andaluza de Guías de Montaña',
    certificate: 'AAGGM',
    link: 'https://aaggm.es/'
  },
  {
    title: 'Asociación Argentina de Guías de Montaña',
    certificate: 'AAGM',
    link: 'https://www.aagm.com.ar/'
  },
  {
    title: 'Asociación Argentina de Instructores de Esquí y Snowboard',
    certificate: 'AADIDESS',
    link: 'https://www.aadidess.com/'
  },
  {
    title: 'Asociación Chilena de Guías/Instructores de Montaña y Escalada',
    certificate: 'ACGM',
    link: 'https://www.acgm.cl/'
  },
  {
    title: 'Asociación Colombiana de Guías de Montaña y Escalada',
    certificate: 'ACGME',
    link: 'https://asoguiasacgme.wixsite.com/'
  },
  {
    title: 'Asociación de Guias de Montana de Peru',
    certificate: 'AGMP',
    link: 'http://www.agmp.pe/'
  },
  {
    title: 'Asociacion de Guias de Montaña y Trekking de Bolivia',
    certificate: 'AGMTB',
    link: 'http://www.agmtb.org/'
  },
  {
    title: 'Asociación de Guías Oficiales de Caminata del Perú',
    certificate: 'AGOCP',
    link: 'https://perutrekkingguide.com/'
  },
  {
    title: 'Asociación Ecuatoriana de Guias de Montaña',
    certificate: 'ASEGUIM',
    link: 'http://www.aseguim.org/'
  },
  {
    title: 'Asociación Española de Guias de Montaña',
    certificate: 'AEGM',
    link: 'http://www.aegm.org/'
  },
  {
    title: 'Asociación Mexicana de Guias de Montaña y Escalada',
    certificate: 'AMGME',
    link: ''
  },
  {
    title: 'Asociación Nacional de Guías de Montaña',
    certificate: 'ANGM',
    link: 'https://angm.cl/'
  },
  {
    title: 'Asociatia Ghizilor Montani din Romania',
    certificate: 'AGMR',
    link: 'https://www.agmr.ro/'
  },
  {
    title: 'Associació de Guies i Acompanyants de Muntanya d´Andorra',
    certificate: 'AGAMA',
    link: 'https://www.agamandorra.org/'
  },
  {
    title: 'Association of Canadian Mountain Guides',
    certificate: 'ACMG',
    link: 'http://www.acmg.ca'
  },
  {
    title: 'Association of Iceñandic Mountain Guides',
    certificate: 'AIMG',
    link: 'http://aimg.is/'
  },
  {
    title: 'Association of Mountaineering Instructors - Mountaineering Instructor Award',
    certificate: 'AMI MIA',
    link: 'https://www.mountain-training.org/membership/ami'
  },
  {
    title: 'Association of Mountaineering Instructors - Mountaineering Instructor Certificate',
    certificate: 'AMI MIC',
    link: 'https://www.mountain-training.org/membership/ami'
  },
  {
    title: 'Association Suisse des Accompagnateurs et Accompagnatrices en Montagne',
    certificate: 'ASAM',
    link: 'https://www.asam-swl.ch/'
  },
  {
    title: 'Associazione Guide Ambientali Europee',
    certificate: 'AGAE',
    link: 'https://www.agae.it/'
  },
  {
    title: 'Associazione Italiana Guide Ambientali Escursionistiche',
    certificate: 'AIGAE',
    link: 'https://www.aigae.org/'
  },
  {
    title: 'Australian Climbing Instructors Association',
    certificate: 'ACIA',
    link: 'https://acia.com.au/'
  },
  {
    title: 'British Association of International Mountain Leaders',
    certificate: 'BAIML',
    link: 'https://baiml.org/'
  },
  {
    title: 'British Mountain Guides',
    certificate: 'BMG',
    link: 'http://www.bmg.org.uk/'
  },
  {
    title: 'Canada Ski Guide Association',
    certificate: 'CSGA',
    link: 'https://canskiguide.com/'
  },
  {
    title: 'Canyon International Commission',
    certificate: 'CIC',
    link: 'https://canskiguide.com/'
  },
  {
    title: 'Ceska asociace horských vudcu',
    certificate: 'CAHV',
    link: 'http://www.horskyvudce.com'
  },
  {
    title: 'Collegio Nazionale Guide Alpine Italiane',
    certificate: 'GAI',
    link: 'https://www.guidealpine.it/'
  },
  {
    title: 'Czech Association of Mountain Guides',
    certificate: 'CMGA',
    link: 'https://www.horskyvudce.com/'
  },
  {
    title: 'Czech Mountain Leader Association',
    certificate: 'CMLA',
    link: 'https://www.horskypruvodce.cz/'
  },
  {
    title: 'East European Mountain Guides Association',
    certificate: 'EEMGA',
    link: 'https://www.eemga.org/'
  },
  {
    title: 'Escuela Provincial de Guías de Alta Montaña y Trekking',
    certificate: 'EPGAMT',
    link: 'http://escueladeguias.net.ar/'
  },
  {
    title: 'Escuela Valenciana de Alta Montaña',
    certificate: 'EVAM',
    link: 'https://www.femecv.com/'
  },
  {
    title: 'Georgian Mountain Guide Association',
    certificate: 'GMGA',
    link: 'http://www.mountainguide.ge/'
  },
  {
    title: 'Hellenic Mountain Guides Association',
    certificate: 'HMGA',
    link: 'https://hmga.gr/en/home/'
  },
  {
    title: 'Hellenic Mountain Leaders Association',
    certificate: 'HMLA',
    link: 'https://hmla.com.gr/'
  },
  {
    title: 'Instituto Superior Andes del Sur',
    certificate: 'ISAS',
    link: 'http://www.isas.edu.ar/web/'
  },
  {
    title: 'Instructor Level 3: River Kayaking',
    certificate: ' ACA - American Canoe Association',
    link: ''
  },
  {
    title: 'International Canyoning Academy Association',
    certificate: 'ICA',
    link: ''
  },
  {
    title: 'International Canyoning Organization for Professionals',
    certificate: 'ICOPro',
    link: 'https://www.icopro.org/'
  },
  {
    title: 'International Federation of Mountain Guide Associations',
    certificate: 'IFMGA',
    link: 'https://ifmga.info/'
  },
  {
    title: 'International Polar Guides Association',
    certificate: 'IPGA',
    link: 'https://polarguides.org/'
  },
  {
    title: 'International Ski Instructors Association',
    certificate: 'ISIA',
    link: 'https://isia.ski/'
  },
  {
    title: 'International Snow Training Academy',
    certificate: 'ISTA',
    link: 'www.ista-education.com'
  },
  {
    title: 'Internationale Vereinigung der Bergführerverbande',
    certificate: 'IVBV',
    link: 'https://ifmga.info/'
  },
  {
    title: 'Japan Mountain Guide Association',
    certificate: 'JMGA',
    link: 'http://www.jfmga.com/'
  },
  {
    title: 'Kyrgyz Mountain Guide Association',
    certificate: 'KMGA',
    link: 'http://mguide.in.kg/'
  },
  {
    title: 'Mountain Leader DTS',
    certificate: 'Mountain Leader DTS',
    link: ''
  },
  {
    title: "Nagano Prefecture's Official Mountain Guides",
    certificate: 'NPOMG',
    link: 'https://www.go-nagano.net/en/nagano-prefecture-hiking-mountain-guides/'
  },
  {
    title: 'Národná asociácia horských vodcov Slovenskej republiky',
    certificate: 'NAHVSR',
    link: 'http://www.nahvsr.sk/'
  },
  {
    title: 'Nepal National Mountain Guide Association',
    certificate: 'NNMGA',
    link: 'http://www.nnmga.org/'
  },
  {
    title: 'New Zealand Mountain Guides Association',
    certificate: 'NZMGA',
    link: 'http://www.nzmga.org.nz/'
  },
  {
    title: 'Norske Tindevegledere - Norwegian Mountain Guides',
    certificate: 'NORTIND',
    link: 'http://www.nortind.no/'
  },
  {
    title: 'Planinarski savez Herceg Bosne',
    certificate: 'PSHB',
    link: 'https://ps-hb.info/'
  },
  {
    title: 'Planinska zveza Slovenije - Alpine Association of Slovenia',
    certificate: 'PZS',
    link: 'https://www.pzs.si/'
  },
  {
    title: 'Polskie Stowarzyszenie Przewodników Wysokogórskich',
    certificate: 'PSPW',
    link: 'http://www.pspw.pl/'
  },
  {
    title: 'Professional Climbing Guides Institute',
    certificate: 'PCGI',
    link: 'https://climbingguidesinstitute.org/'
  },
  {
    title: 'Professional Ski Instructors of America',
    certificate: 'PSIA',
    link: 'https://www.psia-i.org/'
  },
  {
    title: 'Rock Climbing Instructor',
    certificate: 'Rock Climbing Instructor',
    link: ''
  },
  {
    title: 'Russian Mountain Guide Association',
    certificate: 'RMGA',
    link: ''
  },
  {
    title: 'Savez gorskih vodiča Hrvatske',
    certificate: 'SGVH',
    link: 'https://sgvh.hr/'
  },
  {
    title: 'Schweizer Bergführer Verband',
    certificate: 'SBV',
    link: 'https://sbv-asgm.ch/'
  },
  {
    title: 'Ski Instructor ',
    certificate: 'Ski Instructor ',
    link: ''
  },
  {
    title: 'Slovak Association of Mountain Leaders',
    certificate: 'SAML',
    link: 'http://www.horskysprievodca.eu/'
  },
  {
    title: 'Slovenian Association of Mountain Guides',
    certificate: 'ZGVS',
    link: 'http://www.zgvs.si/'
  },
  {
    title: 'Societatea Ghizilor și Liderilor Montani',
    certificate: 'SGLM',
    link: 'http://ghizimontani.org/'
  },
  {
    title: 'Stowarzyszenie Międzynarodowych Przewodników Górskich',
    certificate: 'SMPG',
    link: 'http://uimla.pl/'
  },
  {
    title: 'Svenska Bergsguide Organisationen',
    certificate: 'SBO',
    link: 'http://www.sbo.nu/'
  },
  {
    title: 'Swedish Mountain Leader Organisation',
    certificate: 'SFLO',
    link: 'https://svenskafjalledare.se/'
  },
  {
    title: 'Syndicat National des Accompagnateurs en Montagne',
    certificate: 'SNAM',
    link: 'https://snam.pro/'
  },
  {
    title: 'Syndicat National des Guides de Montagnes',
    certificate: 'SNGM',
    link: 'http://www.sngm.com/'
  },
  {
    title: 'Tasmanian Climbing Instructors Association',
    certificate: 'TCIA',
    link: 'http://tcia.asn.au/'
  },
  {
    title: 'Unión Internacional de Asociaciones de Guías de Montaña',
    certificate: 'UIAGM',
    link: 'https://ifmga.info/'
  },
  {
    title: 'Union of International Mountain Leader Associations',
    certificate: 'UIMLA',
    link: 'https://uimla.org/'
  },
  {
    title: 'Union Professionnelle des Métiers de la Montagne',
    certificate: 'UPMM',
    link: 'http://www.upmm.be/'
  },
  {
    title: 'Unione Valdostana Guide di Alta Montagna',
    certificate: 'UVGAM',
    link: 'http://www.guidealtamontagna.com/'
  },
  {
    title: 'Verband der Oesterreichischen Berg- und Skiführer',
    certificate: 'VOBS',
    link: 'https://www.bergfuehrer.at/'
  },
  {
    title: 'Verband der Südtiroler Berg- und Skiführer',
    certificate: 'VSBS',
    link: 'http://www.bergfuehrer-suedtirol.it/'
  },
  {
    title: 'Verband Deutscher Berg- und Skiführer',
    certificate: 'VDBS',
    link: 'http://www.vdbs.de/'
  }
];

export const VERIFIED_INFO = [
  { label: 'Government Id', value: 'government_id' },
  { label: 'Certifications', value: 'certifications' },
  { label: 'Insurance', value: 'insurance' },
  { label: 'Phone number', value: 'phone_number' },
  { label: 'Email address', value: 'email_address' }
];

export const LANGUAGES = [
  'Afrikaans',
  'Albanian',
  'Armenian',
  'Bosnian',
  'Bulgarian',
  'Catalan',
  'Chinese',
  'Croatian',
  'Czech',
  'English',
  'Estonian',
  'Filipino',
  'Finnish',
  'French',
  'Georgian',
  'German',
  'Greek',
  'Guarani',
  'Hawaiian',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Korean',
  'Kyrgyz',
  'Luxembourgish',
  'Macedonian',
  'Mongolian',
  'Norwegian',
  'Persian',
  'Polish',
  'Portuguese',
  'Romanian',
  'Russian',
  'Serbian',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swedish',
  'Turkish',
  'Turkmen',
  'Ukrainian',
  'Uzbek',
  'Vietnamese'
];

export const GUIDE_TYPES = [
  'Alpine Guide',
  'Apprentice Rock Guide',
  'Aspirant Guide',
  'Canyoning Guide',
  'Climbing Gym',
  'FWQ Athlete',
  'FWT Athlete',
  'Guide Agency',
  'Guiding agency',
  'Hard Ice Guide',
  'Hiking Guide',
  'Master Polar Guide',
  'Mountain Bike Guide',
  'Mountain Guide',
  'Mountain Leader',
  'Nature Guide',
  'Paragliding Instructor',
  'Rock Climbing Instructor',
  'Rock Guide',
  'Single Pitch Instructor',
  'Ski Guide',
  'Ski Instructor',
  'Ski School',
  'Snowboard Instructor',
  'Summer Mountaineering Instructor',
  'Top Rope Climbing Instructor',
  'Tour Operator',
  'Trekking Guide',
  'Winter Mountaineering Instructor'
];
