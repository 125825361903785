import fetcher from './fetcher';

const useGetCurrenciesAvailable = async () => {
  return fetcher({
    url: `/api/v1/currencies/available`,
    method: 'GET'
  });
};

export { useGetCurrenciesAvailable };
