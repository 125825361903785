import { Formik, Form } from 'formik';
import { Box, Typography, Stepper, Step, StepIndicator, IconButton, Button } from '@mui/joy';
import { Select } from '@esui/lib.formik.select';
import { useState } from 'react';

import Simple from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import { useStats } from '../../lib/stats';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Datepicker } from '@esui/lib.formik.datepicker';

import { CalendarMonth, Height } from '@mui/icons-material';
import dayjs from 'dayjs';
import Tree from 'react-d3-tree';

import { useNavigate } from 'react-router-dom';
import './styles.css';

const stepOrder = [
  {
    step: 'B:UNCATEGORIZED',
    color: 'warning',
    hex: '9A5B13'
  },
  {
    step: 'B:REQUESTED',
    color: 'primary',
    hex: '0B6BCB'
  },
  {
    step: 'B:CONFIRMED',
    color: 'warning',
    hex: '9A5B13'
  },
  {
    step: 'B:PAID',
    color: 'success',
    hex: '1F7A1F'
  },
  {
    step: 'B:COMPLETED',
    color: 'success',
    hex: '1F7A1F'
  },

  {
    step: 'B:CANCELLED',
    color: 'danger',
    hex: 'C41C1C'
  },
  {
    step: 'B:ENQUIRED',
    color: 'warning',
    hex: '9A5B13'
  }
];

const loopTree = (tree, deep = 0) => {
  const objectForTree = {};
  for (let key in tree) {
    if (typeof tree[key][1] === 'object') {
      const { count, bookings, bookingsPassState, ...rest } = tree[key][1];

      if (objectForTree.children === undefined) {
        objectForTree.children = [];
      }
      if (Object.keys(rest).length !== 0) {
        for (let keyChild in Object.entries(rest)) {
          objectForTree.children.push(loopTree([Object.entries(rest)[keyChild]], deep + 1));
        }
      }
      objectForTree.count = count;
      objectForTree.bookings = bookings;
      objectForTree.bookingsPassState = bookingsPassState;
      objectForTree.name = `${tree[key][0]}`;
    }
  }

  return objectForTree;
};

const Funnel = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [displayInfo, setDisplayInfo] = useState('');

  const [filter, setFilter] = useState({
    endDate: Date('2023-12-02'),
    startDate: Date('2024-01-01')
  });
  const { data: stats, isLoading } = useQuery(
    ['stats', filter],
    () => {
      return useStats(filter);
    },
    {
      //staleTime: Infinity
    }
  );

  let tree = {};
  let finalTree = {};

  if (!isLoading) {
    // loop booking
    stats.data[0].bookingsAndActivities.map((booking) => {
      const bookingTree = [];
      const pathNode = [];
      // contruct info by activity
      booking.activities.map((activity) => {
        if (activity.guide_state || activity.state) {
          // if (
          //   activity.guide_state !== activity.prev_guide_state ||
          //   activity.state !== activity.prev_state
          // ) {
          if (activity.guide_state_changed || activity.state_changed) {
            const nodeId = `${activity.state}-${activity.guide_state}`;
            bookingTree.push({
              _id: booking._id,
              guide: activity.guide_state,
              booking: activity.state,
              nodeId,
              pathNode: [...pathNode]
            });
            pathNode.push(nodeId);
          }
        }
      });

      // construct tree by info activity
      bookingTree.map((node, key) => {
        let nodeTree = tree;

        if (!node.pathNode.length) {
          nodeTree = { count: 0, bookings: [] };
        }
        node.pathNode.push(node.nodeId);
        node.pathNode.map((path) => {
          if (nodeTree[path] === undefined) {
            nodeTree[path] = { count: 0, bookings: [], bookingsPassState: [] };
          }

          // determine which booking pass for this node
          if (!nodeTree[path].bookingsPassState.includes(node._id)) {
            nodeTree[path].bookingsPassState.push(node._id);
          }

          nodeTree = nodeTree[path];
        });

        // determine where a booking parking
        if (bookingTree.length - 1 === key) {
          nodeTree.bookings.push(node._id);
        }
      });
    });

    finalTree = loopTree(
      Object.entries({
        Bookings: {
          ...tree,
          bookings: [],
          bookingsPassState: stats.data[0].bookingsAndActivities
        }
      })
    );
  }
  return (
    <Simple title="Funnel" fullWith>
      <Formik
        initialValues={{
          startDate: Date(),
          endDate: Date()
        }}
        validateOnChange={false}
        onSubmit={({ endDate, startDate }) => {
          // queryClient.invalidateQueries('stats');

          setFilter({ endDate: dayjs(endDate), startDate: dayjs(startDate) });
        }}>
        {({ values }) => {
          return (
            <Form>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Datepicker
                  name="startDate"
                  label="Start date"
                  locale="es-ES"
                  autoComplete="off"
                  endDecorator={
                    <IconButton>
                      <CalendarMonth />
                    </IconButton>
                  }
                  sx={{
                    '--joy-palette-neutral-plainDisabledColor': '#414141',
                    '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                  }}
                  calendarProps={{
                    locale: 'en-GB',
                    maxDate: new Date(values.endDate)
                  }}
                />
                <Datepicker
                  name="endDate"
                  label="End date"
                  locale="es-ES"
                  autoComplete="off"
                  endDecorator={
                    <IconButton>
                      <CalendarMonth />
                    </IconButton>
                  }
                  sx={{
                    '--joy-palette-neutral-plainDisabledColor': '#414141',
                    '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                  }}
                  calendarProps={{
                    locale: 'en-GB',
                    minDate: new Date(values.startDate)
                  }}
                />

                <ButtonLoading size="sm" type="submit" isLoading={isLoading}>
                  Filter
                </ButtonLoading>
              </Box>
            </Form>
          );
        }}
      </Formik>
      {!isLoading ? (
        <>
          <Box sx={{ height: '600px', display: 'flex', marginTop: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Tree
                translate={{ x: 200, y: 10 }}
                data={finalTree}
                zoom={0.6}
                orientation="vertical"
                // onNodeClick={(node) => {
                //   setDisplayInfo(node);
                // }}
                renderCustomNodeElement={({ nodeDatum, toggleNode }) => {
                  const name = nodeDatum.name.split('-');
                  return (
                    <g>
                      {nodeDatum.children.length ? (
                        <rect
                          width="20"
                          height="20"
                          x="-10"
                          onClick={() => {
                            if (nodeDatum.bookings?.length) {
                              setDisplayInfo(nodeDatum);
                            } else {
                              setDisplayInfo('');
                            }
                          }}
                        />
                      ) : (
                        <circle
                          cx="0"
                          cy="0"
                          r="10"
                          onClick={() => {
                            if (nodeDatum.bookings?.length) {
                              setDisplayInfo(nodeDatum);
                            } else {
                              setDisplayInfo('');
                            }
                          }}
                        />
                      )}
                      {nodeDatum.children.length && (
                        <g transform="translate(-40,0)">
                          <path
                            d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z"
                            fill-rule="nonzero"
                            onClick={toggleNode}
                          />
                        </g>
                      )}
                      <text x="15" dy="10" className="rd3t-label__title">
                        {name[0]}
                      </text>
                      <text x="15" dy="25" className="rd3t-label__title">
                        {name[1]}
                      </text>
                      <text x="15" dy="40" className="rd3t-label__bookings">
                        {`Passed: ${nodeDatum.bookingsPassState?.length}`}
                      </text>
                      <text x="15" dy="53" className="rd3t-label__bookings">
                        {`Parked: ${nodeDatum.bookings?.length}`}
                      </text>
                    </g>
                  );
                }}
              />
            </Box>
            {displayInfo?.bookings?.length ? (
              <Box sx={{ width: '30%', overflowY: 'auto', p: 3, borderLeft: '1px solid #eee' }}>
                <Button size="sm" variant="plain" onClick={() => setDisplayInfo('')}>
                  Close
                </Button>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography level="title-md" fontWeight={600}>
                    {displayInfo.name}
                  </Typography>
                </Box>
                <Button
                  size="sm"
                  onClick={() => {
                    navigate('/funnel/bookings', {
                      state: { bookings: displayInfo?.bookings }
                    });
                  }}>
                  View Bookigs activities
                </Button>
                <Typography level="title-md">Bookings id</Typography>
                {displayInfo?.bookings?.map((booking) => (
                  <Typography level="body-xs">{booking}</Typography>
                ))}
              </Box>
            ) : (
              ''
            )}
          </Box>
        </>
      ) : (
        <>Loading</>
      )}
    </Simple>
  );
};

export default Funnel;
