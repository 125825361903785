import * as React from 'react';
import { Box, Typography, Card } from '@mui/joy';

import ControlButtons from '../../components/PendingApproval/ControlButtons';

const FAQs = ({ data, tripId, field, handleConfirm, updatingState }) => {
  return (
    <Card>
      <Typography level="h5">FAQs</Typography>
      <Box>
        {data.map(({ question, answer, _id }, index) => (
          <Box key={_id} sx={{ margin: '20px 10px' }}>
            <Typography level="body-sm">FAQ {index + 1}</Typography>
            <Typography level="body-sm" sx={{ marginBottom: '5px' }}>
              <strong>Question</strong>: {question}
            </Typography>
            <Typography level="body-sm">
              <strong>Answer:</strong> {answer}
            </Typography>
          </Box>
        ))}
      </Box>
      <ControlButtons
        to={`/trip/${tripId}/FAQ/all`}
        loading={updatingState.loading}
        handleConfirm={() => handleConfirm({ tripId, field })}
      />
    </Card>
  );
};

export default FAQs;
