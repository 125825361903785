import { Input } from '@esui/lib.formik.input';
import { Select } from '@esui/lib.formik.select';
import { Textarea } from '@esui/lib.formik.textarea';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import { useState } from 'react';

export default function Seo() {
  const [showSection, setShowSection] = useState(false);

  return (
    <Box
      sx={{
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '20px'
        }}
        onClick={() => setShowSection((prev) => !prev)}>
        <Typography level="h4">SEO</Typography>
        {showSection ? (
          <ExpandLess sx={{ color: 'black' }} />
        ) : (
          <ExpandMore sx={{ color: 'black' }} />
        )}
      </Box>
      {showSection ? (
        <Box
          sx={{
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}>
          <Input name="seo.slug" label="Slug" />
          <Input name="seo.link" label="Link" />
          <Input name="seo.title" label="Meta-Title" />
          <Textarea name="seo.description" label="Meta-Description" />
          <Select
            name="seo.robot"
            label="Robot"
            // eslint-disable-next-line no-unused-vars
            items={[
              { label: 'index,follow', value: 'index,follow' },
              { label: 'noindex,nofollow', value: 'noindex,nofollow' },
              { label: 'noindex,follow', value: 'noindex,follow' },
              { label: 'index,nofollow', value: 'index,nofollow' }
            ]}
          />
          <Input name="seo.title" label="Meta-Title" />
        </Box>
      ) : null}
    </Box>
  );
}
