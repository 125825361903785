import { useEffect, useState } from 'react';
import { Box, FormControl, Input, Typography, Table, Checkbox, Radio } from '@mui/joy';

import Simple from '../../components/Layouts/MainLayout';
import { getSiteMap, getSiteMapChild } from '../../lib/site-map';
import X2JS from 'x2js';

async function useGetAllUrls() {
  console.log('GETT');
  let allURLs = [];
  const xmlSiteMap = await getSiteMap();

  const x2js = new X2JS();
  const document = x2js.xml2js(xmlSiteMap);

  for await (const { loc } of document.sitemapindex.sitemap) {
    const xmlChild = await getSiteMapChild(loc);

    const child = x2js.xml2js(xmlChild);

    child.urlset?.url?.forEach((url) => {
      if (url.link) {
        allURLs = [...allURLs, ...url?.link?.map((link) => link._href)];
      }
    });
  }

  return allURLs;
}

function calculteInfo(arrUrls) {
  const duplicated = arrUrls.filter(
    (currentValue, currentIndex) => arrUrls.indexOf(currentValue) !== currentIndex
  );
  console.log({ duplicated });
  return {
    duplicated
  };
}

function RenderUrls({ urls }) {
  console.log('rendering', urls.length);
  return (
    <ul>
      {urls.length ? (
        urls
          .filter((url) => url !== '')
          .map((url, key) => (
            <li key={key}>
              <Typography level="body-xs">{url}</Typography>
            </li>
          ))
      ) : (
        <>no hay resultados</>
      )}
    </ul>
  );
}

const SiteMapDebuger = () => {
  const [allUrls, setAllUrls] = useState([]);
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [infoUrls, setInfoUrls] = useState({ duplicated: 0 });
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [toRender, setToRender] = useState([]);
  const [selectedRender, setSelectedRender] = useState(null);

  const [search, setSearch] = useState('');

  const handleRender = (event) => {
    const value = event.target.value;
    setSelectedRender(event.target.value);
    if (value === 'full') {
      setToRender(allUrls);
    } else if (value === 'filtered') {
      setToRender(filteredUrls);
    } else if (value === 'duplicated') {
      setToRender(infoUrls.duplicated);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await useGetAllUrls();
      setAllUrls(results);
      setFilteredUrls(results);
      setInfoUrls(calculteInfo(results));
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const filtered = allUrls.filter((url) => {
        if (search !== '') {
          return url?.includes(search);
        }
        return true;
      });
      setFilteredUrls(filtered);
      setInfoUrls(calculteInfo(filtered));
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search]);

  return (
    <Simple title="Sitemap Debuger">
      <FormControl>
        <Input onChange={(event) => setSearch(event.target.value)} placeholder="Search" />
      </FormControl>
      <Checkbox label="Show results" onChange={(event) => setShowResults(event.target.checked)} />
      <Table size="sm" stripe="odd">
        <tr>
          <td>
            <Radio
              checked={selectedRender === 'full'}
              onChange={handleRender}
              value="full"
              name="radio-buttons"
            />
          </td>
          <th scope="row">Cantidad total de urls</th>
          <td> {allUrls.length}</td>
        </tr>
        <tr>
          <td>
            <Radio
              checked={selectedRender === 'filtered'}
              onChange={handleRender}
              value="filtered"
              name="radio-buttons"
            />
          </td>
          <th scope="row">Cantidad filtrada</th>
          <td> {filteredUrls.length}</td>
        </tr>
        <tr>
          <td>
            <Radio
              checked={selectedRender === 'duplicated'}
              onChange={handleRender}
              value="duplicated"
              name="radio-buttons"
            />
          </td>
          <th scope="row">duplicados</th>
          <td> {infoUrls.duplicated.length}</td>
        </tr>
      </Table>
      <Box>
        {loading && <>loading</>}

        {showResults && <RenderUrls urls={toRender} />}
      </Box>
    </Simple>
  );
};

export default SiteMapDebuger;
