import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/joy';
import { Formik, Form } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';

import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import { useGetLandingPage, useUpdateLandingPage } from '../../lib/landing-pages';
import ButtonLoading from '../../components/ButtonLoading';
import HeaderInformation from '../../components/LandingPage/HeaderInformation';
import Seo from '../../components/LandingPage/Seo';
import Score from '../../components/LandingPage/Score';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import TopTrips from '../../components/LandingPage/TopTrips';
import Faq from '../../components/LandingPage/Faq';
import Translator from '../../components/LandingPage/Translator';
import { useLocale } from '../../hooks/useLocale';
import Selection from '../../components/LandingPage/Selection';

export default function LandingPageInfo() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [locale] = useLocale();

  const { data, isLoading } = useQuery([`landingPage-${id}`], () => useGetLandingPage(id));
  const mutation = useMutation((values) => useUpdateLandingPage(id, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(`landingPage-${id}`);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.history.pushState({}, '', `/landing-pages/${id}?locale=${locale}`);
  }, [locale]);

  if (isLoading) {
    return (
      <MainLayout title="Loading landing page...">
        <Loading />
      </MainLayout>
    );
  }

  const landingPage = data.data[0];

  const locales = Object.keys(landingPage.i18n);

  const landingPageData = landingPage.i18n[locale] || {};

  const { title, header_text, subtitle, seo, more_info, good_to_know, reasons, faq } =
    landingPageData;

  const handleOnSubmit = async (values) => {
    const { score, _id, ...rest } = values;
    await toast.promise(
      mutation.mutateAsync({
        i18n: {
          ...landingPage.i18n,
          [locale]: rest
        },
        score,
        top_trips: values.top_trips.map((v) => v._id),
        header: values.header,
        explore_share_selection: values.explore_share_selection
      }),
      {
        loading: 'Saving...',
        success: 'Saved successfully!',
        error: 'Error while saving.'
      }
    );
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          title,
          header_text,
          subtitle,
          seo,
          more_info,
          good_to_know,
          reasons,
          faq,
          score: landingPage.score,
          top_trips: landingPage.top_trips,
          header: landingPage.header,
          explore_share_selection: landingPage.explore_share_selection
        }}
        onSubmit={handleOnSubmit}>
        {({ values, setFieldValue }) => (
          <Box>
            <Form>
              <MainLayout
                title={landingPageData.title || 'Untitled'}
                options={
                  <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                    Save
                  </ButtonLoading>
                }>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {locales.length > 1 ? <LocaleSelector /> : null}
                    <Translator
                      landingPage={landingPage}
                      pageLocale={locale}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <HeaderInformation headerSrc={values.header} setFieldValue={setFieldValue} />
                  <Score />
                  <Seo />
                  <Selection
                    setFieldValue={setFieldValue}
                    isRecommended={values.explore_share_selection}
                  />
                  <TopTrips values={values.top_trips} />
                  <Faq faqs={values.faq} location={landingPage.country?.title} />
                </Box>
              </MainLayout>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
