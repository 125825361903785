import { Formik, Form } from 'formik';
import { Box, Typography } from '@mui/joy';
import { Select } from '@esui/lib.formik.select';
import { useState } from 'react';

import Simple from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import { useRunSync } from '../../lib/admin';

const RunSyncs = () => {
  const [msg, setMsg] = useState({ running: '', data: [] });
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        toRun: ''
      }}
      validateOnChange={false}
      onSubmit={async ({ toRun }) => {
        setLoading(true);
        try {
          const data = await useRunSync(toRun, {});
          setMsg(data);
        } catch (e) {
          setMsg({ running: 'fail', data: e });
        } finally {
          setLoading(false);
        }
      }}>
      {({ values }) => {
        return (
          <Form>
            <Simple
              title="Run sync"
              options={
                <ButtonLoading size="sm" type="submit" isLoading={loading}>
                  Run
                </ButtonLoading>
              }>
              <Box>
                <Select
                  name="toRun"
                  label="Sync to run"
                  // eslint-disable-next-line no-unused-vars
                  items={[
                    {
                      value: 'syncTripsToAlgolia',
                      label: 'syncTripsToAlgolia'
                    },
                    {
                      value: 'syncLocationsToAlgolia',
                      label: 'syncLocationsToAlgolia'
                    },
                    {
                      value: 'syncCountriesToAlgolia',
                      label: 'syncCountriesToAlgolia'
                    },
                    {
                      value: 'syncRegionsToAlgolia',
                      label: 'syncRegionsToAlgolia'
                    },
                    {
                      value: 'syncLPsTripsCounter',
                      label: 'syncLPsTripsCounter'
                    },
                    {
                      value: 'countReviewsLandingMongo',
                      label: 'countReviewsLandingMongo'
                    },
                    {
                      value: 'countReviewsTripMongo',
                      label: 'countReviewsTripMongo'
                    },
                    {
                      value: 'countReviewsGuideMongo',
                      label: 'countReviewsGuideMongo'
                    },
                    {
                      value: 'publishApprovedTrips',
                      label: 'publishApprovedTrips'
                    }
                  ]}
                />
                {msg.data && msg.running && (
                  <>
                    <Typography sx={{ marginTop: '10px' }}>Fired {msg.running}</Typography>
                    {loading ? <Typography>Loading...</Typography> : null}
                    <Typography>
                      {msg.data &&
                        typeof msg.data !== 'string' &&
                        `Data ${JSON.stringify(msg.data)}`}
                    </Typography>
                    <Typography>
                      {msg.data && typeof msg.data === 'string' && `Data ${msg.data}`}
                    </Typography>
                    <Typography>{msg.error && `Error ${msg.error}`}</Typography>
                  </>
                )}
              </Box>
            </Simple>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RunSyncs;
