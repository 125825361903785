import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Button, Drawer, Typography } from '@mui/joy';

import dayjs from 'dayjs';

import { getTripActivityLog } from '../../lib/activity_log';
import Loading from '../../components/Loading/Loading';
import { useLocale } from '../../hooks/useLocale';

const INFO_FIELDS = ['updatedAt', 'sync_status', '__v'];

export default function History() {
  const { tripId } = useParams();
  const [showHistory, setShowHistory] = useState(false);
  const [locale] = useLocale();

  const { data: history, isLoading } = useQuery(
    [`trip-${tripId}-activity-log`],
    () => getTripActivityLog({ tripId }),
    { enabled: showHistory }
  );

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button variant="plain" onClick={() => setShowHistory(true)}>
          Show history
        </Button>
      </Box>
      <Drawer
        open={showHistory}
        variant="plan"
        anchor="right"
        onClose={() => setShowHistory(false)}>
        <Box sx={{ padding: '30px' }}>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography level="body-lg" sx={{ width: '100%' }}>
              History
            </Typography>
          </Box>
          {isLoading ? (
            <Loading />
          ) : (
            <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
              {!isLoading &&
                history.data.map((log) => (
                  <Box key={log._id} sx={{ borderBottom: '1px solid #ededed' }}>
                    <Typography level="body-lg">
                      {' '}
                      {dayjs(log.createdAt).format('DD/MM/YY, hh:mm')}
                    </Typography>
                    {log.user && <Typography level="body-sm">{log.user.email}</Typography>}
                    <Box sx={{ margin: '10px 0px' }}>
                      <OldValues oldValues={log.old_values} locale={locale} />
                    </Box>
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}

function OldValues({ oldValues, locale }) {
  if (!oldValues) {
    return null;
  }

  const { i18n, ...oldValuesWithoutI18n } = oldValues;

  const oldValuesToDisplay = Object.keys({
    ...(i18n?.[locale] || {}),
    ...oldValuesWithoutI18n
  }).filter((field) => !INFO_FIELDS.includes(field));

  if (!oldValuesToDisplay.length) {
    return null;
  }

  return (
    <Box>
      <Typography sx={{ color: 'green' }}>
        Modified fields →{' '}
        <Typography level="body-md">
          {oldValuesToDisplay.map((field) => field).join(', ')}
        </Typography>
      </Typography>
    </Box>
  );
}
