import { marked } from 'marked';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Textarea,
  Typography
} from '@mui/joy';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import MainLayout from '../../components/Layouts/MainLayout';
import { useGetTripById } from '../../lib/trip';
import Loading from '../../components/Loading/Loading';
import { LOCALSTORAGE_TOKEN } from '../../constants/localStorage';
import useExtractInfo from '../../lib/ai';

function htmlToText(stringHtml) {
  const regex = /(<([^>]+)>)/gi;
  return stringHtml.replace(regex, '');
}

const MAIN_API = import.meta.env.VITE_API;

const AMOUNT_OF_CHATS_TO_LOAD = 100;

export default function AIAssistant() {
  const [configureChatModalOpen, setConfigureChatModalOpen] = useState(false);
  const [lastChatsPage, setLastChatsPage] = useState(0);
  const [lastChatsModal, setLastChatsModal] = useState(false);
  const [query, setQuery] = useState('');
  const [template, setTemplate] = useState('');
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [AIResponse, setAIResponse] = useState([]);
  const [infoToSearch, setInfoToSearch] = useState('');
  const { tripId } = useParams();
  const { data, isLoading } = useQuery(['trip', tripId], () => useGetTripById(tripId), {
    staleTime: Infinity
  });

  if (isLoading)
    return (
      <MainLayout title="AI Assistant">
        <Loading />
      </MainLayout>
    );

  const handleSetInfoToSearch = (info) => {
    setInfoToSearch((prev) => {
      if (!prev.length) return info;
      return `${prev}\n\n${info}`;
    });
  };

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const currentToken = token || localStorage.getItem(LOCALSTORAGE_TOKEN);

  async function fetchMessagesFromChats() {
    const response = await toast.promise(
      fetch(
        `${MAIN_API}/api/v3/admin/ai/${tripId}/load-chats-messages?limit=${AMOUNT_OF_CHATS_TO_LOAD}&skip=${
          lastChatsPage * AMOUNT_OF_CHATS_TO_LOAD
        }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentToken}`
          }
        }
      ),
      {
        loading: 'Loading messages from chats',
        success: 'Messages loaded successfully',
        error: 'Error loading messages from chats'
      }
    );
    const { messages, ok } = await response.json();
    if (!ok) {
      toast.error('Error loading messages from chats');
      return;
    }

    const groupedByBooking = messages.reduce((acc, message) => {
      const mappedMessage = `-${message.sender_role}: ${message.content}`;
      if (!acc[message.booking]) return { ...acc, [message.booking]: [mappedMessage] };
      return {
        ...acc,
        [message.booking]: [...acc[message.booking], mappedMessage]
      };
    }, {});

    const mappedMessages = Object.values(groupedByBooking).map((bookingMessages, index) => {
      bookingMessages.unshift(`---- START CHAT ${index} ----`);
      bookingMessages.push(`---- END CHAT ${index} ----`);
      return bookingMessages;
    });

    handleSetInfoToSearch(mappedMessages.map((v) => v.join('\n')).join('\n\n\n'));
  }

  async function sendRequestToIA() {
    const $aiResponseEl = document.getElementById('ai-response');
    const loaderId = toast.loading('Loading AI response');
    setLoadingResponse(true);
    setAIResponse(
      (prev) =>
        `${prev}YOU: ${query}\n\n--------------------------------------------------------------------------------\n\n`
    );
    if ($aiResponseEl) {
      $aiResponseEl.scrollTop = $aiResponseEl.scrollHeight;
    }
    try {
      const response = await useExtractInfo({ query, infoToSearch, template });
      if (!response.ok) {
        const { error } = await response.json();
        toast.error(error, { id: loaderId });
        setLoadingResponse(false);
        return;
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;

      while (loopRunner) {
        // eslint-disable-next-line no-await-in-loop
        const { value, done } = await reader.read();
        if (done) {
          setAIResponse(
            (prev) =>
              `${prev}\n\n--------------------------------------------------------------------------------`
          );
          if ($aiResponseEl) {
            $aiResponseEl.scrollTop = $aiResponseEl.scrollHeight;
          }
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        //
        setAIResponse((prev) => prev + decodedChunk);
        if ($aiResponseEl) {
          $aiResponseEl.scrollTop = $aiResponseEl.scrollHeight;
        }
      }
      toast.success('AI response loaded successfully', { id: loaderId });
      setLoadingResponse(false);
      setAIResponse((prev) => `${prev}\n\n`);
    } catch (error) {
      toast.error('Error loading AI response', { id: loaderId });
      setLoadingResponse(false);
    }
  }

  return (
    <>
      <Modal open={configureChatModalOpen} onClose={() => setConfigureChatModalOpen(false)}>
        <ModalDialog>
          <DialogTitle>Configure chat</DialogTitle>
          <DialogContent>
            <Button onClick={() => handleSetInfoToSearch(htmlToText(marked(data.content)))}>
              Load &quot;Description&quot;
            </Button>
            <Button onClick={() => handleSetInfoToSearch(data.excerpt)}>
              Load &quot;Excerpt&quot;
            </Button>
            <Button onClick={() => handleSetInfoToSearch(data.price_includes)}>
              Load &quot;Price includes&quot;
            </Button>
            <Button onClick={() => setLastChatsModal(true)}>Load last chats</Button>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfigureChatModalOpen(false);
              }}>
              Confirm
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setConfigureChatModalOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <MainLayout title="AI Assistant" topTitle={data?.title} back={`/trip/${tripId}/options`}>
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ marginTop: '20px', display: 'flex', gap: '5px' }}>
            <Button onClick={() => setConfigureChatModalOpen(true)}>Configure chat</Button>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <FormLabel>Information</FormLabel>
            <Textarea
              maxRows={15}
              value={infoToSearch}
              onChange={(e) => setInfoToSearch(e.target.value)}
            />
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <FormLabel>Context</FormLabel>
            <Textarea
              maxRows={15}
              placeholder="Ex: You are a helpful assistant that translates English to French"
              onChange={(e) => setTemplate(e.target.value)}
            />
          </Box>
          {AIResponse.length ? (
            <Box sx={{ marginTop: '20px' }}>
              <Typography
                id="ai-response"
                sx={{
                  marginTop: '10px',
                  whiteSpace: 'pre-line',
                  maxHeight: '900px',
                  overflow: 'auto'
                }}>
                {AIResponse}
              </Typography>
            </Box>
          ) : null}
          <Box
            sx={{
              marginTop: '20px',
              // position: 'absolute',
              // bottom: 0,
              width: '100%',
              background: 'white',
              padding: '5px'
            }}>
            <Box>
              <Textarea
                maxRows={15}
                placeholder="Ex: Give me a list of FAQ's and its answers based on this information. Separate each QA with a breakline space"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
              <Button
                sx={{ marginTop: '20px' }}
                disabled={loadingResponse || !query.length}
                onClick={() => {
                  sendRequestToIA();
                }}>
                Send request
              </Button>
            </Box>
          </Box>
        </Box>
        <Modal open={lastChatsModal} onClose={() => setLastChatsModal(false)}>
          <ModalDialog>
            <DialogTitle>Last chats amount</DialogTitle>
            <DialogContent>
              <Alert sx={{ marginTop: '20px' }} color="warning">
                Some chats may not have any message, so the amount of chats loaded may be less
              </Alert>
              <Box sx={{ marginTop: '20px' }}>
                <FormLabel>Page</FormLabel>
                <Input type="number" onChange={(e) => setLastChatsPage(e.target.value)} />
              </Box>
              <Box sx={{ marginTop: '20px' }}>
                <FormLabel>Amount</FormLabel>
                <Input disabled type="number" defaultValue={AMOUNT_OF_CHATS_TO_LOAD} />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setLastChatsModal(false);
                  fetchMessagesFromChats();
                }}>
                Load
              </Button>
              <Button variant="plain" color="neutral" onClick={() => setLastChatsModal(false)}>
                Cancel
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </MainLayout>
    </>
  );
}
