import React, { useState } from 'react';
import { Chip } from '@mui/joy';

const PassOrNot = ({ pass }) => {
  if (!pass)
    return (
      <Chip color="danger" size="sm" sx={{ marginRight: 1 }}>
        No pass
      </Chip>
    );

  return (
    <Chip color="success" size="sm" sx={{ marginRight: 1 }}>
      Pass
    </Chip>
  );
};

export default PassOrNot;
