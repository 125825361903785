import { useQuery } from '@tanstack/react-query';
import { IconButton, List, ListItem, ListItemButton, Typography } from '@mui/joy';
import { OpenInNew } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import getStaticPages from '../../lib/static_pages';

const SITE = import.meta.env.VITE_SITE;

export default function StaticPages() {
  const { data, isLoading } = useQuery(['allStaticPages'], () => getStaticPages());

  if (isLoading) {
    return (
      <MainLayout title="Static Pages">
        <Loading />
      </MainLayout>
    );
  }

  return (
    <MainLayout title="Static Pages">
      <List>
        {data.data.map((page) => (
          <ListItem
            key={page._id}
            endAction={
              page.i18n.en_EN.seo_tags.slug && (
                <IconButton
                  size="sm"
                  variant="plain"
                  component="a"
                  href={`${SITE}/blog/${page.i18n.en_EN.seo_tags.slug}`}
                  target="_blank">
                  <OpenInNew />
                </IconButton>
              )
            }>
            <ListItemButton
              component={Link}
              to={`/static-pages/${page._id}/${page.i18n.en_EN.seo_tags.slug}`}>
              <Typography>{page.i18n.en_EN.seo_tags.meta_title}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </MainLayout>
  );
}
