import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, FormLabel } from '@mui/joy';
import { Input } from '@esui/lib.formik.input';
import { Select } from '@esui/lib.formik.select';

import { useGetTripById, usePostChangePrice } from '../../lib/trip';
import Simple from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import CURRENCIES from '../../constants/currencies';
import ButtonLoading from '../../components/ButtonLoading';

const Price = () => {
  const queryClient = useQueryClient();
  const { tripId } = useParams();
  const { data, isLoading } = useQuery(['trip', tripId], () => useGetTripById(tripId), {
    staleTime: Infinity
  });

  const mutation = useMutation(
    (values) => {
      return usePostChangePrice(tripId, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['trip']);
      }
    }
  );

  if (isLoading)
    return (
      <Simple title="Prices">
        <Loading />
      </Simple>
    );

  const initialValues = {
    ...Object.keys(data.pricing || {}).reduce((acc, key) => {
      if (data.pricing[key] !== null) {
        acc[key] = data.pricing[key];
      }
      return acc;
    }, {})
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={(values) => {
        const onlyModified = Object.keys(values).reduce((acc, key) => {
          if (values[key] !== initialValues[key]) {
            acc[key] = values[key];
          }
          return acc;
        }, {});

        const { currency, hasPrice, from, ...rest } = onlyModified;
        mutation.mutate({ currency, pricing: rest });
      }}>
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Simple
              title="Prices"
              topTitle={data?.title}
              options={
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Save
                </ButtonLoading>
              }
              back={`/trip/${tripId}/options`}>
              <Select
                name="currency"
                label="Currency"
                // eslint-disable-next-line no-unused-vars
                items={Object.entries(CURRENCIES).map(([key, value]) => ({
                  value: value.value,
                  label: value.label
                }))}
              />
              {[
                ['1', ''],
                ['2', ''],
                ['3', ''],
                ['4', ''],
                ['5', ''],
                ['6', ''],
                ['7', ''],
                ['8', '']
              ].map(([key]) => {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '10px'
                    }}>
                    <FormLabel>{`Person ${key}`}</FormLabel>
                    <Input
                      simpleField
                      type="number"
                      startDecorator={values.currency}
                      name={key}
                      onKeyPress={(e) => {
                        if (e.key === '.' || e.key === ',') {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue(key, e.target.value.replace(/[.,]/g, ''));
                      }}
                    />
                  </Box>
                );
              })}
            </Simple>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Price;
