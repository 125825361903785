import { Box, Sheet } from '@mui/joy';

import Logo from '../Logo';

export default function LoginLayout({ children }) {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)'
      }}>
      <Sheet
        sx={{
          width: { xs: '75%', sm: '60%', md: '40%', lg: '30%' },
          maxWidth: '650px',
          py: 4,
          px: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          bgcolor: 'neutral.100'
        }}>
        <Box>
          <Box sx={{ width: '230px', justifySelf: 'center', margin: 'auto', marginBottom: '20px' }}>
            <Logo />
          </Box>
          <Box
            sx={{
              flex: 1,
              minWidth: { xs: '100%', sm: '100px', md: '100px' },
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              justifyContent: 'center'
            }}>
            {children}
          </Box>
        </Box>
        {/* <Stack direction="row" flexWrap="wrap">
          <Box
            sx={{
              boxSizing: 'border-box',
              maxWidth: { xs: '100%', sm: '200px', md: '200px' },
              minWidth: { xs: '100%', sm: '100px', md: '100px' },
              flex: 1
            }}
            p={3}>
            <Logo />
          </Box>
        </Stack> */}
      </Sheet>
    </Box>
  );
}
