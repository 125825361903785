import { ArrowLeft, ArrowRight, DoneAll, EditNote, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  Typography
} from '@mui/joy';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Loading from '../../components/Loading/Loading';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import MainLayout from '../../components/Layouts/MainLayout';
import debounce from '../../utils/debounce';
import { useGetBlogPosts } from '../../lib/blog-posts';
import { DEFAULT_LOCALE } from '../../constants/defaults';
import { useLocale } from '../../hooks/useLocale';

const SITE = import.meta.env.VITE_SITE;

const ICON_STATUS_MAPPER = {
  published: <DoneAll color="success" />,
  draft: <EditNote color="disabled" />
};

export default function BlogPosts() {
  const defaultPage = new URLSearchParams(window.location.search).get('page') || 1;
  const defaultSearch = new URLSearchParams(window.location.search).get('search') || '';
  const defaultStatus = new URLSearchParams(window.location.search).get('status') || '';

  const navigate = useNavigate();
  const [page, setPage] = useState(Number(defaultPage));
  const [search, setSearch] = useState(
    defaultSearch || defaultStatus
      ? `?search=${defaultSearch}&locale=${DEFAULT_LOCALE}${
        defaultStatus ? `&status=${defaultStatus}` : ''
      }`
      : ''
  );

  const [locale] = useLocale();
  const { data, isLoading, refetch, isRefetching } = useQuery(['allBlogPosts'], () =>
    useGetBlogPosts({ page: page - 1, search, locale })
  );

  useEffect(() => {
    refetch();
  }, [page, search]);

  if (!locale) return null;

  if (isLoading) {
    return (
      <MainLayout title="Blog Posts">
        <Loading />
      </MainLayout>
    );
  }

  if (!data.data)
    return (
      <MainLayout title="Blog Posts">
        <Typography>No blog posts found</Typography>
      </MainLayout>
    );

  const handleSetNewPage = (newPage) => {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', newPage);
    window.history.pushState({}, '', `/blog-posts?${searchParams.toString()}`);
    setPage(newPage);
  };

  const searchBlogPost = debounce((e) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', 1);
    setPage(1);
    const searchTerm = e.target.value;
    if (!searchTerm) {
      searchParams.delete('search');
      setSearch(`?${searchParams.toString()}`);
      window.history.pushState({}, '', `/blog-posts?${searchParams.toString()}`);
      return;
    }
    searchParams.set('search', searchTerm);
    window.history.pushState({}, '', `/blog-posts?${searchParams.toString()}`);
    setSearch(`?${searchParams.toString()}`);
  }, 500);

  return (
    <MainLayout
      title="Blog Posts"
      options={<Button onClick={() => navigate('/blog-post/new')}>Create new blog post</Button>}>
      <LocaleSelector />
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          justifyContent: 'center',
          marginBottom: '16px',
          marginTop: '16px'
        }}>
        <Input
          autoFocus
          placeholder="Hiking in Slovenia"
          defaultValue={defaultSearch}
          onChange={searchBlogPost}
        />
      </Box>
      {isRefetching ? (
        <Loading />
      ) : (
        <>
          <List>
            {data.data.map((blogPost) => {
              const blogPostData = blogPost.i18n[locale];
              return (
                <Fragment key={blogPost._id}>
                  <ListItem
                    startAction={
                      ICON_STATUS_MAPPER[blogPost.status] || <EditNote color="disabled" />
                    }
                    endAction={
                      blogPostData.seo_tags.slug && (
                        <IconButton
                          size="sm"
                          variant="plain"
                          component="a"
                          href={`${SITE}/blog/${blogPostData.seo_tags.slug}`}
                          target="_blank">
                          <OpenInNew />
                        </IconButton>
                      )
                    }>
                    <ListItemButton
                      component={Link}
                      to={`/blog-post/${blogPost._id}/options?locale=${locale}`}
                      state={{ blogPost }}>
                      <img
                        alt=""
                        src={
                          blogPost.featured_image
                            ? `${blogPost.featured_image.url}?w=180&h=180`
                            : ''
                        }
                        height="60px"
                        width="60px"
                        loading="lazy"
                        style={{ borderRadius: '4px', objectFit: 'cover', marginRight: '5px' }}
                      />
                      <ListItemContent>
                        {blogPostData.title ? (
                          <Typography level="body-lg">{blogPostData.title}</Typography>
                        ) : (
                          <Typography>Untitled</Typography>
                        )}
                        <Typography level="body-sm">
                          Author: {blogPost.author?.firstName} {blogPost.author?.lastName}
                        </Typography>
                        <Typography level="body-sm">
                          Updated at: {dayjs(blogPost.updatedAt).format('DD/MM/YYYY, HH:mm')}
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListDivider />
                </Fragment>
              );
            })}
          </List>
          {!defaultSearch && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                justifyContent: 'center',
                background: 'white',
                padding: '10px 0px'
              }}>
              <Button
                size="sm"
                variant="outlined"
                disabled={page <= 1}
                onClick={() => handleSetNewPage(page - 1)}>
                <ArrowLeft />
              </Button>
              <Typography>
                {page} / {data.metadata.totalPages}
              </Typography>
              <Button size="sm" variant="outlined" onClick={() => handleSetNewPage(page + 1)}>
                <ArrowRight />
              </Button>
            </Box>
          )}
        </>
      )}
    </MainLayout>
  );
}
