import { Box, FormLabel, Textarea as MuiTextarea } from '@mui/joy';
import toast from 'react-hot-toast';
import { Textarea } from '@esui/lib.formik.textarea';

import { usePatchTrip } from '../../lib/trip';
import TranslatedFieldLabel from '../../components/TranslatedFieldLabel/TranslatedFieldLabel';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';

import { DEFAULT_LOCALE, LOCALES_SHORT } from '../../constants/defaults';

import { useLocale } from '../../hooks/useLocale';

import TripEditLayout from './TripEditLayout';

export default function Seo() {
  const [locale] = useLocale();

  if (!locale) return null;

  const handleMutation = async (tripId, newTrip) => {
    const newSlug = newTrip.i18n[locale].link;
    const newData = {
      i18n: {
        ...newTrip.i18n,
        [locale]: {
          ...newTrip.i18n[locale],
          link: newSlug.startsWith('https://')
            ? newSlug
            : locale === DEFAULT_LOCALE
            ? `https://www.explore-share.com/trip/${newSlug}/`
            : `https://www.explore-share.com/${LOCALES_SHORT[
                locale
              ].toLowerCase()}/trip/${newSlug}/`
        }
      },
      followMetaTag: newTrip.followMetaTag,
      link:
        locale !== DEFAULT_LOCALE
          ? newTrip.link
          : newSlug.startsWith('https://')
          ? newSlug
          : `https://www.explore-share.com/trip/${newSlug}/`
    };

    await toast.promise(
      usePatchTrip({
        _id: tripId,
        ...newData
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  return (
    <TripEditLayout title="SEO" handleMutation={handleMutation}>
      {(values, setFieldValue) => (
        <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          <LocaleSelector />
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Title" />}
              name={`i18n.${locale}.seo_tags.title`}
            />
          </Box>
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Description" />}
              name={`i18n.${locale}.seo_tags.description`}
            />
          </Box>
          <Box>
            <Textarea
              disabled
              label="Follow meta tag"
              name="followMetaTag"
              value={values.trip_status === 'Active' ? 'None' : 'noindex-follow'}
            />
          </Box>
          <Box>
            <FormLabel>
              <TranslatedFieldLabel label="Slug" />
            </FormLabel>
            <MuiTextarea
              defaultValue={
                values.i18n[locale] ? values.i18n[locale].link.split('/').filter(Boolean).pop() : ''
              }
              onChange={(e) => setFieldValue(`i18n.${locale}.link`, e.target.value)}
            />
          </Box>
        </Box>
      )}
    </TripEditLayout>
  );
}
