import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import MainLayout from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import Loading from '../../components/Loading/Loading';
import { useGetBlogPostById } from '../../lib/blog-posts';

export default function BlogPostEditLayout({
  children,
  title,
  handleMutation,
  customActionButton
}) {
  const { blogPostId } = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading: isLoadingPost } = useQuery(
    ['blogPost', blogPostId],
    () => useGetBlogPostById(blogPostId),
    {
      staleTime: Infinity
    }
  );

  const mutation = useMutation(
    (values) => {
      return handleMutation(blogPostId, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['blogPost', blogPostId]);
      }
    }
  );

  if (isLoadingPost)
    return (
      <MainLayout title={title}>
        <Loading />
      </MainLayout>
    );

  const blogPost = data?.data[0];

  return (
    <Formik initialValues={blogPost} validateOnChange={false} onSubmit={mutation.mutate}>
      {({ values, setFieldValue }) => (
        <Form>
          <MainLayout
            title={title}
            topTitle={blogPost?.title}
            options={
              customActionButton || (
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Save
                </ButtonLoading>
              )
            }
            back={`/blog-post/${blogPostId}/options`}>
            {children({ ...values, author: blogPost.author }, setFieldValue)}
          </MainLayout>
        </Form>
      )}
    </Formik>
  );
}
