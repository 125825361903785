import React, { useEffect, useState } from 'react';
import { node, string } from 'prop-types';
import { Link as LinkRRD } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/joy';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Header from '../../Header/Header';
import { Menu, MenuBurger } from '../../Menu/Menu';
import Hidden from '../../Helpers/Hidden';

const MainLayout = ({ children, title, topTitle, options, back, help, fullWith = false }) => {
  // const { search } = useLocation();
  const [isIframeState, setIsIframeState] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const isIframe = window.location !== window.parent.location; // new URLSearchParams(search).get('isIframe');

    localStorage.setItem('isIframe', isIframe);
    setIsIframeState(isIframe);
  }, []);

  return (
    <Box sx={{ backgroundColor: '#f9f9f9' }}>
      {isIframeState ? null : (
        <Hidden on="desktop">
          <MenuBurger openMenu={openMenu} setOpenMenu={setOpenMenu} />
        </Hidden>
      )}
      <Header setOpenMenu={setOpenMenu} />
      <Box sx={{ display: 'flex' }}>
        {isIframeState ? null : (
          <Hidden on="mobile">
            <Box sx={{ height: '100%' }}>
              <Menu />
            </Box>
          </Hidden>
        )}
        <Box sx={{ maxWidth: '100%', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%'
            }}>
            <Box sx={{ width: '100%', maxWidth: fullWith ? '100%' : '1000px', margin: '0px auto' }}>
              <Box
                size="sm"
                sx={{
                  position: 'sticky',
                  top: '62px',
                  backgroundColor: '#f9f9f9',
                  borderBottom: title ? '1px solid #e7e7e7' : '',
                  zIndex: 'var(--zIndex-2)',
                  display: 'flex',
                  marginBottom: '10px',
                  alignItems: 'center',
                  padding: 1
                }}>
                {back && (
                  <Button size="sm" component={LinkRRD} to={back} variant="plain" color="neutral">
                    <ArrowBackIosIcon />
                  </Button>
                )}
                <Box>
                  <Typography level="body-md">{topTitle}</Typography>
                  <Typography level="h3" sx={{ margin: '8px 0px' }}>
                    {title}
                  </Typography>
                  {help && <Box sx={{ margin: '5px 0px' }}>{help}</Box>}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end'
                  }}>
                  {options}
                </Box>
              </Box>
              <Box sx={{ padding: 1, background: '#f9f9f9', overflow: 'auto' }}>{children}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

MainLayout.propTypes = {
  children: node.isRequired,
  title: string,
  topTitle: node,
  options: node,
  back: string,
  help: string
};

MainLayout.defaultProps = {
  title: null,
  topTitle: null,
  options: null,
  back: null,
  help: null
};

export default MainLayout;
