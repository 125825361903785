function jsonToCsv(items) {
  const header = Object.keys(items[0]);
  const headerString = header.join(',');
  // handle null or undefined values here
  const replacer = (key, value) => value ?? '';
  const rowItems = items.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
  );
  // join header and body, and break into separate lines
  const csv = [headerString, ...rowItems].join('\r\n');
  return csv;
}

const downloadToFile = (filename, text) => {
  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(new Blob([text], { type: 'text/csv' }));
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};

const stringToColor = (string, saturation = 40, lightness = 75) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

export { jsonToCsv, downloadToFile, stringToColor };
