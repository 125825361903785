import MainLayout from '../../components/Layouts/MainLayout';
import Searcher from '../../components/Searcher';

const Home = () => {
  return (
    <MainLayout>
      <Searcher />
    </MainLayout>
  );
};

export default Home;
