import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import fetcher from './fetcher';

const getBlocksByGuide = async (id) => {
  return fetcher({
    url: `/api/v3/availability/block/guide/${id}`,
    method: 'GET'
  });
};

export function useGetBlocksByGuide(id) {
  const { data: blocks, isFetching } = useQuery(['block', id], () => getBlocksByGuide(id), {
    staleTime: Infinity
  });
  return { blocks, isFetching };
}

const getBlocksByDates = async (start, end) => {
  let filter = '';
  if (start && end) filter = `/${start}/${end}`;

  return fetcher({
    url: `/api/v3/availability/block${filter}`,
    method: 'GET'
  });
};

export function useGetBlocksDates(start, end) {
  const { data: blocks, isFetching } = useQuery(
    ['block', start, end],
    () => getBlocksByDates(start, end),
    {
      staleTime: Infinity
    }
  );
  return { blocks, isFetching };
}
