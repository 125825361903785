import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  ListItemButton,
  List,
  ListItemContent,
  ListItemDecorator,
  Typography,
  Avatar,
  Box,
  Alert
} from '@mui/joy';
import QuizIcon from '@mui/icons-material/Quiz';
import GroupsIcon from '@mui/icons-material/Groups';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AttachMoney from '@mui/icons-material/AttachMoney';
import {
  Assistant,
  DirectionsRun,
  Language,
  LocalOffer,
  Reviews,
  Summarize,
  Feed
} from '@mui/icons-material';

import MainLayout from '../../components/Layouts/MainLayout';

import Loading from '../../components/Loading/Loading';

const OptionsAdmin = () => {
  return (
    <MainLayout title="Admin">
      <List aria-labelledby="ellipsis-list-demo" sx={{ '--List-decorator-size': '56px' }}>
        <ListItemButton component={Link} to={`/admin/sitemapdebuger`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Summarize />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Site Map Debuger</Typography>
            <Typography level="body-sm">Para debugear el sitemap</Typography>
          </ListItemContent>
        </ListItemButton>
        <ListItemButton component={Link} to={`/admin/runsync`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Summarize />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Sicronizadores</Typography>
            <Typography level="body-sm">Chuck Norris dice: NO TOCAR </Typography>
          </ListItemContent>
        </ListItemButton>
        <ListItemButton component={Link} to={`/admin/monitor`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Summarize />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Monitor de queue</Typography>
            <Typography level="body-sm">Chuck Norris dice: ESTE TAMPOCO </Typography>
          </ListItemContent>
        </ListItemButton>
      </List>
    </MainLayout>
  );
};
export default OptionsAdmin;
