import React from 'react';
import { Box, Alert, IconButton } from '@mui/joy';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import { string, object, date, number } from 'yup';
import dayjs from 'dayjs';
import { Input } from '@esui/lib.formik.input';
import { Datepicker } from '@esui/lib.formik.datepicker';

import { CalendarMonth } from '@mui/icons-material';

import {
  usePostNextDepartures,
  usePatchNextDepartures,
  useDeleteNextDepartures,
  useGetTripById
} from '../../lib/trip';
import ButtonAsker from '../../components/ButtonAsker/ButtonAsker';
import ButtonLoading from '../../components/ButtonLoading';
import MainLayout from '../../components/Layouts/MainLayout';

const EditNextDepartures = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { tripId } = useParams();
  const {
    state: { nextDepartures: nextDeparturesData }
  } = useLocation();
  const { data: dataTrip } = useQuery(['trip', tripId], () => useGetTripById(tripId), {
    staleTime: Infinity
  });

  const mutation = useMutation(
    (values) => {
      if (values._id !== 'new') {
        return usePatchNextDepartures(tripId, values);
      }
      return usePostNextDepartures(tripId, values);
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(['nextDepartures']);
        navigate(`/trip/${tripId}/openGroup`);
      }
    }
  );

  const mutationDelete = useMutation(
    () => {
      return useDeleteNextDepartures(tripId, { ...nextDeparturesData });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['nextDepartures']);
        navigate(`/trip/${tripId}/openGroup`);
      }
    }
  );

  const handlerDelete = () => {
    mutationDelete.mutate();
  };

  const handlerSubmit = (values) => {
    const endDate = dayjs.utc(`${values.endDate} 00:00:00Z`).format();
    const departureDate = dayjs.utc(`${values.departureDate} 00:00:00Z`).format();
    if (endDate === 'Invalid Date' || departureDate === 'Invalid Date') {
      return;
    }
    mutation.mutate({
      ...values,
      endDate,
      departureDate
    });
  };

  return (
    <Formik
      initialValues={{
        ...nextDeparturesData,
        endDate:
          nextDeparturesData.endDate &&
          dayjs(nextDeparturesData.endDate).utc(false).format('M/D/YYYY'),
        departureDate:
          nextDeparturesData.departureDate &&
          dayjs(nextDeparturesData.departureDate).utc(false).format('M/D/YYYY')
      }}
      validationSchema={object().shape({
        departureDate: date().required(),
        endDate: date()
          .test('departureDate', 'Start and end dates must not be less.', (value, context) => {
            const { departureDate } = context.parent;
            return departureDate && value && value.getTime() >= departureDate.getTime();
          })
          .required(),
        extra_info: object().shape({
          price: number().positive().label('Price').required(),
          currency: string()
        }),
        seats: number(),
        min_pax: number(),
        filled_seats: number(),
        description: object().shape({
          en_EN: string()
        })
      })}
      onSubmit={handlerSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <MainLayout
            title={
              nextDeparturesData._id === 'new' ? 'New Open group date' : 'Edit Open group date'
            }
            back={`/trip/${tripId}/openGroup`}
            topTitle={dataTrip?.title}
            options={
              <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                Save
              </ButtonLoading>
            }>
            <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
              <Datepicker
                name="departureDate"
                label="Departure Date"
                locale="es-ES"
                autoComplete="off"
                endDecorator={
                  <IconButton>
                    <CalendarMonth />
                  </IconButton>
                }
                sx={{
                  '--joy-palette-neutral-plainDisabledColor': '#414141',
                  '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                }}
                calendarProps={{
                  locale: 'en-GB',
                  ...(values.endDate && { maxDate: new Date(values.endDate) })
                }}
              />
              <Datepicker
                name="endDate"
                label="End date"
                locale="es-ES"
                autoComplete="off"
                endDecorator={
                  <IconButton>
                    <CalendarMonth />
                  </IconButton>
                }
                sx={{
                  '--joy-palette-neutral-plainDisabledColor': '#414141',
                  '--joy-palette-neutral-outlinedDisabledColor': '#414141'
                }}
                calendarProps={{
                  locale: 'en-GB',
                  minDate: new Date(values.departureDate)
                }}
              />
              <Input
                name="extra_info.price"
                label="Price"
                type="number"
                startDecorator={nextDeparturesData.extra_info.currency}
                onKeyPress={(e) => {
                  if (e.key === '.' || e.key === ',') {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setFieldValue('extra_info.price', e.target.value.replace(/[.,]/g, ''));
                }}
              />
              {/* <Input name="seats" label="Seats" />
            <Input name="min_pax" label="Min pax" />
            <Input name="filled_seats" label="Filled seats" />

            <Textarea name="description.en_EN" label="Description" minRows={4} /> */}

              {mutation.isLoading || mutationDelete.isLoading ? (
                <Alert size="sm" color="success">
                  Please, wait a moment.
                </Alert>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    flexDirection: { xs: 'column-reverse', sm: 'row' }
                  }}>
                  {nextDeparturesData._id !== 'new' && (
                    <ButtonAsker
                      size="sm"
                      ask="Are you sure delete?"
                      color="danger"
                      onClick={handlerDelete}>
                      Delete
                    </ButtonAsker>
                  )}
                </Box>
              )}
            </Box>
          </MainLayout>
        </Form>
      )}
    </Formik>
  );
};

export default EditNextDepartures;
