export default function Logo() {
  return (
    <svg version="1.1" viewBox="0 0 487.51 119.8" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.73621 0 0 -.73621 135.82 55.478)">
        <path d="m0 0h17.768c-0.234 3.136-1.045 5.457-2.439 6.967-1.51 1.626-3.658 2.44-6.445 2.44-2.556 0-4.646-0.814-6.271-2.44-1.628-1.628-2.499-3.948-2.613-6.967m20.903-13.588h8.71c-1.28-4.877-3.718-8.596-7.316-11.148-3.601-2.673-8.13-4.007-13.588-4.007-6.619 0-11.788 2.148-15.503 6.445-3.718 4.181-5.575 10.161-5.575 17.942 0 7.432 1.8 13.24 5.401 17.421 3.715 4.294 8.884 6.445 15.504 6.445 6.967 0 12.367-2.091 16.199-6.271 3.716-4.298 5.575-10.278 5.575-17.942v-1.916c0-0.583-0.06-0.989-0.174-1.219h-30.136c0.114-3.602 0.985-6.272 2.613-8.014 1.508-1.859 3.772-2.787 6.794-2.787 1.972 0 3.657 0.405 5.051 1.22 0.348 0.114 0.667 0.317 0.958 0.608 0.289 0.289 0.61 0.61 0.958 0.959 0.115 0.349 0.55 0.811 1.307 1.394 0.754 0.579 1.828 0.87 3.222 0.87" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 192.51 75.996)">
        <path d="m0 0h-13.936l-8.187 14.632-8.536-14.632h-14.11l15.156 23.864-14.284 22.472h13.413l8.012-13.935 8.885 14.981c1.045 2.09 2.787 3.136 5.226 3.136h10.451l-17.419-26.828z" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 219.06 59.067)">
        <path d="m0 0c0 4.528-0.871 8.013-2.613 10.451-1.742 2.439-4.24 3.658-7.491 3.658-3.135 0-5.573-1.219-7.315-3.658-1.628-2.207-2.44-5.691-2.44-10.451 0-4.416 0.871-7.726 2.613-9.929 1.743-2.44 4.239-3.659 7.491-3.659 3.019 0 5.4 1.219 7.142 3.659 1.742 2.437 2.613 5.748 2.613 9.929m-19.859-34.144c0-1.625-0.581-3.018-1.741-4.18-1.163-1.16-2.556-1.742-4.181-1.742h-5.923v43.375c0 8.127 2.379 13.674 7.142 16.636 4.76 2.962 9.929 4.442 15.504 4.442 2.901 0 5.835-0.553 8.797-1.655 2.961-1.105 5.313-2.701 7.055-4.79 3.484-4.529 5.226-10.338 5.226-17.421 0-7.315-1.742-13.238-5.226-17.768-3.484-4.414-7.957-6.619-13.413-6.619-2.905 0-5.46 0.523-7.665 1.568-2.324 1.16-4.181 2.962-5.575 5.4z" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 229.83 31.878)">
        <path d="m0 0h6.271c1.625 0 3.019-0.553 4.181-1.656 1.159-1.105 1.741-2.468 1.741-4.094v-54.174h-12.193z" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 250.74 50.348)">
        <path d="m0 0c-2.438-2.672-3.543-6.649-3.31-11.932 0.232-5.286 1.219-8.97 2.962-11.061 0.697-0.931 1.916-1.716 3.658-2.353 1.742-0.638 3.511-0.695 5.313-0.173 1.799 0.522 3.397 1.829 4.79 3.919 1.394 2.09 2.148 5.457 2.265 10.104 0 4.18-0.67 7.316-2.004 9.407-1.336 2.089-2.873 3.423-4.616 4.006-1.742 0.58-3.483 0.61-5.225 0.087s-3.021-1.192-3.833-2.004m-8.361-30.658c-2.439 2.09-4.181 4.905-5.226 8.448-1.046 3.542-1.568 7.23-1.568 11.061 0 3.136 0.435 6.272 1.306 9.407 0.871 3.136 2.235 5.749 4.094 7.84 3.018 3.483 6.908 5.545 11.672 6.183 4.76 0.637 9.058 0.262 12.89-1.132 4.412-1.742 7.665-4.762 9.755-9.058 1.973-3.833 2.961-8.304 2.961-13.414 0-3.717-0.408-6.91-1.219-9.579-0.814-2.674-1.859-4.939-3.136-6.795-2.787-3.832-6.331-6.214-10.626-7.142-3.832-1.046-7.694-1.132-11.584-0.261-3.892 0.871-6.998 2.352-9.319 4.442" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 272.41 75.996)">
        <path d="m0 0v22.472c0 4.528 0.667 8.331 2.004 11.409 1.333 3.077 3.163 5.545 5.487 7.404 2.204 1.741 4.79 3.018 7.752 3.832 2.961 0.811 6.009 1.219 9.145 1.219h1.742v-12.368h-4.007c-3.369 0-5.865-0.814-7.49-2.438-1.628-1.628-2.439-4.181-2.439-7.666v-23.864z" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 300.88 55.478)">
        <path d="m0 0h17.77c-0.235 3.136-1.047 5.457-2.441 6.967-1.51 1.626-3.658 2.44-6.444 2.44-2.557 0-4.647-0.814-6.272-2.44-1.628-1.628-2.498-3.948-2.613-6.967m20.904-13.588h8.709c-1.279-4.877-3.717-8.596-7.315-11.148-3.601-2.673-8.13-4.007-13.588-4.007-6.619 0-11.788 2.148-15.504 6.445-3.718 4.181-5.574 10.161-5.574 17.942 0 7.432 1.8 13.24 5.4 17.421 3.715 4.294 8.884 6.445 15.504 6.445 6.968 0 12.368-2.091 16.2-6.271 3.715-4.298 5.575-10.278 5.575-17.942v-1.916c0-0.583-0.06-0.989-0.174-1.219h-30.137c0.115-3.602 0.985-6.272 2.613-8.014 1.508-1.859 3.773-2.787 6.794-2.787 1.973 0 3.658 0.405 5.052 1.22 0.348 0.114 0.666 0.317 0.957 0.608l0.959 0.959c0.114 0.349 0.55 0.811 1.307 1.394 0.754 0.579 1.828 0.87 3.222 0.87" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 324.6 62.534)">
        <path d="m0 0v5.053c0 3.135 1.568 4.702 4.704 4.702h19.336v-9.755z" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 342.3 64.71)">
        <path d="m0 0h11.847c0.114-2.324 0.983-4.066 2.611-5.226 1.742-1.162 4.064-1.742 6.969-1.742 2.09 0 3.888 0.406 5.4 1.22 1.394 0.811 2.09 1.916 2.09 3.31 0 2.089-2.438 3.657-7.315 4.703-2.091 0.348-3.66 0.696-4.705 1.045-6.156 1.508-10.278 3.31-12.368 5.399-2.324 2.091-3.483 4.878-3.483 8.363 0 4.411 1.683 8.07 5.051 10.973 3.484 2.671 8.012 4.008 13.588 4.008 6.037 0 10.858-1.337 14.458-4.008 3.367-2.903 5.167-6.678 5.401-11.321h-7.492c-2.206 0-3.892 0.984-5.051 2.96-0.466 0.463-0.931 0.928-1.394 1.394-1.394 1.045-3.31 1.568-5.748 1.568-2.325 0-4.067-0.349-5.226-1.045-1.046-0.697-1.569-1.743-1.569-3.137 0-1.858 3.019-3.425 9.06-4.702 0.695-0.234 1.306-0.408 1.829-0.523 0.523-0.117 1.015-0.174 1.48-0.174 5.923-1.511 10.044-3.253 12.369-5.225 2.205-2.091 3.308-4.879 3.308-8.363 0-5.111-1.858-9.058-5.574-11.844-3.484-2.557-8.709-3.833-15.677-3.833-6.621 0-11.557 1.334-14.807 4.007-3.369 2.669-5.052 6.559-5.052 11.67z" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 395.4 51.502)">
        <path d="m0 0c-1.742 2.439-4.123 3.659-7.142 3.659-3.254 0-5.748-1.163-7.49-3.485-1.743-2.323-2.614-5.516-2.614-9.581v-23.864h-11.846v59.924h5.924c1.624 0 3.018-0.582 4.18-1.742 1.16-1.162 1.742-2.556 1.742-4.181v-13.761c2.091 3.483 5.052 5.688 8.885 6.619 3.833 0.928 7.49 0.697 10.974-0.697 3.249-1.393 5.98-3.601 8.187-6.619 2.205-3.022 3.31-7.202 3.31-12.543v-27h-11.672v24.039c0 1.742-0.147 3.337-0.435 4.79-0.292 1.451-0.958 2.933-2.003 4.442" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 427.08 58.813)">
        <path d="m0 0c-1.627-0.814-3.892-1.568-6.794-2.265l-4.006-0.871c-2.091-0.583-3.601-1.337-4.529-2.264-0.815-1.28-1.22-2.557-1.22-3.833 0-1.742 0.523-3.135 1.569-4.181 1.044-0.931 2.552-1.394 4.529-1.394 3.249 0 5.805 0.929 7.665 2.788 1.854 1.857 2.786 4.412 2.786 7.665zm2.787-21.427c-2.439-1.279-5.024-2.059-7.752-2.351-2.729-0.288-5.139-0.436-7.228-0.436-4.182 0-7.839 1.132-10.975 3.397-3.136 2.264-4.704 6.009-4.704 11.236 0 4.877 1.422 8.362 4.268 10.451 2.844 2.09 6.473 3.484 10.888 4.181 0.58 0 1.277 0.087 2.091 0.261 0.81 0.174 1.74 0.319 2.786 0.436 5.11 0.697 7.664 2.205 7.664 4.529 0 1.742-0.9 2.875-2.699 3.398-1.803 0.522-3.457 0.783-4.965 0.783-1.628 0-3.078-0.234-4.354-0.697-1.28-0.466-2.208-1.337-2.787-2.613h-11.672c0.463 3.599 2.09 6.56 4.878 8.884 3.134 2.67 7.604 4.007 13.413 4.007 6.503 0 11.38-1.105 14.632-3.31 3.367-2.324 5.052-5.574 5.052-9.755v-15.852c0-4.645-0.844-8.275-2.526-10.887-1.685-2.613-3.688-4.502-6.01-5.662" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 436.7 75.996)">
        <path d="m0 0v22.472c0 4.528 0.666 8.331 2.003 11.409 1.333 3.077 3.162 5.545 5.487 7.404 2.205 1.741 4.79 3.018 7.752 3.832 2.96 0.811 6.009 1.219 9.145 1.219h1.742v-12.368h-4.007c-3.369 0-5.865-0.814-7.49-2.438-1.628-1.628-2.44-4.181-2.44-7.666v-23.864z" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 465.17 55.478)">
        <path d="m0 0h17.768c-0.234 3.136-1.045 5.457-2.439 6.967-1.51 1.626-3.658 2.44-6.445 2.44-2.556 0-4.646-0.814-6.271-2.44-1.628-1.628-2.499-3.948-2.613-6.967m20.904-13.588h8.709c-1.279-4.877-3.717-8.596-7.315-11.148-3.602-2.673-8.13-4.007-13.588-4.007-6.619 0-11.789 2.148-15.504 6.445-3.718 4.181-5.575 10.161-5.575 17.942 0 7.432 1.8 13.24 5.401 17.421 3.715 4.294 8.884 6.445 15.503 6.445 6.968 0 12.369-2.091 16.2-6.271 3.716-4.298 5.576-10.278 5.576-17.942v-1.916c0-0.583-0.06-0.989-0.174-1.219h-30.137c0.114-3.602 0.985-6.272 2.613-8.014 1.508-1.859 3.772-2.787 6.793-2.787 1.974 0 3.659 0.405 5.053 1.22 0.347 0.114 0.666 0.317 0.957 0.608 0.289 0.289 0.609 0.61 0.958 0.959 0.114 0.349 0.551 0.811 1.308 1.394 0.754 0.579 1.828 0.87 3.222 0.87" />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 118.65 59.9)">
        <path
          d="m0 0c0-44.002-35.804-79.791-79.805-79.791-44.002 0-79.792 35.789-79.792 79.791 0 44.001 35.79 79.805 79.792 79.805 44.001 0 79.805-35.804 79.805-79.805"
          fill="#fff"
        />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 59.9 1.1551)">
        <path
          d="m0 0c-44.002 0-79.799-35.794-79.799-79.797 0-44.002 35.797-79.799 79.799-79.799 44.003 0 79.801 35.797 79.801 79.799 0 44.003-35.798 79.797-79.801 79.797m0-161.16c-44.864 0-81.363 36.502-81.363 81.366s36.499 81.363 81.363 81.363c44.866 0 81.364-36.499 81.364-81.363s-36.498-81.366-81.364-81.366"
          fill="#bbb"
        />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 59.9 5.2301)">
        <path
          d="m0 0c-40.945 0-74.26-33.315-74.26-74.264 0-40.947 33.315-74.258 74.26-74.258 40.947 0 74.261 33.311 74.261 74.258 0 40.949-33.314 74.264-74.261 74.264"
          fill="#006993"
        />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 113.33 71.496)">
        <path
          d="m0 0c-7.231-33.42-37.029-58.52-72.574-58.52-29.225 0-54.583 16.983-66.676 41.606l79.737 49.367z"
          fill="#6a9d25"
        />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 113.94 68.194)">
        <path
          d="m0 0v-0.027l-0.831-4.453-59.513 32.453-79.738-49.381c-1.13 2.287-2.137 4.643-3.022 7.067l54.841 67.099s4.876 7.164 10.773 7.668c6.21 0.517 11.44-4.985 11.44-4.985z"
          fill="#fff"
        />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 94.709 91.628)">
        <path
          d="m0 0c-2.971 1.282-11.607 1.902-19.287 1.381-2.975-0.2-5.36-0.542-7.005-0.96l-0.011 5e-3 -10.854-2.947 19.768-0.862-0.013 6e-3c2.853-0.109 5.936-0.078 8.881 0.121 7.68 0.519 11.494 1.974 8.521 3.256"
          fill="#1a1919"
        />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 88.861 78.796)">
        <path
          d="m0 0c-1.621-3.486-4.222-6.224-7.313-8.008l0.041-0.013-21.94-11.93-5.148 24.445 0.027-0.014c-0.653 3.555-0.245 7.341 1.403 10.869 4.236 9.096 15.049 13.032 24.146 8.797 9.084-4.249 13.033-15.048 8.784-24.146"
          fill="#fff"
        />
      </g>
      <g transform="matrix(.73621 0 0 -.73621 71.971 70.977)">
        <path
          d="m0 0c-1.67-3.58-0.12-7.83 3.459-9.501 3.579-1.667 7.831-0.122 9.5 3.461 1.668 3.583 0.12 7.83-3.462 9.498-3.578 1.671-7.831 0.12-9.497-3.458"
          fill="#de4321"
        />
      </g>
      <g aria-label="GUIDED ADVENTURES EVERYWHERE">
        <path d="m222.05 89.601v-1.9333h-4.0833v0.8h3.1833v1.0667c0 1.0167-0.2 1.7-0.71667 2.35-0.61666 0.75-1.5333 1.15-2.4667 1.15-0.85 0-1.65-0.31667-2.25-0.91667-0.85-0.85-0.91667-1.7833-0.91667-4.3167 0-2.5333 0.0667-3.4667 0.91667-4.3167 0.6-0.6 1.4-0.91667 2.25-0.91667 1.6 0 2.8 1.0167 3.15 2.6667h0.9c-0.35-2.0833-1.9833-3.4667-4.05-3.4667-1.1333 0-2.1833 0.43333-2.9667 1.2167-1.0833 1.0833-1.1 2.1333-1.1 4.8167 0 2.6667 0.0167 3.7333 1.1 4.8167 0.78334 0.78333 1.8333 1.2167 2.9667 1.2167 1.2 0 2.3333-0.5 3.1167-1.3667 0.71667-0.75 0.96667-1.6167 0.96667-2.8667z" />
        <path d="m233.17 89.884v-8.0167h-0.9v7.9c0 1.9667-1.25 3.2667-3.1667 3.2667-1.9167 0-3.1667-1.3-3.1667-3.2667v-7.9h-0.9v8.0167c0 2.3167 1.6833 3.95 4.0667 3.95 2.3833 0 4.0667-1.6333 4.0667-3.95z" />
        <path d="m236.66 93.734h0.9v-11.867h-0.9z" />
        <path d="m249.32 87.634c0-1.6 0.05-3.4833-1.15-4.6833-0.71667-0.71667-1.7167-1.0833-2.9667-1.0833h-3.9833v11.867h3.9833c1.25 0 2.25-0.36667 2.9667-1.0833 1.2-1.2 1.15-3.4167 1.15-5.0167zm-0.9 0c0 1.3333 0.05 3.4-0.88334 4.3833-0.68333 0.71667-1.6 0.91667-2.55 0.91667h-2.8667v-10.267h2.8667c0.95 0 1.8667 0.2 2.55 0.91667 0.93334 0.98333 0.88334 2.7167 0.88334 4.05z" />
        <path d="m252.51 93.734h7.2v-0.8h-6.3v-4.7833h5.3667v-0.8h-5.3667v-4.6833h6.3v-0.8h-7.2z" />
        <path d="m270.58 87.634c0-1.6 0.05-3.4833-1.15-4.6833-0.71666-0.71667-1.7167-1.0833-2.9667-1.0833h-3.9833v11.867h3.9833c1.25 0 2.25-0.36667 2.9667-1.0833 1.2-1.2 1.15-3.4167 1.15-5.0167zm-0.9 0c0 1.3333 0.05 3.4-0.88333 4.3833-0.68333 0.71667-1.6 0.91667-2.55 0.91667h-2.8667v-10.267h2.8667c0.95 0 1.8667 0.2 2.55 0.91667 0.93333 0.98333 0.88333 2.7167 0.88333 4.05z" />
        <path d="m285.02 93.734h0.96666l-4.3667-11.867h-0.8l-4.3667 11.867h0.96667l1.0333-2.8833h5.5333zm-3.8-10.667 2.4833 6.9833h-4.9667z" />
        <path d="m296.16 87.634c0-1.6 0.05-3.4833-1.15-4.6833-0.71666-0.71667-1.7167-1.0833-2.9667-1.0833h-3.9833v11.867h3.9833c1.25 0 2.25-0.36667 2.9667-1.0833 1.2-1.2 1.15-3.4167 1.15-5.0167zm-0.9 0c0 1.3333 0.05 3.4-0.88333 4.3833-0.68333 0.71667-1.6 0.91667-2.55 0.91667h-2.8667v-10.267h2.8667c0.95 0 1.8667 0.2 2.55 0.91667 0.93333 0.98333 0.88333 2.7167 0.88333 4.05z" />
        <path d="m302.19 93.734 3.9-11.867h-0.95l-3.3333 10.283-3.3333-10.283h-0.95l3.9 11.867z" />
        <path d="m308.08 93.734h7.2v-0.8h-6.3v-4.7833h5.3667v-0.8h-5.3667v-4.6833h6.3v-0.8h-7.2z" />
        <path d="m325.79 93.734h0.9v-11.867h-0.9v10.283l-6.85-10.283h-0.9v11.867h0.9v-10.317z" />
        <path d="m333.45 82.667h3.55v-0.8h-7.9833v0.8h3.5333v11.067h0.9z" />
        <path d="m347.3 89.884v-8.0167h-0.9v7.9c0 1.9667-1.25 3.2667-3.1667 3.2667-1.9167 0-3.1667-1.3-3.1667-3.2667v-7.9h-0.9v8.0167c0 2.3167 1.6833 3.95 4.0667 3.95 2.3833 0 4.0667-1.6333 4.0667-3.95z" />
        <path d="m357.79 93.734h1.05l-2.8667-5.4833c1.6667-0.28333 2.7333-1.4167 2.7333-3.2 0-2.0667-1.4667-3.1833-3.4-3.1833h-4.5167v11.867h0.9v-5.4667h3.2667zm0.0167-8.6667c0 1.65-1.0833 2.4-2.6167 2.4h-3.5v-4.8h3.5c1.5333 0 2.6167 0.75 2.6167 2.4z" />
        <path d="m361.73 93.734h7.2v-0.8h-6.3v-4.7833h5.3667v-0.8h-5.3667v-4.6833h6.3v-0.8h-7.2z" />
        <path d="m378.65 90.551c0-0.95-0.38333-1.7667-1.0333-2.3-0.48333-0.41667-1-0.63333-2.1167-0.78333l-1.2833-0.2c-0.66667-0.11667-1.35-0.38333-1.7667-0.73333-0.41666-0.35-0.61666-0.86667-0.61666-1.55 0-1.5167 1.0167-2.45 2.75-2.45 1.4 0 2.1833 0.43333 2.8833 1.1l0.58333-0.58333c-1.0167-0.88333-1.95-1.2833-3.4167-1.2833-2.2833 0-3.6833 1.25-3.6833 3.25 0 0.93333 0.31667 1.6667 0.93334 2.1833 0.51666 0.45 1.2167 0.73333 2.1167 0.86667l1.3833 0.21667c0.98333 0.13333 1.2667 0.28333 1.7167 0.63333 0.45 0.38333 0.65 0.95 0.65 1.6667 0 1.55-1.1667 2.45-3.05 2.45-1.4167 0-2.3-0.33333-3.3667-1.4l-0.63334 0.63333c1.0833 1.0833 2.2 1.5667 3.95 1.5667 2.4833 0 4-1.2667 4-3.2833z" />
        <path d="m385.75 93.734h7.2v-0.8h-6.3v-4.7833h5.3667v-0.8h-5.3667v-4.6833h6.3v-0.8h-7.2z" />
        <path d="m398.71 93.734 3.9-11.867h-0.95l-3.3333 10.283-3.3333-10.283h-0.95l3.9 11.867z" />
        <path d="m404.6 93.734h7.2v-0.8h-6.3v-4.7833h5.3667v-0.8h-5.3667v-4.6833h6.3v-0.8h-7.2z" />
        <path d="m421.56 93.734h1.05l-2.8667-5.4833c1.6667-0.28333 2.7333-1.4167 2.7333-3.2 0-2.0667-1.4667-3.1833-3.4-3.1833h-4.5167v11.867h0.9v-5.4667h3.2667zm0.0167-8.6667c0 1.65-1.0833 2.4-2.6167 2.4h-3.5v-4.8h3.5c1.5333 0 2.6167 0.75 2.6167 2.4z" />
        <path d="m428.23 88.817 3.5-6.95h-0.96666l-2.95 6-3.0167-6h-0.96667l3.5 6.95v4.9167h0.9z" />
        <path d="m442.63 93.734 2.9667-11.867h-0.95l-2.5 10.317-2.7667-10.317h-0.93333l-2.7667 10.317-2.5-10.317h-0.95l2.9667 11.867h0.91667l2.8-10.333 2.8 10.333z" />
        <path d="m454.85 93.734h0.9v-11.867h-0.9v5.4833h-6.1833v-5.4833h-0.9v11.867h0.9v-5.5833h6.1833z" />
        <path d="m459.42 93.734h7.2v-0.8h-6.3v-4.7833h5.3667v-0.8h-5.3667v-4.6833h6.3v-0.8h-7.2z" />
        <path d="m476.38 93.734h1.05l-2.8667-5.4833c1.6667-0.28333 2.7333-1.4167 2.7333-3.2 0-2.0667-1.4667-3.1833-3.4-3.1833h-4.5167v11.867h0.9v-5.4667h3.2667zm0.0167-8.6667c0 1.65-1.0833 2.4-2.6167 2.4h-3.5v-4.8h3.5c1.5333 0 2.6167 0.75 2.6167 2.4z" />
        <path d="m480.31 93.734h7.2v-0.8h-6.3v-4.7833h5.3667v-0.8h-5.3667v-4.6833h6.3v-0.8h-7.2z" />
      </g>
      <g display="none">
        <text
          x="488.4418"
          y="93.733932"
          fill="#000000"
          style={{
            lineHeight: 1.25,
            fontFamily: 'sans-serif',
            fontSize: '40px',
            letterSpacing: '0px',
            wordSpacing: '0px'
          }}>
          <tspan
            x="488.44183"
            y="93.733932"
            style={{
              fontFamily: 'DINPro',
              fontSize: '16.667px',
              textAlign: 'end',
              textAnchor: 'end'
            }}>
            GUIDED ADVENTURES EVERYWHERE
          </tspan>
        </text>
      </g>
    </svg>
  );
}
