import fetcher from './fetcher';

const VITE_SITE = import.meta.env.VITE_SITE;

export function getSiteMap() {
  return fetcher({
    url: `/api/v3/admin/proxy/${encodeURIComponent(`${VITE_SITE}/sitemap_index.xml`)}`,
    method: 'GET'
  });
}

export function getSiteMapChild(url) {
  console.log(encodeURIComponent(`${url}`));
  return fetcher({
    url: `/api/v3/admin/proxy/${encodeURIComponent(`${url}`)}`,
    method: 'GET'
  });
}
