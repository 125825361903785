import { Box, Button, DialogContent, DialogTitle, FormLabel, Input, Textarea } from '@mui/joy';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { updateReview } from '../../lib/reviews';
import SharedModal from '../SharedModal';

export default function EditReview({ reviewToEdit = null, setReviewToEdit }) {
  const queryClient = useQueryClient();
  const { tripId, guideId } = useParams();

  const mutation = useMutation(
    (values) => {
      return updateReview(reviewToEdit._id, values);
    },
    {
      onSuccess: () => {
        if (tripId) queryClient.invalidateQueries(['tripReviews', tripId]);
        if (guideId) queryClient.invalidateQueries(['guideReviews', guideId]);
      }
    }
  );

  const onEdit = async (e) => {
    const formData = new FormData(e.currentTarget);
    const object = Object.fromEntries(formData.entries());
    const newData = {
      data: {
        comments: object.comments || '',
        guide: Number(object.guide) || 0,
        trip: Number(object.trip) || 0,
        us: Number(object.us || 0)
      },
      ...(object.lead
        ? {
            fake_data: {
              lead: object.lead
            }
          }
        : {})
    };
    e.preventDefault();
    await toast.promise(mutation.mutateAsync(newData), {
      loading: 'Editing review...',
      success: 'Review edited successfully',
      error: 'Error editing review'
    });
    setReviewToEdit(null);
  };

  return (
    <SharedModal isOpen={Boolean(reviewToEdit)} close={() => setReviewToEdit(null)}>
      <DialogTitle>Edit review</DialogTitle>
      <DialogContent>
        {reviewToEdit ? (
          <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} onSubmit={onEdit}>
            {reviewToEdit.fake_data?.lead || !reviewToEdit.lead ? (
              <Box>
                <FormLabel>Lead name</FormLabel>
                <Input defaultValue={reviewToEdit.fake_data?.lead} name="lead" />
              </Box>
            ) : (
              <Box>
                <FormLabel>
                  Lead name (disabled because it&apos;s associated with a booking)
                </FormLabel>
                <Input disabled defaultValue={reviewToEdit.fake_data?.lead} name="lead" />
              </Box>
            )}
            <Box>
              <FormLabel>Comments</FormLabel>
              <Textarea defaultValue={reviewToEdit.data.comments} name="comments" />
            </Box>
            <Box>
              <FormLabel>Guide rating</FormLabel>
              <Input
                type="number"
                defaultValue={reviewToEdit.data.guide}
                name="guide"
                slotProps={{
                  input: {
                    min: 0,
                    max: 5
                  }
                }}
              />
            </Box>
            <Box>
              <FormLabel>Trip rating</FormLabel>
              <Input
                type="number"
                name="trip"
                slotProps={{
                  input: {
                    min: 0,
                    max: 5,
                    defaultValue: reviewToEdit.data.trip
                  }
                }}
              />
            </Box>
            <Box>
              <FormLabel>E&S rating</FormLabel>
              <Input
                type="number"
                name="us"
                slotProps={{
                  input: {
                    min: 0,
                    max: 5,
                    defaultValue: reviewToEdit.data.us
                  }
                }}
              />
            </Box>
            <Button type="submit">Edit</Button>
            <Button variant="plain" color="neutral" onClick={() => setReviewToEdit(null)}>
              Cancel
            </Button>
          </form>
        ) : null}
      </DialogContent>
    </SharedModal>
  );
}
