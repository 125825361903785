import { Box, Checkbox, FormLabel, Input } from '@mui/joy';
import { useState } from 'react';

export default function PasswordField({ setData, value, name = 'password', label = 'Password' }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Input
        required
        type={showPassword ? 'text' : 'password'}
        placeholder="**************"
        value={value}
        name={name}
        {...(setData
          ? {
            onChange: (e) => setData((prev) => ({ ...prev, [name]: e.target.value }))
          }
          : {})}
      />
      <Checkbox
        label="Show password"
        checked={showPassword}
        sx={{ marginTop: '5px' }}
        onChange={() => setShowPassword((prev) => !prev)}
      />
    </Box>
  );
}
