import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@mui/joy';

import Loading from '../../components/Loading/Loading';
import MainLayout from '../../components/Layouts/MainLayout';
import { LOCALSTORAGE_TOKEN } from '../../constants/localStorage';
import { downloadToFile } from '../../utils/general';

const MAIN_API = import.meta.env.VITE_API;
const AMOUNT_OF_CHATS_TO_LOAD = 9999;
const lastChatsPage = 0;

export default function TripDumpInfo() {
  const { tripId } = useParams();
  const [loading, setLoading] = useState(false);

  const handleDownloadChat = async () => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const currentToken = token || localStorage.getItem(LOCALSTORAGE_TOKEN);
    const data = await fetch(
      `${MAIN_API}/api/v3/admin/ai/${tripId}/load-chats-messages?limit=${AMOUNT_OF_CHATS_TO_LOAD}&skip=${
        lastChatsPage * AMOUNT_OF_CHATS_TO_LOAD
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentToken}`
        }
      }
    );

    downloadToFile(`${tripId}-chat.json`, JSON.stringify(await data.json()));
    setLoading(false);
  };

  return (
    <MainLayout title="Dump info">
      {loading ? <Loading /> : <Button onClick={handleDownloadChat}>Download Chat</Button>}
    </MainLayout>
  );
}
