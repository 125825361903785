import { Box, FormLabel, Typography, Input as JoyInput, Alert } from '@mui/joy';
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { useState } from 'react';

import toast from 'react-hot-toast';

import { Textarea } from '@esui/lib.formik.textarea';

import { Info } from '@mui/icons-material';

import Loading from '../../components/Loading/Loading';
import TripEditLayout from '../Trip/TripEditLayout';
import { postGeolocationChange } from '../../lib/trip';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import { useLocale } from '../../hooks/useLocale';

function LocationSearchInput({ handleSelectNewLocation }) {
  const [searchText, setSearchText] = useState('');

  const handleChange = (v) => {
    setSearchText(v);
  };

  const handleSelect = (v) => {
    geocodeByAddress(v)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => handleSelectNewLocation(latLng));
  };

  return (
    <PlacesAutocomplete value={searchText} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box>
          <JoyInput
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
              sx: { marginBottom: '10px' }
            })}
          />
          <Box className="autocomplete-dropdown-container">
            {loading && (
              <Typography sx={{ padding: '8px' }}>
                <Loading />
              </Typography>
            )}
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '8px' }
                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '8px' };
              return (
                <Box
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}>
                  <Typography>{suggestion.description}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}

const Marker = () => {
  return <div className="marker" />;
};

const DEFAULT_CENTER = {
  lat: 10.99835602,
  lng: 77.01502627
};

function Map({ handleSelectLocation, defaultCenter }) {
  const [mapAndMaps, setMapAndMaps] = useState(null);
  const [address, setAddress] = useState(defaultCenter ?? DEFAULT_CENTER);

  const defaultProps = {
    zoom: 11
  };

  const handleSelectNewLocation = (latLng) => {
    setAddress(latLng);
    handleSelectLocation(latLng);
    return new mapAndMaps.maps.Marker({
      position: latLng,
      map: mapAndMaps.map
    });
  };

  return (
    <div style={{ height: '400px', width: '100%', position: 'relative' }}>
      <LocationSearchInput handleSelectNewLocation={handleSelectNewLocation} />
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        style={{ height: '350px', position: 'absolute', width: '100%' }}
        bootstrapURLKeys={{ key: import.meta.env.VITE_GMAPS_API_KEY }}
        defaultCenter={defaultCenter}
        center={address}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => {
          setMapAndMaps({ map, maps });
          return new maps.Marker({
            position: address,
            map
          });
        }}>
        <Marker lat={address.lat} lng={address.lng} />
      </GoogleMapReact>
    </div>
  );
}

export default function GeoLocation() {
  const [locale] = useLocale();

  if (!locale) return null;

  const handleSelectLocation = (latLng, setFieldValue) => {
    setFieldValue(`i18n.${locale}.meeting_point.address`, `${latLng.lat}, ${latLng.lng}`);
  };

  const handleSelectGeolocation = (latLng, setFieldValue) => {
    setFieldValue('geo_location.lat', latLng.lat);
    setFieldValue('geo_location.lng', latLng.lng);
  };

  const handleMutation = async (tripId, values) => {
    const { geo_location, i18n } = values;

    let geo = geo_location;
    if (!geo?.lat && !geo?.lng) {
      const [lat, lng] = i18n[locale].meeting_point.address.split(', ');
      geo = {
        lat: Number(lat),
        lng: Number(lng)
      };
    }

    await toast.promise(
      postGeolocationChange(
        tripId,
        {
          geo_location: geo,
          meeting_point: i18n[locale].meeting_point
        },
        locale
      ),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  return (
    <TripEditLayout title="Geo location" handleMutation={handleMutation}>
      {(values, setFieldValue) => (
        <>
          <LocaleSelector />

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography variant="h2">This is the meeting point for the trip</Typography>
            <Box>
              <FormLabel>Text</FormLabel>
              <Textarea simpleField name={`i18n.${locale}.meeting_point.text`} />
            </Box>

            <Box>
              <Typography level="title-sm">
                Meeting point address (Current: {values.i18n[locale].meeting_point?.address})
              </Typography>
              <Map
                defaultCenter={
                  values.i18n[locale].meeting_point?.address
                    ? {
                        lat: Number(values.i18n[locale].meeting_point.address.split(', ')[0]),
                        lng: Number(values.i18n[locale].meeting_point.address.split(', ')[1])
                      }
                    : null
                }
                handleSelectLocation={(latLng) => handleSelectLocation(latLng, setFieldValue)}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '50px' }}>
            <Typography variant="h2">
              This geolocation will be used to show the trip in the search{' '}
              {values.geo_location?.lat && values.geo_location?.lng && (
                <Typography level="title-sm">
                  (Current: {values.geo_location.lat}, {values.geo_location.lng})
                </Typography>
              )}
            </Typography>
            {!values.geo_location?.lat && !values.geo_location?.lng && (
              <Alert color="primary">
                <Info />
                <Typography>
                  The geolocation is not set. If you save the changes, the meeting point will be
                  used as geolocation value. You can write a different geolocation if you want.
                </Typography>
              </Alert>
            )}
            <Map
              defaultCenter={
                values.geo_location?.lat && values.geo_location?.lng
                  ? {
                      lat: Number(values.geo_location.lat),
                      lng: Number(values.geo_location.lng)
                    }
                  : values.i18n[locale].meeting_point?.address
                    ? {
                      lat: Number(values.i18n[locale].meeting_point.address.split(', ')[0]),
                      lng: Number(values.i18n[locale].meeting_point.address.split(', ')[1])
                    }
                    : null
              }
              handleSelectLocation={(latLng) => handleSelectGeolocation(latLng, setFieldValue)}
            />
          </Box>
        </>
      )}
    </TripEditLayout>
  );
}
