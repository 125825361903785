import { Typography, Box } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';

import Algolia from './Algolia';

const Searcher = () => {
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Box
      sx={{
        marginTop: matches ? '10%' : '5%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
      <Typography
        level="h3"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          textAlign: 'center',
          width: '100%',
          justifyContent: 'center'
        }}>
        Search anything you want:&nbsp;
        <Typography level="body-md" sx={{ fontWeight: 'normal', width: '100%', color: 'gray' }}>
          (Find any guide or trip)
        </Typography>
      </Typography>
      <Algolia />
    </Box>
  );
};

export default Searcher;
