import fetcher from './fetcher';

export function getAllActivities() {
  return fetcher({
    url: '/api/activities',
    method: 'GET'
  });
}

export function getActivityById(id) {
  return fetcher({
    url: `/api/activities?_id=${id}`,
    method: 'GET'
  });
}

export function updateActivity(id, data) {
  return fetcher({
    url: `/api/activities/${id}`,
    method: 'PUT',
    data
  });
}
