import fetcher from '../../lib/fetcher';

const approveContent = async ({ tripId, field }) => {
  try {
    await fetcher({
      url: `/api/v1/dashboard/trips/${tripId}/approveContent?field=${field}`,
      method: 'GET'
    });
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export default approveContent;
