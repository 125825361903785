import { ExpandLess, ExpandMore, Public } from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import { useState } from 'react';

export default function ExpandableContent({ title, smallTitle, children, multiLocale }) {
  const [open, setOpen] = useState(true);
  return (
    <Box
      sx={{
        background: '#fff',
        padding: '20px',
        boxShadow: '0px 8px 8px -5px #eaeaea',
        borderRadius: '4px'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
        onClick={() => setOpen(!open)}>
        {smallTitle && <Typography level="title-md">{smallTitle}</Typography>}
        {title && !multiLocale && <Typography level="title-lg">{title}</Typography>}
        {title && multiLocale && (
          <Typography level="title-lg" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {title} <Public sx={{ fontSize: '15px' }} />
          </Typography>
        )}
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
      {open && (
        <Box sx={{ marginTop: '20px', display: 'flex', gap: '20px', flexDirection: 'column' }}>
          {children}
        </Box>
      )}
    </Box>
  );
}
