import { Input } from '@esui/lib.formik.input';
import { Box, Button, FormLabel, Textarea } from '@mui/joy';
import { FieldArray } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';

import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import Markdown from '../../components/Markdown/Markdown';
import { patchStaticPage } from '../../lib/static_pages';
import { useLocale } from '../../hooks/useLocale';

import StaticPageEditLayout from './StaticPageEditLayout';

export default function Faq() {
  const [locale] = useLocale();

  const handleMutation = async (id, newData) => {
    await toast.promise(
      patchStaticPage({
        id,
        data: newData
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  return (
    <StaticPageEditLayout title="FAQs Page" handleMutation={handleMutation}>
      {(values, setFieldValue) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <LocaleSelector />
          <Box>
            <FormLabel>Meta Title</FormLabel>
            <Input name={`i18n.${locale}.seo_tags.meta_title`} />
          </Box>
          <Box>
            <FormLabel>Meta Description</FormLabel>
            <Textarea name={`i18n.${locale}.seo_tags.meta_description`} />
          </Box>
          <Box>
            <FormLabel>Slug</FormLabel>
            <Input name={`i18n.${locale}.seo_tags.slug`} />
          </Box>
          <Box>
            <FormLabel>Page Title</FormLabel>
            <Markdown
              defaultContent={values.i18n[locale].extra.title || ''}
              handleOnChange={(markdown) => setFieldValue(`i18n.${locale}.extra.title`, markdown)}
            />
          </Box>
          <FieldArray name={`i18n.${locale}.extra.faq`}>
            {({ push, remove }) => (
              <Box>
                {values.i18n[locale].extra.faq.map((section, index) => (
                  <FaqSection
                    key={index}
                    index={index}
                    section={section}
                    setFieldValue={setFieldValue}
                    remove={remove}
                    locale={locale}
                  />
                ))}
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ width: 'fit-content' }}
                    onClick={() => {
                      push({
                        title: '',
                        content: ''
                      });
                    }}
                  >
                    Add section
                  </Button>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      )}
    </StaticPageEditLayout>
  );
}

function FaqSection({ index, section, setFieldValue, locale, remove }) {
  const [showContent, setShowContent] = useState(false);

  return (
    <Box
      key={index}
      sx={{
        margin: '30px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}
    >
      <Box>
        <FormLabel>Title</FormLabel>
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Box sx={{ width: '100%' }}>
            <Input name={`i18n.${locale}.extra.faq.${index}.title`} />
          </Box>
          <Button size="xs" variant="outlined" onClick={() => remove(index)}>
            Remove section
          </Button>
        </Box>
      </Box>
      <Box>
        <Button variant="outlined" onClick={() => setShowContent((prev) => !prev)}>
          {showContent ? 'Hide' : 'Show'} content
        </Button>
        {showContent ? (
          <>
            <FormLabel>Content</FormLabel>
            <Markdown
              defaultContent={section.content || ''}
              handleOnChange={(markdown) =>
                setFieldValue(`i18n.${locale}.extra.faq.${index}.content`, markdown)
              }
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
}
