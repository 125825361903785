import {
  Avatar,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography
} from '@mui/joy';
import { Link, useParams } from 'react-router-dom';
import { DirectionsRun, Language, Summarize } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';

import MainLayout from '../../components/Layouts/MainLayout';
import RevalidatorButton from '../../components/RevalidatorButton';
import Loading from '../../components/Loading/Loading';
import { DEFAULT_LOCALE } from '../../constants/defaults';
import { useGetBlogPostById } from '../../lib/blog-posts';
import { useLocale } from '../../hooks/useLocale';

const SITE = import.meta.env.VITE_SITE;

export default function BlogPostOptions() {
  const { blogPostId } = useParams();
  const [locale] = useLocale();

  const { data, isLoading } = useQuery(
    ['blogPost', blogPostId],
    () => useGetBlogPostById(blogPostId),
    {
      staleTime: Infinity
    }
  );

  if (isLoading)
    return (
      <MainLayout title="Options">
        <Loading />
      </MainLayout>
    );

  const blogPost = data?.data?.[0];
  const slug = blogPost?.i18n?.[locale]?.seo_tags?.slug;
  const link =
    locale === DEFAULT_LOCALE
      ? `${SITE}/blog/${slug}`
      : `${SITE}/${locale.split('_')[0]}/blog/${slug}`;

  return (
    <MainLayout
      back="/blog-posts"
      title="Options"
      topTitle={blogPost?.title}
      options={<RevalidatorButton link={link} />}>
      <List aria-labelledby="ellipsis-list-demo" sx={{ '--List-decorator-size': '56px' }}>
        <ListItemButton component={Link} to={`/blog-post/${blogPost._id}/content`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Summarize />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Content</Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/blog-post/${blogPost._id}/seo`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Language />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>SEO</Typography>
            <Typography level="body-sm">
              Edit meta title, meta description, related landing page and link
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/blog-post/${blogPost._id}/general-info`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <DirectionsRun />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>General info</Typography>
            <Typography level="body-sm">
              Edit status, author, trips, activities and countries
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </List>
    </MainLayout>
  );
}
