import { func } from 'prop-types';
import { Box, Button, IconButton } from '@mui/joy';
import styled from '@emotion/styled';
import { Menu } from '@mui/icons-material';

import Hidden from '../Helpers/Hidden';
import { LOCALSTORAGE_TOKEN } from '../../constants/localStorage';
import Logo from '../Logo';

const Container = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: var(--zIndex-2);
  padding: 10px;
`;

const Header = ({ setOpenMenu }) => {
  const logout = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN);
    window.location.href = '/login';
  };

  return (
    <Container>
      <Box sx={{ width: '150px' }}>
        <Logo />
      </Box>
      <Button variant="plain" onClick={logout}>
        Logout
      </Button>
      <Hidden on="desktop">
        <IconButton
          variant="plain"
          onClick={() => {
            setOpenMenu((prev) => !prev);
          }}>
          <Menu />
        </IconButton>
      </Hidden>
    </Container>
  );
};

Header.propTypes = {
  setOpenMenu: func.isRequired
};

export default Header;
