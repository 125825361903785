import { bool, func } from 'prop-types';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation } from 'react-router-dom';
import {
  Sheet,
  List,
  ListItemButton,
  ListItemDecorator,
  Chip,
  ListItemContent,
  Switch,
  ListDivider
} from '@mui/joy';

import {
  Home,
  AccessTime,
  PublishedWithChanges,
  MonetizationOn,
  Hiking,
  PagesOutlined,
  CardTravel,
  BookOutlined,
  Mail,
  MailOutlineSharp,
  MonitorHeart
} from '@mui/icons-material';

import { useState } from 'react';

import { useGetPendingApprovalRecords } from '../../lib/trip';

const Backdrop = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: var(--zIndex-3);
`;

const Menu = () => {
  const { pathname } = useLocation();
  const { data } = useQuery(['pendingTrips'], useGetPendingApprovalRecords);

  const [autocolapse, setAutocolapse] = useState(localStorage.getItem('autocolapse') === 'true');
  const [open, setOpen] = useState(localStorage.getItem('autocolapse') !== 'true');

  return (
    <Sheet
      sx={{
        position: 'sticky',
        top: '62px',
        width: autocolapse && !open ? '60px' : '300px',
        overflow: 'hidden',
        backgroundColor: '#262e32',
        height: 'calc(100vh - 62px)',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.13)',
        transition: 'width 0.2s'
      }}
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}>
      <List sx={{ padding: '10px', position: 'sticky', top: '62px', minWidth: '300px' }}>
        <ListItemButton
          component={Link}
          to="/"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname === '/' ? '#395066' : '#2c4054'
          }}>
          <ListItemDecorator>
            <Home sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname === '/' ? 600 : 300
            }}>
            Home
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/pendingApproval"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/pendingApproval')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <AccessTime sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/pendingApproval') ? 600 : 300
            }}>
            {' '}
            Pending approval
          </ListItemContent>
          {data?.length > 0 && <Chip size="sm">{data?.length}</Chip>}
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/recentlyPublished"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/recentlyPublished')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <PublishedWithChanges sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/recentlyPublished') ? 600 : 300
            }}>
            Recently updated
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/availability/blocks"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/availability')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <PublishedWithChanges sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/availability') ? 600 : 300
            }}>
            Recently blocked
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/priceCalculator"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/priceCalculator')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <MonetizationOn sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/priceCalculator') ? 600 : 300
            }}>
            Price calculator
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/booking"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/booking')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <Hiking sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/booking') ? 600 : 300
            }}>
            Bookings
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/landing-pages"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/landing-pages')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <PagesOutlined sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/landing-pages') ? 600 : 300
            }}>
            Landing Pages
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/trips"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/trip')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <CardTravel sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/trip') ? 600 : 300
            }}>
            Trips
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/blog-posts"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/blog')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <BookOutlined sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/blog') ? 600 : 300
            }}>
            Blog posts
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/guides"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/trip')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <Hiking sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/guide') ? 600 : 300
            }}>
            Guides
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/static-pages"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/static-pages')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <PagesOutlined sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/static-pages') ? 600 : 300
            }}>
            Static pages
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/activities"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/activities')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <Hiking sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/activities') ? 600 : 300
            }}>
            Activities
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/messaging/mails"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/mails')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <Mail sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/mails') ? 600 : 300
            }}>
            Mails templates
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/messaging/mails-reusable-components"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/mails-reusable-components')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <MailOutlineSharp sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/mails-reusable-components') ? 600 : 300
            }}>
            Mails&apos; reusable components
          </ListItemContent>
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/values"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/values')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <MailOutlineSharp sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/values') ? 600 : 300
            }}>
            General values
          </ListItemContent>
        </ListItemButton>
        {/* <ListItemButton
          component={Link}
          to="/admin/monitor"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/admin/monitor')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <MonitorHeart sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/admin/monitor') ? 600 : 300
            }}>
            Monitor
          </ListItemContent>
        </ListItemButton> */}
        {/* <ListItemButton
          component={Link}
          to="/glossaries"
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054',
            '--joy-palette-neutral-plainActiveBg': pathname.includes('/glossaries')
              ? '#395066'
              : '#2c4054'
          }}>
          <ListItemDecorator>
            <ListIcon sx={{ color: 'white' }} />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: pathname.includes('/glossaries') ? 600 : 300
            }}>
            Glossaries
          </ListItemContent>
        </ListItemButton> */}
        <ListDivider />
        <ListItemButton
          sx={{
            '--joy-palette-neutral-plainHoverBg': '#2c4054'
          }}>
          <ListItemDecorator>
            <Switch
              checked={autocolapse}
              onChange={(event) => {
                setAutocolapse(event.target.checked);

                localStorage.setItem('autocolapse', event.target.checked);
              }}
            />
          </ListItemDecorator>
          <ListItemContent
            sx={{
              color: 'white',
              fontSize: '15px',
              fontWeight: 300
            }}>
            Autocolapse Menu
          </ListItemContent>
        </ListItemButton>
      </List>
    </Sheet>
  );
};

const MenuBurger = ({ openMenu, setOpenMenu }) => {
  return (
    openMenu && (
      <Backdrop
        onClick={() => {
          setOpenMenu(false);
        }}>
        <Menu />
      </Backdrop>
    )
  );
};

MenuBurger.propTypes = {
  openMenu: bool.isRequired,
  setOpenMenu: func.isRequired
};

export { Menu, MenuBurger };
