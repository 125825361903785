import { Box, Button, DialogActions, DialogTitle, Modal, ModalDialog, Typography } from '@mui/joy';

export default function DeleteItemModal({ open, remove, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <Box
          sx={{
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}>
          <DialogTitle>
            <Typography level="h4">Are you sure you want to delete this item?</Typography>
          </DialogTitle>
          <DialogActions>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
              <Button variant="plain" color="neutral" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="solid"
                color="danger"
                onClick={() => {
                  onClose();
                  remove();
                }}>
                Delete
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
