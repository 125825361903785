import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/joy';
import toast from 'react-hot-toast';

import { deleteReview } from '../../lib/reviews';
import SharedModal from '../SharedModal';

export default function DeleteReview({ reviewToDelete, setReviewToDelete }) {
  const { tripId, guideId } = useParams();
  const queryClient = useQueryClient();

  const handleDelete = async (review) => {
    await toast.promise(deleteReview(review._id), {
      loading: 'Deleting review...',
      success: 'Review deleted successfully',
      error: 'Error deleting review'
    });
    if (tripId) queryClient.invalidateQueries(['tripReviews', tripId]);
    if (guideId) queryClient.invalidateQueries(['guideReviews', guideId]);
    setReviewToDelete(null);
  };

  return (
    <SharedModal isOpen={Boolean(reviewToDelete)} close={() => setReviewToDelete(null)}>
      <DialogTitle>Are you sure you want to delete this review?</DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: '10px' }}>
          <Typography level="title-md">
            <u>Lead:</u> {reviewToDelete?.lead?.full_name}
          </Typography>
          <Typography level="title-md">
            <u>Comments:</u> {reviewToDelete?.data?.comments}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="solid" color="danger" onClick={() => handleDelete(reviewToDelete)}>
          Delete
        </Button>
        <Button variant="plain" color="neutral" onClick={() => setReviewToDelete(null)}>
          Cancel
        </Button>
      </DialogActions>
    </SharedModal>
  );
}
