import { string, node, func } from 'prop-types';
import { useState } from 'react';
import { Alert, Button, Box } from '@mui/joy';

const ButtonAsker = ({ ask, onClick, children, color, ...props }) => {
  const [showAsk, setShowAsk] = useState(false);
  return showAsk ? (
    <Alert
      size="sm"
      color={color}
      endDecorator={
        <Box sx={{ display: 'flex', gap: '10px', paddingLeft: '10px' }}>
          <Button onClick={onClick} color={color}>
            Yes
          </Button>
          <Button onClick={() => setShowAsk(false)} variant="text">
            No
          </Button>
        </Box>
      }>
      {ask}
    </Alert>
  ) : (
    <Button onClick={() => setShowAsk(true)} color={color} {...props}>
      {children}
    </Button>
  );
};

ButtonAsker.propTypes = {
  ask: string.isRequired,
  onClick: func.isRequired,
  children: node.isRequired,
  color: string
};
ButtonAsker.defaultProps = {
  color: ''
};
export default ButtonAsker;
