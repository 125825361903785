import { string, node } from 'prop-types';
import { Box } from '@mui/joy';

const Hidden = ({ on, children }) => {
  if (on === 'mobile') {
    return <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{children}</Box>;
  }
  if (on === 'desktop') {
    return <Box sx={{ display: { xs: 'block', sm: 'none' } }}>{children}</Box>;
  }
  return null;
};

Hidden.propTypes = {
  on: string.isRequired,
  children: node.isRequired
};

export default Hidden;
