import { Typography } from '@mui/joy';

import GeneralInfo from './BookingInfo/GeneralInfo';

export default function BookingInfo({ booking }) {
  return (
    <>
      <Typography level="h4">Current data:</Typography>
      <GeneralInfo booking={booking} />
    </>
  );
}
