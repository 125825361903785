import { Button, Typography, Box } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

import MainLayout from '../../components/Layouts/MainLayout';

const Page401 = () => {
  const navigate = useNavigate();
  return (
    <MainLayout title="401">
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography level="h3">Oops, your session is expired.</Typography>
        <Typography level="h4">Please login again.</Typography>
        <Button onClick={() => navigate(-1)}>Go back</Button>
      </Box>
    </MainLayout>
  );
};

export default Page401;
