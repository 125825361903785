import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Typography, Box, Input } from '@mui/joy';
import algoliasearch from 'algoliasearch/lite';
import { useState } from 'react';
import {
  connectSearchBox,
  connectHits,
  InstantSearch,
  connectHighlight,
  Configure,
  Index
} from 'react-instantsearch-dom';

const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_API_KEY
);

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    hit,
    attribute
  });

  return parsedHit.map((part, i) => {
    return part.isHighlighted ? <mark key={i}>{part.value}</mark> : part.value;
  });
});

const HoverableDiv = styled.div`
  :hover {
    background-color: #f1f1f1;
  }
`;

const parseImage = (hit) => {
  const image = hit.images?.medium?.url || hit.avatar;
  const withNoParams = image.replace(/(&|\?)\w.*/g, '');
  return `${withNoParams}?auto=enhance&crop=entropy&fit=crop&q=60&w=30&h=30`;
};

const Hits = connectHits(({ hits, title }) => {
  if (!hits.length) return null;

  const getLink = (id) => {
    if (title === 'Guides') return `/guide/${id}`;
    if (title === 'Trips') return `/trip/${id}/options`;
    return null;
  };

  return (
    <div style={{ marginBottom: '15px' }}>
      <div>
        <Typography level="title-sm" sx={{ padding: '10px' }}>
          {title}
        </Typography>
        <hr style={{ margin: 0, borderTop: 'none', borderBottom: '1px solid rgb(229 229 229)' }} />
      </div>
      {hits.map((hit) => {
        const attribute = hit.post_title ? 'post_title' : 'displayName';
        return (
          <Link
            key={hit.objectID}
            to={getLink(hit.post_id || hit.id || hit.wordpressId || hit.datoId)}
            style={{
              textDecoration: 'none',
              color: '#414141',
              display: 'flex',
              alignItems: 'center'
            }}>
            <HoverableDiv
              style={{
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
                width: '100%'
              }}>
              <img
                alt=""
                src={`${parseImage(hit)}`}
                width="30px"
                height="30px"
                style={{ objectFit: 'cover', borderRadius: '3px' }}
              />
              <div style={{ display: 'flex' }}>
                <Typography level="body-md">
                  <CustomHighlight attribute={attribute} hit={hit} />
                </Typography>
              </div>
            </HoverableDiv>
          </Link>
        );
      })}
    </div>
  );
});

const SearchBox = connectSearchBox(({ refine, setValue }) => (
  <Input
    sx={{ width: '100%' }}
    size="lg"
    placeholder="Jot something down"
    onChange={({ target }) => {
      refine(target.value);
      setValue(target.value);
    }}
  />
));

const Algolia = () => {
  const [value, setValue] = useState(null);
  return (
    <Box sx={{ marginTop: '50px', maxWidth: '500px', width: '100%' }}>
      <InstantSearch searchClient={searchClient} indexName="es_searchable_posts_dato">
        <Configure hitsPerPage={6} />
        <SearchBox setValue={setValue} />
        {value ? (
          <div
            style={{
              boxShadow: '1px 1px 4px rgb(202 202 202)',
              padding: '5px',
              marginTop: '20px',
              borderRadius: 'var(--joy-radius-sm)'
            }}>
            <Hits title="Trips" />
            <Index indexName="es_searchable_guides">
              <Hits title="Guides" />
            </Index>
          </div>
        ) : null}
      </InstantSearch>
    </Box>
  );
};

export default Algolia;
