import { useState } from 'react';

import { DEFAULT_LOCALE } from '../constants/defaults';

export function useLocale() {
  const urlParams = new URLSearchParams(window.location.search);
  const l = urlParams.get('locale') || DEFAULT_LOCALE;
  const [locale, setLocale] = useState(l);

  return [locale, setLocale];
}
