import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListDivider,
  IconButton,
  ListItemContent
} from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Fragment } from 'react';
import { DisabledByDefaultOutlined, DoneAll, EditNote } from '@mui/icons-material';

import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import { useGetRecentlyPublished, useGetRecentlyUpdated } from '../../lib/trip';

const SITE = import.meta.env.VITE_SITE;

const ICON_STATUS_MAPPER = {
  Active: <DoneAll color="success" />,
  Draft: <EditNote color="disabled" />,
  Inactive: <DisabledByDefaultOutlined sx={{ color: 'red' }} />
};

function Item({ record, children }) {
  return (
    <ListItem
      startAction={ICON_STATUS_MAPPER[record.trip_status]}
      endAction={
        record.link && (
          <IconButton
            size="sm"
            variant="plain"
            component="a"
            href={record.link && record.link.replace('https://www.explore-share.com', SITE)}
            target="_blank">
            <OpenInNewIcon />
          </IconButton>
        )
      }>
      <ListItemButton component={Link} to={`/trip/${record._id}/options`} state={{ record }}>
        <img
          alt=""
          src={`${record.cover_url}?w=180&h=180`}
          height="50px"
          width="50px"
          loading="lazy"
          style={{ borderRadius: '4px', objectFit: 'cover', marginRight: '5px' }}
        />
        <ListItemContent>
          <Typography>{record.i18n.en_EN.title || record.title}</Typography>
          {children}
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

const RecentlyPublished = () => {
  const { data: recentlyUpdated, isLoading } = useQuery(
    ['recentlyUpdated'],
    useGetRecentlyUpdated,
    {
      staleTime: Infinity
    }
  );
  const { data: recentlyPublished, isLoading: isLoading2 } = useQuery(
    ['recentlyPublished'],
    useGetRecentlyPublished,
    {
      staleTime: Infinity
    }
  );

  if (isLoading || isLoading2) {
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );
  }

  return (
    <MainLayout title="Recently updated records">
      <Box sx={{ margin: '10px' }}>
        <Typography level="title-sm">These are the last 50 ones:</Typography>
        <List>
          {recentlyUpdated
            .sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt)))
            .map((record) => {
              const lastPublishedAt = dayjs(record.updatedAt).format('MM/DD/YYYY, HH:mm');
              const recordStatus = `Waiting to be published (Edited at: ${lastPublishedAt})`;

              return (
                <Fragment key={record._id}>
                  <Item record={record}>
                    <Typography level="body-sm">Status: {recordStatus}</Typography>
                  </Item>
                  <ListDivider />
                </Fragment>
              );
            })}
          {recentlyPublished
            .sort((a, b) =>
              dayjs(b.sync_status.lastPublishedAt).diff(dayjs(a.sync_status.lastPublishedAt))
            )
            .map((record) => {
              const lastPublishedAt = dayjs(record.sync_status.lastPublishedAt).format(
                'MM/DD/YYYY, HH:mm'
              );
              const recordStatus = `Published at ${lastPublishedAt}`;

              return (
                <Fragment key={record._id}>
                  <Item record={record}>
                    <Typography level="body-sm">Status: {recordStatus}</Typography>
                  </Item>
                  <ListDivider />
                </Fragment>
              );
            })}
        </List>
      </Box>
    </MainLayout>
  );
};

export default RecentlyPublished;
