import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Box, Button, DialogContent, DialogTitle, FormLabel, Input, Textarea } from '@mui/joy';
import { useQueryClient } from '@tanstack/react-query';

import { createGuideReview } from '../../lib/reviews';
import SharedModal from '../../components/SharedModal';

function AddReview() {
  const { guideId } = useParams();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const object = Object.fromEntries(formData.entries());

    const newData = {
      guide: Number(object.guide) || 0,
      trip: Number(object.trip) || 0,
      us: Number(object.us) || 0,
      comments: object.comments || '',
      guideId: Number(guideId),
      ...(object.lead
        ? {
            fake_data: {
              lead: object.lead
            }
          }
        : {})
    };

    const loader = toast.loading('Saving review...');
    try {
      await createGuideReview(newData);
      await queryClient.invalidateQueries(['guideReviews', guideId]);
      toast.success('Review saved', { duration: 5000 });
    } catch (err) {
      toast.error('Error saving review', { duration: 5000 });
    } finally {
      toast.dismiss(loader);
    }

    setOpen(false);
  };

  return (
    <Box>
      <Button onClick={() => setOpen(true)}>Add new review</Button>
      <SharedModal isOpen={open} close={() => setOpen(false)}>
        <DialogTitle>Add new review</DialogTitle>
        <DialogContent sx={{ width: 350 }}>
          <form
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            onSubmit={handleSubmit}>
            <Box>
              <FormLabel>Lead name</FormLabel>
              <Input name="lead" />
            </Box>
            <Box>
              <FormLabel>Comments</FormLabel>
              <Textarea minRows={4} maxRows={10} name="comments" />
            </Box>
            <Box>
              <FormLabel>Guide rating</FormLabel>
              <Input
                name="guide"
                type="number"
                slotProps={{
                  input: {
                    min: 0,
                    max: 5
                  }
                }}
              />
            </Box>
            <Box>
              <FormLabel>Trip rating</FormLabel>
              <Input
                name="trip"
                type="number"
                slotProps={{
                  input: {
                    min: 0,
                    max: 5
                  }
                }}
              />
            </Box>
            <Box>
              <FormLabel>E&S rating</FormLabel>
              <Input
                name="us"
                type="number"
                slotProps={{
                  input: {
                    min: 0,
                    max: 5
                  }
                }}
              />
            </Box>
            <Button type="submit">Save</Button>
            <Button size="sm" variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </form>
        </DialogContent>
      </SharedModal>
    </Box>
  );
}

export default AddReview;
