import { Public } from '@mui/icons-material';
import { Typography } from '@mui/joy';

export default function TranslatedFieldLabel({ label }) {
  return (
    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '14px' }}>
      {label} <Public sx={{ fontSize: '15px' }} />
    </Typography>
  );
}
