import { Button, CircularProgress } from '@mui/joy';
import { bool, node } from 'prop-types';

const ButtonLoading = ({ children, isLoading, ...props }) => {
  return <Button {...props}>{!isLoading ? children : <CircularProgress />}</Button>;
};

ButtonLoading.propTypes = {
  children: node.isRequired,
  isLoading: bool
};

ButtonLoading.defaultProps = {
  isLoading: false
};
export default ButtonLoading;
