import { Input } from '@esui/lib.formik.input';
import { Textarea } from '@esui/lib.formik.textarea';
import { Box } from '@mui/joy';

import { ImageReplacer } from '../ImageUploader/ImageUploader';

export default function HeaderInformation({ headerSrc, setFieldValue }) {
  return (
    <>
      <ImageReplacer url={headerSrc} handleMutation={([url]) => setFieldValue('header', url)} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          background: '#fff',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
        }}>
        <Box>
          <Input name="title" label="Title" />
        </Box>
        <Box>
          {' '}
          <Input name="subtitle" label="Subtitle" />
        </Box>
        <Textarea name="header_text" label="Header Text" />
      </Box>
    </>
  );
}
