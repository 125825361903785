import { useEffect, useState } from 'react';

export default function useIsIframe() {
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    setIsIframe(JSON.parse(localStorage.getItem('isIframe')));
  }, []);

  return isIframe;
}
