import { Box, ListItem, List, ListItemButton, Typography, ListDivider, Alert } from '@mui/joy';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { useGetPendingApprovalRecords, usePatchTrip } from '../../lib/trip';
import Loading from '../../components/Loading/Loading';
import MainLayout from '../../components/Layouts/MainLayout';
import Star from '../../components/Star/Star';
import { useLocale } from '../../hooks/useLocale';

const PendingApproval = () => {
  const [locale] = useLocale();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['pendingTrips'], useGetPendingApprovalRecords, {
    staleTime: Infinity
  });

  const favoriteMutation = useMutation(
    (values) => {
      return usePatchTrip({
        _id: values._id,
        admin_preferences: {
          ...values.admin_preferences,
          favorite: !values.admin_preferences?.favorite
        }
      });
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(['pendingTrips']);
      }
    }
  );

  if (isLoading) {
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <List>
        {data.length ? (
          data.map((trip) => (
            <>
              <ListItem
                key={trip._id}
                startAction={
                  <Star
                    filled={trip.admin_preferences?.favorite}
                    onClick={() => favoriteMutation.mutate(trip)}
                  />
                }>
                <ListItemButton component={Link} to={`/pendingApproval/${trip._id}`}>
                  <img
                    alt=""
                    src={`${trip.cover_url}?w=180&h=180`}
                    height="50px"
                    width="50px"
                    loading="lazy"
                    style={{ borderRadius: '4px', objectFit: 'cover', marginRight: '5px' }}
                  />
                  <Box>
                    <Typography level="body1">{trip.i18n?.[locale].title}</Typography>
                    <Typography level="body-sm">
                      {trip.guide[0] && `${trip.guide[0].first_name} ${trip.guide[0].last_name}`}
                    </Typography>
                    <Typography level="body-sm">
                      Edited:{' '}
                      {trip.sync_status
                        .filter(({ v }) => v === 'DRAFT')
                        .map(({ k }) => {
                          if (k.includes('.')) return k.split('.').at(-1);
                          return k.split('_').join(' ');
                        })
                        .join(', ')}
                    </Typography>

                    {trip.admin_preferences?.comment && (
                      <Alert variant="solid">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `Comment: ${trip.admin_preferences?.comment?.replaceAll(
                              '\n',
                              '<br>'
                            )}`
                          }}
                          style={{ display: 'inline' }}
                        />
                      </Alert>
                    )}
                  </Box>
                </ListItemButton>
              </ListItem>
              <ListDivider />
            </>
          ))
        ) : (
          <Typography level="h4" sx={{ textAlign: 'center' }}>
            No pending approvals
          </Typography>
        )}
      </List>
    </MainLayout>
  );
};

export default PendingApproval;
