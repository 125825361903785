import fetcher from './fetcher';

const useGetTrips = async (token) => {
  return fetcher({
    url: `/api/v1/dashboard/auth/profile`,
    method: 'GET'
  });
};

const useGetGuideById = async (guideId) => {
  return fetcher({
    url: `/api/v1/guides/${guideId}`,
    method: 'GET'
  });
};

const getGuidesWithAggregation = async (aggregation) => {
  const apiUrl = import.meta.env.VITE_API;
  const url = new URL(`${apiUrl}/api/guide/aggregation`);
  url.searchParams.set('query', JSON.stringify(aggregation));

  return fetcher({
    url: url.pathname + url.search,
    method: 'GET'
  });
};

export const patchGuide = async (id, data) => {
  return fetcher({
    url: `/api/v1/dashboard/guides/${id}`,
    method: 'PATCH',
    data
  });
};

export async function createGuide(data) {
  return fetcher({
    url: `/api/v1/dashboard/guides/new`,
    method: 'POST',
    data
  });
}

const usePatchGuide = async (id, data) => {
  return fetcher({
    url: `/api/v1/dashboard/guides/update-basic-info/${id}`,
    method: 'POST',
    data
  });
};

const useGetGuides = async () => {
  return fetcher({
    url: `/api/v1/dashboard/guides/all-guides`,
    method: 'GET'
  });
};

export async function useSearchGuides({ page, search }) {
  const queryParams = search || ``;
  const urlSearchParams = new URLSearchParams(`${queryParams}`);
  const s = urlSearchParams.get('search');

  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          ...(s
            ? {
                $or: [
                  {
                    first_name: {
                      $regex: s,
                      $options: 'i'
                    }
                  },
                  {
                    display_name: {
                      $regex: s,
                      $options: 'i'
                    }
                  }
                ]
              }
            : {}),
          i18n: {
            $exists: true
          }
        }
      },
      {
        $sort: {
          updatedAt: -1
        }
      },
      { $skip: 20 * page },
      { $limit: 20 }
    ])
  );

  return fetcher({
    url: `/api/guide/aggregation?query=${urlSearchParams.get('query').toString()}`,
    method: 'GET'
  });
}

export { useGetTrips, useGetGuideById, usePatchGuide, useGetGuides, getGuidesWithAggregation };
