import React, { useState } from 'react';
import { Button, Input, FormLabel, Box } from '@mui/joy';
import { Link as LinkRRD } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useGetIdBookingByCode } from '../../lib/booking';

import MainLayout from '../../components/Layouts/MainLayout';

const BookingHealthByCode = () => {
  const [code, setCode] = useState('');

  const { data } = useQuery(['booking', code], async () => {
    if (code.length === 6) {
      let results = [];
      try {
        results = await useGetIdBookingByCode(code);
      } catch (e) {}
      return results;
    }
    return [];
  });

  return (
    <MainLayout title="Booking">
      <FormLabel>Booking Code</FormLabel>
      <Input
        name="code"
        label="Code"
        value={code}
        onChange={(e) => {
          if (e.target.value.length <= 6) setCode(e.target.value);
        }}
      />

      {data && data.booking && (
        <Box sx={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
          <LinkRRD to={`/booking/${data.booking}`}>
            <Button>View</Button>
          </LinkRRD>
          <LinkRRD to={`/booking/${data.booking}/price-recalculator`}>
            <Button>Update payment plan</Button>
          </LinkRRD>
          <LinkRRD to={`/booking/${data.booking}/update`}>
            <Button>Update details</Button>
          </LinkRRD>
        </Box>
      )}
    </MainLayout>
  );
};

export default BookingHealthByCode;
