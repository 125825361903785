import { Box } from '@mui/joy';
import { Textarea } from '@esui/lib.formik.textarea';
import toast from 'react-hot-toast';

import { patchBlogPost } from '../../lib/blog-posts';
import TranslatedFieldLabel from '../../components/TranslatedFieldLabel/TranslatedFieldLabel';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';

import { useLocale } from '../../hooks/useLocale';

import BlogPostEditLayout from './BlogPostEditLayout';

export default function Seo() {
  const [locale] = useLocale();

  if (!locale) return null;

  const handleMutation = async (postId, newPost) => {
    await toast.promise(
      patchBlogPost({
        _id: postId,
        i18n: {
          ...newPost.i18n,
          [locale]: {
            ...newPost.i18n[locale],
            seo_tags: {
              ...newPost.i18n[locale].seo_tags
            }
          }
        }
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  return (
    <BlogPostEditLayout title="SEO" handleMutation={handleMutation}>
      {() => (
        <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          <LocaleSelector />
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Title" />}
              name={`i18n.${locale}.seo_tags.meta_title`}
            />
          </Box>
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Description" />}
              name={`i18n.${locale}.seo_tags.meta_description`}
            />
          </Box>
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Slug" />}
              name={`i18n.${locale}.seo_tags.slug`}
            />
          </Box>
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Related LP link" />}
              name={`i18n.${locale}.seo_tags.related_lp_link`}
            />
          </Box>
        </Box>
      )}
    </BlogPostEditLayout>
  );
}
