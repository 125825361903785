import fetcher from './fetcher';

export function updateReview(_id, values) {
  return fetcher({
    url: `/api/v3/admin/reviews/${_id}`,
    method: 'PUT',
    data: values
  });
}

export function createReview(values) {
  return fetcher({
    url: '/api/v1/dashboard/trips/reviews/new?revalidate=true',
    method: 'POST',
    data: values
  });
}

export function createGuideReview(values) {
  return fetcher({
    url: '/api/v1/dashboard/guides/reviews/new?revalidate=true',
    method: 'POST',
    data: values
  });
}

export const deleteReview = async (reviewId) => {
  return fetcher({
    url: `/api/v3/admin/reviews/${reviewId}`,
    method: 'PUT',
    data: { _deleted: true }
  });
};

export const getTripReviews = async (tripId) => {
  const apiUrl = import.meta.env.VITE_API;
  const url = new URL(`${apiUrl}/api/reviews/aggregation?revalidate=true`);

  url.searchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          trip: Number(tripId),
          _deleted: { $ne: true }
        }
      },
      {
        $lookup: {
          from: 'leadsv2',
          localField: 'lead',
          foreignField: '_id',
          as: 'lead'
        }
      },
      {
        $unwind: {
          path: '$lead',
          preserveNullAndEmptyArrays: true
        }
      },
      {
        $sort: {
          created: -1
        }
      }
    ])
  );

  url.searchParams.set('limit', 300);

  const { data: reviews } = await fetcher({
    url: url.toString().replace(apiUrl, ''),
    method: 'GET'
  });

  return reviews;
};

export const getGuideReviews = async (guideId) => {
  const apiUrl = import.meta.env.VITE_API;
  const url = new URL(`${apiUrl}/api/reviews/aggregation?revalidate=true`);

  url.searchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          guide: Number(guideId),
          _deleted: { $ne: true }
        }
      },
      {
        $lookup: {
          from: 'leadsv2',
          localField: 'lead',
          foreignField: '_id',
          as: 'lead'
        }
      },
      {
        $unwind: {
          path: '$lead',
          preserveNullAndEmptyArrays: true
        }
      },
      {
        $sort: {
          created: -1
        }
      }
    ])
  );

  url.searchParams.set('limit', 300);

  const { data: reviews } = await fetcher({
    url: url.toString().replace(apiUrl, ''),
    method: 'GET'
  });

  return reviews;
};
