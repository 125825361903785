import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getMail } from '../../lib/mails';
import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';

export default function EditMailsReusableComponent() {
  const { id } = useParams();
  const { data, isLoading } = useQuery([`mails-reusable-component-${id}`], () => getMail(id));

  if (isLoading) {
    return (
      <MainLayout title="Mail's reusable components">
        <Loading />
      </MainLayout>
    );
  }

  const component = data?.data;

  return (
    <MainLayout title="Mail's reusable components" back="/messaging/mails-reusable-components">
      <pre>{JSON.stringify(component, null, 2)}</pre>
    </MainLayout>
  );
}
