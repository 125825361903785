import fetcher from './fetcher';

const API = import.meta.env.VITE_API;

const createAggregation = (aggregation, type) => {
  const url = new URL(`${API}/api/${type}/aggregation`);
  url.searchParams.set('query', JSON.stringify(aggregation));

  return url.pathname + url.search;
};

export async function getAllActivities() {
  return fetcher({
    url: '/api/activities',
    method: 'GET'
  });
}

export async function getAllAuthors() {
  return fetcher({
    url: '/api/authors',
    method: 'GET'
  });
}

export async function getAllCountries() {
  return fetcher({
    url: '/api/country',
    method: 'GET'
  });
}

export async function getAllLocations(aggregation) {
  return fetcher({
    url: createAggregation(aggregation, 'location'),
    method: 'GET'
  });
}

export async function getAllRegions(aggregation) {
  return fetcher({
    url: createAggregation(aggregation, 'region'),
    method: 'GET'
  });
}

export async function getAllPeriods() {
  return fetcher({
    url: '/api/period',
    method: 'GET'
  });
}

export async function getAllMountainRanges() {
  return fetcher({
    url: '/api/mountainRange',
    method: 'GET'
  });
}
