import React from 'react';
import Typography from '@mui/joy/Typography';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Box, Stack } from '@mui/joy';
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';

import { usePostLogin } from '../../lib/auth';
import { LOCALSTORAGE_TOKEN } from '../../constants/localStorage';
import LoginLayout from '../../components/Login/Layout';
import PasswordField from '../../components/Login/PasswordField';

export default function Login() {
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = e.target;
    const logged = await toast.promise(
      usePostLogin({
        email: email.value,
        password: password.value
      }),
      {
        loading: 'Logging in...',
        success: 'Logged in!',
        error: 'Error logging in'
      }
    );

    if (logged?.token) {
      localStorage.setItem(LOCALSTORAGE_TOKEN, logged?.token);
      navigate('/');
    }
  };
  return (
    <form onSubmit={handleLogin}>
      <LoginLayout>
        <Box sx={{ marginBottom: 1.4 }}>
          <Typography level="h4" component="h1">
            Welcome!
          </Typography>
        </Box>
        <Box>
          <FormLabel>Email</FormLabel>
          <Input required name="email" type="email" placeholder="johndoe@email.com" />
        </Box>
        <PasswordField />
        <Stack direction="row" sx={{ mt: 1, justifyContent: 'end' }}>
          <Link to="/forgot-password">
            <Button variant="plain">Forgot password?</Button>
          </Link>
          <Button type="submit">Log in</Button>
        </Stack>
      </LoginLayout>
    </form>
  );
}
