import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/joy';
import { Formik, Form } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';

import { Textarea } from '@esui/lib.formik.textarea';

import { Select } from '@esui/lib.formik.select';

import { Input } from '@esui/lib.formik.input';

import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import {
  useUpdateRedirect,
  useCreateRedirect,
  useDeleteRedirect,
  useGetRedirectById
} from '../../lib/redirect';
import ButtonLoading from '../../components/ButtonLoading';

export default function RedirectForm() {
  const { id } = useParams();
  const queryClient = useQueryClient();

  let isLoading = false;
  let data = {
    data: { destination: '', source: '', code: 307, description: '' }
  };

  if (id !== 'create') {
    const { data: dataRedirect, isLoading: isLoadingRedirect } = useQuery([`redirect-${id}`], () =>
      useGetRedirectById(id)
    );
    isLoading = isLoadingRedirect;
    data = dataRedirect;
  }

  const mutation =
    id !== 'create'
      ? useMutation((values) => useUpdateRedirect(id, values), {
          onSuccess: () => {
            queryClient.invalidateQueries(`redirect-${id}`);
          }
        })
      : useMutation((values) => useCreateRedirect(values), {
          onSuccess: () => {
            queryClient.invalidateQueries(`redirect-${id}`);
          }
        });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <MainLayout title="Loading redirect...">
        <Loading />
      </MainLayout>
    );
  }

  const handleDelete = async (data) => {
    await useDeleteRedirect(data._id, data);
    window.location.replace(`/redirect`);
  };

  const handleOnSubmit = async (values) => {
    await toast.promise(
      mutation.mutateAsync(
        {
          ...values
        },
        {
          onSuccess: (result) => {
            window.location.replace(`/redirect/${result.data._id}`);
          }
        }
      ),
      {
        loading: 'Saving...',
        success: 'Saved successfully!',
        error: 'Error while saving.'
      }
    );
  };

  const { destination, source, code, description } = data.data;

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          destination,
          source,
          code,
          description
        }}
        onSubmit={handleOnSubmit}>
        {({ values, setFieldValue }) => (
          <Box>
            <Form>
              <MainLayout
                back="/redirect"
                title="Redirect"
                options={
                  <Box sx={{ display: 'flex', gap: 3 }}>
                    <ButtonLoading
                      color="danger"
                      size="sm"
                      isLoading={mutation.isLoading}
                      onClick={() => handleDelete(data[0])}>
                      Delete
                    </ButtonLoading>
                    <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                      Save
                    </ButtonLoading>
                  </Box>
                }>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Input name="source" label="Soruce" />
                  <Input name="destination" label="Destination" />
                  <Select
                    name="code"
                    label="Code"
                    // eslint-disable-next-line no-unused-vars
                    items={[
                      { label: 'Temporal (307)', value: 307 },
                      { label: 'Permanent (308)', value: 308 }
                    ]}
                  />
                  <Textarea
                    name="description"
                    label="Description"
                    minRows={4}
                    sx={{ marginBottom: '5px' }}
                  />
                </Box>
              </MainLayout>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
