import useRevalidatePage from '../../hooks/useRevalidatePage';
import ButtonLoading from '../ButtonLoading';

export default function RevalidatorButton({ link }) {
  const [revalidating, handleRevalidate] = useRevalidatePage();

  return (
    <ButtonLoading isLoading={revalidating} onClick={() => handleRevalidate(link)}>
      Revalidate this page
    </ButtonLoading>
  );
}
