import { List, ListItem, ListItemContent, Typography, ListItemButton } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import MainLayout from '../../components/Layouts/MainLayout';
import { useGetValuesByGroup } from '../../lib/values';
import Loading from '../../components/Loading/Loading';

export default function ListGlossaries() {
  const { data, isLoading } = useQuery(['allGlossaries'], () =>
    useGetValuesByGroup('TRANSLATE_GLOSSARIES')
  );

  if (isLoading)
    return (
      <MainLayout title="Glossaries">
        <Loading />
      </MainLayout>
    );

  return (
    <MainLayout title="Glossaries">
      <List>
        {data.map((glossary) => (
          <ListItem key={glossary._id}>
            <ListItemButton component={Link} to={`/glossaries/${glossary._id}`}>
              <ListItemContent>
                <Typography>{glossary.key}</Typography>
                <Typography level="body-sm">{glossary.value.slice(0, 80)}...</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </MainLayout>
  );
}
