import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  DialogActions,
  IconButton,
  Typography,
  Select,
  Option,
  FormLabel,
  Box
} from '@mui/joy';
import { useEffect, useState } from 'react';
import { Translate } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';

import { useGetValueByKeyByGroup } from '../../lib/values';

import { DEFAULT_LOCALE, LOCALES, LOCALES_SHORT } from '../../constants/defaults';
import { useTranslate } from '../../hooks/useTranslate';

export default function Translator({ landingPage, setFieldValue, pageLocale }) {
  const [fromLanguage, setFromLanguage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [glossary, setGlossary] = useState('');

  const { translate } = useTranslate({ pageLocale, fromLanguage });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setFromLanguage(null);
  };

  const landingInfo = landingPage.i18n;
  const locales = Object.keys(landingInfo);

  const { refetch } = useQuery(
    [`value`],
    () =>
      useGetValueByKeyByGroup(
        'TRANSLATE_GLOSSARIES',
        `${LOCALES_SHORT[fromLanguage]}_${LOCALES_SHORT[pageLocale]}`
      ),
    {
      enabled: false
    }
  );

  const handleTranslate = async () => {
    const parsedResponse = await translate(glossary, landingInfo[fromLanguage]);
    setFieldValue('title', parsedResponse.title);
    setFieldValue('subtitle', parsedResponse.subtitle);
    setFieldValue('header_text', parsedResponse.header_text);
    setFieldValue('seo.title', parsedResponse.seo.title);
    setFieldValue('seo.description', parsedResponse.seo.description);

    const activitySlug = landingPage.activityData?.i18n[pageLocale]?.slug;
    const countrySlug = landingPage.country?.i18n[pageLocale]?.slug;
    const locationSlug =
      landingPage.locationData?.i18n[pageLocale]?.slug || landingPage.locationData?.slug || null;
    const regionSlug =
      landingPage.regionData?.i18n[pageLocale]?.slug || landingPage.regionData?.slug || null;

    const countrySlugFinal = activitySlug ? countrySlug : `trips-${countrySlug}`;

    const pathname = [activitySlug, countrySlugFinal, locationSlug || regionSlug]
      .filter(Boolean)
      .join('/');

    const locale = pageLocale.split('_')[0];
    const pathnameWithLocale = pageLocale === DEFAULT_LOCALE ? pathname : `${locale}/${pathname}`;
    const link = `${import.meta.env.VITE_SITE}/${pathnameWithLocale}/`;

    setFieldValue('seo.slug', pathname);
    setFieldValue('seo.link', link);

    if (parsedResponse.faq.length) {
      parsedResponse.faq.forEach((faq, i) => {
        setFieldValue(`faq.${i}.title`, faq.title);
        setFieldValue(`faq.${i}.text`, faq.text);
        setFieldValue(`faq.${i}.order`, faq.order);
      });
    }

    handleCloseModal();
  };

  useEffect(() => {
    if (!fromLanguage) return;
    refetch().then(({ data }) => setGlossary(data.value));
  }, [fromLanguage]);

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <ModalDialog>
          <DialogTitle>Translate to {LOCALES[pageLocale]}</DialogTitle>
          <DialogContent>
            <Typography>
              This action will apply an automatic translation using GPT technology.
            </Typography>
            <Box sx={{ marginTop: '20px' }}>
              <FormLabel required>Translate from</FormLabel>

              <Select onChange={(e, v) => setFromLanguage(v)}>
                {locales
                  .filter((locale) => locale !== pageLocale)
                  .map((locale) => (
                    <Option key={locale} value={locale}>
                      {LOCALES[locale]}
                    </Option>
                  ))}
              </Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={!fromLanguage} onClick={handleTranslate}>
              Translate
            </Button>
            <Button variant="plain" color="neutral" onClick={handleCloseModal}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <IconButton
        sx={{ width: 'fit-content', margin: '10px auto' }}
        variant="outlined"
        onClick={handleOpenModal}>
        <Translate />
      </IconButton>
    </>
  );
}
