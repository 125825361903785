import { Formik, Form, FieldArray } from 'formik';
import { Box, Button, FormLabel, Table, Typography } from '@mui/joy';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Input } from '@esui/lib.formik.input';
import { Select } from '@esui/lib.formik.select';
import { useState } from 'react';

import MainLayout from '../../components/Layouts/MainLayout';
import { previewTestPriceReCalculator } from '../../lib/booking';

import PreviewData from './PreviewData';

dayjs.extend(utc);

const PAYMENT_METHODS = {
  CREDIT_CARD: 'cc',
  WIRE: 'wire',
  CASH: 'cash'
};

const PAYMENT_METHODS_LABELS = [
  { label: 'Credit Card', value: PAYMENT_METHODS.CREDIT_CARD },
  { label: 'Wire', value: PAYMENT_METHODS.WIRE },
  {
    label: 'Cash',
    value: PAYMENT_METHODS.CASH
  }
];

const PAYMENT_STATUS = {
  PAID: 'succeeded',
  PENDING: 'pending',
  FAILED: 'failed',
  NONE: 'none'
};

const NEW_INSTALLMENT = {
  installment: 100,
  method: PAYMENT_METHODS.CREDIT_CARD,
  total: 0,
  status: PAYMENT_STATUS.NONE,
  new: true
};

export default function TestPriceReCalculator() {
  const { state } = useLocation();
  const [previewedData, setPreviewedData] = useState(null);

  const initialData = {
    price_per_person: state.original.price,
    quantity: state.original.quantity,
    duration: state.original.duration,
    installments: state.final,
    displayCurrency: state.original.displayCurrency,
    newInstallments: []
  };

  const handlePreview = async (values) => {
    const mappedValues = {
      ...values,
      installments: values.installments
        .filter((installment) => !installment.new)
        .map((installment) => ({
          ...installment,
          data: {
            ...installment.data,
            total: state.final.find((finalInstallment) => finalInstallment._id === installment._id)
              .data.total
          },
          total: Number(installment.data.total)
        })),
      newInstallments: values.installments
        .filter((installment) => installment.new)
        .map(({ installment, method, total }) => ({
          method,
          total: Number(total),
          installment: Number(installment)
        })),
      ...state
    };
    const newData = await previewTestPriceReCalculator(mappedValues);
    setPreviewedData(newData);
  };

  const closePreview = () => {
    setPreviewedData(null);
  };

  return (
    <>
      <MainLayout title="Price ReCalculator">
        <Formik initialValues={initialData} validateOnChange={false} onSubmit={handlePreview}>
          {({ values, setFieldValue }) => (
            <Form>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Lead currency</FormLabel>
                  <Input simpleField disabled name="displayCurrency" />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Travellers</FormLabel>
                  <Input simpleField name="quantity" type="number" />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Duration in days</FormLabel>
                  <Input simpleField name="duration" type="number" />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Price per person</FormLabel>
                  <Input simpleField name="price_per_person" type="number" />
                </Box>
              </Box>

              <FormLabel sx={{ marginTop: '20px' }}>Installments</FormLabel>
              <Box sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <FieldArray
                  name="installments"
                  render={(arrayHelpers) => (
                    <Table sx={{ tableLayout: 'auto', marginTop: '20px' }}>
                      <thead>
                        <tr>
                          <th style={{ height: '20px' }}> </th>
                          <th style={{ height: '20px' }}>
                            <Typography>Amount</Typography>
                          </th>
                          <th style={{ height: '20px' }}>
                            <Typography>Method</Typography>
                          </th>
                          <th style={{ height: '20px' }}>
                            <Typography>Percentage</Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.installments && values.installments.length > 0 ? (
                          values.installments.map((installment, index) => {
                            return (
                              <tr key={installment._id}>
                                <td>
                                  <Box sx={{ display: 'flex', gap: '4px' }}>
                                    {/* <Button
                                      variant="outlined"
                                      size="xs"
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}>
                                      -<tool-tip role="tooltip">Remove this installment</tool-tip>
                                    </Button> */}
                                    <Button
                                      variant="outlined"
                                      size="xs"
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.insert(index + 1, NEW_INSTALLMENT)
                                      }>
                                      +
                                      <tool-tip role="tooltip">
                                        Add a new payment after this one
                                      </tool-tip>
                                    </Button>
                                  </Box>
                                </td>
                                <td>
                                  <Input
                                    name={
                                      installment.new
                                        ? `installments.${index}.total`
                                        : `installments.${index}.data.total`
                                    }
                                  />
                                </td>
                                <td>
                                  <Select
                                    name={
                                      installment.new
                                        ? `installments.${index}.method`
                                        : `installments.${index}.data.fragment.method`
                                    }
                                    items={PAYMENT_METHODS_LABELS}
                                    onChange={() => {
                                      if (installment.new) return;
                                      setFieldValue(
                                        `installments.${index}.data.fragment.oldMethod`,
                                        installment.data.fragment.method
                                      );
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    name={`installments.${index}.installment`}
                                    disabled={!installment.new}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <button type="button" onClick={() => arrayHelpers.push(NEW_INSTALLMENT)}>
                            Add an installment
                          </button>
                        )}
                      </tbody>
                    </Table>
                  )}
                />
              </Box>
              <Box sx={{ marginTop: 3 }}>
                <Button variant="outlined" type="submit" size="sm">
                  Preview new payment plan
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </MainLayout>
      {previewedData ? (
        <PreviewData test previewedData={previewedData} closePreview={closePreview} />
      ) : null}
    </>
  );
}
