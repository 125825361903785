import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getGuideReviews } from '../../lib/reviews';
import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import Reviews from '../../components/Reviews/Reviews';

import AddReview from './AddReview';

export default function GuideReviews() {
  const { guideId } = useParams();
  const { data: reviews, isLoading } = useQuery(
    ['guideReviews', guideId],
    () => getGuideReviews(guideId),
    {
      staleTime: Infinity
    }
  );

  if (isLoading)
    return (
      <MainLayout title="Reviews">
        <Loading />
      </MainLayout>
    );

  return <Reviews reviews={reviews} addReviewComponent={<AddReview />} />;
}
