/* eslint-disable react/button-has-type */
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  Typography
} from '@mui/joy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';

import { ArrowLeft, ArrowRight } from '@mui/icons-material';

import MainLayout from '../../components/Layouts/MainLayout';
import { useGetRedirect } from '../../lib/redirect';
import Loading from '../../components/Loading/Loading';
import debounce from '../../utils/debounce';

const SITE = import.meta.env.VITE_SITE;

export default function RedirectList() {
  const perPage = 10;
  const defaultPage = new URLSearchParams(window.location.search).get('page') || 0;
  const defaultSearch = new URLSearchParams(window.location.search).get('search') || '';

  const [page, setPage] = useState(Number(defaultPage));
  const [search, setSearch] = useState(defaultSearch);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['allRedirects', page * perPage, page],
    () =>
      useGetRedirect(
        [
          {
            $match: {
              ...(search !== '' && {
                $or: [
                  {
                    destination: {
                      $regex: search,
                      $options: 'i'
                    }
                  },
                  {
                    source: {
                      $regex: search,
                      $options: 'i'
                    }
                  }
                ]
              })
            }
          },

          {
            $skip: page * perPage
          },
          ...(!search ? [{ $limit: perPage }] : [])
        ],
        { cache: false }
      )
  );

  useEffect(() => {
    refetch();
  }, [page, search]);

  if (isLoading) {
    return (
      <MainLayout title="Redirects">
        <Loading />
      </MainLayout>
    );
  }

  console.log(data);
  const handleSetNewPage = (newPage) => {
    window.scrollTo(0, 0);
    window.history.pushState({}, '', `/redirect?page=${newPage}`);
    setPage(newPage);
  };

  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (!searchTerm) {
      window.history.pushState({}, '', `/redirect?page=${page}`);
      setSearch('');
      return;
    }
    window.history.pushState({}, '', `/redirect?page=${page}&search=${searchTerm}`);
    setSearch(`${searchTerm}`);
  }, 500);

  return (
    <MainLayout
      title="Redirects"
      options={
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Link to={'/redirect/create'}>
            <Button size="sm">New</Button>
          </Link>
        </Box>
      }>
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          justifyContent: 'center',
          marginBottom: '16px',
          marginTop: '16px'
        }}>
        <Input
          autoFocus
          placeholder="/fr/mont-blanc"
          defaultValue={defaultSearch}
          onChange={handleSearch}
        />
      </Box>
      {isRefetching ? (
        <Loading />
      ) : (
        <>
          <List>
            {data.map((redirect) => {
              return (
                <Fragment key={redirect._id}>
                  <ListItem>
                    <ListItemButton
                      component={Link}
                      to={`/redirect/${redirect._id}`}
                      state={{ redirect }}>
                      <ListItemContent>
                        <Typography level="body-md">{redirect.code}</Typography>
                        <Typography level="body-sm">
                          {redirect.source} - {redirect.destination}{' '}
                        </Typography>
                        <Typography level="body-sm">
                          Updated at: {dayjs(redirect.updatedAt).format('MM/DD/YYYY, HH:mm')}
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListDivider />
                </Fragment>
              );
            })}
          </List>
        </>
      )}
    </MainLayout>
  );
}
