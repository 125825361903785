import * as React from 'react';
import { Box, Typography, Card } from '@mui/joy';

import { shape, bool, string, func, arrayOf } from 'prop-types';

import ControlButtons from '../../components/PendingApproval/ControlButtons';

const WhatsIncluded = ({ data, tripId, field, handleConfirm, updatingState }) => {
  return (
    <Card>
      <Typography level="h5">What's Included</Typography>
      <Box>
        {data.length ? (
          data.map((value) => (
            <Typography key={value} level="body-sm">
              {value}
            </Typography>
          ))
        ) : (
          <Box>
            <Typography level="body-sm" sx={{ fontStyle: 'italic' }}>
              All open groups were deleted
            </Typography>
          </Box>
        )}
      </Box>
      <ControlButtons
        to={`/trip/${tripId}/price-details`}
        loading={updatingState.loading}
        handleConfirm={() => handleConfirm({ tripId, field })}
      />
    </Card>
  );
};

WhatsIncluded.propTypes = {
  data: arrayOf(string).isRequired,
  tripId: string.isRequired,
  field: string.isRequired,
  handleConfirm: func.isRequired,
  updatingState: shape({
    loading: bool
  }).isRequired
};

export default WhatsIncluded;
