import { Box, Button, Option, Select, Sheet, Table, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import Loading from '../../components/Loading/Loading';
import { getAgendaStatus, getLastJobs } from '../../lib/health';

function useAgendaStatus() {
  const { data, isLoading, isRefetching, error, isError, refetch } = useQuery(
    ['agenda'],
    async () => {
      try {
        return await getAgendaStatus();
      } catch (err) {
        throw new Error(err.message);
      }
    }
  );
  return { data, isLoading, isRefetching, isError, error: error?.message, refetch };
}

function useLastJobs() {
  const { data, isLoading, error, isError, refetch, isRefetching } = useQuery(
    ['agenda-last-jobs'],
    async () => {
      try {
        return getLastJobs();
      } catch (err) {
        throw new Error(err.message);
      }
    },
    {
      refetchOnWindowFocus: false
    }
  );

  return { data, isLoading, isError, error: error?.message, refetch, isRefetching };
}

function Definitions({ _definitions }) {
  const [showDefinitions, setShowDefinitions] = useState(false);

  return (
    <Sheet sx={{ padding: 2 }}>
      <Typography fontWeight={600}>Definitions</Typography>
      {Object.keys(_definitions).length ? (
        <Box>
          <Typography level="body-sm">
            🟢 {Object.keys(_definitions).length} definitions found
          </Typography>
          <Button
            variant="outlined"
            sx={{ marginTop: 1 }}
            onClick={() => setShowDefinitions((prev) => !prev)}>
            {showDefinitions ? 'Hide definitions' : 'Show definitions'}
          </Button>
          {showDefinitions &&
            Object.keys(_definitions).map((key) => (
              <Typography key={key} level="body-sm">
                {key}
              </Typography>
            ))}
        </Box>
      ) : (
        <Typography level="body-sm">🔴 No definitions found</Typography>
      )}
    </Sheet>
  );
}

function JobQueue({ data }) {
  const [showTable, setShowTable] = useState(false);

  if (!data.length) {
    return (
      <Sheet sx={{ padding: 2 }}>
        <Typography fontWeight={600}>Job queue</Typography>
        <Typography>🟡 No jobs in the queue</Typography>
      </Sheet>
    );
  }
  return (
    <Sheet sx={{ padding: 2 }}>
      <Typography fontWeight={600}>Job queue</Typography>
      <Typography level="body-sm">🟢 {data.length} jobs in the queue</Typography>
      <Typography level="body-sm">
        🔴 {data.filter((v) => v.failCount > 0).length} jobs in the queue have failed
      </Typography>
      <Button
        variant="outlined"
        sx={{ marginTop: 1 }}
        onClick={() => setShowTable((prev) => !prev)}>
        {showTable ? 'Hide job queue' : 'Show job queue'}
      </Button>
      {showTable ? (
        <Table>
          <thead>
            <tr>
              <th>Job name</th>
              <th>Data</th>
              <th>Locked at</th>
              <th>Last run at</th>
              <th>Repeat at</th>
              <th>Failed at</th>
              <th>Fail reason</th>
              <th>Fail count</th>
              <th>Next run at</th>
              <th>Last finished at</th>
            </tr>
          </thead>
          <tbody>
            {data.map((job) => (
              <tr key={job._id}>
                <td>{job.name}</td>
                <td style={{ wordWrap: 'break-word' }}>{JSON.stringify(job.data)}</td>
                <td>{job.lockedAt ? dayjs(job.lockedAt).format('YYYY-MM-DD HH:mm:ss') : null}</td>
                <td>{job.lastRunAt ? dayjs(job.lastRunAt).format('YYYY-MM-DD HH:mm:ss') : null}</td>
                <td>{job.repeatAt}</td>
                <td>{job.failedAt ? dayjs(job.failedAt).format('YYYY-MM-DD HH:mm:ss') : null}</td>
                <td style={{ wordWrap: 'break-word' }}>{job.failReason}</td>
                <td>{job.failCount}</td>
                <td>{job.nextRunAt ? dayjs(job.nextRunAt).format('YYYY-MM-DD HH:mm:ss') : null}</td>
                <td>
                  {job.lastFinishedAt
                    ? dayjs(job.lastFinishedAt).format('YYYY-MM-DD HH:mm:ss')
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </Sheet>
  );
}

function LockedJobs({ data }) {
  const [showTable, setShowTable] = useState(false);

  if (!data.length) {
    return (
      <Sheet sx={{ padding: 2 }}>
        <Typography fontWeight={600}>Locked jobs</Typography>
        <Typography>🟡 No locked jobs</Typography>
      </Sheet>
    );
  }
  return (
    <Sheet sx={{ padding: 2 }}>
      <Typography fontWeight={600}>Locked jobs</Typography>
      <Typography level="body-sm">🟢 {data.length} locked jobs</Typography>
      <Button
        variant="outlined"
        sx={{ marginTop: 1 }}
        onClick={() => setShowTable((prev) => !prev)}>
        {showTable ? 'Hide locked jobs' : 'Show locked jobs'}
      </Button>
      {showTable ? (
        <Table>
          <thead>
            <tr>
              <th>Job name</th>
              <th>Data</th>
              <th>Locked at</th>
              <th>Last run at</th>
              <th>Next run at</th>
            </tr>
          </thead>
          <tbody>
            {data.map((job) => (
              <tr key={job._id}>
                <td>{job.name}</td>
                <td style={{ wordWrap: 'break-word' }}>{JSON.stringify(job.data)}</td>
                <td>{job.lockedAt ? dayjs(job.lockedAt).format('YYYY-MM-DD HH:mm:ss') : null}</td>
                <td>{job.lastRunAt ? dayjs(job.lastRunAt).format('YYYY-MM-DD HH:mm:ss') : null}</td>
                <td>
                  {job.nextRunAt ? dayjs(job.nextRunAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </Sheet>
  );
}

function RawJson({ data }) {
  const [show, setShow] = useState(false);
  return (
    <Sheet sx={{ padding: 2 }}>
      <Box>
        <Button variant="outlined" onClick={() => setShow((prev) => !prev)}>
          {show ? 'Hide raw JSON' : 'Show raw JSON'}
        </Button>
        {show && (
          <>
            <pre style={{ fontSize: 14 }}>{JSON.stringify(data, null, 4)}</pre>
            <Button variant="outlined" onClick={() => setShow(false)}>
              Hide raw JSON
            </Button>
          </>
        )}
      </Box>
    </Sheet>
  );
}

function LastJobsProcessTime() {
  const [refreshTime, setRefreshTime] = useState(10);

  const { data: serversData, isLoading, isError, error, isRefetching, refetch } = useLastJobs();

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, refreshTime * 60 * 1000);

    return () => clearInterval(interval);
  }, [refreshTime, refetch]);

  if (isLoading)
    return (
      <Box>
        <Typography level="h4" sx={{ margin: '5px 0px' }}>
          Last jobs process time
        </Typography>
        <Typography>Loading... Please wait, this process may take a few minutes.</Typography>
      </Box>
    );

  return (
    <Box>
      <Typography level="h4" sx={{ margin: '5px 0px' }}>
        Last jobs process time
      </Typography>
      {isError && error ? (
        <Typography>🔴 Couldn&apos;t fetch last jobs process time</Typography>
      ) : null}
      {Object.keys(serversData).map((server) => {
        const fetchFailed = !Array.isArray(serversData[server]) && serversData[server].status;

        if (fetchFailed) {
          return (
            <Box key={server}>
              <Typography sx={{ margin: '5px 0px' }} level="h4">
                {server}:
              </Typography>
              <Typography>🔴 Couldn&apos;t fetch last jobs process time - {fetchFailed}</Typography>
            </Box>
          );
        }

        const jobsFound = Object.values(serversData[server]).reduce(
          (acc, booking) => acc + booking.jobs.length,
          0
        );

        return (
          <Box key={server}>
            <Typography sx={{ margin: '5px 0px' }} fontWeight={600}>
              {server}:
            </Typography>
            <Typography sx={{ marginBottom: 2 }}>🟢 {jobsFound} jobs found</Typography>
            <Typography level="body-sm">Refresh time:</Typography>
            <Select
              defaultValue={refreshTime}
              sx={{ marginBottom: 2 }}
              onChange={(e, v) => setRefreshTime(v)}>
              <Option value={5}>5 minutes</Option>
              <Option value={10}>10 minutes</Option>
              <Option value={20}>20 minutes</Option>
              <Option value={30}>30 minutes</Option>
              <Option value={60}>1 hour</Option>
            </Select>

            {isRefetching ? (
              <Typography>
                Updating information... Please wait, this process may take a few minutes.
              </Typography>
            ) : null}
            {jobsFound > 0 ? (
              <Sheet>
                <Table>
                  <thead>
                    <tr>
                      <th>Job name</th>
                      <th>Booking code</th>
                      <th>Booking id</th>
                      <th>Booking created at</th>
                      <th>Last run at</th>
                      <th>Diff</th>
                      <th>Guide state</th>
                      <th>Booking state</th>
                    </tr>
                  </thead>
                  <tbody>
                    {serversData[server].map((booking) =>
                      booking.jobs.map((job) => (
                        <tr key={job._id}>
                          <td>{job.name}</td>
                          <td>{booking.code}</td>
                          <td style={{ wordWrap: 'break-word' }}>{booking._id}</td>
                          <td>{dayjs(booking.created).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>
                            {job.lastRunAt
                              ? dayjs(job.lastRunAt).format('YYYY-MM-DD HH:mm:ss')
                              : null}
                          </td>
                          <td>
                            {(
                              dayjs(job.lastFinishedAt).diff(dayjs(booking.created), 'seconds') /
                              60 /
                              24
                            ).toFixed(5)}
                          </td>
                          <td style={{ wordWrap: 'break-word' }}>{booking.guide_state}</td>
                          <td style={{ wordWrap: 'break-word' }}>{booking.state}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Sheet>
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
}

function AgendaInfo() {
  const [refreshTime, setRefreshTime] = useState(1);
  const [lastCheckedAt, setLastCheckedAt] = useState(new Date());
  const { data, isLoading, isRefetching, isError, error, refetch } = useAgendaStatus();

  useEffect(() => {
    if (!isLoading && !isRefetching) setLastCheckedAt(new Date());
  }, [isLoading, isRefetching]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, refreshTime * 60 * 1000);

    return () => clearInterval(interval);
  }, [refreshTime, refetch]);

  return (
    <Box>
      <Typography level="h3">Agenda status: </Typography>
      <Typography level="body-sm">Refresh time:</Typography>
      <Select
        defaultValue={refreshTime}
        sx={{ marginBottom: 2 }}
        onChange={(e, v) => setRefreshTime(v)}>
        <Option value={1}>1 minute</Option>
        <Option value={2}>2 minutes</Option>
        <Option value={5}>5 minutes</Option>
        <Option value={10}>10 minutes</Option>
        <Option value={20}>20 minutes</Option>
      </Select>
      {isError ? (
        <Box>
          <Typography>🔴 Couldn&apos;t fetch agenda status</Typography>
          <Typography level="body-sm">{error}</Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '10px' }}>
          {isRefetching ? (
            <Typography level="body-xs">Updating information...</Typography>
          ) : isLoading ? null : (
            <Typography level="body-xs">
              Last updated at: {lastCheckedAt.toLocaleTimeString()}
            </Typography>
          )}
          {isLoading ? <Loading /> : null}
          {data
            ? Object.keys(data).map((server) => {
                const serverData = data[server];
                const fetchFailed = !Array.isArray(serverData) && serverData.status;

                if (fetchFailed) {
                  return (
                    <Box key={server}>
                      <Typography level="h4" sx={{ margin: '5px 0px' }} fontWeight={600}>
                        {server}:
                      </Typography>
                      <Typography>🔴 Couldn&apos;t fetch agenda status - {fetchFailed}</Typography>
                    </Box>
                  );
                }

                return (
                  <Box key={server}>
                    <Typography level="h4">{server}:</Typography>
                    <Typography sx={{ padding: 2 }}>
                      <Typography fontWeight={600}>Max concurrency: </Typography>
                      {serverData._maxConcurrency}
                    </Typography>
                    <Definitions _definitions={serverData._definitions} />
                    <JobQueue data={serverData._jobQueue._queue} />
                    <LockedJobs data={serverData._lockedJobs} />
                    <RawJson data={serverData} />
                  </Box>
                );
              })
            : null}
        </Box>
      )}
    </Box>
  );
}

export function AgendaStatus() {
  return (
    <>
      <AgendaInfo />
      <LastJobsProcessTime />
    </>
  );
}
