import fetcher from './fetcher';

export async function useGetRedirect(aggregation, options = { cache: true }) {
  const apiUrl = import.meta.env.VITE_API;
  const url = new URL(`${apiUrl}/api/redirect/aggregation`);

  url.searchParams.set('query', JSON.stringify(aggregation));
  if (options.cache === false) {
    url.searchParams.set('revalidate', 1);
  }

  url.searchParams.set('limit', 9999);

  const { data: redirects } = await fetcher({
    url: url.toString().replace(apiUrl, ''),
    method: 'GET'
  });

  return redirects;
}

export function useGetRedirectById(id, values) {
  return fetcher({
    url: `/api/redirect/${id}`,
    method: 'GET',
    data: values
  });
}

export function useUpdateRedirect(id, values) {
  return fetcher({
    url: `/api/redirect/${id}`,
    method: 'PUT',
    data: values
  });
}

export function useCreateRedirect(values) {
  return fetcher({
    url: `/api/redirect`,
    method: 'POST',
    data: values
  });
}

export function useDeleteRedirect(id, data) {
  return fetcher({
    url: `/api/redirect/${id}`,
    method: 'DELETE',
    data
  });
}
