/* eslint-disable import/prefer-default-export */
const DEFAULT_LOCALE = 'en_EN';

const LOCALES = {
  en_EN: 'English',
  fr_FR: 'French',
  es_ES: 'Spanish'
};

const LOCALES_SHORT = {
  en_EN: 'EN',
  fr_FR: 'FR',
  es_ES: 'ES'
};

const DEFAULT_FAQS = {
  en_EN: [
    'What types of outdoor adventures are popular in <country>?',
    'When is the best time to embark on an outdoor adventure in <country> ?',
    'Do I need any special permits or licenses for these adventures?',
    'How safe are outdoor adventures in <country>?',
    'Can I book guided tours for my adventures?',
    'What should I pack for my <country> outdoor adventure?',
    'Are there any cultural or environmental considerations I should be aware of?',
    'Do I need travel insurance for outdoor activities in <country>?',
    'What is the level of difficulty for the adventures listed?',
    'Can I plan a custom itinerary for my adventure trip in <country>?'
  ],
  fr_FR: [
    "Quels sont les types d'aventures en plein air les plus populaires dans le <country> ?",
    'Quel est le meilleur moment pour se lancer dans une aventure en plein air dans le <country> ?',
    "Ai-je besoin d'un permis ou d'une licence spéciale pour ces aventures ?",
    'Quel est le degré de sécurité des aventures en plein air dans le <country> ?',
    'Puis-je réserver des visites guidées pour mes aventures ?',
    'Que dois-je emporter pour mon aventure en plein air dans le <country> ?',
    'Y a-t-il des considérations culturelles ou environnementales dont je devrais tenir compte ?',
    'Faut-il souscrire une assurance voyage pour les activités de plein air dans le <country> ?',
    'Quel est le niveau de difficulté des aventures proposées ?',
    "Puis-je planifier un itinéraire personnalisé pour mon voyage d'aventure dans le <country> ?"
  ],
  es_ES: [
    '¿Cuáles son los tipos de aventuras al aire libre más populares en <country>?',
    '¿Cuál es el mejor momento para embarcarse en una aventura al aire libre en <country>?',
    '¿Necesito un permiso o una licencia especial para estas aventuras?',
    '¿Cuál es el nivel de seguridad de las aventuras al aire libre en <country>?',
    '¿Puedo reservar tours guiados para mis aventuras?',
    '¿Qué debo llevar para mi aventura al aire libre en <country>?',
    '¿Existen consideraciones culturales o medioambientales que deba tener en cuenta?',
    '¿Es necesario obtener un seguro de viaje para las actividades al aire libre en <country>?',
    '¿Cuál es el nivel de dificultad de las aventuras propuestas?',
    '¿Puedo planificar una ruta personalizada para mi viaje de aventura en <country>?'
  ]
};

const FEES = { EXCHANGE_FEE: 2, CREDIT_CARD_FEE: 2.3, CUSTOMER_FEE: 5, GUIDE_FEE: 15 };

export { DEFAULT_LOCALE, FEES, LOCALES, DEFAULT_FAQS, LOCALES_SHORT };
