import fetcher from './fetcher';

export default function useGetLandingPages({ page, search, locale }) {
  const searchParams = new URLSearchParams('');
  const searchObject = new URLSearchParams(search);

  searchParams.set(
    'query',
    JSON.stringify([
      searchObject.get('s')
        ? {
            $match: {
              [`i18n.${locale}.title`]: {
                $regex: searchObject.get('s') || '',
                $options: 'i'
              }
            }
          }
        : {
          $match: {
            [`i18n.${locale}`]: { $exists: true }
          }
        },
        {
          $sort: {
            updatedAt: -1
          }
        },
        {
          $skip: page * 20
        },
        {
          $limit: 20
        }
    ])
  );

  return fetcher({
    url: `/api/landingPages/aggregation/?${searchParams.toString()}`,
    method: 'GET'
  });
}

export function useGetLandingPage(id) {
  return fetcher({
    url: `/api/landingPages/?_id=${id}`,
    method: 'GET'
  });
}

export function useUpdateLandingPage(id, values) {
  return fetcher({
    url: `/api/landingPages/${id}`,
    method: 'PUT',
    data: values
  });
}
