import { useState, createContext, useContext, useMemo } from 'react';
import { node, string, shape } from 'prop-types';

import rosetta from 'rosetta';

const i18n = rosetta();

const I18nContext = createContext();

const I18nContextProvider = ({ children, locale, table }) => {
  const [currentLocale, setCurrentLocale] = useState(locale);

  i18n.locale(locale);
  i18n.set(locale, table);

  const i18nWrapper = useMemo(
    () => ({
      currentLocale,
      t: (...args) => {
        const trans = i18n.t(args[0], args[1], currentLocale);
        return trans || args[0];
      },
      table: () => table,
      locale: (...args) => i18n.locale(...args),
      changeLocale: async (newLocale) => {
        if (newLocale !== currentLocale && newLocale !== 'en') {
          const script = document.createElement('script');
          script.src = `https://statics.explore-share.com/locales/es-guide/${newLocale}.js`;
          script.setAttribute('async', '');

          script.onload = () => {
            const newTable = window[`${newLocale}_table`];
            i18n.set(newLocale, newTable);
            i18n.locale(newLocale);

            setCurrentLocale(newLocale);
          };
          document.head.appendChild(script);
        }
      }
    }),
    [currentLocale, table]
  );
  return <I18nContext.Provider value={{ ...i18nWrapper }}>{children}</I18nContext.Provider>;
};

I18nContextProvider.propTypes = {
  children: node.isRequired,
  table: shape({}).isRequired,
  locale: string.isRequired
};

const useI18n = () => {
  const i18nContext = useContext(I18nContext);
  return i18nContext;
};

export default I18nContextProvider;
export { I18nContext, useI18n };
