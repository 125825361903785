import { Box, Button, Input, Typography } from '@mui/joy';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import MainLayout from '../../components/Layouts/MainLayout';
import { createGuide } from '../../lib/guide';

const PHONE_REGEX = /^\+?[1-9]\d{1,14}$/;

export default function NewGuide() {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(null);

  const create = async () => {
    if (!firstName || !lastName) return;
    setSaving(true);

    if (phone && !PHONE_REGEX.test(phone)) {
      setSaving(false);
      toast.error('Invalid phone number');
      return;
    }

    try {
      const resp = await toast.promise(createGuide({ firstName, lastName, phone, email }), {
        loading: 'Creating guide...',
        success: 'Guide created successfully',
        error: 'Error while creating guide'
      });

      setTimeout(() => {
        setSaving(false);
        navigate(`/guide/${resp._id}`);
      }, 500);
    } catch (e) {
      setSaving(false);
    }
  };

  return (
    <MainLayout title="New Guide">
      <Box
        sx={{
          maxWidth: '400px',
          margin: 'auto'
        }}>
        <Typography level="title-md">
          Please, provide a first name and last name for the guide
        </Typography>
        <Input
          placeholder="First name"
          sx={{ marginTop: '5px' }}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          placeholder="Last name"
          sx={{ marginTop: '5px' }}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          placeholder="Email"
          sx={{ marginTop: '5px' }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder="Phone"
          sx={{ marginTop: '5px' }}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Button
          sx={{ width: '100%', marginTop: '5px' }}
          disabled={saving || !firstName?.length || !lastName?.length}
          onClick={create}>
          Continue
        </Button>
      </Box>
    </MainLayout>
  );
}
