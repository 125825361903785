import fetcher from './fetcher';

const useGetRefresh = async () => {
  return fetcher({
    url: `/api/v2/auth/refresh`,
    method: 'GET'
  });
};

const usePostLogin = async (data) => {
  return fetcher({
    url: `/api/v1/dashboard/auth/login`,
    method: 'POST',
    data
  });
};

const usePostForgot = async (data) => {
  return fetcher({
    url: `/api/v3/auth/forgot`,
    method: 'POST',
    data
  });
};

const useUpdatePassword = async (data) => {
  return fetcher({
    url: `/api/v3/auth/new-password`,
    method: 'PUT',
    data
  });
};

const useValidateHashExpiry = async (hash) => {
  return fetcher({
    url: `/api/v3/auth/validate-hash-expiry/${hash}`,
    method: 'GET'
  });
};

export { useGetRefresh, usePostLogin, usePostForgot, useUpdatePassword, useValidateHashExpiry };
