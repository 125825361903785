import React, { useState } from 'react';
import Typography from '@mui/joy/Typography';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Box, Stack } from '@mui/joy';
import toast from 'react-hot-toast';

import { usePostForgot } from '../../lib/auth';
import LoginLayout from '../../components/Login/Layout';

export default function ForgotPassword() {
  const [success, setSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      email: e.target.email.value
    };

    await toast.promise(usePostForgot(data), {
      loading: 'Sending email...',
      success: 'Email sent!',
      error: 'Error sending email'
    });

    setSuccess(true);

    return true;
  };
  return (
    <form onSubmit={handleLogin}>
      <LoginLayout>
        {!success ? (
          <>
            <div>
              <Typography level="h4" component="h1">
                <b>Reset your password</b>
              </Typography>
            </div>
            <Box>
              <FormLabel>Email</FormLabel>
              <Input required name="email" type="email" placeholder="johndoe@email.com" />
            </Box>

            <Stack direction="row" sx={{ mt: 1, justifyContent: 'end' }}>
              <Button type="submit">Reset password</Button>
            </Stack>
          </>
        ) : (
          <Typography level="p" component="h1">
            Please be sure to check your email inbox for a link that will enable you to complete the
            password reset process.
          </Typography>
        )}
      </LoginLayout>
    </form>
  );
}
