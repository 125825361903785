import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import Reviews from '../../components/Reviews/Reviews';
import { getTripReviews } from '../../lib/reviews';
import Loading from '../../components/Loading/Loading';
import MainLayout from '../../components/Layouts/MainLayout';

import AddReview from './AddReview';

export default function TripReviews() {
  const { tripId } = useParams();
  const { data: reviews, isLoading } = useQuery(
    ['tripReviews', tripId],
    () => getTripReviews(tripId),
    {
      staleTime: Infinity
    }
  );

  if (isLoading)
    return (
      <MainLayout title="Reviews">
        <Loading />
      </MainLayout>
    );

  return <Reviews reviews={reviews} addReviewComponent={<AddReview />} />;
}
