import { useState } from 'react';
import { Box, Button, Input, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import MainLayout from '../../components/Layouts/MainLayout';
import { createBlogPost } from '../../lib/blog-posts';

export default function BlogPostNew() {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState('');

  const create = async () => {
    if (!title) return;
    setSaving(true);
    const { data } = await toast.promise(createBlogPost({ title }), {
      loading: 'Creating blog post...',
      success: 'Blog post created successfully',
      error: 'Error while creating blog post'
    });

    setTimeout(() => {
      setSaving(false);
      navigate(`/blog-post/${data._id}/options`);
    }, 500);
  };

  return (
    <MainLayout title="Blog Posts">
      <Box
        sx={{
          maxWidth: '400px',
          margin: 'auto'
        }}>
        <Typography level="title-md">Please, provide a title for the blog post</Typography>
        <Input
          placeholder="Title"
          sx={{ marginTop: '5px' }}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Button
          sx={{ width: '100%', marginTop: '5px' }}
          disabled={saving || !title.length}
          onClick={create}>
          Continue
        </Button>
      </Box>
    </MainLayout>
  );
}
