import { Box } from '@mui/joy';

import MainLayout from '../../components/Layouts/MainLayout';

import { SystemStatus } from './SystemStatus';
import { AgendaStatus } from './AgendaStatus';

export default function Monitor() {
  return (
    <MainLayout title="Healthy monitor">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <SystemStatus />
        <AgendaStatus />
      </Box>
    </MainLayout>
  );
}
