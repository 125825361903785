import { Box, Button, Option, Select, Table, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import SharedModal from '../../components/SharedModal';
import { confirmPriceReCalculator } from '../../lib/booking';
import ToastText from '../../components/ToastText';

export default function PreviewData({ previewedData, closePreview, test }) {
  const { id } = useParams();

  const confirmInformation = async () => {
    await toast.promise(
      confirmPriceReCalculator(previewedData, id),
      {
        loading: <ToastText>Updating payment plan...</ToastText>,
        success: <ToastText>Payment plan updated!</ToastText>,
        error: <ToastText>Something went wrong</ToastText>
      },
      {
        success: { duration: 3000 },
        error: { duration: 3000 }
      }
    );
    closePreview();
    window.location.reload();
  };

  return (
    <SharedModal isOpen={Boolean(previewedData.payment_plan)} close={closePreview}>
      <Typography level="h3">Preview</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: '20px' }}>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Guide currency:</span>{' '}
          {previewedData.payment_plan.original.currency}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Lead currency:</span>{' '}
          {previewedData.payment_plan.original.displayCurrency}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Rate:</span>{' '}
          {previewedData.payment_plan.final.find((p) => p.first).data?.rate}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Total:</span>{' '}
          {previewedData.payment_plan.computedCalculations.total.toFixed(2)}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Customer fee:</span>{' '}
          {previewedData.payment_plan.computedCalculations.totalCustomerFee.toFixed(2)}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>CC fee:</span>{' '}
          {previewedData.payment_plan.computedCalculations.totalCcFee.toFixed(2)}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Guide fee:</span>{' '}
          {previewedData.payment_plan.computedCalculations.guideFee.toFixed(2)}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Price per person:</span>{' '}
          {previewedData.payment_plan.computedCalculations.pricePerPerson.toFixed(2)}
        </Typography>
        <Typography>
          <span style={{ textDecoration: 'underline' }}>Price per person total:</span>{' '}
          {previewedData.payment_plan.computedCalculations.pricePerPersonTotal.toFixed(2)}
        </Typography>
        <Box sx={{ marginTop: '20px' }}>
          <Typography level="h4">Payments</Typography>
          <Table sx={{ width: 'auto' }}>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Method</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {previewedData.payments.map((payment) => (
                <tr key={payment.installment_id}>
                  <td>
                    {previewedData.payment_plan.original.displayCurrency}{' '}
                    {payment.amount.toFixed(2)}
                  </td>
                  <td>{typeof payment.method === 'object' ? '' : payment.method}</td>
                  <td>
                    <Select
                      defaultValue={payment.status}
                      onChange={(e, v) => {
                        const payments = [...previewedData.payments];
                        const index = payments.findIndex(
                          (p) => p.installment_id === payment.installment_id
                        );
                        payments[index].status = v;
                        return payments;
                      }}>
                      <Option value="succeeded">Succeeded</Option>
                      <Option value="failed">Failed</Option>
                      <Option value="none">None</Option>
                      {payment.method === 'wire' && (
                        <Option value="pending">Pending validation</Option>
                      )}
                      {payment.method === 'cc' && <Option value="pending">Pre-authorized</Option>}
                    </Select>
                  </td>
                  <td>{payment.ts ? dayjs.utc(payment.ts).format('DD MMM, YY') : null}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        <Typography sx={{ margin: '10px 0px' }}>
          <span style={{ textDecoration: 'underline' }}>All payments total:</span>{' '}
          {previewedData.payments.reduce((acc, payment) => acc + payment.amount, 0).toFixed(2)}
        </Typography>
        {test ? null : (
          <>
            <Button color="danger" variant="outlined" onClick={closePreview}>
              Cancel
            </Button>
            <Button onClick={confirmInformation}>Confirm</Button>
          </>
        )}
      </Box>
    </SharedModal>
  );
}
