import React, { useState } from 'react';
import { Box, List, ListDivider, Typography, Button, ListItem, Card, CardOverflow } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useGetBooking } from '../../lib/booking';
import Loading from '../../components/Loading/Loading';
import MainLayout from '../../components/Layouts/MainLayout';

import PassOrNot from './PassOrNot';
import ActivityList from './ActivityList';

dayjs.extend(utc);

const BookingHealth = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(['booking'], () => useGetBooking(id));
  const [showPaymentPlan, setShowPaymentPlan] = useState(false);

  if (isLoading)
    return (
      <MainLayout title="Booking">
        <Loading />
      </MainLayout>
    );

  const { code, created, geo, price_per_person, version, payment_plan, currency, payments, state } =
    data.booking;

  return (
    <MainLayout title="Booking" topTitle={`${data?.booking.code} ${data?.booking.version}`}>
      <Box>
        <Box sx={{ flex: 1 }}>
          <List>
            <ListItem>{code}</ListItem>
            <ListItem>
              <Typography
                level="body-sm"
                component="label"
                startDecorator={
                  state === 'B:PAID' && (
                    <PassOrNot
                      pass={
                        state === 'B:PAID' &&
                        payments.filter((payment) => payment.status === 'succeeded').length > 0
                      }
                    />
                  )
                }>
                State: {state}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                level="body-sm"
                component="label"
                startDecorator={<PassOrNot pass={!!created} />}>
                {' '}
                Created: {created}
              </Typography>
            </ListItem>
            {/* <ListItem>
              <PassOrNot pass={!!last_updated} /> Last updated: {last_updated}
            </ListItem> */}
            {geo ? (
              <ListItem>
                <Typography
                  level="body-sm"
                  component="label"
                  startDecorator={
                    <PassOrNot
                      pass={!!geo || !!geo.country_code || geo.country_code !== 'Not found'}
                    />
                  }>
                  {' '}
                  Geo: {geo.country_code}
                </Typography>
              </ListItem>
            ) : null}
            <ListItem>
              <Typography
                level="body-sm"
                component="label"
                startDecorator={
                  <PassOrNot
                    pass={
                      !!price_per_person ||
                      (version === '2.3.0' && price_per_person === payment_plan.original.price)
                    }
                  />
                }>
                {' '}
                Price per person: {price_per_person}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                level="body-sm"
                component="label"
                startDecorator={<PassOrNot pass={!!currency} />}>
                {' '}
                Currency: {currency}
              </Typography>
            </ListItem>
            {version === '2.3.0' && payment_plan && (
              <>
                <ListItem>
                  <Typography
                    level="body-sm"
                    component="label"
                    startDecorator={
                      <PassOrNot
                        pass={
                          !!payment_plan &&
                          !!payment_plan.original &&
                          !!payment_plan.final &&
                          !!payment_plan.computedCalculations &&
                          !!payment_plan.config
                        }
                      />
                    }>
                    Payment plan
                  </Typography>
                </ListItem>

                <ListItem>
                  <Card variant="outlined">
                    <Typography level="h2" fontSize="sm" sx={{ mb: 0.5 }}>
                      Installments
                    </Typography>
                    <List>
                      {payment_plan.final.map((installment, key) => (
                        <ListItem>
                          <Typography key={key} level="body-sm" component="li">
                            {installment.installment}% {installment.data.currency}{' '}
                            {installment.data.total}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                    <CardOverflow
                      variant="soft"
                      sx={{
                        bgcolor: 'background.level1'
                      }}>
                      <Typography level="body3" sx={{ fontWeight: 'md', color: 'text.secondary' }}>
                        <Button
                          size="sm"
                          variant="plain"
                          onClick={() => setShowPaymentPlan((prev) => !prev)}>
                          {showPaymentPlan ? 'Hide payment plan' : 'Show payment plan'}
                        </Button>
                      </Typography>

                      {showPaymentPlan && (
                        <>
                          <ListDivider orientation="vertical" />
                          <Typography
                            level="body3"
                            sx={{ fontWeight: 'md', color: 'text.secondary' }}>
                            <pre>{JSON.stringify(payment_plan, null, 4)}</pre>
                          </Typography>
                        </>
                      )}
                    </CardOverflow>
                  </Card>
                </ListItem>
              </>
            )}

            <ListItem>
              <Typography
                level="body-sm"
                component="label"
                startDecorator={<PassOrNot pass={!!payments} />}>
                Payments: {payments.length}
                {version === '2.3.0' && ` / ${payment_plan.final.length}`}
              </Typography>
            </ListItem>

            {payments.length > 0 && (
              <ListItem>
                <Card variant="outlined">
                  {payments.map((payment, key) => (
                    <Typography
                      key={key}
                      level="body-sm"
                      component="label"
                      startDecorator={
                        <PassOrNot pass={!!payment.status && payment.status === 'succeeded'} />
                      }>
                      {payment.amount} {payment.status}
                    </Typography>
                  ))}
                </Card>
              </ListItem>
            )}
          </List>
        </Box>
        <ActivityList activities={data.activity} />
        <Typography level="h3">Payment plan</Typography>
        <pre>{JSON.stringify(payment_plan, null, 4)}</pre>
      </Box>
    </MainLayout>
  );
};

export default BookingHealth;
