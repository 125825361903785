import fetcher from './fetcher';

export default function getStaticPages() {
  const urlSearchParams = new URLSearchParams('');

  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          type: 'static-page',
          'i18n.en_EN.seo_tags.slug': {
            $in: ['terms', 'faq']
          }
        }
      },
      {
        $project: {
          'i18n.en_EN.seo_tags': 1
        }
      }
    ])
  );

  return fetcher({
    url: `/api/blog_posts/aggregation?${urlSearchParams.toString()}`,
    method: 'GET'
  });
}

export function getStaticPage({ id }) {
  const urlSearchParams = new URLSearchParams('');

  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          _id: id
        }
      }
    ])
  );

  return fetcher({
    url: `/api/blog_posts/aggregation?${urlSearchParams.toString()}`,
    method: 'GET'
  });
}

export function patchStaticPage({ id, data }) {
  return fetcher({
    url: `/api/blog_posts/${id}`,
    method: 'PUT',
    data
  });
}
