import { Box, Button, Typography } from '@mui/joy';
import { useState } from 'react';

import SharedModal from '../components/SharedModal';

const useConfirmModal = ({ loading, handleConfirm, title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const modal = (
    <SharedModal close={close} isOpen={isOpen}>
      <Typography level="title-sm" sx={{ marginRight: '20px' }}>
        {title || 'Are you sure you want to approve this content change?'}
      </Typography>
      <Typography level="body-sm" sx={{ marginRight: '20px' }}>
        {description || 'This action cannot be undone.'}
      </Typography>

      <Box sx={{ width: '100%', display: 'flex', gap: '5px', marginTop: '10px' }}>
        <Button
          disabled={loading}
          sx={{ width: '50%' }}
          color="neutral"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          sx={{ width: '50%' }}
          onClick={() => {
            handleConfirm();
            close();
          }}>
          {loading ? 'Loading...' : 'Confirm'}
        </Button>
      </Box>
    </SharedModal>
  );

  return { open, modal };
};

export default useConfirmModal;
