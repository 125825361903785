import { Box, Button, IconButton, Typography } from '@mui/joy';
import { FieldArray } from 'formik';
import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { useState } from 'react';

import ExpandSectionLayout from './ExpandSectionLayout';
import DeleteItemModal from './DeleteItemModal';
import TopTripsModal from './TopTripsModal';
import { useLocale } from '../../hooks/useLocale';

export default function TopTrips({ values, prop = 'top_trips' }) {
  const [locale] = useLocale();
  const [newTripsModalOpen, setNewTripsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  return (
    <ExpandSectionLayout title="Top Trips">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <FieldArray name={prop}>
          {({ push, remove }) => (
            <>
              {values.map((trip, i) => (
                <Box key={trip._id} sx={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt={trip.i18n[locale].title}
                    src={`${trip.cover_url}?w=180&h=180`}
                    height="50px"
                    width="50px"
                    loading="lazy"
                    style={{ borderRadius: '4px', objectFit: 'cover', marginRight: '5px' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center'
                    }}>
                    <Typography>
                      {trip.i18n[locale].title} <Typography level="body-xs">#{trip._id}</Typography>
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setDeleteModalOpen(true);
                        setItemToDelete(i);
                      }}>
                      <RemoveCircleOutlineOutlined color="danger" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              <Button
                variant="outlined"
                sx={{ width: 'fit-content' }}
                onClick={() => setNewTripsModalOpen(true)}>
                Add top trips
              </Button>
              <DeleteItemModal
                open={deleteModalOpen}
                remove={() => {
                  remove(itemToDelete);
                }}
                onClose={() => {
                  setDeleteModalOpen(false);
                  setItemToDelete(null);
                }}
              />
              <TopTripsModal
                open={newTripsModalOpen}
                push={push}
                currentTrips={values.map((v) => v._id)}
                onClose={() => setNewTripsModalOpen(false)}
              />
            </>
          )}
        </FieldArray>
      </Box>
    </ExpandSectionLayout>
  );
}
