import { Box } from '@mui/joy';
import { BlocklyWorkspace } from 'react-blockly';
import { useCallback, useEffect, useState } from 'react';

import INITIAL_TOOLBOX_JSON from '../../utils/blockly/blockyInitialToolbox';
import HtmlGenerator from '../../utils/blockly/html-generators';

function BlockyContent({
  locale,
  setHtmlValue,
  defaultContent,
  variable,
  setJsonValue,
  sx,
  copyContent,
  setCopyContent
}) {
  const [toolBox, setToolBox] = useState(null);
  const onWorkspaceChange = useCallback((workspace) => {
    const htmlCode = HtmlGenerator.workspaceToCode(workspace);
    return setHtmlValue(htmlCode);
  }, []);

  const onJsonChange = useCallback((json) => {
    setJsonValue(`i18n.${locale}.${variable}_raw_data`, JSON.stringify(json));
  }, []);

  const [key, setKey] = useState(0);

  useEffect(() => {
    if (!copyContent) return;
    setKey((prev) => prev + 1);
    setCopyContent(false);
  }, [defaultContent]);

  useEffect(async () => {
    setToolBox(await INITIAL_TOOLBOX_JSON());
  }, []);

  if (!toolBox) return 'Loading..';

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <BlocklyWorkspace
        key={`${key}-${variable}`}
        className="full-width-height"
        toolboxConfiguration={toolBox}
        workspaceConfiguration={{
          grid: {
            spacing: 20,
            length: 3,
            colour: '#ccc',
            snap: true
          },
          trashcan: false
        }}
        initialJson={JSON.parse(defaultContent || '{}')}
        onJsonChange={onJsonChange}
        onWorkspaceChange={onWorkspaceChange}
        onImportError={(error) => console.log('error', error)}
      />
    </Box>
  );
}

export default BlockyContent;
