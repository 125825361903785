import { Autocomplete, Box, IconButton, Typography } from '@mui/joy';
import { FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Delete } from '@mui/icons-material';

import debounce from '../utils/debounce';
import { DEFAULT_LOCALE } from '../constants/defaults';

import Loading from './Loading/Loading';

export default function ArrayField({
  remoteQuery,
  currentValues,
  valueKey,
  fn,
  name,
  locale = DEFAULT_LOCALE
}) {
  const [aggregation, setAggregation] = useState([
    {
      $match: {
        title: { $regex: '', $options: 'i' }
      }
    },
    remoteQuery ? { $limit: 10 } : {}
  ]);

  const { data, isLoading, isRefetching, refetch } = useQuery([valueKey], () => fn(aggregation));

  const searchRecord = debounce((e) => {
    const searchTerm = e.target.value;
    if (!searchTerm) return;
    setAggregation([
      {
        $match: {
          title: { $regex: searchTerm, $options: 'i' }
        }
      },
      {
        $limit: 10
      }
    ]);
  }, 500);

  useEffect(() => {
    if (remoteQuery) {
      refetch();
    }
  }, [aggregation]);

  if (isLoading) return <Loading />;

  const options = data.data.map((value) => ({
    value: value._id,
    label: value.i18n[locale]?.title || value.title || '',
    _id: value._id
  }));

  if (valueKey === 'periods') {
    options.sort((a, b) => a._id - b._id);
  }

  return (
    <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column', marginTop: '10px' }}>
      <FieldArray name={name || valueKey}>
        {({ push, remove }) => (
          <>
            <Autocomplete
              options={options}
              value={null}
              noOptionsText={isRefetching ? 'Loading...' : 'No results found'}
              onInputChange={(e) => remoteQuery && searchRecord(e)}
              onChange={(e, value) => {
                if (!value) return;
                push({
                  _id: value.value,
                  i18n: {
                    [locale]: {
                      title: value.label
                    }
                  }
                });
              }}
            />
            {currentValues.map((value, index) => (
              <Box key={value._id} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Typography level="title-md">
                  {value.i18n[locale]?.title || value.title || ''}
                </Typography>
                <IconButton size="sm" variant="outlined" onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </Box>
            ))}
          </>
        )}
      </FieldArray>
    </Box>
  );
}
