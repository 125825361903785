import { Formik, Form, FieldArray } from 'formik';
import { Box, Button, FormLabel, Table, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Input } from '@esui/lib.formik.input';
import { Select } from '@esui/lib.formik.select';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import MainLayout from '../../components/Layouts/MainLayout';
import { previewPriceReCalculator, useGetBooking } from '../../lib/booking';
import Loading from '../../components/Loading/Loading';
import ToastText from '../../components/ToastText';

import PreviewData from './PreviewData';

dayjs.extend(utc);

const PAYMENT_METHODS = {
  CREDIT_CARD: 'cc',
  WIRE: 'wire',
  CASH: 'cash'
};

const PAYMENT_METHODS_LABELS = [
  { label: 'Credit Card', value: PAYMENT_METHODS.CREDIT_CARD },
  { label: 'Wire', value: PAYMENT_METHODS.WIRE },
  {
    label: 'Cash',
    value: PAYMENT_METHODS.CASH
  }
];

const PAYMENT_STATUS = {
  PAID: 'succeeded',
  PENDING: 'pending',
  FAILED: 'failed',
  NONE: 'none'
};

const NEW_INSTALLMENT = {
  installment: 100,
  method: PAYMENT_METHODS.CREDIT_CARD,
  total: 0,
  status: PAYMENT_STATUS.NONE,
  new: true
};

export default function PriceReCalculator() {
  const [showFees, setShowFees] = useState(false);
  const { id } = useParams();
  const { data, isLoading } = useQuery(['booking', id], () => useGetBooking(id), {
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });
  const [previewedData, setPreviewedData] = useState(null);

  if (isLoading)
    return (
      <MainLayout title="Price Recalculator">
        <Loading />
      </MainLayout>
    );

  const { payment_plan, people_count, price_per_person, duration, payments } = data.booking;

  const initialData = {
    price_per_person: payment_plan ? payment_plan.original.price : price_per_person,
    quantity: payment_plan ? payment_plan.original.quantity : people_count,
    duration: payment_plan ? payment_plan.original.duration : duration,
    installments: payment_plan ? payment_plan.final : [],
    displayCurrency: payment_plan ? payment_plan.original.displayCurrency : 'USD',
    currency: payment_plan?.original.currency,
    newInstallments: [],
    EXCHANGE_FEE: payment_plan ? payment_plan.config.FEE_VALUES.EXCHANGE_FEE : '',
    CREDIT_CARD_FEE: payment_plan ? payment_plan.config.FEE_VALUES.CREDIT_CARD_FEE : '',
    CUSTOMER_FEE: payment_plan ? payment_plan.config.FEE_VALUES.CUSTOMER_FEE : '',
    GUIDE_FEE: payment_plan ? payment_plan.config.FEE_VALUES.GUIDE_FEE : ''
  };

  const handlePreview = async (values) => {
    const newInstallments = values.installments.filter((installment) => installment.new);
    const oldInstallments = values.installments.filter((installment) => !installment.new);

    const otherNewInstallment = oldInstallments
      .filter((v) => v.data.total === '')
      .map((v) => ({
        method: v.data.fragment.method,
        total: 0,
        installment: v.installment,
        first: v.first
      }));
    const mergedNewInstallments = [...newInstallments, ...otherNewInstallment];
    const allNewInstallments = mergedNewInstallments.map(({ method, total }, key) => ({
      method,
      total: Number(total),
      installment:
        mergedNewInstallments.length === 1 ? 100 : Number(100 / mergedNewInstallments.length),
      first: oldInstallments.length === 0 && key === 0 && true
    }));

    const mappedValues = {
      ...values,
      installments: oldInstallments
        .filter((v) => v.data.total !== '')
        .map((installment) => ({
          ...installment,
          data: {
            ...installment.data,
            total: payment_plan.final.find(
              (finalInstallment) => finalInstallment._id === installment._id
            ).data.total
          },

          total: Number(installment.data.total)
        })),
      newInstallments: allNewInstallments
    };

    const newInstallmentsPercentage = mappedValues.newInstallments.reduce(
      (acc, { installment }) => acc + installment,
      0
    );

    if (newInstallmentsPercentage !== 100 && allNewInstallments.length > 0) {
      return toast.error(
        <ToastText>
          New installments must sum 100%. You have {newInstallments.length} new installments with a
          total of {newInstallmentsPercentage}%
        </ToastText>
      );
    }

    try {
      const newData = await previewPriceReCalculator(mappedValues, id);
      return setPreviewedData(newData);
    } catch (error) {
      console.log(error.message);
    }
  };

  const closePreview = () => {
    setPreviewedData(null);
  };

  return (
    <>
      <MainLayout title="Price ReCalculator">
        <Formik initialValues={initialData} validateOnChange={false} onSubmit={handlePreview}>
          {({ values, setFieldValue }) => (
            <Form>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Guide currency</FormLabel>
                  <Input simpleField disabled name="currency" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Lead currency</FormLabel>
                  <Input simpleField disabled name="displayCurrency" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Travellers</FormLabel>
                  <Input simpleField name="quantity" type="number" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Duration in days</FormLabel>
                  <Input simpleField name="duration" type="number" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Price per person</FormLabel>
                  <Input simpleField name="price_per_person" type="number" />
                </Box>
                <Button onClick={() => setShowFees(!showFees)}>
                  {showFees ? 'Hide fees' : 'Show fees'}
                </Button>
                {showFees && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                      }}>
                      <FormLabel>Exchange fee</FormLabel>
                      <Input simpleField name="EXCHANGE_FEE" type="number" />
                    </Box>
                    {values.EXCHANGE_FEE === '' && 'Not defined'}

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                      }}>
                      <FormLabel>Credit card fee</FormLabel>
                      <Input simpleField name="CREDIT_CARD_FEE" type="number" />
                    </Box>
                    {values.CREDIT_CARD_FEE === '' && 'Not defined'}

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                      }}>
                      <FormLabel>Customer fee</FormLabel>
                      <Input simpleField name="CUSTOMER_FEE" type="number" />
                    </Box>
                    {values.CUSTOMER_FEE === '' && 'Not defined'}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                      }}>
                      <FormLabel>Guide fee</FormLabel>
                      <Input simpleField name="GUIDE_FEE" type="number" />
                    </Box>
                    {values.GUIDE_FEE === '' && 'Not defined'}
                  </>
                )}
              </Box>

              <FormLabel sx={{ marginTop: '20px' }}>Installments</FormLabel>
              <Box sx={{ overflowX: 'auto' }}>
                <FieldArray
                  name="installments"
                  render={(arrayHelpers) => (
                    <Table sx={{ tableLayout: 'auto', marginTop: '20px' }}>
                      <thead>
                        <tr>
                          <th style={{ height: '20px' }}> </th>
                          <th style={{ height: '20px' }}>
                            <Typography>Amount</Typography>
                          </th>
                          <th style={{ height: '20px' }}>
                            <Typography>Paid at</Typography>
                          </th>
                          <th style={{ height: '20px' }}>
                            <Typography>Method</Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.installments && values.installments.length > 0 ? (
                          values.installments.map((installment, index) => {
                            const payment = payments.find(
                              (p) => p.installment_id === installment._id
                            );
                            const previousInstallment = payment_plan.final[index];
                            return (
                              <tr key={installment._id || `new-${index}`}>
                                <td>
                                  <Box sx={{ display: 'flex', gap: '4px' }}>
                                    <Button
                                      variant="outlined"
                                      size="xs"
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}>
                                      -<tool-tip role="tooltip">Remove this installment</tool-tip>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      size="xs"
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.insert(index + 1, NEW_INSTALLMENT)
                                      }>
                                      +
                                      <tool-tip role="tooltip">
                                        Add a new payment after this one
                                      </tool-tip>
                                    </Button>
                                  </Box>
                                </td>
                                <td>
                                  <Typography level="body3">
                                    {installment.new
                                      ? 'Amount will be calculated automatically'
                                      : `Original amount: ${
                                        previousInstallment?.data?.total || ''
                                      } (Leave empty to automatically)`}
                                  </Typography>
                                  <Input
                                    type="number"
                                    disabled={
                                      installment.new ||
                                      (payment?.status === PAYMENT_STATUS.PENDING &&
                                        PAYMENT_METHODS.CREDIT_CARD)
                                    }
                                    name={
                                      installment.new
                                        ? `installments.${index}.total`
                                        : `installments.${index}.data.total`
                                    }
                                  />
                                </td>
                                <td>
                                  {(payment && payment.status === PAYMENT_STATUS.PAID) ||
                                  (payment &&
                                    payment.status === PAYMENT_STATUS.PENDING &&
                                    payment.method === PAYMENT_METHODS.CREDIT_CARD) ? (
                                      <Typography>
                                        {dayjs.utc(payment?.ts).format('DD MMM, YY')}
                                      </Typography>
                                    ) : null}
                                </td>
                                <td>
                                  <Select
                                    name={
                                      installment.new
                                        ? `installments.${index}.method`
                                        : `installments.${index}.data.fragment.method`
                                    }
                                    items={PAYMENT_METHODS_LABELS}
                                    onChange={() => {
                                      if (installment.new) return;
                                      setFieldValue(
                                        `installments.${index}.data.fragment.oldMethod`,
                                        installment.data.fragment.method
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <button type="button" onClick={() => arrayHelpers.push(NEW_INSTALLMENT)}>
                            Add an installment
                          </button>
                        )}
                      </tbody>
                    </Table>
                  )}
                />
              </Box>
              <Box sx={{ marginTop: 3 }}>
                <Button variant="outlined" type="submit" size="sm">
                  Preview new payment plan
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </MainLayout>
      {previewedData ? (
        <PreviewData previewedData={previewedData} closePreview={closePreview} />
      ) : null}
    </>
  );
}
