import Blockly from 'blockly';

import { getMailsTemplates } from '../../lib/mails';

import HtmlGenerator from './html-generators';

const htmlBlocks = [
  {
    type: 'for_loop_html',
    message0: 'for each index in %1 do %2',
    args0: [
      {
        type: 'field_input',
        name: 'FROM'
      },
      {
        type: 'input_statement',
        name: 'DO'
      }
    ],
    previousStatement: null,
    nextStatement: null
  },
  {
    type: 'baseframe',
    message0: 'document %1 header %2 %3 content %4 %5',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'head',
        check: 'header'
      },
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'body',
        check: 'html'
      }
    ],
    colour: 0,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'html',
    message0: 'document %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'document'
      }
    ],
    colour: 0,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'body',
    message0: 'content %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: 'document',
    nextStatement: 'document',
    colour: 0,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'head',
    message0: 'header %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'header'
      }
    ],
    previousStatement: 'document',
    nextStatement: 'document',
    colour: 0,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'title',
    message0: 'title %1',
    args0: [
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: 'header',
    nextStatement: 'header',
    colour: 0,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'paragraph',
    message0: 'paragraph %1 %2',
    args0: [
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: 'html',
    nextStatement: 'html',
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'plaintext',
    message0: 'text %1',
    args0: [
      {
        type: 'field_input',
        name: 'content',
        text: ''
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 330,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'division',
    message0: 'division %1 %2',
    args0: [
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: 'html',
    nextStatement: 'html',
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'style',
    message0: 'style =  %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'NAME',
        check: 'css'
      }
    ],
    inputsInline: true,
    output: 'attribute',
    colour: 230,
    tooltip: '',
    helpUrl: ''
  },
  {
    type: 'color',
    message0: 'text colour :  %1',
    args0: [
      {
        type: 'field_colour',
        name: 'NAME',
        colour: '#ff0000'
      }
    ],
    previousStatement: 'css',
    nextStatement: 'css',
    colour: 230,
    tooltip: '',
    helpUrl: ''
  },
  {
    type: 'bgcolour',
    message0: 'background colour :  %1',
    args0: [
      {
        type: 'field_colour',
        name: 'NAME',
        colour: '#ff0000'
      }
    ],
    previousStatement: 'css',
    nextStatement: 'css',
    colour: 230,
    tooltip: '',
    helpUrl: ''
  },
  {
    type: 'genericstyle',
    message0: '%1 : %2',
    args0: [
      {
        type: 'field_input',
        name: 'property',
        text: 'property'
      },
      {
        type: 'field_input',
        name: 'value',
        text: 'value'
      }
    ],
    previousStatement: 'css',
    nextStatement: 'css',
    colour: 230,
    tooltip: '',
    helpUrl: ''
  },
  {
    type: 'generictag',
    message0: '< %1 > %2 %3',
    args0: [
      {
        type: 'field_input',
        name: 'NAME',
        text: 'tag'
      },
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'more_attributes',
    message0: '%1 %2 %3',
    args0: [
      {
        type: 'input_value',
        name: 'NAME1',
        check: 'attribute'
      },
      {
        type: 'input_value',
        name: 'NAME2',
        check: 'attribute'
      },
      {
        type: 'input_value',
        name: 'NAME3',
        check: 'attribute'
      }
    ],
    output: 'attribute',
    colour: 230,
    tooltip: '',
    helpUrl: ''
  },
  {
    type: 'genericattribute',
    message0: '%1  =  %2',
    args0: [
      {
        type: 'field_input',
        name: 'attribute',
        text: 'attribute'
      },
      {
        type: 'field_input',
        name: 'value',
        text: 'value'
      }
    ],
    inputsInline: true,
    output: 'attribute',
    colour: 230,
    tooltip: '',
    helpUrl: ''
  },
  {
    type: 'link',
    message0: 'link to %1 %2 %3 %4',
    args0: [
      {
        type: 'field_input',
        name: 'NAME',
        text: 'target'
      },
      {
        type: 'input_dummy'
      },
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: 'html',
    nextStatement: 'html',
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'span',
    message0: 'span %1 %2',
    args0: [
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: 'html',
    nextStatement: 'html',
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'image',
    message0: 'image url="%1" alt="%2" width=%3 height=%4 %5',
    args0: [
      {
        type: 'field_input',
        name: 'IMAGE',
        text: 'URL'
      },
      {
        type: 'field_input',
        name: 'ALT',
        text: 'alternative text'
      },
      {
        type: 'field_input',
        name: 'WIDTH',
        text: 'width'
      },
      {
        type: 'field_input',
        name: 'HEIGHT',
        text: 'height'
      },
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      }
    ],
    previousStatement: 'html',
    nextStatement: 'html',
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'emphasise',
    message0: 'emphasise %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'strong',
    message0: 'important %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'headline',
    message0: 'headline %1 %2 %3',
    args0: [
      {
        type: 'field_dropdown',
        name: 'NAME',
        options: [
          ['level 1', 'h1'],
          ['level 2', 'h2'],
          ['level 3', 'h2'],
          ['level 4', 'h4'],
          ['level 5', 'h5'],
          ['level 6', 'h6']
        ]
      },
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'linebreak',
    message0: 'line break',
    previousStatement: null,
    nextStatement: null,
    colour: 330,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'horizontalbreak',
    message0: 'topic break',
    previousStatement: null,
    nextStatement: null,
    colour: 330,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'unorderedlist',
    message0: 'unordered list %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'NAME'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 330,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'orderedlist',
    message0: 'ordered list %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'NAME'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 330,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'listelement',
    message0: 'list item %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'inserted',
    message0: 'inserted %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'italic',
    message0: 'italic %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: null
  },
  {
    type: 'deleted',
    message0: 'deleted %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'super',
    message0: 'superscript %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'sub',
    message0: 'subscript %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'code',
    message0: 'code %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'quote',
    message0: 'quote %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'blockquote',
    message0: 'blockquote %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'sample',
    message0: 'sample output %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'keyboard',
    message0: 'keyboard input %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'variable',
    message0: 'variable %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'form',
    message0: 'form %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'table',
    message0: 'table width=%1 align=%2 %3 %4',
    args0: [
      {
        type: 'field_input',
        name: 'WIDTH',
        text: 'width'
      },
      {
        type: 'field_input',
        name: 'ALIGN',
        text: 'align'
      },
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'table'
      }
    ],
    previousStatement: 'html',
    nextStatement: 'html',
    colour: 180,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'tablerow',
    message0: 'row %1 %2 %3',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'tablerow'
      }
    ],
    previousStatement: 'table',
    nextStatement: 'table',
    colour: 180,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'tablecell',
    message0: 'entry %1 %2 %3',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 180,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'input_text',
    message0: 'text input %1',
    args0: [
      {
        type: 'field_input',
        name: 'default',
        text: ''
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'button',
    message0: 'button %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'NAME'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'input',
    message0: '%1 input %2 %3',
    args0: [
      {
        type: 'field_dropdown',
        name: 'type',
        options: [
          ['text', 'text'],
          ['email', 'email'],
          ['number', 'number'],
          ['password', 'password'],
          ['checkbox', 'checkbox'],
          ['radiobutton', 'radio'],
          ['button', 'button'],
          ['colour', 'color'],
          ['date', 'date'],
          ['local time', 'datetime-local'],
          ['file', 'file'],
          ['hidden', 'hidden'],
          ['image', 'image'],
          ['month', 'month'],
          ['range', 'range'],
          ['reset', 'reset'],
          ['search', 'search'],
          ['submit', 'submit'],
          ['telephone number', 'tel'],
          ['time', 'time'],
          ['url', 'url'],
          ['week', 'week']
        ]
      },
      {
        type: 'field_input',
        name: 'value',
        text: ''
      },
      {
        type: 'input_value',
        name: 'text'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'script',
    message0: 'script %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'code'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 90,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  },
  {
    type: 'onclick',
    message0: 'on click =  %1 %2',
    args0: [
      {
        type: 'input_dummy'
      },
      {
        type: 'input_statement',
        name: 'NAME',
        check: 'code'
      }
    ],
    inputsInline: true,
    output: 'attribute',
    colour: 230,
    tooltip: '',
    helpUrl: ''
  },
  {
    type: 'body_attributes',
    message0: 'content %1 %2',
    args0: [
      {
        type: 'input_value',
        name: 'NAME',
        check: 'attribute'
      },
      {
        type: 'input_statement',
        name: 'content',
        check: 'html'
      }
    ],
    previousStatement: 'document',
    nextStatement: 'document',
    colour: 0,
    tooltip: '',
    helpUrl: 'http://www.w3schools.com/tags/tag_html.asp'
  }
];

Blockly.defineBlocksWithJsonArray([
  {
    type: 'logic_boolean_html',
    message0: '%1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'BOOL',
        options: [
          ['%{BKY_LOGIC_BOOLEAN_TRUE}', 'TRUE'],
          ['%{BKY_LOGIC_BOOLEAN_FALSE}', 'FALSE']
        ]
      }
    ],
    output: 'Boolean',
    style: 'logic_blocks',
    tooltip: '%{BKY_LOGIC_BOOLEAN_TOOLTIP}',
    helpUrl: '%{BKY_LOGIC_BOOLEAN_HELPURL}'
  },
  {
    type: 'controls_if_html',
    message0: '%{BKY_CONTROLS_IF_MSG_IF} %1',
    args0: [
      {
        type: 'input_value',
        name: 'IF0',
        check: 'Boolean'
      }
    ],
    message1: '%{BKY_CONTROLS_IF_MSG_THEN} %1',
    args1: [
      {
        type: 'input_statement',
        name: 'DO0'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    style: 'logic_blocks',
    helpUrl: '%{BKY_CONTROLS_IF_HELPURL}',
    suppressPrefixSuffix: true,
    mutator: 'controls_if_mutator',
    extensions: ['controls_if_tooltip']
  },
  // If/else block that does not use a mutator.
  {
    type: 'controls_ifelse_html',
    message0: '%{BKY_CONTROLS_IF_MSG_IF} %1',
    args0: [
      {
        type: 'input_value',
        name: 'IF0',
        check: 'Boolean'
      }
    ],
    message1: '%{BKY_CONTROLS_IF_MSG_THEN} %1',
    args1: [
      {
        type: 'input_statement',
        name: 'DO0'
      }
    ],
    message2: '%{BKY_CONTROLS_IF_MSG_ELSE} %1',
    args2: [
      {
        type: 'input_statement',
        name: 'ELSE'
      }
    ],
    previousStatement: null,
    nextStatement: null,
    style: 'logic_blocks',
    tooltip: '%{BKYCONTROLS_IF_TOOLTIP_2}',
    helpUrl: '%{BKY_CONTROLS_IF_HELPURL}',
    suppressPrefixSuffix: true,
    extensions: ['controls_if_tooltip']
  },
  {
    type: 'logic_value_exists_html',
    message0: 'Value exists %1',
    args0: [
      {
        type: 'field_input',
        name: 'IF'
      }
    ],
    output: null,
    style: 'logic_blocks',
    helpUrl: null
  },
  {
    type: 'logic_null_html',
    message0: '%{BKY_LOGIC_NULL}',
    output: null,
    style: 'logic_blocks',
    tooltip: '%{BKY_LOGIC_NULL_TOOLTIP}',
    helpUrl: '%{BKY_LOGIC_NULL_HELPURL}'
  },
  {
    type: 'logic_compare_html',
    message0: '%1 %2 %3',
    args0: [
      {
        type: 'field_input',
        name: 'A'
      },
      {
        type: 'field_dropdown',
        name: 'OP',
        options: [
          ['=', 'EQ'],
          ['\u2260', 'NEQ'],
          ['\u200F<', 'LT'],
          ['\u200F\u2264', 'LTE'],
          ['\u200F>', 'GT'],
          ['\u200F\u2265', 'GTE']
        ]
      },
      {
        type: 'input_value',
        name: 'B'
      }
    ],
    inputsInline: true,
    output: 'Boolean',
    style: 'logic_blocks',
    helpUrl: '%{BKY_LOGIC_COMPARE_HELPURL}',
    extensions: ['logic_compare', 'logic_op_tooltip']
  },
  {
    type: 'logic_compare_variables_html',
    message0: '%1 %2 %3',
    args0: [
      {
        type: 'field_input',
        name: 'A'
      },
      {
        type: 'field_dropdown',
        name: 'OP',
        options: [
          ['=', 'EQ'],
          ['\u2260', 'NEQ'],
          ['\u200F<', 'LT'],
          ['\u200F\u2264', 'LTE'],
          ['\u200F>', 'GT'],
          ['\u200F\u2265', 'GTE']
        ]
      },
      {
        type: 'field_input',
        name: 'B'
      }
    ],
    inputsInline: true,
    output: 'Boolean',
    style: 'logic_blocks',
    helpUrl: '%{BKY_LOGIC_COMPARE_HELPURL}',
    extensions: ['logic_compare', 'logic_op_tooltip']
  },
  {
    type: 'logic_operation_html',
    message0: '%1 %2 %3',
    args0: [
      {
        type: 'input_value',
        name: 'A',
        check: 'Boolean'
      },
      {
        type: 'field_dropdown',
        name: 'OP',
        options: [
          ['%{BKY_LOGIC_OPERATION_AND}', 'AND'],
          ['%{BKY_LOGIC_OPERATION_OR}', 'OR']
        ]
      },
      {
        type: 'input_value',
        name: 'B',
        check: 'Boolean'
      }
    ],
    inputsInline: true,
    output: 'Boolean',
    style: 'logic_blocks',
    helpUrl: '%{BKY_LOGIC_OPERATION_HELPURL}',
    extensions: ['logic_op_tooltip']
  },
  {
    type: 'logic_negate_html',
    message0: '%{BKY_LOGIC_NEGATE_TITLE}',
    args0: [
      {
        type: 'input_value',
        name: 'BOOL',
        check: 'Boolean'
      }
    ],
    output: 'Boolean',
    style: 'logic_blocks',
    tooltip: '%{BKY_LOGIC_NEGATE_TOOLTIP}',
    helpUrl: '%{BKY_LOGIC_NEGATE_HELPURL}'
  },
  {
    type: 'logic_ternary_html',
    message0: '%{BKY_LOGIC_TERNARY_CONDITION} %1',
    args0: [
      {
        type: 'input_value',
        name: 'IF',
        check: 'Boolean'
      }
    ],
    message1: '%{BKY_LOGIC_TERNARY_IF_TRUE} %1',
    args1: [
      {
        type: 'field_input',
        name: 'THEN'
      }
    ],
    message2: '%{BKY_LOGIC_TERNARY_IF_FALSE} %1',
    args2: [
      {
        type: 'field_input',
        name: 'ELSE'
      }
    ],
    output: null,
    style: 'logic_blocks',
    tooltip: '%{BKY_LOGIC_TERNARY_TOOLTIP}',
    helpUrl: '%{BKY_LOGIC_TERNARY_HELPURL}',
    extensions: ['logic_ternary']
  }
  // Block representing the if statement in the controls_if mutator.
  // {
  //   type: 'controls_if_if_html',
  //   message0: '%{BKY_CONTROLS_IF_IF_TITLE_IF}',
  //   nextStatement: null,
  //   enableContextMenu: false,
  //   style: 'logic_blocks',
  //   tooltip: '%{BKY_CONTROLS_IF_IF_TOOLTIP}'
  // },
  // Block representing the else-if statement in the controls_if mutator.
  // {
  //   type: 'controls_if_elseif_html',
  //   message0: '%{BKY_CONTROLS_IF_ELSEIF_TITLE_ELSEIF}',
  //   previousStatement: null,
  //   nextStatement: null,
  //   enableContextMenu: false,
  //   style: 'logic_blocks',
  //   tooltip: '%{BKY_CONTROLS_IF_ELSEIF_TOOLTIP}'
  // },
  // // Block representing the else statement in the controls_if mutator.
  // {
  //   type: 'controls_if_else_html',
  //   message0: '%{BKY_CONTROLS_IF_ELSE_TITLE_ELSE}',
  //   previousStatement: null,
  //   enableContextMenu: false,
  //   style: 'logic_blocks',
  //   tooltip: '%{BKY_CONTROLS_IF_ELSE_TOOLTIP}'
  // }
]);

export default async function generateHtmlBlocks() {
  Object.keys(htmlBlocks).forEach((iBlock) => {
    function makeBlock(json) {
      Blockly.Blocks[json.type] = {
        init() {
          this.jsonInit(json);
        }
      };
    }
    makeBlock(htmlBlocks[iBlock]);
  });

  try {
    const { data: blocks } = await getMailsTemplates();
    Blockly.Blocks.template = {
      init() {
        this.jsonInit({
          message0: 'Templates dropdown %1',
          args0: [
            {
              type: 'field_dropdown',
              name: 'TEMPLATE',
              options: blocks
                .filter((block) => block.type !== 'component')
                .map((block) => [
                  block.template_key,
                  `<include key="${block.template_key}"></include>`
                ])
            }
          ],
          tooltip: '',
          previousStatement: null,
          nextStatement: null
        });
      }
    };

    blocks
      .filter((block) => block.type === 'component')
      .forEach((block) => {
        const fields = [];

        block.inputs.forEach((input) => {
          fields.push({
            type: input.type || 'field_input',
            name: input.name,
            text: input.name
          });
        });

        Blockly.Blocks[block.template_key] = {
          init() {
            this.jsonInit({
              message0: `${block.template_key} ${fields.map((f, k) => `%${k + 1}`).join(' ')}`,
              args0: [...fields],
              tooltip: '',
              previousStatement: null,
              nextStatement: null
            });
          }
        };

        HtmlGenerator[block.template_key] = function (blockin) {
          const properties = [];
          block.inputs.forEach((input) => {
            if (input.type === 'input_statement') {
              properties.push({
                name: input.name,
                value: HtmlGenerator.statementToCode(blockin, input.name)
              });
            } else if (input.type === 'input_value') {
              properties.push({
                name: input.name,
                value: HtmlGenerator.valueToCode(blockin, input.name, HtmlGenerator.ORDER_ATOMIC)
              });
            } else {
              properties.push({ name: input.name, value: blockin.getFieldValue(input.name) });
            }
          });

          return `<include key="${block.template_key}">
           ${properties
             .map(
               (p) => `<variable key="${p.name}">${p.value?.replaceAll('\n', '').trim()}</variable>`
             )
             .join(' ')} 
            </include>`;
        };
      });

    HtmlGenerator.template = function (block) {
      const code = block.getFieldValue('TEMPLATE');

      return `${code}\n`;
    };
  } catch (error) {
    console.log(error);
  }
}
