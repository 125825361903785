import { FilterAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Typography
} from '@mui/joy';

import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import SharedModal from '../../components/SharedModal';
import debounce from '../../utils/debounce';
import { getGuidesWithAggregation } from '../../lib/guide';
import Loading from '../../components/Loading/Loading';

export default function Filters({ handleFilterByGuide, handleFilterByStatus, setSearch }) {
  const [aggregation, setAggregation] = useState([
    {
      $match: {
        first_name: { $regex: '', $options: 'i' }
      }
    },
    { $limit: 5 }
  ]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const { data, isLoading, isRefetching, refetch } = useQuery(['guides'], () =>
    getGuidesWithAggregation(aggregation)
  );

  const searchRecord = debounce((e) => {
    const searchTerm = e.target.value;
    setAggregation([
      {
        $match: {
          first_name: { $regex: searchTerm, $options: 'i' }
        }
      },
      {
        $limit: 5
      }
    ]);
  }, 500);

  useEffect(() => {
    refetch();
  }, [aggregation]);

  return (
    <>
      <Box sx={{ position: 'relative ' }}>
        {window.location.search.length ? (
          <Box
            sx={{
              background: 'red',
              height: '10px',
              width: '10px',
              borderRadius: '100%',
              right: '-3px',
              top: '-3px',
              zIndex: 1,
              position: 'absolute'
            }}
          />
        ) : null}
        <IconButton variant="outlined" onClick={() => setFilterModalOpen(true)}>
          <FilterAlt />
        </IconButton>
      </Box>
      <SharedModal isOpen={filterModalOpen} close={() => setFilterModalOpen(false)}>
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <Box sx={{ marginTop: '20px' }}>
            <FormLabel>Filter by status</FormLabel>
            <Select onChange={(e, v) => handleFilterByStatus(v)}>
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
              <Option value="Draft">Draft</Option>
            </Select>
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <FormLabel>Filter by guide</FormLabel>
            <Input placeholder="Mitja" onChange={searchRecord} />
            <Box
              sx={{
                display: 'flex',
                background: 'white',
                flexDirection: 'column',
                gap: '10px',
                marginTop: '10px'
              }}>
              {isLoading || isRefetching ? (
                <Loading />
              ) : (
                data.data.map((guide) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                      cursor: 'pointer',
                      ':hover': { background: '#fafafa' },
                      padding: '8px'
                    }}
                    onClick={() => handleFilterByGuide(guide._id)}>
                    <img
                      height={30}
                      width={30}
                      src={guide.avatar_url}
                      alt="guide"
                      style={{ objectFit: 'cover', borderRadius: '4px' }}
                    />
                    <Typography key={guide._id}>
                      {guide.first_name} {guide.last_name}
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                setFilterModalOpen(false);
              }}>
              Confirm
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setFilterModalOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setSearch('');
                window.history.pushState({}, '', '/trips');
                setFilterModalOpen(false);
              }}>
              Clear filters
            </Button>
          </DialogActions>
        </DialogContent>
      </SharedModal>
    </>
  );
}
