import { Box, Button, Typography } from '@mui/joy';
import { useState } from 'react';
import { JsonToTable } from 'react-json-to-table';
import formatMoney from '../../utils/formatMoney';

const NORMAL_LEAD = 'normal_lead';
const NORMAL_GUIDE = 'normal_guide';
const TABLE = 'table';

const ParseResult = ({ result }) => {
  const [view, setView] = useState(NORMAL_LEAD);
  if (!result) return null;

  const currencyToShow =
    view === NORMAL_LEAD ? result.original.displayCurrency : result.original.currency;

  return (
    <Box>
      <Typography level="h3" sx={{ margin: '15px 0px' }}>
        Results
      </Typography>
      <Box sx={{ margin: '15px 0px', display: 'flex', gap: '4px' }}>
        <Button
          variant="outlined"
          sx={{
            background: view === TABLE ? '#ddf1fe' : '',
            border: view === TABLE ? 'var(--variant-borderWidth) solid' : ''
          }}
          onClick={() => setView(TABLE)}>
          Table view
        </Button>
        <Button
          variant="outlined"
          sx={{
            background: view === NORMAL_LEAD ? '#ddf1fe' : '',
            border: view === NORMAL_LEAD ? 'var(--variant-borderWidth) solid' : ''
          }}
          onClick={() => setView(NORMAL_LEAD)}>
          Normal view - Lead
        </Button>
        <Button
          variant="outlined"
          sx={{
            background: view === NORMAL_GUIDE ? '#ddf1fe' : '',
            border: view === NORMAL_GUIDE ? 'var(--variant-borderWidth) solid' : ''
          }}
          onClick={() => setView(NORMAL_GUIDE)}>
          Normal view - Guide
        </Button>
      </Box>

      {view === TABLE ? (
        <JsonToTable json={result} />
      ) : (
        <>
          <Box sx={{ margin: '15px 0px' }}>
            <Typography level="h5">Original:</Typography>
            <Box sx={{ margin: '10px' }}>
              <Typography>
                <span style={{ fontWeight: 'bold' }}> Price: </span>{' '}
                {formatMoney(result.original.price, result.original.currency)}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}> Quantity: </span> {result.original.quantity}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}> Duration: </span> {result.original.duration}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}> Guide currency: </span>{' '}
                {result.original.currency}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}> Lead currency: </span>
                {result.original.displayCurrency}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ margin: '15px 0px' }}>
            <Typography level="h5">Payments:</Typography>
            <Box sx={{ margin: '0px 10px', display: 'flex', gap: '10px' }}>
              {(view === NORMAL_LEAD ? result.final : result.originalCurrencyFinal).map(
                (payment, index) => (
                  <Box key={index} sx={{ marginTop: '15px', background: 'white', padding: '20px' }}>
                    <Typography>
                      <span style={{ fontWeight: 'bold' }}> Payment number: </span> {index + 1}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: 'bold' }}> Percentage: </span>{' '}
                      {payment.installment}%
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: 'bold' }}> Method: </span>{' '}
                      {payment.data.fragment.method}
                    </Typography>
                    <Typography>
                      <span style={{ fontWeight: 'bold' }}> Price: </span>
                      {currencyToShow}
                      {formatMoney(payment.data.price, currencyToShow)}
                    </Typography>
                    <Box>
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>Fees: </span>
                      </Typography>
                      <Box sx={{ marginLeft: '10px' }}>
                        {payment.data.summary.map((fee, index) => (
                          <Box key={index}>
                            <Typography>
                              <span style={{ fontWeight: 'bold' }}> Name: </span> {fee.name}
                            </Typography>
                            <Typography>
                              <span style={{ fontWeight: 'bold' }}> Amount: </span> {currencyToShow}{' '}
                              {formatMoney(fee.value, currencyToShow)}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>

                    <Box>
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>Hidden fees: </span>
                      </Typography>
                      <Box sx={{ marginLeft: '10px' }}>
                        {payment.data.hidden.map((fee, index) => (
                          <Box key={index}>
                            <Typography>
                              <span style={{ fontWeight: 'bold' }}> Name: </span> {fee.name}
                            </Typography>
                            <Typography>
                              <span style={{ fontWeight: 'bold' }}> Amount: </span> {currencyToShow}{' '}
                              {formatMoney(fee.value, currencyToShow)}
                            </Typography>
                            {/* <Typography>
                              <span style={{ fontWeight: 'bold' }}> Per unit: </span>{' '}
                              {view === NORMAL_LEAD
                                ? result.original.displayCurrency
                                : result.original.currency}{' '}
                              {formatMoney(fee.perUnit)}
                            </Typography> */}
                            {/* <Typography>
                              <span style={{ fontWeight: 'bold' }}>Currency: </span> {fee.currency}
                            </Typography> */}
                          </Box>
                        ))}
                      </Box>
                    </Box>

                    <Box>
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>Subtotal: </span> {currencyToShow}{' '}
                        {formatMoney(payment.data.subtotal, currencyToShow)}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>Total: </span> {currencyToShow}{' '}
                        {formatMoney(payment.data.total, currencyToShow)}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>Rate: </span> {payment.data.rate}
                      </Typography>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          </Box>

          {view === NORMAL_LEAD ? (
            <Box sx={{ margin: '15px 0px' }}>
              <Typography level="h5">Computed calculations in lead currency:</Typography>
              <Box sx={{ margin: '10px' }}>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}> Total: </span>{' '}
                  {result.original.displayCurrency}{' '}
                  {formatMoney(result.computedCalculations.total, result.original.displayCurrency)}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Subtotal: </span>{' '}
                  {result.original.displayCurrency}{' '}
                  {formatMoney(
                    result.computedCalculations.subtotal,
                    result.original.displayCurrency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Total customer fee: </span>{' '}
                  {result.original.displayCurrency}{' '}
                  {formatMoney(
                    result.computedCalculations.totalCustomerFee,
                    result.original.displayCurrency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Total cc fee: </span>{' '}
                  {result.original.displayCurrency}{' '}
                  {formatMoney(
                    result.computedCalculations.totalCcFee,
                    result.original.displayCurrency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Total guide fee: </span>{' '}
                  {result.original.displayCurrency}{' '}
                  {formatMoney(
                    result.computedCalculations.guideFee,
                    result.original.displayCurrency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Exchange fee: </span>{' '}
                  {result.computedCalculations.exchange_fee.currency}{' '}
                  {formatMoney(
                    result.computedCalculations.exchange_fee.value,
                    result.computedCalculations.exchange_fee.currency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Original exchange fee: </span>{' '}
                  {result.computedCalculations.original_exchange_fee?.currency}{' '}
                  {formatMoney(
                    result.computedCalculations.original_exchange_fee?.value,
                    result.computedCalculations.original_exchange_fee?.currency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Price per person without fees: </span>{' '}
                  {result.original.displayCurrency}{' '}
                  {formatMoney(
                    result.computedCalculations.pricePerPerson,
                    result.original.displayCurrency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Price per person with fees: </span>{' '}
                  {result.original.displayCurrency}{' '}
                  {formatMoney(
                    result.computedCalculations.pricePerPersonTotal,
                    result.original.displayCurrency
                  )}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ margin: '15px 0px' }}>
              <Typography level="h5">Computed calculations in guide currency:</Typography>
              <Box sx={{ margin: '10px' }}>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}> Total: </span> {result.original.currency}{' '}
                  {formatMoney(
                    result.originalCurrencyComputedCalculations.totalWithoutExchangeFee,
                    result.original.currency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Subtotal: </span> {result.original.currency}{' '}
                  {formatMoney(
                    result.originalCurrencyComputedCalculations.subtotal,
                    result.original.currency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Total customer fee: </span>{' '}
                  {result.original.currency}{' '}
                  {formatMoney(
                    result.originalCurrencyComputedCalculations.totalCustomerFee,
                    result.original.currency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Total cc fee: </span>{' '}
                  {result.original.currency}{' '}
                  {formatMoney(
                    result.originalCurrencyComputedCalculations.totalCcFee,
                    result.original.currency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Total guide fee: </span>{' '}
                  {result.original.currency}{' '}
                  {formatMoney(
                    result.originalCurrencyComputedCalculations.guideFee,
                    result.original.currency
                  )}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Price per person with fees: </span>{' '}
                  {result.original.currency}{' '}
                  {formatMoney(
                    result.originalCurrencyComputedCalculations.pricePerPersonTotal,
                    result.original.currency
                  )}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ParseResult;
