import { Box, Checkbox } from '@mui/joy';

export default function Selection({ setFieldValue, isRecommended }) {
  return (
    <Box
      sx={{
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
      }}>
      <Checkbox
        label="Recommended tag"
        defaultChecked={isRecommended}
        onChange={(e) => setFieldValue('explore_share_selection', e.target.checked)}
      />
    </Box>
  );
}
