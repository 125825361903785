import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/joy';

import { useEffect, useReducer } from 'react';

import MainLayout from '../../../components/Layouts/MainLayout';
import { useGetBooking } from '../../../lib/booking';
import Loading from '../../../components/Loading/Loading';
import { useGetGuides } from '../../../lib/guide';

import BookingInfo from './BookingInfo';
import NewBookingInfo from './NewBookingInfo';

const actionTypes = {
  PRICE_PER_PERSON: 'price_per_person',
  PEOPLE_COUNT: 'people_count',
  DURATION: 'duration',
  DEPARTURE_DATE: 'departure_date',
  CUSTOMER_FEE: 'customer_fee',
  STATE: 'state',
  CANCELLATION_REASON: 'cancellation_reason',
  PAYMENTS: 'payments',
  PAYMENT_PLAN: 'payment_plan',
  GUIDE: 'guide',
  RESET: 'reset',
  PAYMENT_METHOD: 'payment_method',
  PAYMENT_OPTIONS_METHOD: 'payment_options_method',
  PAYMENT_OPTIONS_DEPOSIT_PERCENTAGE: 'payment_options_deposit_percentage',
  GUIDE_STATE: 'guide_state'
};

function reducer(state, action) {
  const type = action.type.toUpperCase();
  if (action.payload == null) return state;
  if (action.payload === '') return state;
  if (!actionTypes[type]) return state;
  if (action.type === actionTypes.RESET) return { ...action.payload, updated: false };

  if (actionTypes.PAYMENT_OPTIONS_DEPOSIT_PERCENTAGE === action.type) {
    return {
      ...state,
      updated: !!action.payload,
      payment_options: {
        ...state.payment_options,
        deposit_percentage: action.payload
      }
    };
  }

  if (actionTypes.PAYMENT_OPTIONS_METHOD === action.type) {
    return {
      ...state,
      updated: !!action.payload,
      payment_options: {
        ...state.payment_options,
        payment_method: action.payload,
        rest_to_the_guide: action.payload === 'cash'
      }
    };
  }

  return {
    ...state,
    updated: !!action.payload,
    [action.type]: action.payload
  };
}

export default function UpdateBooking() {
  const { id } = useParams();
  const { data: guidesData, isLoading: loadingGuide } = useQuery(['guides'], useGetGuides);
  const { data, isLoading } = useQuery(['booking', id], () => useGetBooking(id));
  const [newBooking, dispatch] = useReducer(reducer, { ...data?.booking, updated: false });

  useEffect(() => {
    if (data?.booking) dispatch({ type: actionTypes.RESET, payload: data?.booking });
  }, [data?.booking]);

  if (isLoading || loadingGuide)
    return (
      <MainLayout title="Update booking">
        <Loading />
      </MainLayout>
    );

  if (!data) {
    return (
      <MainLayout title="Update booking">
        <Box sx={{ marginTop: '20px' }}>
          <Typography level="h4">Booking not found</Typography>
        </Box>
      </MainLayout>
    );
  }

  const { booking } = data;

  return (
    <MainLayout title="Update booking" topTitle={`${booking.code} ${booking.version}`}>
      <Box sx={{ marginTop: '20px' }}>
        <BookingInfo booking={newBooking} />
        <NewBookingInfo
          newBooking={newBooking}
          booking={booking}
          dispatch={dispatch}
          actionTypes={actionTypes}
          guides={guidesData}
        />
      </Box>
    </MainLayout>
  );
}
