import { Box, Typography } from '@mui/joy';

import { LOCALES } from '../../constants/defaults';
import { useLocale } from '../../hooks/useLocale';

export default function LocaleSelector({ customSetLocale }) {
  const [locale] = useLocale();

  const handleSetLocale = (l) => {
    window.location.search = `?locale=${l}`;
    window.reload();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {Object.keys(LOCALES).map((l) => (
        <Box
          key={l}
          sx={{
            background: locale === l ? '#d1d1d1' : '#e8e8e8',
            padding: '6px 10px',
            border: 'none',
            cursor: 'pointer',
            '&:hover':
              locale === l
                ? null
                : {
                  background: '#ddd'
                },
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
          }}
          onClick={() => (customSetLocale ? customSetLocale(l) : handleSetLocale(l))}>
          <Typography>{LOCALES[l]}</Typography>
        </Box>
      ))}
    </Box>
  );
}
