import { getMailsTemplates } from '../../lib/mails';

export default async () => {
  const { data: blocks } = await getMailsTemplates();
  return {
    kind: 'categoryToolbox',
    contents: [
      {
        kind: 'category',
        name: 'Templates',
        colour: 210,
        contents: [
          {
            kind: 'block',
            type: 'template'
          },
          ...blocks
            .filter((block) => block.type === 'component')
            .map((block) => ({ kind: 'block', type: block.template_key }))
        ]
      },
      {
        kind: 'category',
        name: 'Logic',
        colour: 210,
        contents: [
          {
            kind: 'block',
            type: 'controls_if_html'
          },
          {
            kind: 'block',
            type: 'logic_compare_html'
          },
          {
            kind: 'block',
            type: 'logic_compare_variables_html'
          },
          {
            kind: 'block',
            blockxml: '<block type="logic_operation_html"><field name="OP">AND</field></block>'
          },
          {
            kind: 'block',
            type: 'logic_negate_html'
          },
          {
            kind: 'block',
            blockxml: '<block type="logic_boolean_html"><field name="BOOL">TRUE</field></block>'
          },
          {
            kind: 'block',
            type: 'logic_null_html'
          },
          {
            kind: 'block',
            type: 'logic_value_exists_html'
          },
          {
            kind: 'block',
            type: 'logic_ternary_html'
          },
          {
            kind: 'block',
            type: 'controls_ifelse_html'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Loops',
        colour: 120,
        contents: [
          {
            kind: 'block',
            type: 'for_loop_html'
          },
          {
            kind: 'block',
            blockxml:
              '<block type="controls_repeat_ext">\n' +
              '      <value name="TIMES">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">10</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="controls_whileUntil">\n' +
              '      <field name="MODE">WHILE</field>\n' +
              '    </block>'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="controls_for">\n' +
              '      <field name="VAR" id="C(8;cYCF}~vSgkxzJ+{O" variabletype="">i</field>\n' +
              '      <value name="FROM">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">1</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="TO">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">10</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="BY">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">1</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="controls_forEach">\n' +
              '      <field name="VAR" id="Cg!CSk/ZJo2XQN3=VVrz" variabletype="">j</field>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="controls_flow_statements">\n' +
              '      <field name="FLOW">BREAK</field>\n' +
              '    </block>\n'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Math',
        colour: 230,
        contents: [
          {
            kind: 'block',
            blockxml:
              '    <block type="math_round">\n' +
              '      <field name="OP">ROUND</field>\n' +
              '      <value name="NUM">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">3.1</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_number">\n' +
              '      <field name="NUM">0</field>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_single">\n' +
              '      <field name="OP">ROOT</field>\n' +
              '      <value name="NUM">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">9</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_trig">\n' +
              '      <field name="OP">SIN</field>\n' +
              '      <value name="NUM">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">45</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_constant">\n' +
              '      <field name="CONSTANT">PI</field>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_number_property">\n' +
              '      <mutation divisor_input="false"></mutation>\n' +
              '      <field name="PROPERTY">EVEN</field>\n' +
              '      <value name="NUMBER_TO_CHECK">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">0</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_arithmetic">\n' +
              '      <field name="OP">ADD</field>\n' +
              '      <value name="A">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">1</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="B">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">1</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_on_list">\n' +
              '      <mutation op="SUM"></mutation>\n' +
              '      <field name="OP">SUM</field>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_modulo">\n' +
              '      <value name="DIVIDEND">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">64</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="DIVISOR">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">10</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_constrain">\n' +
              '      <value name="VALUE">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">50</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="LOW">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">1</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="HIGH">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">100</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="math_random_int">\n' +
              '      <value name="FROM">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">1</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="TO">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">100</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            type: 'math_random_float'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Text',
        colour: 160,
        contents: [
          {
            kind: 'block',
            blockxml:
              '    <block type="text_charAt">\n' +
              '      <mutation at="true"></mutation>\n' +
              '      <field name="WHERE">FROM_START</field>\n' +
              '      <value name="VALUE">\n' +
              '        <block type="variables_get">\n' +
              '          <field name="VAR" id="q@$ZF(L?Zo/z`d{o.Bp!" variabletype="">text</field>\n' +
              '        </block>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text">\n' + '      <field name="TEXT"></field>\n' + '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_append">\n' +
              '      <field name="VAR" id=":};P,s[*|I8+L^-.EbRi" variabletype="">item</field>\n' +
              '      <value name="TEXT">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT"></field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_length">\n' +
              '      <value name="VALUE">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT">abc</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_isEmpty">\n' +
              '      <value name="VALUE">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT"></field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_indexOf">\n' +
              '      <field name="END">FIRST</field>\n' +
              '      <value name="VALUE">\n' +
              '        <block type="variables_get">\n' +
              '          <field name="VAR" id="q@$ZF(L?Zo/z`d{o.Bp!" variabletype="">text</field>\n' +
              '        </block>\n' +
              '      </value>\n' +
              '      <value name="FIND">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT">abc</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_join">\n' +
              '      <mutation items="2"></mutation>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_getSubstring">\n' +
              '      <mutation at1="true" at2="true"></mutation>\n' +
              '      <field name="WHERE1">FROM_START</field>\n' +
              '      <field name="WHERE2">FROM_START</field>\n' +
              '      <value name="STRING">\n' +
              '        <block type="variables_get">\n' +
              '          <field name="VAR" id="q@$ZF(L?Zo/z`d{o.Bp!" variabletype="">text</field>\n' +
              '        </block>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_changeCase">\n' +
              '      <field name="CASE">UPPERCASE</field>\n' +
              '      <value name="TEXT">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT">abc</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_trim">\n' +
              '      <field name="MODE">BOTH</field>\n' +
              '      <value name="TEXT">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT">abc</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_print">\n' +
              '      <value name="TEXT">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT">abc</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="text_prompt_ext">\n' +
              '      <mutation type="TEXT"></mutation>\n' +
              '      <field name="TYPE">TEXT</field>\n' +
              '      <value name="TEXT">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT">abc</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Lists',
        colour: 259,
        contents: [
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_indexOf">\n' +
              '      <field name="END">FIRST</field>\n' +
              '      <value name="VALUE">\n' +
              '        <block type="variables_get">\n' +
              '          <field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>\n' +
              '        </block>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_create_with">\n' +
              '      <mutation items="0"></mutation>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_repeat">\n' +
              '      <value name="NUM">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">5</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            type: 'lists_length'
          },
          {
            kind: 'block',
            type: 'lists_isEmpty'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_create_with">\n' +
              '      <mutation items="3"></mutation>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_getIndex">\n' +
              '      <mutation statement="false" at="true"></mutation>\n' +
              '      <field name="MODE">GET</field>\n' +
              '      <field name="WHERE">FROM_START</field>\n' +
              '      <value name="VALUE">\n' +
              '        <block type="variables_get">\n' +
              '          <field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>\n' +
              '        </block>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_setIndex">\n' +
              '      <mutation at="true"></mutation>\n' +
              '      <field name="MODE">SET</field>\n' +
              '      <field name="WHERE">FROM_START</field>\n' +
              '      <value name="LIST">\n' +
              '        <block type="variables_get">\n' +
              '          <field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>\n' +
              '        </block>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_getSublist">\n' +
              '      <mutation at1="true" at2="true"></mutation>\n' +
              '      <field name="WHERE1">FROM_START</field>\n' +
              '      <field name="WHERE2">FROM_START</field>\n' +
              '      <value name="LIST">\n' +
              '        <block type="variables_get">\n' +
              '          <field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>\n' +
              '        </block>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_split">\n' +
              '      <mutation mode="SPLIT"></mutation>\n' +
              '      <field name="MODE">SPLIT</field>\n' +
              '      <value name="DELIM">\n' +
              '        <shadow type="text">\n' +
              '          <field name="TEXT">,</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="lists_sort">\n' +
              '      <field name="TYPE">NUMERIC</field>\n' +
              '      <field name="DIRECTION">1</field>\n' +
              '    </block>\n'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Colour',
        colour: 19,
        contents: [
          {
            kind: 'block',
            blockxml:
              '    <block type="colour_picker">\n' +
              '      <field name="COLOUR">#ff0000</field>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            type: 'colour_random'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="colour_rgb">\n' +
              '      <value name="RED">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">100</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="GREEN">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">50</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="BLUE">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">0</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          },
          {
            kind: 'block',
            blockxml:
              '    <block type="colour_blend">\n' +
              '      <value name="COLOUR1">\n' +
              '        <shadow type="colour_picker">\n' +
              '          <field name="COLOUR">#ff0000</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="COLOUR2">\n' +
              '        <shadow type="colour_picker">\n' +
              '          <field name="COLOUR">#3333ff</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '      <value name="RATIO">\n' +
              '        <shadow type="math_number">\n' +
              '          <field name="NUM">0.5</field>\n' +
              '        </shadow>\n' +
              '      </value>\n' +
              '    </block>\n'
          }
        ]
      },
      { kind: 'sep' },
      {
        kind: 'category',
        name: 'Custom Button',
        colour: 19,
        contents: [
          {
            kind: 'button',
            text: 'A button',
            callbackKey: 'myFirstButtonPressed'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Base frame',
        colour: 230,
        contents: [
          {
            kind: 'block',
            type: 'html',
            blockxml:
              '<block type="html">       <statement name="content">         <block type="head">           <statement name="content">             <block type="title">               <statement name="content">                 <block type="plaintext">                   <field name="content"></field>                 </block>               </statement>             </block>           </statement>           <next>             <block type="body"></block>           </next>         </block>       </statement>     </block>'
          },
          {
            kind: 'block',
            type: 'head',
            blockxml:
              '<block type="head">           <statement name="content">             <block type="title">               <statement name="content">                 <block type="plaintext">                   <field name="content"></field>                 </block>               </statement>             </block>           </statement>           <next>             <block type="body"></block>           </next>         </block>'
          },
          {
            kind: 'block',
            type: 'title',
            blockxml:
              '<block type="title">               <statement name="content">                 <block type="plaintext">                   <field name="content"></field>                 </block>               </statement>             </block>'
          },
          {
            kind: 'block',
            type: 'plaintext',
            blockxml:
              '<block type="plaintext">                   <field name="content"></field>                 </block>'
          },
          {
            kind: 'block',
            type: 'body',
            blockxml: '<block type="body"></block>'
          },
          {
            kind: 'block',
            type: 'html',
            blockxml: '<block type="html"></block>'
          },
          {
            kind: 'block',
            type: 'body',
            blockxml: '<block type="body"></block>'
          },
          {
            kind: 'block',
            type: 'body_attributes',
            blockxml: '<block type="body_attributes"></block>'
          },
          {
            kind: 'block',
            type: 'head',
            blockxml: '<block type="head"></block>'
          },
          {
            kind: 'block',
            type: 'title',
            blockxml:
              '<block type="title">       <statement name="content">         <block type="plaintext">           <field name="content"></field>         </block>       </statement>     </block>'
          },
          {
            kind: 'block',
            type: 'plaintext',
            blockxml:
              '<block type="plaintext">           <field name="content"></field>         </block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Text structure',
        colour: 160,
        contents: [
          {
            kind: 'block',
            type: 'plaintext',
            blockxml: '<block type="plaintext">       <field name="content"></field>     </block>'
          },
          {
            kind: 'block',
            type: 'horizontalbreak',
            blockxml: '<block type="horizontalbreak"></block>'
          },
          {
            kind: 'block',
            type: 'linebreak',
            blockxml: '<block type="linebreak"></block>'
          },
          {
            kind: 'block',
            type: 'paragraph',
            blockxml: '<block type="paragraph"></block>'
          },
          {
            kind: 'block',
            type: 'headline',
            blockxml: '<block type="headline">       <field name="NAME">h1</field>     </block>'
          },
          {
            kind: 'block',
            type: 'link',
            blockxml: '<block type="link">       <field name="NAME">target</field>     </block>'
          },
          {
            kind: 'block',
            type: 'image',
            blockxml:
              '<block type="image">       <field name="IMAGE">URL</field>       <field name="ALT">alternative text</field> <field name="WIDTH">width</field>  <field name="HEIGHT">height</field>  <field name="STYLE">style</field>  </block>'
          },
          {
            kind: 'block',
            type: 'generictag',
            blockxml: '<block type="generictag">       <field name="NAME">tag</field>     </block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Text markup',
        colour: 210,
        contents: [
          {
            kind: 'block',
            type: 'emphasise',
            blockxml: '<block type="emphasise"></block>'
          },
          {
            kind: 'block',
            type: 'italic',
            blockxml: '<block type="italic"></block>'
          },
          {
            kind: 'block',
            type: 'inserted',
            blockxml: '<block type="inserted"></block>'
          },
          {
            kind: 'block',
            type: 'strong',
            blockxml: '<block type="strong"></block>'
          },
          {
            kind: 'block',
            type: 'deleted',
            blockxml: '<block type="deleted"></block>'
          },
          {
            kind: 'block',
            type: 'super',
            blockxml: '<block type="super"></block>'
          },
          {
            kind: 'block',
            type: 'sub',
            blockxml: '<block type="sub"></block>'
          },
          {
            kind: 'block',
            type: 'code',
            blockxml: '<block type="code"></block>'
          },
          {
            kind: 'block',
            type: 'quote',
            blockxml: '<block type="quote"></block>'
          },
          {
            kind: 'block',
            type: 'blockquote',
            blockxml: '<block type="blockquote"></block>'
          },
          {
            kind: 'block',
            type: 'sample',
            blockxml: '<block type="sample"></block>'
          },
          {
            kind: 'block',
            type: 'keyboard',
            blockxml: '<block type="keyboard"></block>'
          },
          {
            kind: 'block',
            type: 'variable',
            blockxml: '<block type="variable"></block>'
          },
          {
            kind: 'block',
            type: 'division',
            blockxml: '<block type="division"></block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Style',
        colour: 310,
        contents: [
          {
            kind: 'block',
            type: 'span',
            blockxml:
              '<block type="span">       <value name="NAME">         <block type="style"></block>       </value>     </block>'
          },
          {
            kind: 'block',
            type: 'style',
            blockxml: '<block type="style"></block>'
          },
          {
            kind: 'block',
            type: 'style',
            blockxml: '<block type="style"></block>'
          },
          {
            kind: 'block',
            type: 'color',
            blockxml: '<block type="color">       <field name="NAME">#ff0000</field>     </block>'
          },
          {
            kind: 'block',
            type: 'bgcolour',
            blockxml:
              '<block type="bgcolour">       <field name="NAME">#ff0000</field>     </block>'
          },
          {
            kind: 'block',
            type: 'genericstyle',
            blockxml:
              '<block type="genericstyle">       <field name="property">property</field>       <field name="value">value</field>     </block>'
          },
          {
            kind: 'block',
            type: 'span',
            blockxml: '<block type="span"></block>'
          },
          {
            kind: 'block',
            type: 'division',
            blockxml: '<block type="division"></block>'
          },
          {
            kind: 'block',
            type: 'generictag',
            blockxml: '<block type="generictag">       <field name="NAME">tag</field>     </block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Enumeration',
        colour: 120,
        contents: [
          {
            kind: 'block',
            type: 'unorderedlist',
            blockxml: '<block type="unorderedlist"></block>'
          },
          {
            kind: 'block',
            type: 'orderedlist',
            blockxml: '<block type="orderedlist"></block>'
          },
          {
            kind: 'block',
            type: 'listelement',
            blockxml: '<block type="listelement"></block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Tables',
        colour: 190,
        contents: [
          {
            kind: 'block',
            type: 'table',
            blockxml:
              '<block type="table">    <field name="WIDTH">width</field> <field name="ALIGN">align</field>    <statement name="content">         <block type="tablerow">           <statement name="content">             <block type="tablecell">               <next>                 <block type="tablecell"></block>               </next>             </block>           </statement>           <next>             <block type="tablerow">               <statement name="content">                 <block type="tablecell">                   <next>                     <block type="tablecell"></block>                   </next>                 </block>               </statement>             </block>           </next>         </block>       </statement>     </block>'
          },
          {
            kind: 'block',
            type: 'tablerow',
            blockxml:
              '<block type="tablerow">           <statement name="content">             <block type="tablecell">               <next>                 <block type="tablecell"></block>               </next>             </block>           </statement>           <next>             <block type="tablerow">               <statement name="content">                 <block type="tablecell">                   <next>                     <block type="tablecell"></block>                   </next>                 </block>               </statement>             </block>           </next>         </block>'
          },
          {
            kind: 'block',
            type: 'tablecell',
            blockxml:
              '<block type="tablecell">               <next>                 <block type="tablecell"></block>               </next>             </block>'
          },
          {
            kind: 'block',
            type: 'tablecell',
            blockxml: '<block type="tablecell"></block>'
          },
          {
            kind: 'block',
            type: 'tablerow',
            blockxml:
              '<block type="tablerow">               <statement name="content">                 <block type="tablecell">                   <next>                     <block type="tablecell"></block>                   </next>                 </block>               </statement>             </block>'
          },
          {
            kind: 'block',
            type: 'tablecell',
            blockxml:
              '<block type="tablecell">                   <next>                     <block type="tablecell"></block>                   </next>                 </block>'
          },
          {
            kind: 'block',
            type: 'tablecell',
            blockxml: '<block type="tablecell"></block>'
          },
          {
            kind: 'block',
            type: 'table',
            blockxml: '<block type="table"></block>'
          },
          {
            kind: 'block',
            type: 'tablerow',
            blockxml: '<block type="tablerow"></block>'
          },
          {
            kind: 'block',
            type: 'tablecell',
            blockxml: '<block type="tablecell"></block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Forms',
        colour: 330,
        contents: [
          {
            kind: 'block',
            type: 'form',
            blockxml: '<block type="form"></block>'
          },
          {
            kind: 'block',
            type: 'input_text',
            blockxml: '<block type="input_text">       <field name="default"></field>     </block>'
          },
          {
            kind: 'block',
            type: 'button',
            blockxml: '<block type="button"></block>'
          },
          {
            kind: 'block',
            type: 'input',
            blockxml:
              '<block type="input">       <field name="type">text</field>       <field name="value"></field>     </block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Script',
        colour: 120,
        contents: [
          {
            kind: 'block',
            type: 'script',
            blockxml: '<block type="script"></block>'
          },
          {
            kind: 'block',
            type: 'onclick',
            blockxml: '<block type="onclick"></block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Variables',
        custom: 'VARIABLE',
        colour: 330
      },
      {
        kind: 'category',
        name: 'Variables HTML',
        colour: 630,
        contents: [
          {
            kind: 'block',
            type: 'variables_get',
            blockxml: '<block type="variables_get"></block>'
          },
          {
            kind: 'block',
            type: 'variables_set',
            blockxml: '<block type="variables_set"></block>'
          }
        ]
      },
      {
        kind: 'category',
        name: 'Functions',
        custom: 'PROCEDURE',
        colour: 290
      }
    ]
  };
};
