import { Formik, Form } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Box, Button, Checkbox, FormLabel, Slider } from '@mui/joy';
import { Input } from '@esui/lib.formik.input';
import { Select } from '@esui/lib.formik.select';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

import { applyPaymentPlan, usePostPriceCalculator } from '../../lib/priceCalculator';
import Simple from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import ButtonLoading from '../../components/ButtonLoading';
import { useGetCurrenciesAvailable } from '../../lib/currencies';
import { FEES } from '../../constants/defaults';
import SharedModal from '../../components/SharedModal';

import ParseResult from './ParseResult';

const PriceCalculator = () => {
  const [result, setResult] = useState(null);

  const { data, isLoading } = useQuery(['currenciesAvailable'], () => useGetCurrenciesAvailable());

  const mutation = useMutation(
    (values) => {
      return usePostPriceCalculator(values);
    },
    {
      onSuccess: (v) => setResult(v)
    }
  );

  if (isLoading)
    return (
      <Simple title="Price Calculator">
        <Loading />
      </Simple>
    );

  const PAYMENT_MODALITIES = {
    UP_FRONT: '75-later',
    FULL: 'total-now'
  };

  const PAYMENT_METHODS = {
    CREDIT_CARD: 'cc',
    WIRE: 'wire',
    DEPOSIT: 'deposit',
    STRIPE: 'stripe',
    NONE: 'none',
    INSTALLMENTS: 'installments',
    CASH: 'cash',
    GROUP: 'group'
  };

  const initialValues = {
    price: 1000,
    quantity: 1,
    duration: 1,
    currency: 'EUR',
    displayCurrency: 'USD',
    modality: PAYMENT_MODALITIES.FULL,
    EXCHANGE_FEE: FEES.EXCHANGE_FEE,
    CREDIT_CARD_FEE: FEES.CREDIT_CARD_FEE,
    CUSTOMER_FEE: FEES.CUSTOMER_FEE,
    GUIDE_FEE: FEES.GUIDE_FEE,
    method: PAYMENT_METHODS.NONE,
    installments: 1,
    depositPercentage: 25,
    customRate: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={(values) => {
        const newValues = { ...values };

        if (newValues.modality === PAYMENT_METHODS.INSTALLMENTS) {
          const fragmentation = (() => {
            const installments = [];
            const percentageEachInstallment = 100 / newValues.installments;

            for (let i = 1; i <= newValues.installments; i++) {
              installments.push({
                installment: percentageEachInstallment,
                method: PAYMENT_METHODS.CREDIT_CARD,
                first: i === 1
              });
            }

            return installments;
          })();
          newValues.fragmentation = fragmentation;
        }

        if (newValues.modality === PAYMENT_METHODS.GROUP) {
          const fragmentation = (() => {
            const installments = [];
            const percentageEachInstallment = 100 / newValues.quantity;

            for (let i = 1; i <= newValues.quantity; i++) {
              installments.push({
                installment: percentageEachInstallment,
                method: PAYMENT_METHODS.CREDIT_CARD,
                first: i === 1
              });
            }

            return installments;
          })();
          newValues.fragmentation = fragmentation;
        }

        if (newValues.modality === PAYMENT_MODALITIES.UP_FRONT) {
          const fragmentation = [
            {
              installment: newValues.depositPercentage,
              method: PAYMENT_METHODS.CREDIT_CARD,
              first: true
            },
            {
              installment: 100 - newValues.depositPercentage,
              method: newValues.method,
              first: false
            }
          ];
          newValues.fragmentation = fragmentation;
        }

        if (newValues.method === `${PAYMENT_METHODS.WIRE}-deposit`) {
          const fragmentation = [
            {
              installment: newValues.depositPercentage,
              method: PAYMENT_METHODS.WIRE,
              first: true
            },
            {
              installment: 100 - newValues.depositPercentage,
              method: PAYMENT_METHODS.WIRE,
              first: false
            }
          ];
          newValues.fragmentation = fragmentation;
        } else if (newValues.method === PAYMENT_METHODS.GROUP) {
          const fragmentation = (() => {
            const installments = [];
            const percentageEachInstallmentDeposit =
              newValues.depositPercentage / newValues.quantity;

            const percentageEachInstallment =
              (100 - newValues.depositPercentage) / newValues.quantity;

            // it contruct each first payments (it include customer_fee in each one)
            for (let i = 1; i <= newValues.quantity; i++) {
              installments.push({
                installment: percentageEachInstallmentDeposit,
                method: PAYMENT_METHODS.CREDIT_CARD,
                first: true
              });
            }
            // it contruct each second payments (it only include credit_card_fee in each one)
            for (let i = 1; i <= newValues.quantity; i++) {
              installments.push({
                installment: percentageEachInstallment,
                method: PAYMENT_METHODS.CREDIT_CARD,
                first: false
              });
            }

            return installments;
          })();
          newValues.fragmentation = fragmentation;
        }

        if (newValues.modality === PAYMENT_METHODS.CASH) {
          newValues.method = PAYMENT_METHODS.CASH;
          newValues.fragmentation = [
            {
              installment: 100,
              method: PAYMENT_METHODS.CASH,
              first: true
            }
          ];
        }

        mutation.mutate(newValues);
      }}>
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Simple
              title="Price Calculator"
              options={
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Calculate
                </ButtonLoading>
              }>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  //  justifyContent: 'space-between',
                  marginTop: '10px'
                }}>
                <FormLabel>Price</FormLabel>
                <Select
                  sx={{
                    marginTop: '10px'
                  }}
                  name="currency"
                  // label="Currency"
                  // eslint-disable-next-line no-unused-vars
                  items={data.currencies.map((value) => ({
                    value,
                    label: value
                  }))}
                />
                <Input
                  simpleField
                  name="price"
                  type="number"
                  sx={{
                    flex: 1
                  }}
                />
              </Box>

              <Box sx={{ marginTop: '10px' }} />
              <Select
                name="displayCurrency"
                label="Exchange to"
                // eslint-disable-next-line no-unused-vars
                items={data.currencies.map((value) => ({
                  value,
                  label: value
                }))}
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}>
                <FormLabel>Rate (optional)</FormLabel>
                <Input simpleField name="customRate" type="number" />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}>
                <FormLabel>Travellers</FormLabel>
                <Input simpleField name="quantity" type="number" />
              </Box>

              <DurationInDays />

              <Box sx={{ marginTop: '10px' }} />
              <Select
                name="modality"
                label="Modality"
                // eslint-disable-next-line no-unused-vars
                items={[
                  { label: 'FULL', value: PAYMENT_MODALITIES.FULL },
                  { label: 'UP FRONT', value: PAYMENT_MODALITIES.UP_FRONT },
                  { label: 'INSTALLMENTS', value: PAYMENT_METHODS.INSTALLMENTS },
                  { label: 'CASH', value: PAYMENT_METHODS.CASH },
                  { label: 'GROUP', value: PAYMENT_METHODS.GROUP }
                ]}
              />
              <Box sx={{ marginBottom: '10px' }} />

              {values.modality === PAYMENT_MODALITIES.UP_FRONT && (
                <Select
                  name="method"
                  label="Payment method"
                  // eslint-disable-next-line no-unused-vars
                  items={[
                    { label: 'Credit Card', value: PAYMENT_METHODS.CREDIT_CARD },
                    { label: 'Wire', value: PAYMENT_METHODS.WIRE },
                    { label: 'Wire in deposit', value: `${PAYMENT_METHODS.WIRE}-deposit` },
                    { label: 'Group', value: PAYMENT_METHODS.GROUP },
                    { label: 'None', value: PAYMENT_METHODS.NONE }
                  ]}
                />
              )}

              {values.modality === PAYMENT_MODALITIES.FULL && (
                <Select
                  name="method"
                  label="Payment method"
                  // eslint-disable-next-line no-unused-vars
                  items={[
                    { label: 'Credit Card', value: PAYMENT_METHODS.CREDIT_CARD },
                    { label: 'Wire', value: PAYMENT_METHODS.WIRE },
                    { label: 'None', value: PAYMENT_METHODS.NONE }
                  ]}
                />
              )}

              {values.modality === PAYMENT_MODALITIES.UP_FRONT && (
                <Box
                  sx={{
                    marginTop: '10px'
                  }}>
                  <FormLabel>Deposit percentage</FormLabel>
                  <Slider
                    defaultValue={25}
                    step={5}
                    min={10}
                    max={90}
                    valueLabelDisplay="on"
                    marks={[
                      {
                        value: 15,
                        label: '15%'
                      },
                      {
                        value: 50,
                        label: '50%'
                      },
                      {
                        value: 85,
                        label: '85%'
                      }
                    ]}
                    onChange={(e, value) => {
                      setFieldValue('depositPercentage', value);
                    }}
                  />
                </Box>
              )}

              {values.modality === PAYMENT_METHODS.INSTALLMENTS && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                  }}>
                  <FormLabel>Number of installments</FormLabel>
                  <Input simpleField name="installments" type="number" />
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}>
                <FormLabel>Exchange fee</FormLabel>
                <Input simpleField name="EXCHANGE_FEE" type="number" />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}>
                <FormLabel>Credit card fee</FormLabel>
                <Input simpleField name="CREDIT_CARD_FEE" type="number" />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}>
                <FormLabel>Customer fee</FormLabel>
                <Input simpleField name="CUSTOMER_FEE" type="number" />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '10px'
                }}>
                <FormLabel>Guide fee</FormLabel>
                <Input simpleField name="GUIDE_FEE" type="number" />
              </Box>

              {result ? (
                <Box sx={{ margin: '10px 0px' }}>
                  <Link to="/booking/test-price-recalculator" state={result}>
                    <Button>Recalculate</Button>
                  </Link>
                  <ApplyToBooking result={result} />
                </Box>
              ) : null}

              <ParseResult result={result} />
            </Simple>
          </Form>
        );
      }}
    </Formik>
  );
};

function DurationInDays() {
  const [isVariable, setIsVariable] = useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px'
      }}>
      <Box>
        <FormLabel>Duration in days</FormLabel>
        <Checkbox
          checked={isVariable}
          label="Is variable?"
          onChange={() => setIsVariable(!isVariable)}
        />
      </Box>
      <Input simpleField name="duration" type="number" disabled={!isVariable} />
    </Box>
  );
}

function ApplyToBooking({ result }) {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [bookingCode, setBookingCode] = useState('');

  const _applyPaymentPlan = async () => {
    try {
      await toast.promise(applyPaymentPlan(result, bookingCode), {
        loading: 'Applying payment plan...',
        success: 'Payment plan applied successfully',
        error: 'Error applying payment plan'
      });
      setConfirmOpen(false);
      setIsOpen(false);
    } catch {}
  };

  return (
    <>
      <SharedModal isOpen={isOpen} close={() => setIsOpen(false)}>
        <Box>
          <Input
            name="bookingCode"
            label="Booking CODE"
            onChange={(e) => setBookingCode(e.target.value)}
          />
          <Button sx={{ marginTop: '10px' }} onClick={() => setConfirmOpen(true)}>
            Apply
          </Button>
        </Box>
        <SharedModal isOpen={confirmOpen} close={() => setConfirmOpen(false)}>
          <Box>
            <Box sx={{ marginBottom: '10px' }}>
              Are you sure you want to apply this to the booking?
            </Box>
            <Button onClick={_applyPaymentPlan}>Yes</Button>
            <Button sx={{ marginLeft: '10px' }} onClick={() => setConfirmOpen(false)}>
              No
            </Button>
          </Box>
        </SharedModal>
      </SharedModal>
      <Button sx={{ marginLeft: '10px' }} onClick={() => setIsOpen(true)}>
        Apply to booking
      </Button>
    </>
  );
}

export default PriceCalculator;
