import { Box, Button, Typography, Input, Stack } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import LocaleSelector from '../LandingPage/LocaleSelector';
import SharedModal from '../SharedModal';
import { DEFAULT_LOCALE } from '../../constants/defaults';
import { getMailPreview, sendPreviewMail } from '../../lib/mails';
import Loading from '../Loading/Loading';

import MakeCurrentVersion from './MakeCurrentVersion';

function PreviewModal({ isOpen, close, i18n, locale, isCurrentVersion, mail_name }) {
  const [previewLocale, setPreviewLocale] = useState(locale);
  const queryClient = useQueryClient();
  const [bookingCode, setBookingCode] = useState('');

  const html = i18n[previewLocale]?.content || i18n[DEFAULT_LOCALE]?.content;
  const subject = i18n[previewLocale]?.subject || i18n[DEFAULT_LOCALE]?.subject;

  const {
    data: previewMailData,
    isLoading,
    refetch
  } = useQuery(
    ['mail-preview'],
    async () => {
      try {
        return await getMailPreview({ previewLocale, html, subject, bookingCode, mail_name });
      } catch (error) {
        console.log(error);
        return true;
      }
    },
    {
      enabled: isOpen
    }
  );

  useEffect(() => {
    if (!isOpen) return;
    toast.promise(refetch(), {
      loading: 'Loading preview...',
      success: 'Loaded!',
      error: 'An error occurred'
    });
  }, [previewLocale]);

  const [email, setEmail] = useState('');

  if (!previewMailData || !isOpen) return null;

  if (isLoading) {
    return (
      <SharedModal isOpen={isOpen} close={close}>
        <Loading />
      </SharedModal>
    );
  }

  const { html: previewMail, subject: previewSubject } = previewMailData;

  const handleSendPreview = async () => {
    if (!email) return toast.error('Please enter an email');
    await toast.promise(sendPreviewMail(email, { previewMail, previewSubject }), {
      loading: 'Sending...',
      success: 'Mail sent!',
      error: 'An error occurred'
    });
  };

  const handleTestBooking = async () => {
    if (!bookingCode) return toast.error('Please enter a booking code');
    await toast.promise(refetch(), {
      loading: 'Loading booking data...',
      success: 'Loaded!',
      error: 'An error occurred'
    });
    return null;
  };

  const customSetLocale = (l) => {
    setPreviewLocale(l);
    queryClient.invalidateQueries(['mail-preview']);
  };

  return (
    <SharedModal isOpen={isOpen} close={close}>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Box>
          <Box sx={{ position: 'sticky', top: '10px' }}>
            <Box sx={{ marginTop: '20px', display: 'flex' }}>
              <Input
                label="Email"
                name="email"
                placeholder="your@email.com"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button onClick={handleSendPreview}>Send preview</Button>
            </Box>
            <Box sx={{ display: 'flex', marginBottom: '20px' }}>
              <Input
                label="Booking code"
                name="booking_code"
                placeholder="A1B2C3"
                onChange={(e) => setBookingCode(e.target.value)}
              />
              <Button onClick={handleTestBooking}>Test booking</Button>
            </Box>
            <LocaleSelector customSetLocale={customSetLocale} locale={previewLocale} />
            {!isCurrentVersion && <MakeCurrentVersion />}
          </Box>
        </Box>
        <Box>
          <Box sx={{ marginTop: '20px' }}>
            <Typography level="h6">Subject:</Typography>
            <Box dangerouslySetInnerHTML={{ __html: previewSubject }} sx={{ fontSize: '24px' }} />
          </Box>
          <Box dangerouslySetInnerHTML={{ __html: previewMail }} sx={{ marginTop: '20px' }} />
        </Box>
      </Stack>
    </SharedModal>
  );
}

export default PreviewModal;
