import { Box } from '@mui/joy';
import { Textarea } from '@esui/lib.formik.textarea';
import toast from 'react-hot-toast';

import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import TranslatedFieldLabel from '../../components/TranslatedFieldLabel/TranslatedFieldLabel';
import { patchGuide } from '../../lib/guide';
import { DEFAULT_LOCALE } from '../../constants/defaults';

import { useLocale } from '../../hooks/useLocale';

import GuideEditLayout from './GuideEditLayout';

const keysByLocale = {
  en_EN: {
    title: 'seo_tags.title',
    description: 'seo_tags.description',
    link: 'link'
  },
  fr_FR: {
    title: 'i18n.fr_FR.seo_tags.title',
    description: 'i18n.fr_FR.seo_tags.description',
    link: 'i18n.fr_FR.link'
  }
};

export default function GuideSeo() {
  const [locale] = useLocale();

  if (!locale) return null;

  const handleMutation = async (guideId, newGuide) => {
    const newData = {
      link: newGuide.link.startsWith('https://')
        ? newGuide.link
        : `https://www.explore-share.com/mountain-guide/${newGuide.link}/`,
      seo_tags: {
        title: newGuide.seo_tags.title,
        description: newGuide.seo_tags.description
      },
      i18n: {
        ...newGuide.i18n,
        fr_FR: {
          ...newGuide.i18n.fr_FR,
          link: newGuide.i18n.fr_FR.link.startsWith('https://')
            ? newGuide.i18n.fr_FR.link
            : `https://www.explore-share.com/fr/guide/${newGuide.link}/`
        }
      }
    };
    await toast.promise(patchGuide(guideId, newData), {
      loading: 'Saving changes...',
      success: 'Changes saved successfully',
      error: 'Error while saving changes'
    });
  };

  return (
    <GuideEditLayout title="SEO" handleMutation={handleMutation}>
      {(values) => (
        <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          <LocaleSelector />
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Title" />}
              name={keysByLocale[locale].title}
            />
          </Box>
          <Box>
            <Textarea
              label={<TranslatedFieldLabel label="Description" />}
              name={keysByLocale[locale].description}
            />
          </Box>
          <Box>
            <Textarea
              label="Slug"
              name={keysByLocale[locale].link}
              value={
                locale === DEFAULT_LOCALE
                  ? values.link.split('/').filter(Boolean).pop()
                  : values.i18n.fr_FR.link.split('/').filter(Boolean).pop()
              }
            />
          </Box>
        </Box>
      )}
    </GuideEditLayout>
  );
}
