/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, Typography, Box, Button } from '@mui/joy';
import { Formik, Form, FieldArray } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Textarea } from '@esui/lib.formik.textarea';

import { useGetValuesByGroup } from '../../lib/values';
import { usePatchAllFAQ, useGetTripById } from '../../lib/trip';
import Loading from '../../components/Loading/Loading';
import ButtonAsker from '../../components/ButtonAsker/ButtonAsker';
import MainLayout from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import { DEFAULT_LOCALE } from '../../constants/defaults';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import { useLocale } from '../../hooks/useLocale';

const AllEditFAQ = () => {
  const queryClient = useQueryClient();
  const { tripId } = useParams();
  const [locale] = useLocale();

  const { data: dataTrip, isLoading: isLoadingTrip } = useQuery(
    ['trip', tripId],
    async () => {
      const trip = await useGetTripById(tripId);
      const GROUPS_VALUES = `${trip.main_activity?.title.toUpperCase()}-SUGGESTED_FAQ`;

      const suggested = await useGetValuesByGroup(GROUPS_VALUES);
      return {
        trip,
        suggested
      };
    },

    {
      staleTime: Infinity
    }
  );

  // const { data: dataFAQ, isLoading: isLoadingFAQ } = useQuery(['FAQs'], () =>
  //   useGetFAQByTrip(tripId)
  // );

  const mutation = useMutation((values) => usePatchAllFAQ(tripId, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(['FAQs']);
    }
  });

  if (isLoadingTrip)
    return (
      <MainLayout title="FAQs">
        <Loading />
      </MainLayout>
    );

  const locales = Object.keys(dataTrip.trip.i18n);

  const initialValues = {
    suggested: dataTrip.suggested.map(({ key, value }) => {
      return {
        questionKey: key,
        question: value,
        answer:
          dataTrip.trip.i18n[locale]?.faqs &&
          dataTrip.trip.i18n[locale]?.faqs.find((question) => question.questionKey === key)?.answer
      };
    }),
    custom:
      (dataTrip.trip.i18n[locale]?.faqs &&
        dataTrip.trip.i18n[locale]?.faqs.filter(
          (question) => 'question' in question && !('questionKey' in question) && question
        )) ||
      []
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={(values) => {
        mutation.mutate({
          locale: locale,
          FAQs: [...values.suggested.filter((question) => question.answer !== ''), ...values.custom]
        });
      }}>
      {({ values }) => {
        return (
          <Form>
            <MainLayout
              title="FAQs"
              topTitle={dataTrip?.title}
              options={
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Save
                </ButtonLoading>
              }
              back={`/trip/${tripId}/options`}>
              {locales.length > 1 ? <LocaleSelector /> : null}
              <List>
                {locale === DEFAULT_LOCALE && dataTrip.suggested.length > 0 && (
                  <>
                    <ListItem>
                      <Typography level="h4">Suggested FAQ</Typography>
                    </ListItem>

                    <FieldArray name="suggested">
                      {() =>
                        values.suggested.length > 0 &&
                        values.suggested.map(({ questionKey, question }, index) => {
                          return (
                            <ListItem
                              key={questionKey}
                              sx={{
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start'
                              }}>
                              <Typography level="body-sm">{question}</Typography>
                              <Box sx={{ width: '100%' }}>
                                <Textarea name={`suggested.${index}.answer`} />
                              </Box>
                            </ListItem>
                          );
                        })
                      }
                    </FieldArray>
                  </>
                )}

                <ListItem>
                  <Typography level="h4">Custom FAQ</Typography>
                </ListItem>
                <FieldArray name="custom">
                  {({ push, remove }) => (
                    <>
                      {values.custom.length > 0 &&
                        values.custom.map(({ question }, index) => {
                          return (
                            <ListItem
                              key={`${index}`}
                              sx={{
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start'
                              }}>
                              <Box sx={{ width: '100%' }}>
                                <Textarea name={`custom.${index}.question`} label="Question" />
                              </Box>
                              <Box sx={{ width: '100%' }}>
                                <Textarea name={`custom.${index}.answer`} label="Answer" />
                              </Box>
                              <Box
                                sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonAsker
                                  size="sm"
                                  ask={`Are you sure remove "${question}"?`}
                                  color="danger"
                                  variant="plain"
                                  onClick={() => {
                                    remove(index);
                                  }}>
                                  Remove this question
                                </ButtonAsker>
                              </Box>
                            </ListItem>
                          );
                        })}
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          size="sm"
                          onClick={() => {
                            push({ question: '', answer: '' });
                          }}>
                          Add a custom FAQ
                        </Button>
                      </Box>
                    </>
                  )}
                </FieldArray>
              </List>
            </MainLayout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AllEditFAQ;
