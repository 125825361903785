import React, { useState } from 'react';
import { ListItem, ListItemDecorator, ListItemContent, Typography, Avatar } from '@mui/joy';

import { stringToColor } from '../../utils/general';
import {
  AssignmentTurnedIn,
  Email,
  MarkEmailRead,
  Feedback,
  Calculate,
  Score,
  Favorite,
  Flag,
  ContactEmergency
} from '@mui/icons-material';

const actionIcon = (action) => {
  const icons = {
    'E:AGENDA_JOB_COMPLETE': <AssignmentTurnedIn />,
    'E:SEND_EMAIL': <Email />,
    'E:SEND_EMAIL_SUCCESS': <MarkEmailRead />,
    'E:AGENDA_JOB_FAILED': <Feedback />,
    'A:PRICE_RECALCULATOR': <Calculate />,
    'M:CALCULATE_SCORING': <Score />,
    'E:GUIDE_CONFIRMED': <Favorite />,
    'E:UNCATEGORIZED': <Flag />,
    'E:UPDATE_ESSENTIAL_INFO': <ContactEmergency />
  };
  return icons[action] || action.split(':')[0];
};

const ActivityItem = ({ activity }) => {
  const [show, setShow] = useState(false);

  return (
    <ListItem
      onClick={() => {
        setShow((prev) => !prev);
      }}>
      <ListItemDecorator>
        <Avatar size="sm" sx={{ backgroundColor: stringToColor(activity.action) }}>
          {actionIcon(activity.action)}
        </Avatar>
      </ListItemDecorator>
      <ListItemContent>
        <Typography level="title-sm">{activity.action}</Typography>
        <Typography level="body-xs" noWrap>
          {activity.timestamp}
        </Typography>
        {show && (
          <Typography level="body-sm" sx={{ mt: 0.5, mb: 2 }}>
            <pre style={{ textWrap: 'balance' }}>{JSON.stringify(activity.data, null, 4)}</pre>
          </Typography>
        )}
      </ListItemContent>
    </ListItem>
  );
};

export default ActivityItem;
