import { Button } from '@mui/joy';

import { DEFAULT_LOCALE } from '../../constants/defaults';

export default function CopyContent({ locale, setFieldValue, values, setCopyContent, variant }) {
  return (
    locale !== DEFAULT_LOCALE && (
      <Button
        variant="outlined"
        onClick={() => {
          setFieldValue(`i18n.${locale}.${variant}`, values.i18n[DEFAULT_LOCALE][variant]);
          setFieldValue(
            `i18n.${locale}.${variant}_raw_data`,
            values.i18n[DEFAULT_LOCALE][`${variant}_raw_data`]
          );
          setCopyContent(true);
        }}>
        Copy from English
      </Button>
    )
  );
}
