import { Box, Button, Table, Typography, Chip } from '@mui/joy';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import MainLayout from '../Layouts/MainLayout';

import DeleteReview from './DeleteReview';
import EditReview from './EditReview';

export default function Reviews({ reviews, addReviewComponent }) {
  const { tripId, guideId } = useParams();
  const [reviewToEdit, setReviewToEdit] = useState(null);
  const [reviewToDelete, setReviewToDelete] = useState(null);

  const backPathname = tripId ? `/trip/${tripId}/options` : `/guide/${guideId}/`;

  if (!reviews.length) {
    return (
      <MainLayout title="Reviews" options={addReviewComponent} back={backPathname}>
        <Typography>No reviews yet</Typography>
      </MainLayout>
    );
  }

  return (
    <>
      <MainLayout title="Reviews" options={addReviewComponent} back={backPathname}>
        <Table borderAxis="bothBetween">
          <thead>
            <tr>
              <th style={{ width: 70 }}>Lead</th>
              <th style={{ width: 300 }}>Comment</th>
              <th style={{ width: 70 }}>Guide rating</th>
              <th style={{ width: 70 }}>Trip rating</th>
              <th style={{ width: 70 }}>Us rating</th>
              <th style={{ width: 55 }}>Date</th>
              <th aria-label="last" style={{ width: 100 }} />
            </tr>
          </thead>
          <tbody>
            {reviews.map((review) => (
              <tr key={review._id}>
               <td> {review.fake_data ? (
                 <>{review.fake_data.lead} 
                 {review.fake_data.source && <Chip>{review.fake_data.source}</Chip>}</>
                ) : (
                  
                   <> {review.lead?.first_name} {review.lead?.last_name}</>
                  
                )}

                </td>
                <td>{review.data.comments}</td>
                <td>{review.data.guide}</td>
                <td>{review.data.trip}</td>
                <td>{review.data.us}</td>
                <td>{dayjs.utc(review.created).format('DD/MM/YYYY')}</td>
                <td>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      size="sm"
                      variant="plain"
                      color="neutral"
                      onClick={() => setReviewToEdit(review)}>
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      variant="soft"
                      color="danger"
                      onClick={() => setReviewToDelete(review)}>
                      Delete
                    </Button>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </MainLayout>
      <EditReview reviewToEdit={reviewToEdit} setReviewToEdit={setReviewToEdit} />
      <DeleteReview reviewToDelete={reviewToDelete} setReviewToDelete={setReviewToDelete} />
    </>
  );
}
