import React from 'react';
import { Box, List, ListItemButton, ListDivider, ListItemContent } from '@mui/joy';
import { Link as LinkRRD } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Loading from '../../components/Loading/Loading';
import MainLayout from '../../components/Layouts/MainLayout';
import { useGetListValues } from '../../lib/values';

const ListValues = () => {
  const { data, isLoading } = useQuery(['listValues'], () => useGetListValues());

  if (isLoading)
    return (
      <MainLayout title="Values">
        <Loading />
      </MainLayout>
    );

  return (
    <MainLayout title="Values">
      <List>
        {data
          .filter((v) => ['SITE', 'ES_CUSTOMER'].includes(v.group))
          .map((value) => {
            return (
              <Box key={`${value._id}`}>
                <ListItemButton component={LinkRRD} to={`/values/${value._id}`}>
                  <ListItemContent>
                    [{value.group}] {value.key}
                  </ListItemContent>
                </ListItemButton>
                <ListDivider />
              </Box>
            );
          })}
      </List>
    </MainLayout>
  );
};

export default ListValues;
