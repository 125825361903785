import React from 'react';
import {
  Box,
  List,
  ListItemButton,
  ListDivider,
  Typography,
  Button,
  ListItemContent
} from '@mui/joy';
import { Link as LinkRRD, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  useGetTripById,
  useGetNextDeparturesByTrip,
  useDeleteNextDepartures
} from '../../lib/trip';
import Loading from '../../components/Loading/Loading';
import ButtonAsker from '../../components/ButtonAsker/ButtonAsker';
import MainLayout from '../../components/Layouts/MainLayout';

dayjs.extend(utc);

const NextDepartures = () => {
  const queryClient = useQueryClient();
  const { tripId } = useParams();
  const { data, isLoading } = useQuery(['nextDepartures'], () =>
    useGetNextDeparturesByTrip(tripId)
  );
  const { data: dataTrip } = useQuery(['trip', tripId], () => useGetTripById(tripId), {
    staleTime: Infinity
  });

  const mutationDelete = useMutation(
    (nextDeparturesData) => {
      return useDeleteNextDepartures(tripId, nextDeparturesData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['nextDepartures']);
      }
    }
  );

  if (isLoading)
    return (
      <MainLayout title="Open group date">
        <Loading />
      </MainLayout>
    );

  return (
    <MainLayout
      title="Open group date"
      topTitle={dataTrip?.title}
      options={
        <Button
          component={LinkRRD}
          size="sm"
          state={{
            nextDepartures: {
              _id: 'new',
              departureDate: '',
              endDate: '',
              extra_info: {
                price: '',
                currency: dataTrip && dataTrip.pricing?.currency
              },
              seats: '',
              min_pax: '',
              filled_seats: '',
              description: {
                en_EN: ''
              }
            }
          }}
          to={`/trip/${tripId}/openGroup/new`}
          variant="plain">
          Add new Open group date
        </Button>
      }
      back={`/trip/${tripId}/options`}>
      <List>
        {data.map((departure) => {
          return (
            <Box key={`${tripId}-${departure._id}`}>
              <ListItemButton
                component={LinkRRD}
                to={`/trip/${tripId}/openGroup/${departure._id}`}
                state={{ nextDepartures: departure }}>
                <ListItemContent>
                  {dayjs(departure.departureDate).utc(false).format('DD/MM/YYYY')} -
                  {dayjs(departure.endDate).utc(false).format('DD/MM/YYYY')}
                </ListItemContent>

                <ButtonAsker
                  size="sm"
                  overPosition
                  variant="plain"
                  ask="Are you sure delete?"
                  color="danger"
                  onClick={(event) => {
                    event.preventDefault();
                    mutationDelete.mutate(departure);
                  }}>
                  Borrar
                </ButtonAsker>
              </ListItemButton>
              <ListDivider />
            </Box>
          );
        })}
      </List>

      {(data.length <= 0 || !data) && (
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <Typography level="h4">There is no Open group date yet</Typography>
          <Typography level="body-sm">You could start a new Open group date</Typography>

          <Button
            component={LinkRRD}
            size="sm"
            state={{
              nextDepartures: {
                _id: 'new',
                departureDate: '',
                endDate: '',
                extra_info: {
                  price: '',
                  currency: dataTrip && dataTrip.pricing?.currency
                },
                seats: '',
                min_pax: '',
                filled_seats: '',
                description: {
                  en_EN: ''
                }
              }
            }}
            to={`/trip/${tripId}/openGroup/new`}>
            Add new Open group date
          </Button>
        </Box>
      )}
    </MainLayout>
  );
};

export default NextDepartures;
