import { Box, Typography } from '@mui/joy';
import { marked } from 'marked';
import { useLocation } from 'react-router-dom';

export default function BlogPostContentPreview() {
  const location = useLocation();
  console.log(location.state.content);
  return (
    <Box>
      <Box sx={{ height: '450px', position: 'relative', background: 'black' }}>
        <img
          style={{
            opacity: 0.6,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            position: 'absolute'
          }}
          src={`${location.state.header}?fit=clip&fm=webp&h=800&q=30&w=1600`}
          alt={location.state.title}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ position: 'absolute', width: '665px', bottom: 0 }}>
            <Typography sx={{ fontSize: '36px', color: 'white' }}>
              {location.state.title}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '20px 0px', gap: '5px' }}>
              <img
                style={{ borderRadius: '50%' }}
                src={location.state.author.avatar.url}
                alt="avatar"
                height={50}
                width={50}
              />
              <Box>
                <Typography sx={{ color: 'white' }}>
                  {location.state.author.firstName} {location.state.author.lastName}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div
        dangerouslySetInnerHTML={{ __html: marked(location.state.content) }}
        className="preview-markdown"
        style={{ maxWidth: '665px', margin: 'auto', fontFamily: 'system-ui' }}
      />
    </Box>
  );
}
