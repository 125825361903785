import { Input } from '@esui/lib.formik.input';
import { Box } from '@mui/joy';

export default function Score() {
  return (
    <Box
      sx={{
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'
      }}>
      <Input name="score" label="Score" />
    </Box>
  );
}
