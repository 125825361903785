/* eslint-disable react/button-has-type */
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  Typography
} from '@mui/joy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ArrowLeft,
  ArrowRight,
  DisabledByDefaultOutlined,
  DoneAll,
  EditNote
} from '@mui/icons-material';

import dayjs from 'dayjs';

import MainLayout from '../components/Layouts/MainLayout';
import Loading from '../components/Loading/Loading';
import debounce from '../utils/debounce';
import { useSearchGuides } from '../lib/guide';

const SITE = import.meta.env.VITE_SITE;

const ICON_STATUS_MAPPER = {
  Active: <DoneAll color="success" />,
  Draft: <EditNote color="disabled" />,
  Inactive: <DisabledByDefaultOutlined sx={{ color: 'red' }} />
};

export default function Guides() {
  const defaultPage = new URLSearchParams(window.location.search).get('page') || 1;
  const defaultSearch = new URLSearchParams(window.location.search).get('search') || '';

  const navigate = useNavigate();
  const [page, setPage] = useState(Number(defaultPage));
  const [search, setSearch] = useState(defaultSearch ? `?search=${defaultSearch}` : '');

  const { data, isLoading, refetch, isRefetching } = useQuery(['allGuides'], () =>
    useSearchGuides({ page: page - 1, search })
  );

  useEffect(() => {
    refetch();
  }, [page, search]);

  if (isLoading) {
    return (
      <MainLayout title="Guides">
        <Loading />
      </MainLayout>
    );
  }

  const handleSetNewPage = (newPage) => {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', newPage);
    window.history.pushState({}, '', `/guides?${searchParams.toString()}`);
    setPage(newPage);
  };

  const searchTrip = debounce((e) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', 1);
    setPage(1);
    const searchTerm = e.target.value;
    if (!searchTerm) {
      searchParams.delete('search');
      setSearch(`?${searchParams.toString()}`);
      window.history.pushState({}, '', `/guides?${searchParams.toString()}`);
      return;
    }
    searchParams.set('search', searchTerm);
    window.history.pushState({}, '', `/guides?${searchParams.toString()}`);
    setSearch(`?${searchParams.toString()}`);
  }, 500);

  return (
    <MainLayout
      title="Guides"
      options={<Button onClick={() => navigate('/guide/new')}>Create new guide</Button>}>
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          justifyContent: 'center',
          marginBottom: '16px',
          marginTop: '16px'
        }}>
        <Input
          autoFocus
          placeholder="Mitja Sorn"
          defaultValue={defaultSearch}
          onChange={searchTrip}
        />
      </Box>
      {isRefetching ? (
        <Loading />
      ) : (
        <>
          <List>
            {data.data.map((guide) => {
              return (
                <Fragment key={guide._id}>
                  <ListItem
                    startAction={ICON_STATUS_MAPPER[guide.trip_status]}
                    endAction={
                      guide.link && (
                        <IconButton
                          size="sm"
                          variant="plain"
                          component="a"
                          href={guide.link.replace('https://www.explore-share.com', SITE)}
                          target="_blank">
                          <OpenInNewIcon />
                        </IconButton>
                      )
                    }>
                    <ListItemButton component={Link} to={`/guide/${guide._id}`} state={{ guide }}>
                      <img
                        alt=""
                        src={`${guide.avatar_url}?w=180&h=180`}
                        height="60px"
                        width="60px"
                        loading="lazy"
                        style={{ borderRadius: '4px', objectFit: 'cover', marginRight: '5px' }}
                      />
                      <ListItemContent>
                        <Typography level="body-lg">
                          {`${guide.first_name} ${guide.last_name}`}
                        </Typography>
                        <Typography level="body-sm">
                          Updated at: {dayjs(guide.updatedAt).format('DD/MM/YYYY, HH:mm')}
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListDivider />
                </Fragment>
              );
            })}
          </List>
          {!defaultSearch && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                justifyContent: 'center',
                background: 'white',
                padding: '10px 0px'
              }}>
              <Button
                size="sm"
                variant="outlined"
                disabled={page <= 1}
                onClick={() => handleSetNewPage(page - 1)}>
                <ArrowLeft />
              </Button>
              <Typography>
                {page} / {data.metadata.totalPages}
              </Typography>
              <Button size="sm" variant="outlined" onClick={() => handleSetNewPage(page + 1)}>
                <ArrowRight />
              </Button>
            </Box>
          )}
        </>
      )}
    </MainLayout>
  );
}
