import * as React from 'react';
import { Box, Typography, Card } from '@mui/joy';

import ControlButtons from '../../components/PendingApproval/ControlButtons';

const PriceIncludes = ({ data, tripId, field, handleConfirm, updatingState }) => {
  return (
    <Card>
      <Typography level="h5">Price includes</Typography>
      <Box>
        <Typography level="body-sm">
          <div
            dangerouslySetInnerHTML={{
              __html: data.price_includes.replaceAll('\n', '<br />') || '<i>Price includes removed</i>'
            }}
          />
        </Typography>
      </Box>
      <ControlButtons
        to={`/trip/${tripId}/price-details`}
        loading={updatingState.loading}
        handleConfirm={() => handleConfirm({ tripId, field })}
      />
    </Card>
  );
};

export default PriceIncludes;
