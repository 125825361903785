import { Textarea, Button, FormLabel, Box, Select, Option, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import MainLayout from '../../components/Layouts/MainLayout';
import { useGetValue, usePatchValue } from '../../lib/values';
import Loading from '../../components/Loading/Loading';
import { useTranslate } from '../../hooks/useTranslate';
import { LOCALES } from '../../constants/defaults';

export default function EditGlossary() {
  const { id } = useParams();
  const { data: glossary, isLoading } = useQuery([`glossary-${id}`], () => useGetValue(id));
  const [toLanguage, setToLanguage] = useState('en');
  const [fromLanguage, setFromLanguage] = useState(null);
  const [textToTranslate, setTextToTranslate] = useState('');
  const [conversation, setConversation] = useState([]);
  const [glossaryContent, setGlossaryContent] = useState('');
  const { translate } = useTranslate({ pageLocale: toLanguage, fromLanguage });

  useEffect(() => {
    if (glossary) {
      setGlossaryContent(glossary.value);
    }
  }, [glossary]);

  if (isLoading)
    return (
      <MainLayout title="Loading glossary...">
        <Loading />
      </MainLayout>
    );

  const testTranslation = async () => {
    let valueToTranslate;
    try {
      valueToTranslate = JSON.parse(textToTranslate);
    } catch (error) {
      valueToTranslate = textToTranslate;
    }
    const response = await translate(glossary.value, valueToTranslate);
    setConversation((prev) => [...prev, response.text]);
  };

  const updateGlossary = async () => {
    await toast.promise(usePatchValue(glossary._id, { value: glossaryContent }), {
      loading: 'Updating glossary...',
      success: 'Glossary updated successfully',
      error: 'Failed to update glossary'
    });
  };

  return (
    <MainLayout
      title={glossary.key}
      options={<Button onClick={updateGlossary}>Update glossary</Button>}>
      <FormLabel>Current value</FormLabel>
      <Textarea
        sx={{ marginBottom: '20px' }}
        defaultValue={glossary.value}
        maxRows={15}
        onChange={(e) => {
          setGlossaryContent(e.target.value);
        }}
      />

      <FormLabel>Text to translate</FormLabel>
      <Textarea
        sx={{ marginBottom: '20px' }}
        value={textToTranslate}
        maxRows={10}
        onChange={(e) => setTextToTranslate(e.target.value)}
      />

      <Box sx={{ marginBottom: '20px' }}>
        <FormLabel required>Translate from</FormLabel>
        <Select onChange={(e, v) => setFromLanguage(v)}>
          {Object.values(LOCALES).map((locale) => (
            <Option key={locale} value={locale}>
              {locale}
            </Option>
          ))}
        </Select>
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <FormLabel required>Translate to</FormLabel>
        <Select onChange={(e, v) => setToLanguage(v)}>
          {Object.values(LOCALES).map((locale) => (
            <Option key={locale} value={locale}>
              {locale}
            </Option>
          ))}
        </Select>
      </Box>

      <Button sx={{ marginBottom: '20px' }} onClick={testTranslation}>
        Test translation
      </Button>

      {conversation.length ? (
        <>
          <Typography level="h4" sx={{ marginBottom: '10px' }}>
            Conversation
          </Typography>
          {conversation.map((item, index) => (
            <Box key={index} sx={{ marginBottom: '20px' }}>
              <Typography>{item}</Typography>
              <hr />
            </Box>
          ))}
        </>
      ) : null}
    </MainLayout>
  );
}
