import { Box, FormLabel } from '@mui/joy';
import { Textarea } from '@esui/lib.formik.textarea';
import toast from 'react-hot-toast';

import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import ExpandableContent from '../Trip/ExpandableContent';
import { ImageReplacer } from '../../components/ImageUploader/ImageUploader';
import TranslatedFieldLabel from '../../components/TranslatedFieldLabel/TranslatedFieldLabel';
import Markdown from '../../components/Markdown/Markdown';
import { patchGuide } from '../../lib/guide';

import { DEFAULT_LOCALE } from '../../constants/defaults';

import GuideEditLayout from './GuideEditLayout';
import { useLocale } from '../../hooks/useLocale';

const keysByLocale = {
  en_EN: {
    description: 'description'
  },
  fr_FR: {
    title: 'i18n.fr_FR.title',
    excerpt: 'i18n.fr_FR.excerpt',
    content: 'i18n.fr_FR.content',
    highlights: 'i18n.fr_FR.highlights',
    itinerary: 'i18n.fr_FR.itinerary',
    accommodation: 'i18n.fr_FR.accommodation',
    extra_information: 'i18n.fr_FR.extra_information',
    cancellation_policies: 'i18n.fr_FR.cancellation_policies',
    disclaimer: 'i18n.fr_FR.disclaimer',
    description: 'i18n.fr_FR.description'
  }
};

export default function GuideContent() {
  const [locale] = useLocale();

  if (!locale) return null;

  const handleMutation = async (guideId, newGuide) => {
    const newData = {
      display_name: newGuide.display_name,
      first_name: newGuide.first_name,
      last_name: newGuide.last_name,
      description: newGuide.description,
      email: newGuide.email,
      phone_number: newGuide.phone_number,
      residence: newGuide.residence,
      avatar_url: newGuide.avatar_url,
      i18n: {
        ...newGuide.i18n,
        fr_FR: newGuide.i18n.fr_FR
      }
    };
    await toast.promise(patchGuide(guideId, newData), {
      loading: 'Saving changes...',
      success: 'Changes saved successfully',
      error: (error) => {
        return `Failed to save changes: ${error.response.data.message}`;
      }
    });
  };

  return (
    <GuideEditLayout title="Content" handleMutation={handleMutation}>
      {(values, setFieldValue) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <LocaleSelector />
          <ExpandableContent title="Main content">
            <Box>
              <FormLabel>Header</FormLabel>
              <ImageReplacer
                url={values.avatar_url}
                handleMutation={([url]) => setFieldValue('avatar_url', url)}
              />
            </Box>
            <Box>
              <Textarea label="Display name" name="display_name" />
            </Box>
            <Box>
              <Textarea label="First name" name="first_name" />
            </Box>
            <Box>
              <Textarea label="Last name" name="last_name" />
            </Box>
            <Box>
              <Textarea label="Email" name="email" />
            </Box>
            <Box>
              <Textarea label="Phone number" name="phone_number" />
            </Box>
            <Box>
              <Textarea label="Residence" name="residence" />
            </Box>
            <Box>
              <FormLabel>
                <TranslatedFieldLabel label="Description" />
              </FormLabel>
              {locale === DEFAULT_LOCALE ? (
                <Markdown
                  defaultContent={values.description || ''}
                  handleOnChange={(markdown) => setFieldValue('description', markdown)}
                />
              ) : (
                <Markdown
                  defaultContent={values.i18n[locale].description || ''}
                  handleOnChange={(markdown) =>
                    setFieldValue(keysByLocale[locale].description, markdown)
                  }
                />
              )}
            </Box>
          </ExpandableContent>
        </Box>
      )}
    </GuideEditLayout>
  );
}
