import { Formik, Form } from 'formik';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepIndicator,
  IconButton,
  Button,
  Divider,
  Table,
  Card,
  CardContent
} from '@mui/joy';
import { Select } from '@esui/lib.formik.select';
import { useState } from 'react';

import Simple from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import { useStatsByBookings } from '../../lib/stats';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';

import './styles.css';
import { useLocation } from 'react-router-dom';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const stepOrder = [
  {
    step: 'B:UNCATEGORIZED',
    color: 'warning',
    hex: '9A5B13'
  },
  {
    step: 'B:REQUESTED',
    color: 'primary',
    hex: '0B6BCB'
  },
  {
    step: 'B:CONFIRMED',
    color: 'warning',
    hex: '9A5B13'
  },
  {
    step: 'B:PAID',
    color: 'success',
    hex: '1F7A1F'
  },
  {
    step: 'B:COMPLETED',
    color: 'success',
    hex: '1F7A1F'
  },

  {
    step: 'B:CANCELLED',
    color: 'danger',
    hex: 'C41C1C'
  },
  {
    step: 'B:ENQUIRED',
    color: 'warning',
    hex: '9A5B13'
  }
];

const Bookings = () => {
  // const { bookings } = useParams();
  const {
    state: { bookings }
  } = useLocation();
  const queryClient = useQueryClient();

  console.log({ bookings });

  if (!bookings.length) return '';

  const { data: bookingsInfo, isLoading } = useQuery(
    ['bookings', bookings],
    () => {
      return useStatsByBookings(bookings.map((id) => `ObjectId('${id}')`));
    },
    {
      //staleTime: Infinity
    }
  );

  const orderByDate = (booking) => {
    const steps = [
      ...booking.activities.map((activity) => ({
        dateToOrder: activity.timestamp,
        type: 'activity',
        display: `${activity.action}`,
        ...activity
      })),
      ...booking.messages.map((message) => ({
        dateToOrder: message.ts,
        type: 'message',
        display: `${message.sender} - ${message.content}`,
        ...message
      })),
      ...booking.payments.map((payment) => ({
        dateToOrder: payment.ts,
        type: 'payment',
        display: `${payment.amount} - ${payment.method} - ${payment.status}`,
        ...payment
      }))
    ];

    const sortedSteps = steps.sort((objA, objB) => Number(objA.date) - Number(objB.date));
    console.log({ sortedSteps });
    return sortedSteps;
  };

  return (
    <Simple title="Bookings">
      {!isLoading ? (
        <Box>
          {bookingsInfo.data.map((booking) => {
            const steps = orderByDate(booking);
            return (
              <Box sx={{ mb: 3 }}>
                <Card variant="outlined" size="md" sx={{ mb: 1 }}>
                  <CardContent>
                    <Table>
                      <tr>
                        <td>
                          <Typography level="title-md">{booking.code}</Typography>
                        </td>
                        <td>
                          <Typography level="body-xs">{booking.created}</Typography>
                        </td>
                        <td>
                          <Typography level="body-xs">{booking.state}</Typography>

                          <Typography level="body-xs">{booking.guide_state}</Typography>
                        </td>
                      </tr>
                    </Table>
                  </CardContent>
                </Card>

                <Stepper size="sm" orientation="vertical">
                  {steps.map((step) => (
                    <Step
                      indicator={
                        <StepIndicator
                          variant="solid"
                          color={step.type === 'message' ? 'primary' : 'warning'}>
                          {step.type === 'message' && <ChatBubbleIcon />}
                          {step.type === 'activity' && <LocalActivityIcon />}
                          {step.type === 'payment' && <AttachMoneyIcon />}
                        </StepIndicator>
                      }>
                      <Box>
                        <Typography level="body-xs">{step.dateToOrder}</Typography>
                        {step.display}
                      </Box>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            );
          })}
        </Box>
      ) : (
        <>Loading</>
      )}
    </Simple>
  );
};

export default Bookings;
