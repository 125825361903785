import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/joy';
import { Formik, Form } from 'formik';
import { Input } from '@esui/lib.formik.input';
import toast from 'react-hot-toast';

import { getActivityById, updateActivity } from '../../lib/activities';
import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import ButtonLoading from '../../components/ButtonLoading';
import { useLocale } from '../../hooks/useLocale';

export default function Activity() {
  const { id } = useParams();
  const { data, isLoading } = useQuery([`activity-${id}`], () => getActivityById(id));
  const [locale] = useLocale();
  const queryClient = useQueryClient();

  const mutation = useMutation((values) => updateActivity(id, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(`landingPage-${id}`);
    }
  });

  if (isLoading) {
    return (
      <MainLayout title="Loading activity...">
        <Loading />
      </MainLayout>
    );
  }

  const activity = data.data[0];
  const activityData = activity.i18n[locale] || {};
  const locales = Object.keys(activity.i18n);

  const handleOnSubmit = async (values) => {
    const { _id, ...rest } = values;
    await toast.promise(
      mutation.mutateAsync({
        i18n: {
          ...activity.i18n,
          [locale]: rest
        }
      }),
      {
        loading: 'Saving...',
        success: 'Saved successfully!',
        error: 'Error while saving.'
      }
    );
  };

  return (
    <Box>
      <Formik
        initialValues={{
          title: activityData.title || '',
          slug: activityData.slug || ''
        }}
        onSubmit={handleOnSubmit}>
        {() => (
          <Box>
            <Form>
              <MainLayout
                title={activityData.title || 'Untitled'}
                options={
                  <ButtonLoading size="sm" type="submit" isLoading={false}>
                    Save
                  </ButtonLoading>
                }>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {locales.length > 1 ? <LocaleSelector /> : null}
                  </Box>
                  <Box>
                    <Input name="title" label="Title" />
                  </Box>
                  <Box>
                    <Input name="slug" label="Slug" />
                  </Box>
                </Box>
              </MainLayout>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
