import { Box, Typography } from '@mui/joy';

export default function GeneralInfo({ booking }) {
  const quantity = booking.payment_plan
    ? booking.payment_plan.original.quantity
    : booking.people_count;

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Typography level="body-md">
        <Typography fontWeight="bold">Booking state:</Typography> {booking.state}
      </Typography>
      <Typography level="body-md">
        <Typography fontWeight="bold">Guide state:</Typography> {booking.guide_state}
      </Typography>
      {booking.cancellation_reason ? (
        <Typography level="body-md">
          <Typography fontWeight="bold">Cancellation reason: </Typography>
          {booking.cancellation_reason}
        </Typography>
      ) : null}
      {booking.departure_date ? (
        <Typography level="body-md">
          <Typography fontWeight="bold">Departure date: </Typography>
          {typeof booking.departure_date === 'string'
            ? booking.departure_date
            : booking.departure_date.toISOString().split('T')[0].split('-').reverse().join('-')}
        </Typography>
      ) : null}
      <Typography level="body-md">
        <Typography fontWeight="bold">Quantity:</Typography> {quantity}
      </Typography>
      <Typography level="body-md">
        <Typography fontWeight="bold">Days:</Typography> {booking.duration}
      </Typography>
      <Typography level="body-md">
        <Typography fontWeight="bold">Payment method:</Typography> {booking.payment_method}
      </Typography>
      {booking.payment_options?.payment_method ? (
        <Typography level="body-md">
          <Typography fontWeight="bold">Payment method for balance:</Typography>{' '}
          {booking.payment_options.payment_method}
        </Typography>
      ) : null}
    </Box>
  );
}
