import {
  Drawer,
  Sheet,
  DialogTitle,
  ModalClose,
  List,
  ListItem,
  Button,
  Stack,
  Divider,
  Select,
  Option,
  SvgIcon,
  Input,
  Typography,
  FormHelperText,
  Checkbox,
  FormControl
} from '@mui/joy';

import { DeleteForever } from '@mui/icons-material';
import { useTranslateText } from '../../lib/ai';
import { useEffect, useState } from 'react';
import { useGetValueByKeyByGroup } from '../../lib/values';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { LOCALES_SHORT } from '../../constants/defaults';
import { DEFAULT_LOCALE, LOCALES } from '../../constants/defaults';
//import { useUpdateLandingPage, useGetLandingPage } from '../../lib/landing-pages';
import { useGetTripById, usePatchTrip, useGetFAQByTrip, usePatchAllFAQ } from '../../lib/trip';
import { downloadToFile, jsonToCsv } from '../../utils/general';
import slugify from 'slugify';

const actions = [{ value: 'translate', label: 'Translate' }];

const TripBulk = ({ bulkPage, open, setOpen }) => {
  const queryClient = useQueryClient();

  const [csvStatics, setCsvStatics] = useState({});

  const [openOptions, setOpenOptions] = useState(false);
  const [fromLanguage, setFromLanguage] = useState(DEFAULT_LOCALE);
  const [toLanguage, setToLanguage] = useState(null);
  const [glossary, setGlossary] = useState('');
  const [translating, setTranslating] = useState(false);

  const [bulk, setBulk] = useState(bulkPage);
  const [changeslug, setChangeslug] = useState(false);

  const [changecontent, setChangecontent] = useState(false);
  const [changecontentConfirmed, setChangecontentConfirmed] = useState(false);

  const { refetch } = useQuery(
    [`value`],
    async () => {
      try {
        return await useGetValueByKeyByGroup(
          'TRANSLATE_GLOSSARIES',
          `${LOCALES_SHORT[fromLanguage]}_${LOCALES_SHORT[toLanguage]}`
        );
      } catch (e) {
        return true;
      }
    },
    {
      enabled: false
    }
  );

  const handleTranslate = async () => {
    setTranslating(true);
    window.onbeforeunload = function () {
      return 'Translate working ...';
    };

    for await (const page of bulk) {
      try {
        const trip = await queryClient.fetchQuery({
          queryKey: [`trip-${page._id}`],
          queryFn: async () => {
            try {
              return await useGetTripById(parseInt(page._id));
            } catch (e) {
              page.success = false;
              page.comment = 'not found';
              return false;
            }
          }
        });

        if (trip) {
          const tripInfo = trip.i18n;

          if (!changecontentConfirmed && tripInfo[toLanguage]?.title !== '') {
            page.success = false;
            page.comment = 'pre-existing information';
            return false;
          }

          const toTranslate = tripInfo[fromLanguage];
          toTranslate.temp_slug = toTranslate.link
            .replace('https://www.explore-share.com/trip/', '')
            .replaceAll('-', ' ')
            .replaceAll('/', ' ');

          const translate = await useTranslateText({
            template: `
        I am an expert translator. My goal is to provide the best translation possible. I will translate your text from ${LOCALES[fromLanguage]} to ${LOCALES[toLanguage]}.
        In the JSON, I will find words that are between curly braces like {{ location }}, this words are variables and I don't have to be translate them, they must be kept them in the original language.
        I have a glossary with the words and their translations also indication for get a best translation, and I will use it to translate the text.
      `,
            query: `
        Please, translate this JSON to ${LOCALES[toLanguage]}, translate only the values and not the keys. Respond with the same json but translated. And remember, you need to use the glossary for the translation.
      `,
            data: JSON.stringify(toTranslate),
            glossary
          });

          const { response: json } = await translate.json();

          const parsedResponse = JSON.parse(json.replace('```json\n', '').replace('\n```', ''));

          //  if (tripInfo[toLanguage].link === '') {

          console.log('---', changeslug, tripInfo[fromLanguage].link);

          if (changeslug || toTranslate.link === '') {
            parsedResponse.link = `https://www.explore-share.com/${
              toLanguage.split('_')[0]
            }/trip/${slugify(parsedResponse.title).toLowerCase()}`;
          } else {
            parsedResponse.link = toTranslate.link;
          } // if changeslug

          delete parsedResponse.temp_slug;

          //  }

          const finalData = {
            _id: trip._id,
            i18n: {
              ...trip.i18n,
              [toLanguage]: parsedResponse
            },
            'sync_status.i18n': 'WAITING_PUBLISHED'
          };

          await usePatchTrip(finalData);

          page.success = true;
          page.url = parsedResponse.link;
        }
        setCsvStatics((prev) => ({ ...prev, success: prev.success + 1 }));
      } catch (e) {
        page.success = false;
        setCsvStatics((prev) => ({ ...prev, error: prev.error + 1 }));
        console.log(e);
      }
    }

    downloadToFile('result.csv', jsonToCsv(bulk));
    setTranslating(false);
    window.onbeforeunload = null;
  };

  useEffect(() => {
    if (!fromLanguage || !toLanguage) return;
    try {
      refetch({ throwOnError: true }).then(({ data }) => setGlossary(data.value));
    } catch (e) {}
  }, [fromLanguage, toLanguage]);

  const handleChange = (event, newValue) => {
    setOpenOptions(newValue);
  };

  const handleUploadFile = (e) => {
    console.log(e.target.files);

    var reader = new FileReader();
    reader.onload = function (progressEvent) {
      const text = this.result;

      var lines = text.split('\n');
      lines.shift();

      setBulk(lines.map((item) => ({ _id: item })));
      setCsvStatics({ count: lines.length });
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <Drawer
      size="md"
      variant="plain"
      open={open}
      onClose={() => {
        if (!translating) {
          setOpen(false);
        }
      }}
      slotProps={{
        content: {
          sx: {
            bgcolor: 'transparent',
            p: { md: 3, sm: 0 },
            boxShadow: 'none'
          }
        }
      }}>
      <Sheet
        sx={{
          borderRadius: 'md',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '100%',
          overflow: 'auto'
        }}>
        <DialogTitle>Bulk Action</DialogTitle>
        <ModalClose />

        {bulkPage.length ? (
          <List>
            {bulkPage.map((item) => (
              <ListItem
                endAction={
                  <Button size="sm">
                    <DeleteForever />
                  </Button>
                }>
                {item.title}
              </ListItem>
            ))}
          </List>
        ) : (
          <>
            <Typography level="body-sm">Upload a csv with _id column (need header)</Typography>
            <Button
              component="label"
              role={undefined}
              tabIndex={-1}
              variant="outlined"
              color="neutral"
              startDecorator={
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                  </svg>
                </SvgIcon>
              }>
              Upload a file
              <Input
                type="file"
                onChange={handleUploadFile}
                sx={{
                  clip: 'rect(0 0 0 0)',
                  clipPath: 'inset(50%)',
                  height: '1px',
                  overflow: 'hidden',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  whiteSpace: 'nowrap',
                  width: '1px'
                }}
              />
            </Button>
            {csvStatics.count ? (
              <Typography color="neutral">Rows found {csvStatics.count}</Typography>
            ) : null}
            {csvStatics.success ? (
              <Typography color="success">Rows success {csvStatics.success}</Typography>
            ) : null}
            {csvStatics.error ? (
              <Typography color="danger">Rows fail {csvStatics.error}</Typography>
            ) : null}
          </>
        )}

        <Divider sx={{ mt: 'auto' }} />
        <Stack direction="column" justifyContent="space-between" useFlexGap spacing={1}>
          <Select placeholder="Choose one…" onChange={handleChange}>
            {actions.map((item) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>

          {openOptions === 'translate' && (
            <>
              <Stack direction="row" justifyContent="space-between" gap={2}>
                <Select
                  defaultValue={DEFAULT_LOCALE}
                  sx={{ flex: 1 }}
                  placeholder="Translate From .."
                  onChange={(event, newValue) => setFromLanguage(newValue)}>
                  {Object.entries(LOCALES_SHORT).map((item) => (
                    <Option value={item[0]}>{item[1]}</Option>
                  ))}
                </Select>

                <Select
                  sx={{ flex: 1 }}
                  placeholder="Translate to .."
                  onChange={(event, newValue) => setToLanguage(newValue)}>
                  {Object.entries(LOCALES_SHORT).map((item) =>
                    DEFAULT_LOCALE !== item[0] ? <Option value={item[0]}>{item[1]}</Option> : null
                  )}
                </Select>
              </Stack>

              <FormControl>
                <Checkbox
                  label="Overwrite translation"
                  size="sm"
                  color="danger"
                  onChange={(event) => setChangecontent(event.target.checked)}
                />
              </FormControl>

              {!changecontent ? (
                <FormHelperText level="body-xs">
                  If you check this option, the translator will ignore pre-existing content.
                </FormHelperText>
              ) : (
                <FormHelperText level="body-xs">
                  <Input
                    placeholder="type `overwrite` for confirmation"
                    onChange={(event) =>
                      setChangecontentConfirmed(event.target.value === 'overwrite')
                    }
                  />
                </FormHelperText>
              )}

              {changecontentConfirmed ? (
                <FormControl>
                  <Checkbox
                    label="Change slug"
                    size="sm"
                    color="danger"
                    onChange={(event) => setChangeslug(event.target.checked)}
                  />
                  <FormHelperText level="body-xs">
                    If you check this option, the slug and URL will be changed.
                  </FormHelperText>
                </FormControl>
              ) : null}
            </>
          )}

          <Button onClick={() => handleTranslate()} loading={translating}>
            Run
          </Button>
        </Stack>
      </Sheet>
    </Drawer>
  );
};

export default TripBulk;
