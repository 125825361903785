import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/joy';
import { useState } from 'react';

import MainLayout from '../../components/Layouts/MainLayout';
import { useGetTripById } from '../../lib/trip';
import Loading from '../../components/Loading/Loading';
import approveContent from '../../utils/pendingApproval/approveContent';
import mapDataToApprove from '../../utils/pendingApproval/mapDataToApprove';
import TripTopTile from '../../components/TripTopTile/TripTopTile';
import AdminComment from '../../components/AdminComment/AdminComment';

const PendingTrip = () => {
  const queryClient = useQueryClient();
  const { tripId } = useParams();

  const [updatingState, setUpdatingState] = useState({ completed: false, loading: false });

  const { data: trip, isLoading } = useQuery(['trip', tripId], () => useGetTripById(tripId), {
    staleTime: Infinity
  });

  const { mutate } = useMutation(
    (params) => {
      setUpdatingState((prev) => ({ ...prev, loading: true }));
      return approveContent(params);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['trip']);
        queryClient.invalidateQueries(['pendingTrips']);
        setUpdatingState({ completed: true, loading: false });
      }
    }
  );

  if (isLoading) {
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );
  }

  const getData = (data, field) => {
    if (field.includes('.')) {
      const [i18n, locale, key] = field.split('.');
      return data[i18n][locale];
    }
    return data[field];
  };

  // order cards by trip options
  const sortingArray = ['price', 'whats_included', 'price_includes', 'next_departures', 'faqs'];
  const editedInformation = Object.keys(trip.sync_status)
    .filter((k) => trip.sync_status[k] === 'DRAFT')
    .sort(function (a, b) {
      return sortingArray.indexOf(a) - sortingArray.indexOf(b);
    });

  return (
    <MainLayout
      title="Pending Approval"
      topTitle={<TripTopTile trip={trip} />}
      help={
        <Typography level="body-sm">
          {trip.guide.first_name && `${trip.guide.first_name} ${trip.guide.last_name}`} -
          <Typography level="body-sm" component="a" href={`mailto:${trip.guide.email}`}>
            {trip.guide.email}
          </Typography>
        </Typography>
      }
      back="/pendingApproval">
      <Box>
        <AdminComment id={tripId} />
        <Typography level="h4" sx={{ margin: '15px 0px' }}>
          Edited information
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '10px',
            flexDirection: 'column'
          }}>
          {editedInformation.length > 0 ? (
            editedInformation.map((field, index) => {
              const { data, Component } = mapDataToApprove(getData(trip, field), field);
              if (data == null) return null;
              return (
                <Box key={index}>
                  <Component
                    data={data}
                    tripId={tripId}
                    field={field}
                    handleConfirm={mutate}
                    updatingState={updatingState}
                  />
                </Box>
              );
            })
          ) : (
            <Typography>Nothing to do</Typography>
          )}
        </Box>
      </Box>
    </MainLayout>
  );
};

export default PendingTrip;
