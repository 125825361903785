import fetcher from './fetcher';

const ES_PROVIDERS_API = import.meta.env.VITE_ES_PROVIDERS_API;

export function getMail(id) {
  return fetcher({
    url: `/api/mail/${id}`,
    method: 'GET'
  });
}

export function getMailVersions(mail_name) {
  return fetcher({
    url: `/api/mails/?mail_name=${mail_name}&sortBy=_id:desc&limit=20`,
    method: 'GET'
  });
}

export function getMails() {
  const urlSearchParams = new URLSearchParams('');

  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          mail_name: { $exists: true },
          current: true
        }
      },
      {
        $project: {
          mail_name: 1,
          to: 1,
          comments: 1
        }
      }
    ])
  );

  return fetcher({
    url: `/api/mails/aggregation?query=${urlSearchParams.get('query').toString()}`,
    method: 'GET'
  });
}

export function getMailsTemplates() {
  const urlSearchParams = new URLSearchParams('');

  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          template_key: { $exists: true }
        }
      }
    ])
  );

  return fetcher({
    url: `/api/mails/aggregation?${urlSearchParams.toString()}`,
    method: 'GET'
  });
}

export function editMail(data, id) {
  return fetcher({
    url: `/api/v3/admin/messaging/update/${id}`,
    method: 'POST',
    data
  });
}

export function makeVersionCurrent(id) {
  return fetcher({
    url: `/api/v3/admin/messaging/make-current/${id}`,
    method: 'PUT'
  });
}

export function getMailPreview({ previewLocale, html, bookingCode, subject, mail_name }) {
  return fetcher({
    url: `${ES_PROVIDERS_API}/es-messaging/mails/preview?locale=${previewLocale}&template=${mail_name}`,
    method: 'POST',
    data: {
      html,
      subject,
      bookingCode
    }
  });
}

export function sendPreviewMail(email, { previewMail, previewSubject }) {
  return fetcher({
    url: `${ES_PROVIDERS_API}/es-messaging/mails/preview/send`,
    method: 'POST',
    data: {
      to: email,
      html: previewMail,
      subject: previewSubject
    }
  });
}
