import React, { useState } from 'react';
import { Box, List, Switch, Typography, Stack, Chip } from '@mui/joy';

import ActivityItem from './ActivityItem';

import { stringToColor } from '../../utils/general';

const ActivityList = ({ activities }) => {
  const [filter, setFilter] = useState([]);

  const uniqueActions = activities
    .map((activity) => activity.action)
    .filter((value, index, array) => array.indexOf(value) === index);

  const group = Object.groupBy(activities, ({ action }) => action);

  return (
    <Stack direction="row" justifyContent="center" alignItems="top" overFlow="auto" spacing={2}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'sticky', top: 0 }}>
          {uniqueActions.map((action, key) => {
            return (
              <Typography
                key={key}
                sx={{ backgroundColor: stringToColor(action), p: 0.5 }}
                level="body-sm"
                component="label"
                startDecorator={
                  <>
                    <Chip size="sm">{group[action].length}</Chip>

                    <Switch
                      size="sm"
                      sx={{ m: 0.3 }}
                      onChange={(event) =>
                        setFilter((prev) => {
                          if (prev.includes(action) && !event.target.checked) {
                            return prev.filter((item) => item !== action);
                          }

                          if (!prev.includes(action) && event.target.checked) {
                            prev.push(action);

                            return [...prev];
                          }

                          return [...prev];
                        })
                      }
                    />
                  </>
                }>
                {action}
              </Typography>
            );
          })}
        </Box>
      </Box>
      <Box>
        <List>
          {activities.map((activity, key) => {
            if (filter.length > 0 && !filter.includes(activity.action)) return null;
            return <ActivityItem key={key} activity={activity} />;
          })}
        </List>
      </Box>
    </Stack>
  );
};

export default ActivityList;
