import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';

const HtmlGenerator = new Blockly.Generator('HTML');

HtmlGenerator.ORDER_ATOMIC = 0;
HtmlGenerator.ORDER_NONE = 0;

HtmlGenerator.init = function (workspace) {};
HtmlGenerator.finish = function (code) {
  return code;
};

HtmlGenerator.scrub_ = function (block, code) {
  const nextBlock = block.nextConnection && block.nextConnection.targetBlock();
  const nextCode = HtmlGenerator.blockToCode(nextBlock);
  return code + nextCode;
};

const OPERATION_TO_HTML = {
  EQ: '==',
  NEQ: '!=',
  LT: '<',
  LTE: '<=',
  GT: '>',
  GTE: '>='
};

HtmlGenerator.for_loop_html = function (block) {
  const value_from = block.getFieldValue('FROM');
  const statements_do = HtmlGenerator.statementToCode(block, 'DO');
  const code = `
    <% for (let index = 0; index < ${value_from}.length; index++) { %>
      \n${statements_do}
    <% } %>\n`;
  return code;
};

HtmlGenerator.controls_if_html = function (block) {
  const value_if0 = HtmlGenerator.valueToCode(block, 'IF0', HtmlGenerator.ORDER_ATOMIC);
  const statements_do0 = HtmlGenerator.statementToCode(block, 'DO0');
  const code = `<% if (${value_if0}) { %>\n ${statements_do0} <% } %>\n`;
  return code;
};

HtmlGenerator.logic_compare_html = function (block) {
  const value_a = block.getFieldValue('A');
  const dropdown_op = block.getFieldValue('OP');
  const value_b = HtmlGenerator.valueToCode(block, 'B', HtmlGenerator.ORDER_ATOMIC) || 'null';
  const code = `${value_a} ${OPERATION_TO_HTML[dropdown_op]} ${value_b}`;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};
HtmlGenerator.logic_compare_variables_html = function (block) {
  const value_a = block.getFieldValue('A');
  const dropdown_op = block.getFieldValue('OP');
  const value_b = block.getFieldValue('B');
  const code = `${value_a} ${OPERATION_TO_HTML[dropdown_op]} ${value_b}`;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.logic_operation_html = function (block) {
  const value_a = HtmlGenerator.valueToCode(block, 'A', HtmlGenerator.ORDER_ATOMIC);
  const dropdown_op = block.getFieldValue('OP');
  const value_b = HtmlGenerator.valueToCode(block, 'B', HtmlGenerator.ORDER_ATOMIC);
  const mapOperator = {
    AND: '&&',
    OR: '||'
  };
  const code = `${value_a} ${mapOperator[dropdown_op]} ${value_b}`;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.logic_negate_html = function (block) {
  const value_bool = HtmlGenerator.valueToCode(block, 'BOOL', HtmlGenerator.ORDER_ATOMIC);
  const code = `!${value_bool}`;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.logic_boolean_html = function (block) {
  const dropdown_bool = block.getFieldValue('BOOL');
  const code = dropdown_bool === 'TRUE' ? 'true' : 'false';
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.logic_null_html = function (block) {
  const code = 'null';
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.logic_value_exists_html = function (block) {
  const value_value = block.getFieldValue('IF');
  const code = `typeof ${value_value} !== 'undefined' && !!${value_value}`;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.logic_ternary_html = function (block) {
  let value_if = HtmlGenerator.valueToCode(block, 'IF', HtmlGenerator.ORDER_ATOMIC);
  if (value_if === 'null') value_if = block.getFieldValue('IF');

  const value_then = block.getFieldValue('THEN');
  const value_else = block.getFieldValue('ELSE');
  const code = `<%= ${value_if} ? "${value_then}" : "${value_else}" %>`;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.controls_ifelse_html = function (block) {
  const value_if0 = HtmlGenerator.valueToCode(block, 'IF0', HtmlGenerator.ORDER_ATOMIC);
  const statements_do0 = HtmlGenerator.statementToCode(block, 'DO0');
  const statements_else = HtmlGenerator.statementToCode(block, 'ELSE');
  const code = `<% if (${value_if0}) { %>\n ${statements_do0} <% } else { %>\n ${statements_else} <% } %>\n`;
  return code;
};

// function removeIndentAndTrailingNewline() {}

HtmlGenerator.baseframe = function (block) {
  const statements_head = HtmlGenerator.statementToCode(block, 'head');
  const statements_body = HtmlGenerator.statementToCode(block, 'body');

  const code = `<!DOCTYPE HTML>\n<html>\n<head>\n  <meta charset="utf-8">\n${statements_head}</head>\n\n<body>\n${statements_body}</body>\n</html>\n`;

  return code;
};

HtmlGenerator.html = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<!DOCTYPE HTML>\n<html>\n${statements_content}</html>\n`;
  return code;
};

HtmlGenerator.text = function (block) {
  const text_content = block.getFieldValue('TEXT');
  const code = `"${text_content}"\n`;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.math_number = function (block) {
  const number_value = block.getFieldValue('NUM');
  const code = number_value;
  return [code, HtmlGenerator.ORDER_ATOMIC];
};

HtmlGenerator.body = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<body>\n${statements_content}</body>\n`;
  return code;
};

HtmlGenerator.head = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<head>\n  <meta charset="utf-8">\n${statements_content}</head>\n`;
  return code;
};

HtmlGenerator.title = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');

  if (statements_content != '') document.getElementById('title').innerText = statements_content;
  else document.getElementById('title').innerText = 'untitled web page';

  const code = `<title>${statements_content.trim()}</title>\n`;
  return code;
};

HtmlGenerator.paragraph = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const code = `<p ${value_name}>\n${statements_content}</p>\n`;
  return code;
};

HtmlGenerator.plaintext = function (block) {
  const text_content = block.getFieldValue('content');
  const code = `${text_content}\n`;
  return code;
};

HtmlGenerator.division = function (block) {
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<div${value_name}>\n${statements_content}</div>\n`;
  return code;
};

HtmlGenerator.style = function (block) {
  const statements_name = HtmlGenerator.statementToCode(block, 'NAME');
  const code = ` style="${statements_name.trim()}"`;
  return [code, HtmlGenerator.ORDER_NONE];
};

HtmlGenerator.color = function (block) {
  const colour_name = block.getFieldValue('NAME');
  const code = `color: ${colour_name};`;
  return code;
};

HtmlGenerator.bgcolour = function (block) {
  const colour_name = block.getFieldValue('NAME');
  const code = `background-color: ${colour_name};`;
  return code;
};

HtmlGenerator.genericstyle = function (block) {
  const text_property = block.getFieldValue('property');
  const text_value = block.getFieldValue('value');
  const code = `${text_property}: ${text_value};`;
  return code;
};

HtmlGenerator.generictag = function (block) {
  const text_name = block.getFieldValue('NAME');
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<${text_name}${value_name}>\n${statements_content}</${text_name}>\n`;
  return code;
};

HtmlGenerator.more_attributes = function (block) {
  const value_name1 = HtmlGenerator.valueToCode(block, 'NAME1', HtmlGenerator.ORDER_ATOMIC);
  const value_name2 = HtmlGenerator.valueToCode(block, 'NAME2', HtmlGenerator.ORDER_ATOMIC);
  const value_name3 = HtmlGenerator.valueToCode(block, 'NAME3', HtmlGenerator.ORDER_ATOMIC);
  const code = value_name1 + value_name2 + value_name3;
  return [code, HtmlGenerator.ORDER_NONE];
};

HtmlGenerator.genericattribute = function (block) {
  const text_attribute = block.getFieldValue('attribute');
  const text_value = block.getFieldValue('value');
  const code = ` ${text_attribute}="${text_value}"`;
  return [code, HtmlGenerator.ORDER_NONE];
};

HtmlGenerator.link = function (block) {
  const text_name = block.getFieldValue('NAME');
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const code = `<a href="${text_name}" ${value_name}>${statements_content.trim()}</a>\n`;
  return code;
};

HtmlGenerator.span = function (block) {
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<span${value_name}>${statements_content.trim()}</span>\n`;
  return code;
};

HtmlGenerator.image = function (block) {
  const text_image = block.getFieldValue('IMAGE');
  const text_alt = block.getFieldValue('ALT');
  const text_width = block.getFieldValue('WIDTH');
  const text_height = block.getFieldValue('HEIGHT');
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const code = `<img src="${text_image}" alt="${text_alt}" width="${text_width}" height="${text_height}" ${value_name} >\n`;
  return code;
};

HtmlGenerator.emphasise = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<em>${statements_content.trim()}</em>\n`;
  return code;
};

HtmlGenerator.strong = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<strong>${statements_content.trim()}</strong>\n`;
  return code;
};

HtmlGenerator.headline = function (block) {
  const dropdown_name = block.getFieldValue('NAME');
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<${dropdown_name}>${statements_content.trim()}</${dropdown_name}>\n`;
  return code;
};

HtmlGenerator.linebreak = function (block) {
  const code = '<br>\n';
  return code;
};

HtmlGenerator.horizontalbreak = function (block) {
  const code = '<hr>\n';
  return code;
};

HtmlGenerator.unorderedlist = function (block) {
  const statements_name = HtmlGenerator.statementToCode(block, 'NAME');
  const code = `<ul>\n${statements_name}</ul>\n`;
  return code;
};

HtmlGenerator.orderedlist = function (block) {
  const statements_name = HtmlGenerator.statementToCode(block, 'NAME');
  const code = `<ol>\n${statements_name}</ol>\n`;
  return code;
};

HtmlGenerator.listelement = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<li>${statements_content}</li>\n`;
  return code;
};

HtmlGenerator.inserted = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<ins>${statements_content.trim()}</ins>\n`;
  return code;
};

HtmlGenerator.italic = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<i>${statements_content.trim()}</i>\n`;
  return code;
};

HtmlGenerator.deleted = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<del>${statements_content.trim()}</del>\n`;
  return code;
};

HtmlGenerator.super = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<sup>${statements_content.trim()}</sup>\n`;
  return code;
};

HtmlGenerator.sub = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<sub>${statements_content.trim()}</sub>\n`;
  return code;
};

HtmlGenerator.code = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<code>\n${statements_content}</code>\n`;
  return code;
};

HtmlGenerator.quote = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<q>${statements_content.trim()}</q>\n`;
  return code;
};

HtmlGenerator.blockquote = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<blockquote>\n${statements_content}</blockquote>\n`;
  return code;
};

HtmlGenerator.sample = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<samp>\n${statements_content}</samp>\n`;
  return code;
};

HtmlGenerator.keyboard = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<kbd>\n${statements_content}</kbd>\n`;
  return code;
};

HtmlGenerator.variable = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<var>${statements_content.trim()}</var>\n`;
  return code;
};

HtmlGenerator.form = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<form>\n${statements_content}</form>\n`;
  return code;
};

HtmlGenerator.table = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const table_width = block.getFieldValue('WIDTH');
  const table_align = block.getFieldValue('ALIGN');
  const code = `<table ${table_width ? `width="${table_width}"` : ''}  ${
    table_align ? `align="${table_align}"` : ''
  }>\n${statements_content}</table>\n`;
  return code;
};

HtmlGenerator.tablerow = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const code = `<tr ${value_name}>\n${statements_content}</tr>\n`;
  return code;
};

HtmlGenerator.tablecell = function (block) {
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const code = `<td ${value_name}>${statements_content.trim()}</td>\n`;
  return code;
};

HtmlGenerator.input_text = function (block) {
  const text_default = block.getFieldValue('default');
  const code = `<input value="${text_default}">\n`;
  return code;
};

HtmlGenerator.button = function (block) {
  const statements_name = HtmlGenerator.statementToCode(block, 'NAME');
  const code = `<button>${statements_name.trim()}</button>\n`;
  return code;
};

HtmlGenerator.input = function (block) {
  const dropdown_type = block.getFieldValue('type');
  const text_value = block.getFieldValue('value');
  const value_text = HtmlGenerator.valueToCode(block, 'text', HtmlGenerator.ORDER_ATOMIC);
  const code = `<input type="${dropdown_type}" value="${text_value}"${value_text} />\n`;
  return code;
};

HtmlGenerator.script = function (block) {
  const statements_content = javascriptGenerator.statementToCode(block, 'content');
  const code = `<script>\n${statements_content}</script>\n`;
  return code;
};

HtmlGenerator.onclick = function (block) {
  const statements_name = javascriptGenerator.statementToCode(block, 'NAME');
  const code = ` onclick="${statements_name.trim()}"`;
  return [code, HtmlGenerator.ORDER_NONE];
};

HtmlGenerator.body_attributes = function (block) {
  const value_name = HtmlGenerator.valueToCode(block, 'NAME', HtmlGenerator.ORDER_ATOMIC);
  const statements_content = HtmlGenerator.statementToCode(block, 'content');
  const code = `<body${value_name}>\n${statements_content}</body>\n`;
  return code;
};

export default HtmlGenerator;
