import { useQuery } from '@tanstack/react-query';
import { List, ListItemButton, ListItemContent, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';

import MainLayout from '../../components/Layouts/MainLayout';
import { getAllActivities } from '../../lib/taxonomies';
import Loading from '../../components/Loading/Loading';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import { useLocale } from '../../hooks/useLocale';

export default function Activities() {
  const [locale] = useLocale();

  const { data, isLoading } = useQuery(['allActivities'], () => getAllActivities());

  if (!locale) return null;

  if (isLoading) {
    return (
      <MainLayout title="Activities">
        <Loading />
      </MainLayout>
    );
  }

  const { data: activities } = data;

  return (
    <MainLayout title="Activities">
      <LocaleSelector />
      <List>
        {activities.map((activity) => (
          <ListItemButton
            key={activity._id}
            component={Link}
            to={`/activities/${activity._id}?locale=${locale}`}>
            <ListItemContent>
              {activity.i18n[locale] ? (
                <Typography>{activity.i18n[locale].title}</Typography>
              ) : (
                <Typography>Untitled</Typography>
              )}
            </ListItemContent>
          </ListItemButton>
        ))}
      </List>
    </MainLayout>
  );
}
