import {
  Alert,
  Avatar,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography
} from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LockIcon from '@mui/icons-material/Lock';

import { DirectionsRun, Language, Reviews, Summarize } from '@mui/icons-material';

import MainLayout from '../../components/Layouts/MainLayout';
import RevalidatorButton from '../../components/RevalidatorButton';
import { useGetGuideById } from '../../lib/guide';
import { useLocale } from '../../hooks/useLocale';
import Loading from '../../components/Loading/Loading';

function validateRequiredFields(guide, locale) {
  const missingFields = [];

  if (
    !guide.seo_tags ||
    (locale !== 'en_EN' && Object.values(guide.seo_tags[locale]).some((tag) => !tag))
  ) {
    missingFields.push('SEO tags');
  }

  if (!guide.email) {
    missingFields.push('email');
  }

  if (!guide.phone_number) {
    missingFields.push('phone');
  }

  if (!guide.first_name) {
    missingFields.push('first name');
  }

  if (!guide.last_name) {
    missingFields.push('last name');
  }

  if (!guide.display_name) {
    missingFields.push('display name');
  }

  if (!guide.description || (locale !== 'en_EN' && !guide.i18n[locale].description)) {
    missingFields.push(`description ${locale}`);
  }

  if (!guide.fee_category) {
    missingFields.push('fee category');
  }

  if (!guide.guide_type) {
    missingFields.push('guide type');
  }

  if (!guide.guided_countries) {
    missingFields.push('guided countries');
  }

  if (!guide.languages) {
    missingFields.push('languages');
  }

  return missingFields.join(', ');
}

export default function EditGuide() {
  const [locale] = useLocale();
  const { guideId } = useParams();
  const { data: guide, isLoading } = useQuery(['guide', guideId], () => useGetGuideById(guideId), {
    staleTime: Infinity
  });

  if (isLoading)
    return (
      <MainLayout title="Options">
        <Loading />
      </MainLayout>
    );

  const missingFields = validateRequiredFields(guide, locale);

  return (
    <MainLayout
      back="/guides"
      title="Options"
      topTitle={guide?.first_name}
      options={<RevalidatorButton link={guide?.link} />}>
      <List aria-labelledby="ellipsis-list-demo" sx={{ '--List-decorator-size': '56px' }}>
        {missingFields ? (
          <Alert color="danger">
            <Typography fontWeight="bold" level="md">
              Important fields missing to publish this guide:
            </Typography>
            <Typography level="sm">{missingFields}</Typography>
          </Alert>
        ) : null}

        <ListItemButton component={Link} to={`/guide/${guideId}/content`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Summarize />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Content</Typography>
            <Typography level="body-sm">Edit title, photo and description</Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/guide/${guideId}/general-info`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <DirectionsRun />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>General info</Typography>
            <Typography level="body-sm">General info about the guide</Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/guide/${guideId}/seo`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Language />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>SEO</Typography>
            <Typography level="body-sm">Edit title, description and tags</Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/guide/${guideId}/reviews`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Reviews />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Reviews</Typography>
            <Typography level="body-sm">
              Create, update or delete this guide&apos;s reviews
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/guide/${guideId}/settings`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <SettingsSuggestIcon />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Settings</Typography>
            <Typography level="body-sm">Settings related to the guide</Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/guide/${guideId}/blocks`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <LockIcon />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Blocks</Typography>
            <Typography level="body-sm">List date blocks</Typography>
          </ListItemContent>
        </ListItemButton>
      </List>
    </MainLayout>
  );
}
