import fetcher from './fetcher';

const useGetValuesByGroup = async (group) => {
  return fetcher({
    url: `/api/v1/dashboard/values/group/${group}`,
    method: 'GET'
  });
};

const useGetValuesByGroupAndKey = async (group, key) => {
  return fetcher({
    url: `/api/v1/dashboard/values/group/${group}/key/${key}`,
    method: 'GET'
  });
};

const useGetValue = async (id) => {
  return await fetcher({
    url: `/api/v1/dashboard/values/${id}`,
    method: 'GET'
  });
};

const useGetListValues = async (group, key) => {
  return await fetcher({
    url: `/api/v1/dashboard/values`,
    method: 'GET'
  });
};

const useGetValueByKeyByGroup = async (group, key) => {
  return await fetcher({
    url: `/api/v1/dashboard/values/group/${group}/key/${key}`,
    method: 'GET'
  });
};

const usePatchValue = async (id, data) => {
  return fetcher({
    url: `/api/v1/dashboard/values/${id}`,
    method: 'PATCH',
    data
  });
};

export {
  useGetValuesByGroup,
  useGetValue,
  useGetListValues,
  usePatchValue,
  useGetValuesByGroupAndKey,
  useGetValueByKeyByGroup
};
