import { Box, Button, FormLabel, Input, Stack, Tooltip, Typography } from '@mui/joy';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import LoginLayout from '../../components/Login/Layout';
import { useUpdatePassword, useValidateHashExpiry } from '../../lib/auth';
import Loading from '../../components/Loading/Loading';
import PasswordField from '../../components/Login/PasswordField';

function generateSecurePassword(length) {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?';

  let password = '';
  for (let i = 0; i < length; ++i) {
    const charIndex = Math.floor(Math.random() * charset.length);
    password += charset[charIndex];
  }

  return password;
}

export default function NewPassword() {
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' });
  const queryParams = new URLSearchParams(window.location.search);

  const hash = queryParams.get('hash');

  const { data, isLoading, isSuccess } = useQuery(
    ['validate-hash-expiry', hash],
    () => {
      return useValidateHashExpiry(hash);
    },
    {
      enabled: Boolean(hash)
    }
  );

  const suggestedPassword = useMemo(() => generateSecurePassword(20), []);

  useEffect(() => {
    if ((!isLoading && !isSuccess) || !hash || data?.expired) {
      navigate('/login');
    }
  }, [data, hash, isLoading, isSuccess]);

  if (isLoading && hash) {
    return (
      <LoginLayout>
        <Loading />
      </LoginLayout>
    );
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await toast.promise(
      useUpdatePassword({ password: e.target.password.value, hash: queryParams.get('hash') }),
      {
        loading: 'Changing password...',
        success: 'Password changed!',
        error: 'Error changing password'
      }
    );
    setTimeout(() => {
      navigate('/login');
    }, 1000);
  };

  const passwordsNotMatch = Boolean(
    passwords.password !== passwords.confirmPassword && passwords.confirmPassword
  );

  return (
    <form onSubmit={handleOnSubmit}>
      <LoginLayout>
        <Box>
          <PasswordField setData={setPasswords} value={passwords.password} />
        </Box>
        <Tooltip title={suggestedPassword}>
          <Button
            variant="plain"
            onClick={() =>
              setPasswords((prev) => ({
                ...prev,
                password: suggestedPassword,
                confirmPassword: suggestedPassword
              }))
            }>
            Use suggested secure password
          </Button>
        </Tooltip>
        <Box>
          <PasswordField
            setData={setPasswords}
            value={passwords.confirmPassword}
            label="Confirm Password"
            name="confirmPassword"
          />
          {passwordsNotMatch && (
            <Typography level="body-sm" style={{ color: 'red' }}>
              Passwords do not match
            </Typography>
          )}
        </Box>
        <Stack direction="row" sx={{ mt: 1, justifyContent: 'end' }}>
          <Button
            disabled={!passwords.password || !passwords.confirmPassword || passwordsNotMatch}
            type="submit">
            Change password
          </Button>
        </Stack>
      </LoginLayout>
    </form>
  );
}
