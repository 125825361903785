import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { Theme } from '@esui/lib.theme';
import * as Sentry from '@sentry/react';
import { Toaster } from 'react-hot-toast';

import { LOCALSTORAGE_TOKEN } from './constants/localStorage';
import I18nContextProvider from './contexts/i18nContext';
import App from './App';
import enTable from './lib/locales/en';
import './main.css';
import { useGetRefresh } from './lib/auth';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'https://1e04f1688988dae8cfb7d1f708075176@o4504248202231808.ingest.us.sentry.io/4507261990928384',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactionsnpm
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/www.explore-share\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const RQC = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [refreshContext, setRefreshContext] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${
      import.meta.env.VITE_GMAPS_API_KEY
    }&libraries=places`;
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: async (error) => {
        if (error.response?.status === 404) {
          navigate('/error/404');
        }
        if (error.response?.status === 403) {
          navigate('/error/404');
        }
        if (error.response?.status === 500) {
          navigate('/error/500');
        }
        if (error.response?.status === 401) {
          let newToken = '';

          try {
            newToken = await useGetRefresh();

            const currentToken = newToken.token;
            const params = new URLSearchParams(window.location.search);
            params.set('token', currentToken);

            // re init app with new token
            window.history.pushState({}, 'ES-Guide', `${window.location.pathname}?${params}`);
            setRefreshContext((prev) => !prev);
          } catch (e) {
            localStorage.removeItem(LOCALSTORAGE_TOKEN);
            navigate('/login');
            return false;
          }
        }
        return null;
      }
    }),
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const rootNode = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RQC>
        <Theme>
          <I18nContextProvider locale="EN" table={enTable}>
            <Toaster containerStyle={{ zIndex: 9999999 }} />
            <App />
          </I18nContextProvider>
        </Theme>
      </RQC>
    </BrowserRouter>
  </React.StrictMode>,
  rootNode
);
