import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import { Transition } from 'react-transition-group';

export default function SharedModal({ children, close, isOpen }) {
  return (
    <Transition in={isOpen} timeout={200}>
      {(state) => (
        <Modal
          keepMounted
          open={isOpen}
          componentsProps={{
            backdrop: {
              sx: {
                opacity: 0,
                backdropFilter: 'none',
                transition: `opacity 400ms, backdrop-filter 200ms`,
                ...{
                  entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                  entered: { opacity: 1, backdropFilter: 'blur(8px)' }
                }[state]
              }
            }
          }}
          sx={{
            visibility: state === 'exited' ? 'hidden' : 'visible'
          }}>
          <ModalDialog
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              opacity: 0,
              transition: `opacity 200ms`,
              overflow: 'auto',
              ...{
                entering: { opacity: 1 },
                entered: { opacity: 1 }
              }[state]
            }}>
            <ModalClose onClick={close} />
            {children}
          </ModalDialog>
        </Modal>
      )}
    </Transition>
  );
}
