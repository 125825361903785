import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Box, FormLabel } from '@mui/joy';
import { Input } from '@esui/lib.formik.input';

import Simple from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import Loading from '../../components/Loading/Loading';
import { useGetGuideById, usePatchGuide } from '../../lib/guide';

const EditGuide = () => {
  const { guideId } = useParams();

  const { data, isLoading } = useQuery(['guide', guideId], () => useGetGuideById(guideId), {
    staleTime: Infinity
  });
  const mutation = useMutation(
    (values) => {
      return usePatchGuide(guideId, values);
    },
    {
      // onSuccess: (data) => {
      //   //  setResult(data);
      // }
    }
  );

  if (isLoading)
    return (
      <Simple title="Guide Settings">
        <Loading />
      </Simple>
    );

  return (
    <Formik
      initialValues={{
        google_place_id: data.google_place_id
      }}
      validateOnChange={false}
      onSubmit={(values) => {
        mutation.mutate(values);
      }}>
      {({ values }) => {
        return (
          <Form>
            <Simple
              title="Guide settings"
              back={`/guide/${guideId}`}
              topTitle={data?.title}
              options={
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Save
                </ButtonLoading>
              }>
              <Box>
                <FormLabel>Google place ID</FormLabel>
                <Input name="google_place_id" type="text" />
              </Box>
            </Simple>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditGuide;
