import fetcher from './fetcher';

export async function useGetBooking(id) {
  return fetcher({
    url: `/api/v1/dashboard/bookings/${id}`,
    method: 'GET'
  });
}

export async function useGetIdBookingByCode(id) {
  return fetcher({
    url: `/api/v1/dashboard/search/exact?query=${id}`,
    method: 'GET'
  });
}

export async function updateBookingInformation(data, id) {
  return fetcher({
    url: `/api/v3/admin/${id}/update-information`,
    method: 'PATCH',
    data
  });
}

export async function previewPriceReCalculator(data, id) {
  return fetcher({
    url: `/api/v3/admin/${id}/preview-price-recalculator`,
    method: 'POST',
    data
  });
}

export async function previewTestPriceReCalculator(data) {
  return fetcher({
    url: `/api/v3/admin/preview-test-price-recalculator`,
    method: 'POST',
    data
  });
}

export function confirmPriceReCalculator(data, id) {
  return fetcher({
    url: `/api/v3/admin/${id}/confirm-price-recalculator`,
    method: 'POST',
    data
  });
}
