import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  ListItemButton,
  List,
  ListItemContent,
  ListItemDecorator,
  Typography,
  Avatar,
  Box,
  Alert
} from '@mui/joy';
import QuizIcon from '@mui/icons-material/Quiz';
import GroupsIcon from '@mui/icons-material/Groups';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AttachMoney from '@mui/icons-material/AttachMoney';
import {
  Assistant,
  DirectionsRun,
  Language,
  LocalOffer,
  Reviews,
  Summarize,
  Feed,
  Hotel
} from '@mui/icons-material';

import { useGetTripById } from '../../lib/trip';
import MainLayout from '../../components/Layouts/MainLayout';
import useIsIframe from '../../hooks/useIsIframe';
import RevalidatorButton from '../../components/RevalidatorButton';
import Loading from '../../components/Loading/Loading';

import { useLocale } from '../../hooks/useLocale';

import History from './History';

const validateRequiredFields = (tripData, locale) => {
  const missingFields = [];
  if (!tripData.main_activity) {
    missingFields.push('main activity');
  }
  if (!tripData.activities?.length) {
    missingFields.push('activities');
  }
  if (!tripData.countries?.length) {
    missingFields.push('countries');
  }
  if (!tripData.guide) {
    missingFields.push('guide');
  }
  if (!tripData.cover_url) {
    missingFields.push('header');
  }
  if (!tripData.i18n[locale].title) {
    missingFields.push('title');
  }
  if (!tripData.i18n[locale].excerpt) {
    missingFields.push('excerpt');
  }
  if (!tripData.i18n[locale].link) {
    missingFields.push('link');
  }
  if (!tripData.duration?.length) {
    missingFields.push('duration');
  }
  return missingFields.join(', ');
};

const OptionsTrip = () => {
  const isIframe = useIsIframe();
  const { tripId } = useParams();
  const [locale] = useLocale();

  const { data: tripData, isLoading } = useQuery(['trip', tripId], () => useGetTripById(tripId), {
    staleTime: Infinity
  });

  if (isLoading)
    return (
      <MainLayout title="Options">
        <Loading />
      </MainLayout>
    );

  const { title, link } = tripData.i18n[locale];

  const waitingPublishedFields = Object.keys(tripData.sync_status || {}).filter(
    (key) => tripData.sync_status[key] === 'WAITING_PUBLISHED'
  );

  const pendingApprovalFields = Object.keys(tripData.sync_status || {}).filter(
    (key) => tripData.sync_status[key] === 'DRAFT'
  );

  const missingFields = validateRequiredFields(tripData, locale);

  return (
    <MainLayout
      back={isIframe ? null : '/trips'}
      title="Options"
      topTitle={title}
      options={<RevalidatorButton link={link} />}>
      <Box>
        {waitingPublishedFields.length > 0 && (
          <Alert color="success">
            <Typography fontWeight="bold" level="md">
              Waiting to be published:
            </Typography>
            <Typography level="sm">
              The following fields are waiting to be published: {waitingPublishedFields.join(', ')}
            </Typography>
          </Alert>
        )}
        {pendingApprovalFields.length > 0 && (
          <Alert color="danger">
            <Typography fontWeight="bold" level="md">
              Pending approval:
            </Typography>
            <Typography level="sm">
              The following fields are pending approval: {pendingApprovalFields.join(', ')}
            </Typography>
          </Alert>
        )}
        {missingFields ? (
          <Alert color="danger">
            <Typography fontWeight="bold" level="md">
              Required fields missing to publish this trip:
            </Typography>
            <Typography level="sm">{missingFields}</Typography>
          </Alert>
        ) : null}
      </Box>
      <History />
      <List aria-labelledby="ellipsis-list-demo" sx={{ '--List-decorator-size': '56px' }}>
        <ListItemButton component={Link} to={`/trip/${tripData._id}/content`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Summarize />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Content</Typography>
            <Typography level="body-sm">
              Edit title, photos, excerpt, content, highlights, itinerary, accommodation, extra
              information, cancellation policies, disclaimer and URL of the source content.
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/seo`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Language />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>SEO</Typography>
            <Typography level="body-sm">
              Edit meta title, meta description, follow meta tag and link
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/general-info`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <DirectionsRun />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>General info</Typography>
            <Typography level="body-sm">
              Edit guide, periods, duration, tags, guide ratio, status, trip type, fitness level,
              trip level, skill level, activities, countries, locations, regions, mountain ranges
              and main activity
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/price`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <AttachMoney />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Prices</Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/price-details`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <LocalOffer />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Price details</Typography>
            <Typography level="body-sm">
              Edit price details, what&apos;s included and what the customer needs to bring
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/openGroup`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <GroupsIcon />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Next departures</Typography>
            <Typography level="body-sm">
              Publish open group dates on this tour so that more clients can join!
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/hotels`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Hotel />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Hotels</Typography>
            <Typography level="body-sm">
              Create or update hotels recommended for this trip
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/FAQ/all`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <QuizIcon />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>FAQs</Typography>
            <Typography level="body-sm">Create or update FAQs in this trip</Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/geolocation`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <GpsFixedIcon />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Geolocation</Typography>
            <Typography level="body-sm">
              Set geolocation for the search page and also the meeting point.
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/ai-assistant`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Assistant />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>AI Assistant</Typography>
            <Typography level="body-sm">
              Find relevant information about this trip using AI
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/reviews`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Reviews />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Reviews</Typography>
            <Typography level="body-sm">
              Create, update or delete this trip&apos;s reviews
            </Typography>
          </ListItemContent>
        </ListItemButton>

        <ListItemButton component={Link} to={`/trip/${tripData._id}/dumpInfo`}>
          <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
            <Avatar size="lg" variant="plain">
              <Feed />
            </Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography>Dump information</Typography>
            <Typography level="body-sm">Download infomation related to trip</Typography>
          </ListItemContent>
        </ListItemButton>
      </List>
    </MainLayout>
  );
};

export default OptionsTrip;
