const CURRENCIES = {
  ARS: {
    value: 'ARS',
    label: 'ARS'
  },
  CAD: {
    value: 'CAD',
    label: 'CAD'
  },
  CHF: {
    value: 'CHF',
    label: 'CHF'
  },
  EUR: {
    value: 'EUR',
    label: 'EUR'
  },
  GBP: {
    value: 'GBP',
    label: 'GBP'
  },
  ISK: {
    value: 'ISK',
    label: 'ISK'
  },
  JPY: {
    value: 'JPY',
    label: 'JPY'
  },
  NOK: {
    value: 'NOK',
    label: 'NOK'
  },
  NZD: {
    value: 'NZD',
    label: 'NZD'
  },
  PLN: {
    value: 'PLN',
    label: 'PLN'
  },
  SEK: {
    value: 'SEK',
    label: 'SEK'
  },
  USD: {
    value: 'USD',
    label: 'USD'
  }
};

export default CURRENCIES;
