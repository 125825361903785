import { Box, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getSystemStatus } from '../../lib/health';

const mapStatus = {
  healthy: '🟢 healthy',
  error: '🔴 error',
  loading: 'loading...'
};

function useStatus() {
  const { data, isLoading, isRefetching, isError, error } = useQuery(
    ['monitor'],
    async () => {
      try {
        return await getSystemStatus();
      } catch (err) {
        throw new Error(err.message);
      }
    },
    {
      refetchInterval: 1 * 60 * 1000
    }
  );
  return { data, isLoading, isRefetching, isError, error: error?.message };
}

export function SystemStatus() {
  const [lastCheckedAt, setLastCheckedAt] = useState(new Date());

  const { data, isLoading, isRefetching } = useStatus();

  useEffect(() => {
    if (!isLoading && !isRefetching) setLastCheckedAt(new Date());
  }, [isLoading, isRefetching]);

  const getStatus = (v) => {
    if (isLoading || isRefetching) return 'loading';
    return v.status;
  };

  return (
    <Box>
      <Typography level="h3">System status: </Typography>
      {Object.keys(data || {}).map((server) => (
        <Typography key={server} sx={{ margin: '5px 0px' }}>
          {server}: {mapStatus[getStatus(data[server])]}
        </Typography>
      ))}
      <Typography level="body-xs" sx={{ marginBottom: 1 }}>
        Last checked at: {lastCheckedAt.toLocaleTimeString()}
      </Typography>
    </Box>
  );
}
