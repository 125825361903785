/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { LOCALSTORAGE_TOKEN } from '../constants/localStorage';
import { useUserProfile } from '../lib/user';
import { useGetRefresh } from '../lib/auth';
import Loading from '../components/Loading/Loading';
import { Box } from '@mui/joy';

const UserContext = createContext();

const useUser = () => useContext(UserContext);

const refreshToken = async () => {
  const newTokenData = await useGetRefresh();
  return newTokenData;
};

const UserProvider = () => {
  // eslint-disable-next-line no-unused-vars
  const [refreshContext, setRefreshContext] = useState(false);

  const user = useUser();

  if (user) {
    return (
      <UserContext.Provider value={user}>
        <Outlet />
      </UserContext.Provider>
    );
  }

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const currentToken = token || localStorage.getItem(LOCALSTORAGE_TOKEN);
  axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`;

  const { data, isSuccess } = useQuery(
    ['userProfile', currentToken],
    () => useUserProfile(currentToken),
    {
      retry: 1,
      staleTime: 0,
      cacheTime: 0
    }
  );

  if (isSuccess) {
    localStorage.setItem(LOCALSTORAGE_TOKEN, currentToken);
    return (
      <UserContext.Provider
        value={{
          ...data,
          token: currentToken,
          refreshToken
        }}>
        <Outlet />
      </UserContext.Provider>
    );
  }

  return (
    <UserContext.Provider value={{}}>
      <Box sx={{ height: '100vh' }}>
        <Loading />
      </Box>
    </UserContext.Provider>
  );
};

export default UserProvider;
export { useUser, UserContext };
