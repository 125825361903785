import { useState } from 'react';
import { shape } from 'prop-types';
import { IconButton, Box } from '@mui/joy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usePatchTrip } from '../../lib/trip';
import Star from '../Star/Star';
import { useLocale } from '../../hooks/useLocale';

const SITE = import.meta.env.VITE_SITE;

const TripTopTile = ({ trip }) => {
  const [locale] = useLocale();
  const [tripState, setTripState] = useState(trip);
  const queryClient = useQueryClient();
  const favoriteMutation = useMutation(
    (values) => {
      return usePatchTrip({
        ...values,
        admin_preferences: {
          ...values.admin_preferences,
          favorite: !values.admin_preferences?.favorite
        }
      });
    },
    {
      onSuccess: (tripUpdated) => {
        setTripState(tripUpdated);

        queryClient.invalidateQueries(['trips']);
      }
    }
  );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Star
        filled={tripState.admin_preferences?.favorite}
        sx={{
          '--IconButton-size': '20px',
          padding: 0
        }}
        onClick={() => favoriteMutation.mutate(tripState)}
      />
      {trip.i18n[locale].title}
      {trip.i18n[locale].link && (
        <IconButton
          sx={{
            '--IconButton-size': '20px',
            margin: 0
          }}
          variant="plain"
          component="a"
          href={
            trip.i18n[locale].link &&
            trip.i18n[locale].link.replace('https://www.explore-share.com', SITE)
          }
          target="_blank">
          <OpenInNewIcon />
        </IconButton>
      )}
    </Box>
  );
};

TripTopTile.propTypes = {
  trip: shape({})
};

TripTopTile.defaultProps = {
  trip: null
};

export default TripTopTile;
