import { number } from 'prop-types';
import { Box, Button } from '@mui/joy';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import { Textarea } from '@esui/lib.formik.textarea';

import { useGetTripById, usePatchTrip } from '../../lib/trip';

const AdminComment = ({ id }) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(['trip'], () => useGetTripById(id));

  const mutation = useMutation(
    (values) => {
      return usePatchTrip({
        _id: values._id,
        admin_preferences: {
          comment: values.comment
        }
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['FAQs']);
      }
    }
  );

  const handlerSubmit = (values) => {
    mutation.mutate(values);
  };

  if (isLoading) return <>Loading</>;
  return (
    <Formik
      initialValues={{
        _id: data._id,
        comment: data.admin_preferences?.comment
      }}
      onSubmit={handlerSubmit}>
      <Form>
        <Box>
          <Textarea name="comment" minRows={4} sx={{ marginBottom: '5px' }} />
          <Button type="submit">Save comment</Button>
        </Box>
      </Form>
    </Formik>
  );
};

AdminComment.propTypes = {
  id: number.isRequired
};

export default AdminComment;
