import fetcher from './fetcher';

const agendaJobsRunningUrl = '/api/v3/admin/agenda/running';
const lastAgendaJobsUrl = '/api/v3/admin/agenda/last_jobs';

const { VITE_API } = import.meta.env;
const isProd = import.meta.env.VITE_NODE_ENV === 'production';

const servers = isProd
  ? {
      'Server 1': 'https://srv1.explore-share.com',
      'Server 2': 'https://srv2.explore-share.com'
    }
  : {
      'Server 1': VITE_API
    };

export async function getAgendaStatus() {
  const agendaStatusByServer = {};

  for await (const server of Object.keys(servers)) {
    const url = servers[server] + agendaJobsRunningUrl;
    try {
      agendaStatusByServer[server] = await fetcher({
        url,
        method: 'GET'
      });
    } catch (err) {
      agendaStatusByServer[server] = { status: err.message };
    }
  }

  return agendaStatusByServer;
}

export async function getLastJobs() {
  const lastJobsByServer = {};
  const server = 'Server 1';

  const url = servers[server] + lastAgendaJobsUrl;
  try {
    lastJobsByServer[server] = await fetcher({
      url,
      method: 'GET'
    });
  } catch (err) {
    lastJobsByServer[server] = { status: err.message };
  }

  return lastJobsByServer;
}

export async function getSystemStatus() {
  const serverStatus = {};

  for await (const server of Object.keys(servers)) {
    const url = servers[server];
    try {
      serverStatus[server] = await fetcher({
        url,
        method: 'GET'
      });
    } catch (err) {
      serverStatus[server] = { status: 'error' };
    }
  }

  return serverStatus;
}
