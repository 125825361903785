import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography
} from '@mui/joy';
import { useState } from 'react';

import { DEFAULT_FAQS, DEFAULT_LOCALE } from '../../constants/defaults';

export default function AddDefaultFaqModal({ open, onClose, onConfirm, location }) {
  const locale = new URLSearchParams(window.location.search).get('locale') || DEFAULT_LOCALE;
  const [selectedCustomFaq, setSelectedCustomFaq] = useState(null);

  return (
    <Modal open={open}>
      <ModalDialog sx={{ width: '500px' }}>
        <Box
          sx={{
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}>
          <DialogTitle>
            <Typography level="h4">Add default faq</Typography>
          </DialogTitle>
          <DialogContent>
            <Select placeholder="Choose one..." onChange={(e, v) => setSelectedCustomFaq(v)}>
              {DEFAULT_FAQS[locale]
                .map((faq) => faq.replace('<country>', location))
                .map((faq) => (
                  <Option key={faq} value={faq}>
                    {faq}
                  </Option>
                ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
              <Button
                variant="plain"
                color="neutral"
                onClick={() => {
                  onClose();
                  setSelectedCustomFaq(null);
                }}>
                Cancel
              </Button>
              <Button
                disabled={!selectedCustomFaq}
                onClick={() => {
                  onClose();
                  setSelectedCustomFaq(null);
                  onConfirm(selectedCustomFaq);
                }}>
                Add
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
