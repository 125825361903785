export default async function revalidatePage(data) {
  const bodyContent = JSON.stringify(data);

  const response = await fetch(`${import.meta.env.VITE_SITE}/api/revalidateByURL/`, {
    method: 'POST',
    body: bodyContent,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  return response;
}
