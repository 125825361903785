import { DEFAULT_LOCALE } from '../constants/defaults';

import fetcher from './fetcher';

export const patchBlogPost = async ({ _id, ...data }) => {
  return fetcher({
    url: `/api/blog_posts/${_id}`,
    method: 'PUT',
    data
  });
};

export async function useGetBlogPostById(id) {
  const urlSearchParams = new URLSearchParams(``);
  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          _id: id
        }
      },
      {
        $lookup: {
          from: 'countriesv2',
          localField: 'countries',
          foreignField: '_id',
          as: 'countries'
        }
      },
      {
        $lookup: {
          from: 'activitiesv2',
          localField: 'activities',
          foreignField: '_id',
          as: 'activities'
        }
      },
      {
        $lookup: {
          from: 'tripsv2',
          localField: 'trips',
          foreignField: '_id',
          as: 'trips'
        }
      },
      {
        $lookup: {
          from: 'authors',
          localField: 'author',
          foreignField: '_id',
          as: 'author'
        }
      },
      {
        $unwind: {
          path: '$author',
          preserveNullAndEmptyArrays: true
        }
      }
    ])
  );

  return fetcher({
    url: `/api/blog_posts/aggregation?query=${urlSearchParams.get('query').toString()}`,
    method: 'GET'
  });
}

export async function useGetBlogPosts({ page, search, locale = DEFAULT_LOCALE }) {
  const queryParams = search || ``;
  const urlSearchParams = new URLSearchParams(`${queryParams}`);
  const s = urlSearchParams.get('search');
  // const status = urlSearchParams.get('status');

  urlSearchParams.set(
    'query',
    JSON.stringify([
      {
        $match: {
          ...(s
            ? {
                [`i18n.${locale}.title`]: {
                  $regex: s,
                  $options: 'i'
                }
              }
            : {}),
          i18n: {
            $exists: true
          }
          // ...(status
          //   ? {
          //       status: {
          //         $eq: status
          //       }
          //     }
          //   : {})
        }
      },
      {
        $sort: {
          updatedAt: -1
        }
      },
      { $skip: 20 * page },
      { $limit: 20 },
      {
        $lookup: {
          from: 'authors',
          localField: 'author',
          foreignField: '_id',
          as: 'author'
        }
      },
      {
        $unwind: {
          path: '$author',
          preserveNullAndEmptyArrays: true
        }
      },
      {
        $project: {
          _id: 1,
          featured_image: 1,
          updatedAt: 1,
          author: 1,
          status: 1,
          i18n: 1
        }
      }
    ])
  );

  return fetcher({
    url: `/api/blog_posts/aggregation?query=${urlSearchParams.get('query').toString()}`,
    method: 'GET'
  });
}

export async function createBlogPost(data) {
  return fetcher({
    url: '/api/blog_posts/new',
    method: 'POST',
    data
  });
}
