import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { Box } from '@mui/joy';

import MainLayout from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import Loading from '../../components/Loading/Loading';
import { getStaticPage } from '../../lib/static_pages';
import RevalidatorButton from '../../components/RevalidatorButton';

export default function StaticPageEditLayout({
  children,
  title,
  handleMutation,
  customActionButton
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading: isLoadingPost } = useQuery(
    ['staticPage', id],
    () => getStaticPage({ id }),
    {
      staleTime: Infinity
    }
  );

  const mutation = useMutation(
    (values) => {
      return handleMutation(id, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['staticPage', id]);
      }
    }
  );

  if (isLoadingPost)
    return (
      <MainLayout title={title}>
        <Loading />
      </MainLayout>
    );

  const staticPage = data?.data[0];

  return (
    <Formik initialValues={staticPage} validateOnChange={false} onSubmit={mutation.mutate}>
      {({ values, setFieldValue }) => (
        <Form>
          <MainLayout
            title={title}
            topTitle={staticPage.i18n.en_EN.seo_tags.meta_title}
            options={
              customActionButton || (
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                    Save
                  </ButtonLoading>
                  <RevalidatorButton
                    link={`${import.meta.env.VITE_SITE}/${staticPage.i18n.en_EN.seo_tags.slug}`}
                  />
                </Box>
              )
            }
            back="/static-pages">
            {children(values, setFieldValue)}
          </MainLayout>
        </Form>
      )}
    </Formik>
  );
}
