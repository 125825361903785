import fetcher from './fetcher';

export async function usePostPriceCalculator(data) {
  return fetcher({
    url: `/api/v1/currencies/budget-prices`,
    method: 'POST',
    data
  });
}

export async function quoteNewPayments(data, bookingId) {
  return fetcher({
    url: `/api/v3/admin/${bookingId}/quote-new-payments`,
    method: 'POST',
    data
  });
}

export async function applyPaymentPlan(data, bookingCode) {
  return fetcher({
    url: `/api/v3/admin/booking/${bookingCode}/price-calculator`,
    method: 'PUT',
    data
  });
}
