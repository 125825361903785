import { Box, FormLabel, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { Select } from '@esui/lib.formik.select';
import toast from 'react-hot-toast';

import ArrayField from '../../components/ArrayField';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import { getAllActivities, getAllAuthors, getAllCountries } from '../../lib/taxonomies';
import ExpandableContent from '../Trip/ExpandableContent';
import Loading from '../../components/Loading/Loading';
import { patchBlogPost } from '../../lib/blog-posts';
import TopTrips from '../../components/LandingPage/TopTrips';

import { useLocale } from '../../hooks/useLocale';

import BlogPostEditLayout from './BlogPostEditLayout';

export default function GeneralInfo() {
  const [locale] = useLocale();

  const handleMutation = async (postId, newPost) => {
    const newData = {
      activities: newPost.activities.map((v) => v._id),
      countries: newPost.countries.map((v) => v._id),
      author: newPost.author,
      trips: newPost.trips.map((v) => v._id),
      status: newPost.status
    };
    await toast.promise(
      patchBlogPost({
        _id: postId,
        ...newData
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  return (
    <BlogPostEditLayout title="General Info" handleMutation={handleMutation}>
      {({ activities, countries, author, trips }) => (
        <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          <LocaleSelector />

          <Box>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              items={[
                { label: 'Draft', value: 'draft' },
                { label: 'Published', value: 'published' }
              ]}
            />
          </Box>

          <ExpandableContent title="Activities">
            <ArrayField
              valueKey="activities"
              currentValues={activities}
              fn={getAllActivities}
              name="activities"
              locale={locale}
            />
          </ExpandableContent>

          <ExpandableContent title="Countries">
            <ArrayField
              valueKey="countries"
              currentValues={countries}
              name="countries"
              fn={getAllCountries}
              locale={locale}
            />
          </ExpandableContent>

          <ExpandableContent title="Author">
            <AuthorsArrayField valueKey="author" author={author} fn={getAllAuthors} />
          </ExpandableContent>

          <TopTrips values={trips} prop="trips" />
        </Box>
      )}
    </BlogPostEditLayout>
  );
}

function AuthorsArrayField({ author, valueKey, fn }) {
  const { data, isLoading } = useQuery([valueKey], () => fn());

  if (isLoading) return <Loading />;

  const allAuthors = data?.data;

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography>
          Current: {author?.firstName} {author?.lastName}
        </Typography>
        <Select
          name="author"
          items={
            allAuthors?.map((a) => ({
              label: `${a.firstName} ${a.lastName}`,
              value: a._id
            })) || []
          }
        />
      </Box>
    </Box>
  );
}
