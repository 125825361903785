import axios from 'axios';

axios.defaults.baseURL = import.meta.env.VITE_API;

const fetcher = async ({ url, data, method, headers, params }) => {
  const config = {
    url,
    method,
    data,
    params,
    headers
  };
  const response = await axios(config);
  return response.data;
};

export default fetcher;
