import * as React from 'react';
import { Box, Typography, Card } from '@mui/joy';

import { shape, bool, string, func, number, oneOf } from 'prop-types';

import ControlButtons from '../../components/PendingApproval/ControlButtons';

const Pricing = ({ data, tripId, field, handleConfirm, updatingState }) => {
  return (
    <Card>
      <Typography level="h5">Pricing</Typography>
      <Box sx={{ marginTop: '5px' }}>
        {data.length ? (
          data.map(({ people, price, currency }, index) => (
            <Box key={index} sx={{ margin: '10px 0px' }}>
              <Typography level="body-sm">
                {currency ? (
                  <>
                    <strong>Currency</strong>: {currency}
                  </>
                ) : null}
              </Typography>
              <Typography level="body-sm">
                {price && (
                  <>
                    <strong>Group of {people}</strong>: {price}
                  </>
                )}
              </Typography>
            </Box>
          ))
        ) : (
          <Box>
            <Typography level="title-sm" sx={{ fontStyle: 'italic' }}>
              All prices were deleted
            </Typography>
          </Box>
        )}
      </Box>
      <ControlButtons
        to={`/trip/${tripId}/price`}
        loading={updatingState.loading}
        handleConfirm={() => handleConfirm({ tripId, field })}
      />
    </Card>
  );
};

Pricing.propTypes = {
  data: shape({}).isRequired,
  tripId: oneOf([string, number]).isRequired,
  field: string.isRequired,
  handleConfirm: func.isRequired,
  updatingState: {
    loading: bool
  }.isRequired
};

export default Pricing;
