import fetcher from './fetcher';

const useRunSync = async (torun, data) => {
  return fetcher({
    url: `/api/v3/admin/sync/${torun}`,
    method: 'POST',
    data
  });
};

export { useRunSync };
