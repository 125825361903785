import fetcher from './fetcher';

const useUserProfile = async (token) => {
  return fetcher({
    url: `/api/v1/dashboard/auth/profile`,
    method: 'GET'
  });
};

export { useUserProfile };
