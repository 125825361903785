import { Box, Button, IconButton } from '@mui/joy';
import { FieldArray } from 'formik';
import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { Input } from '@esui/lib.formik.input';

import { Textarea } from '@esui/lib.formik.textarea';

import ExpandSectionLayout from './ExpandSectionLayout';
import DeleteItemModal from './DeleteItemModal';
import AddDefaultFaqModal from './AddDefaultFaqModal';

export default function Faq({ faqs, location }) {
  const [newFaqModalOpen, setNewFaqModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  return (
    <ExpandSectionLayout title="Faq">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <FieldArray name="faq">
          {({ push, remove }) => (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '50px'
                }}>
                {faqs?.length > 0
                  ? faqs.map((item, i) => (
                      <Box
                        key={item._id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          position: 'relative'
                        }}>
                        <Box sx={{ position: 'absolute', right: 0 }}>
                          <IconButton
                            onClick={() => {
                              setDeleteModalOpen(true);
                              setItemToDelete(i);
                            }}>
                            <RemoveCircleOutlineOutlined color="danger" />
                          </IconButton>
                        </Box>
                        <Input name={`faq.${i}.title`} label="Title" />
                        <Textarea name={`faq.${i}.text`} label="Text" />
                      </Box>
                    ))
                  : null}
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ width: 'fit-content', marginRight: '5px' }}
                    onClick={() => {
                      push({
                        title: '',
                        text: ''
                      });
                    }}>
                    Add custom faq
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ width: 'fit-content' }}
                    onClick={() => setNewFaqModalOpen(true)}>
                    Add default faq
                  </Button>
                </Box>
              </Box>
              <AddDefaultFaqModal
                open={newFaqModalOpen}
                location={location}
                onClose={() => setNewFaqModalOpen(false)}
                onConfirm={(selectedCustomFaq) =>
                  push({
                    title: selectedCustomFaq,
                    text: ''
                  })
                }
              />
              <DeleteItemModal
                open={deleteModalOpen}
                remove={() => {
                  remove(itemToDelete);
                }}
                onClose={() => {
                  setDeleteModalOpen(false);
                  setItemToDelete(null);
                }}
              />
            </>
          )}
        </FieldArray>
      </Box>
    </ExpandSectionLayout>
  );
}
