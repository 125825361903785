import { Box } from '@mui/joy';
import { Textarea } from '@esui/lib.formik.textarea';
import { Checkbox } from '@esui/lib.formik.checkbox';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';

import { getToBring, getWhatsIncluded, usePatchTrip } from '../../lib/trip';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import TranslatedFieldLabel from '../../components/TranslatedFieldLabel/TranslatedFieldLabel';
import { useLocale } from '../../hooks/useLocale';

import TripEditLayout from './TripEditLayout';
import ExpandableContent from './ExpandableContent';

export default function PriceDetails() {
  const [locale] = useLocale();

  const handleMutation = async (tripId, newTrip) => {
    const newData = {
      whats_included2: newTrip.whats_included2,
      to_bring2: newTrip.to_bring2,
      i18n: newTrip.i18n
    };
    await toast.promise(
      usePatchTrip({
        _id: tripId,
        ...newData
      }),
      {
        loading: 'Saving changes...',
        success: 'Changes saved successfully',
        error: 'Error while saving changes'
      }
    );
  };

  const { data, isLoading } = useQuery(['whats_included'], () => getWhatsIncluded());
  const { data: toBring, isLoading: isLoadingToBring } = useQuery(['to_bring'], () => getToBring());

  if (isLoading || isLoadingToBring) {
    return <Box>Loading...</Box>;
  }

  return (
    <TripEditLayout title="Price details" handleMutation={handleMutation}>
      {(values) => (
        <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
          <LocaleSelector />
          <Textarea
            minRows={3}
            maxRows={15}
            label={<TranslatedFieldLabel label="Price details" />}
            name={`i18n.${locale}.price_includes`}
          />
          <Box>
            <ExpandableContent title="What's included">
              <Checkbox
                name="whats_included2"
                items={data.data.map(({ _id, i18n }) => ({ value: _id, label: i18n.en_EN }))}
                listItemPropChecked={{ variant: 'soft', color: 'primary' }}
              />
            </ExpandableContent>
          </Box>
          <Box>
            <ExpandableContent title="What to bring">
              <Checkbox
                items={toBring.data.map(({ _id, i18n }) => ({ value: _id, label: i18n.en_EN }))}
                name="to_bring2"
                listItemPropChecked={{ variant: 'soft', color: 'primary' }}
              />
              <Box>
                <Textarea
                  minRows={3}
                  maxRows={15}
                  label={<TranslatedFieldLabel label="Other gear" />}
                  name={`i18n.${locale}.other_gear_to_bring`}
                />
              </Box>
            </ExpandableContent>
          </Box>
        </Box>
      )}
    </TripEditLayout>
  );
}
