import { useQuery } from '@tanstack/react-query';
import { Box, List, ListItemButton } from '@mui/joy';
import { Link } from 'react-router-dom';

import { getMailsTemplates } from '../../lib/mails';
import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';

export default function ListMailsReusableComponents() {
  const { data, isLoading } = useQuery(['mails-reusable-components'], () => getMailsTemplates());

  if (isLoading) {
    return (
      <MainLayout title="Mail's reusable components">
        <Loading />
      </MainLayout>
    );
  }

  const components = data?.data;

  return (
    <MainLayout title="Mail's reusable components">
      {components?.length ? (
        <Box>
          <List>
            {components.map((component) => (
              <ListItemButton
                key={component._id}
                component={Link}
                to={`/messaging/mails-reusable-components/${component._id}`}>
                {component.template_key}
              </ListItemButton>
            ))}
          </List>
        </Box>
      ) : (
        <Box>No components found</Box>
      )}
    </MainLayout>
  );
}
