import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Input } from '@esui/lib.formik.input';
import { Select } from '@esui/lib.formik.select';
import { Box, Button, FormLabel, Select as MuiSelect, Option } from '@mui/joy';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Textarea } from '@esui/lib.formik.textarea';

import MainLayout from '../../components/Layouts/MainLayout';
import Loading from '../../components/Loading/Loading';
import { editMail, getMail, getMailVersions } from '../../lib/mails';
import ButtonLoading from '../../components/ButtonLoading';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import generateHtmlBlocks from '../../utils/blockly/html-blocks';
import PreviewModal from '../../components/Mails/PreviewModal';
import BlockyContent from '../../components/Mails/BlocklyContent';
import CopyContent from '../../components/Mails/CopyContent';
import { useLocale } from '../../hooks/useLocale';

export default function EditMail() {
  const { id } = useParams();
  const { data, isLoading } = useQuery([`mail-${id}`], () => getMail(id));
  const { data: mailVersions, isLoading: isLoadingVersions } = useQuery(
    [`mail-versions-${data?.data?.mail_name}`],
    () => getMailVersions(data?.data?.mail_name),
    {
      enabled: !isLoading
    }
  );

  const [copyContent, setCopyContent] = useState(false);

  const queryClient = useQueryClient();
  const mutate = useMutation((values) => editMail(values, id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`mail-${id}`]);
    }
  });

  const [locale] = useLocale();

  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  useEffect(() => {
    if (!locale) return;
    generateHtmlBlocks(locale);
  }, [locale]);

  if (isLoading || isLoadingVersions) {
    return (
      <MainLayout title="Loading mail...">
        <Loading />
      </MainLayout>
    );
  }

  const handleOnSubmit = async (values) => {
    const { _id } = await toast.promise(mutate.mutateAsync({ ...values, type: 'mail' }), {
      loading: 'Saving...',
      success: 'Mail saved!',
      error: 'An error occurred'
    });
    window.location.href = `/messaging/mails/${_id}`;
  };

  const mail = data.data;

  return (
    <Box>
      <Formik initialValues={mail} validateOnChange={false} onSubmit={handleOnSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <MainLayout
              title="Edit mail"
              topTitle={mail?.mail_name}
              options={
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <Box>
                    <FormLabel>Version</FormLabel>
                    <MuiSelect
                      defaultValue={mail._id}
                      onChange={(e, option) => {
                        window.location = `/messaging/mails/${option}`;
                      }}
                    >
                      {mailVersions.data.map((version) => (
                        <Option key={version._id} value={version._id} disabled={id === version._id}>
                          {new Date(version.updatedAt).toLocaleDateString()}
                          {' at '}
                          {new Date(version.updatedAt).toLocaleTimeString()}
                          {version.current && ' (current)'}
                        </Option>
                      ))}
                    </MuiSelect>
                  </Box>
                  <Button variant="outlined" onClick={() => setPreviewModalOpen(true)}>
                    Preview
                  </Button>
                  <ButtonLoading size="sm" type="submit" isLoading={false}>
                    Save
                  </ButtonLoading>
                </Box>
              }
              back="/messaging/mails"
            >
              <LocaleSelector />
              <Box sx={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
                <Box>
                  <FormLabel>To</FormLabel>
                  <Select
                    name="to"
                    items={[
                      { label: 'Lead email', value: '{{lead.email}}' },
                      { label: 'Guide email', value: '{{guide.email}}' },
                      { label: 'User email', value: '{{user.email}}' }
                    ]}
                  />
                </Box>
                <Box>
                  <Textarea name="comments" label="Comments" />
                </Box>
                <Box>
                  <Input name="bcc" label="Bcc" />
                </Box>
                <Box>
                  <Input name="reply_to" label="Reply to" />
                </Box>
                <Box>
                  <Input name="from" label="From" />
                </Box>
                <Box>
                  <FormLabel>Subject</FormLabel>
                  <CopyContent
                    locale={locale}
                    setFieldValue={setFieldValue}
                    values={values}
                    setCopyContent={setCopyContent}
                    variant="subject"
                  />
                  <BlockyContent
                    sx={{ height: 300 }}
                    locale={locale}
                    setJsonValue={setFieldValue}
                    setHtmlValue={(code) => {
                      setFieldValue(`i18n.${locale}.subject`, code);
                    }}
                    defaultContent={values.i18n[locale]?.subject_raw_data}
                    copyContent={copyContent}
                    setCopyContent={setCopyContent}
                    variable="subject"
                  />
                </Box>
                <Box>
                  <FormLabel>Content</FormLabel>
                  <CopyContent
                    locale={locale}
                    setFieldValue={setFieldValue}
                    values={values}
                    setCopyContent={setCopyContent}
                    variant="content"
                  />
                  <BlockyContent
                    sx={{ height: 900 }}
                    locale={locale}
                    setJsonValue={setFieldValue}
                    setHtmlValue={(code) => {
                      setFieldValue(
                        `i18n.${locale}.content`,
                        code.replace(/<\/\w+\s+[^>]*>/g, (match) =>
                          match.replace(/\s+\w+="[^"]*"/g, '')
                        )
                      );
                    }}
                    defaultContent={values.i18n[locale]?.content_raw_data}
                    copyContent={copyContent}
                    setCopyContent={setCopyContent}
                    variable="content"
                  />
                </Box>
              </Box>
            </MainLayout>
            <PreviewModal
              isOpen={previewModalOpen}
              close={() => setPreviewModalOpen(false)}
              i18n={values.i18n}
              mail_name={values.mail_name}
              locale={locale}
              isCurrentVersion={mail.current}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
}
