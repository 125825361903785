import Autocomplete from '@mui/joy/Autocomplete';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Typography from '@mui/joy/Typography';

import { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import { updateBookingInformation } from '../../../lib/booking';
import SharedModal from '../../../components/SharedModal';
import ToastText from '../../../components/ToastText';

import Dates from './Dates';

export default function NewBookingInfo({ newBooking, booking, guides, dispatch, actionTypes }) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmationModalOpen(false);
    await toast.promise(
      updateBookingInformation(newBooking, booking._id),
      {
        loading: <ToastText>Updating booking...</ToastText>,
        success: <ToastText>Booking updated successfully</ToastText>,
        error: <ToastText>Error updating booking</ToastText>
      },
      {
        position: 'bottom-center',
        success: {
          duration: 3000
        },
        error: {
          duration: 3000
        }
      }
    );
  };

  const currentGuide = useMemo(() => {
    if (!newBooking.guide || !guides) return null;
    return guides.find((guide) => guide._id === newBooking.guide._id);
  }, [guides, newBooking.guide]);

  return (
    <Box sx={{ marginTop: '30px' }}>
      <Typography level="h4">New data:</Typography>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Typography level="body-sm">Amount of travellers</Typography>
              <Input
                value={newBooking.people_count}
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  dispatch({ type: actionTypes.PEOPLE_COUNT, payload: Number(e.target.value) })
                }
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Typography level="body-sm">Duration</Typography>
              <Input
                value={newBooking.duration}
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  dispatch({ type: actionTypes.DURATION, payload: Number(e.target.value) })
                }
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Dates
                title="New departure date"
                defaultDate={booking.departure_date}
                handleNewDate={(newDate) => {
                  dispatch({ type: actionTypes.DEPARTURE_DATE, payload: newDate });
                }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Typography level="body-sm">Booking state</Typography>
              <Select
                value={newBooking.state}
                onChange={(e, value) => dispatch({ type: actionTypes.STATE, payload: value })}>
                <Option value="B:REQUESTED">Requested</Option>
                <Option value="B:CANCELLED">Cancelled</Option>
                <Option value="B:PAID">Paid</Option>
                <Option value="B:COMPLETED">Completed</Option>
              </Select>
            </Grid>
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Typography level="body-sm">Guide state</Typography>
              <Select
                value={newBooking.guide_state}
                onChange={(e, value) =>
                  dispatch({ type: actionTypes.GUIDE_STATE, payload: value })
                }>
                <Option value="G:AWAITING_GUIDE_CONFIRMATION">Awaiting guide confirmation</Option>
                <Option value="G:CONFIRMED">Confirmed</Option>
                <Option value="G:CONFIRMED_PRICE_CHANGED">Confirmed price changed</Option>
                <Option value="G:DECLINED">Declined</Option>
                <Option value="G:DECLINED_INVIABLE">Declined inviable</Option>
              </Select>
            </Grid>
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Typography level="body-sm">Cancellation reason</Typography>
              <Select
                value={newBooking.cancellation_reason}
                onChange={(e, value) =>
                  dispatch({ type: actionTypes.CANCELLATION_REASON, payload: value })
                }>
                <Option value="PARTIAL_REFUND">Partial refund</Option>
                <Option value="FULL_REFUND">Full refund</Option>
                <Option value="NO_REFUND">No refund</Option>
              </Select>
            </Grid>
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Typography level="body-sm">Payment method</Typography>
              <Select
                value={newBooking.payment_method}
                onChange={(e, value) =>
                  dispatch({ type: actionTypes.PAYMENT_METHOD, payload: value })
                }>
                <Option value="cc">Credit Card</Option>
                <Option value="wire">Wire</Option>
                <Option value="deposit">Deposit</Option>
                <Option value="cash">Cash</Option>
              </Select>
            </Grid>
            {newBooking.payment_method === 'deposit' ? (
              <>
                <Grid xs={12} md={6} lg={4} xl={3}>
                  <Typography level="body-sm">Payment method for balance</Typography>
                  <Select
                    value={newBooking.payment_options.payment_method}
                    onChange={(e, value) =>
                      dispatch({ type: actionTypes.PAYMENT_OPTIONS_METHOD, payload: value })
                    }>
                    <Option value="cash">Cash</Option>
                    <Option value="cc-wire">Wire</Option>
                    <Option value="cc">Credit Card</Option>
                  </Select>
                </Grid>
                <Grid xs={12} md={6} lg={4} xl={3}>
                  <Typography level="body-sm">Deposit percentage</Typography>
                  <Input
                    value={newBooking.payment_options.deposit_percentage}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      dispatch({
                        type: actionTypes.PAYMENT_OPTIONS_DEPOSIT_PERCENTAGE,
                        payload: Number(e.target.value)
                      })
                    }
                  />
                </Grid>
              </>
            ) : null}
            <Grid xs={12} md={6} lg={4} xl={3}>
              <Typography level="body-sm">Customer fee %</Typography>
              <Input
                value={newBooking.customer_fee}
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  dispatch({ type: actionTypes.CUSTOMER_FEE, payload: Number(e.target.value) })
                }
              />
            </Grid>
            {currentGuide && (
              <Grid xs={12} md={6} lg={4} xl={3}>
                <Typography level="body-sm">Guide</Typography>
                <Autocomplete
                  defaultValue={`${currentGuide?.first_name} ${currentGuide?.last_name} / ${currentGuide?.email} (${currentGuide?._id})`}
                  options={guides.map(
                    (option) =>
                      `${option.first_name} ${option.last_name} / ${option.email} (${option._id})`
                  )}
                  onChange={(e, value) => {
                    if (!value) return;
                    const selectedGuide = guides.find(
                      (guide) =>
                        `${guide.first_name} ${guide.last_name} / ${guide.email} (${guide._id})` ===
                        value
                    );
                    dispatch({ type: actionTypes.GUIDE, payload: selectedGuide });
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Box sx={{ marginTop: '10px', display: 'flex', gap: '5px', justifyContent: 'end' }}>
            <Button
              variant="outlined"
              disabled={!newBooking.updated}
              onClick={() => {
                setConfirmationModalOpen(false);
                dispatch({ type: actionTypes.RESET, payload: booking });
              }}>
              Reset
            </Button>
            <Button /*disabled={!newBooking.updated}*/ onClick={() => setConfirmationModalOpen(true)}>
              Save
            </Button>
          </Box>
        </form>
      </Box>
      <SharedModal close={() => setConfirmationModalOpen(false)} isOpen={confirmationModalOpen}>
        <Typography level="h4">Are you sure you want to update this booking?</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'end', gap: '5px' }}>
          <Button
            sx={{ marginTop: '10px' }}
            variant="outlined"
            color="danger"
            onClick={() => setConfirmationModalOpen(false)}>
            Cancel
          </Button>
          <Button sx={{ marginTop: '10px' }} onClick={handleSubmit}>
            Confirm
          </Button>
        </Box>
      </SharedModal>
    </Box>
  );
}
