import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Box } from '@mui/joy';
import { Input } from '@esui/lib.formik.input';

import Simple from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import { useGetValue, usePatchValue } from '../../lib/values';
import Loading from '../../components/Loading/Loading';
import Markdown from '../../components/Markdown/Markdown';

const EditValues = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(['values', id], () => useGetValue(id), {
    staleTime: Infinity
  });

  const mutation = useMutation((values) => {
    const value = (() => {
      if (typeof values.value === 'string') {
        return values.value.replaceAll('<!---->', '');
      }
      return values.value.map((v) => {
        return {
          ...v,
          value: v.answer.replaceAll('<!---->', '')
        };
      });
    })();
    return usePatchValue(id, {
      ...values,
      value
    });
  });

  if (isLoading)
    return (
      <Simple title="Prices">
        <Loading />
      </Simple>
    );

  return (
    <Formik
      initialValues={{
        value: data.value,
        label: data.label
      }}
      validateOnChange={false}
      onSubmit={(values) => {
        mutation.mutate(values);
      }}>
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Simple
              title={data.key}
              back="/values"
              options={
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Save
                </ButtonLoading>
              }>
              <Box>
                {data.key.includes('FAQ') ? (
                  values.value.map((value, i) => (
                    <Box key={value._id} sx={{ margin: '20px 0px' }}>
                      <Input name={`value.${i}.question`} type="text" />
                      <Markdown
                        defaultContent={value.answer?.replaceAll('<!---->', '') || ''}
                        handleOnChange={(markdown) => setFieldValue(`value.${i}.answer`, markdown)}
                      />
                    </Box>
                  ))
                ) : (
                  <Markdown
                    defaultContent={values.value?.replaceAll('<!---->', '') || ''}
                    handleOnChange={(markdown) => setFieldValue(`value`, markdown)}
                  />
                )}
              </Box>
              {/* {data.type === 'MARKDOWN' && (
                <>
                  <Typography level="h3">Result</Typography>
                  <Typography>
                    <ReactMarkdown
                      children={values.value}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        table: ({ ...props }) => <table style={{ width: '100%' }} {...props} />,
                        h2: ({ ...props }) => <h2 {...props} />,
                        td: ({ ...props }) => <td style={{ border: 0 }} {...props} />,
                        th: ({ ...props }) => <td style={{ border: 0 }} {...props} />
                      }}
                    />
                  </Typography>
                </>
              )} */}
            </Simple>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditValues;
