const ES_PROVIDERS_API = import.meta.env.VITE_ES_PROVIDERS_API;

export async function useTranslateText({ template, query, data, ...rest }) {
  const response = await fetch(`${ES_PROVIDERS_API}/ai/gpt/translate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      template,
      query,
      data,
      ...rest
    })
  });
  return response;
}

export default async function useExtractInfo({ query, infoToSearch, template, stream = true }) {
  const response = await fetch(`${ES_PROVIDERS_API}/ai/gpt/extract-info-and-create-doc`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      data: infoToSearch,
      template,
      stream
    })
  });
  return response;
}
