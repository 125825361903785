const enTable = {
  states: {
    UPCOMING: `UPCOMING`,
    TO_CONFIRM: `TO CONFIRM`,
    WAITING_PAYMENT: `WAITING PAYMENT`,
    CANCELLED: `CANCELLED`,
    COMPLETE: `COMPLETE`,
    ENQUIRED: `ENQUIRED`
  },
  buttons: {
    moreResults: 'More results'
  },
  forms: {
    filterByStatus: 'Filter by status',
    allStatus: 'All status'
  }
};

export default enTable;
