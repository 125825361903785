import { Box, Chip, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import utc from 'dayjs/plugin/utc';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import MainLayout from '../../components/Layouts/MainLayout';
import useIsIframe from '../../hooks/useIsIframe';

import { useGetBlocksByGuide } from '../../lib/unavailability';
import { useGetGuideById } from '../../lib/guide';
import { useLocale } from '../../hooks/useLocale';

dayjs.extend(utc);

export default function GuideBlocks() {
  const [locale] = useLocale();
  const isIframe = useIsIframe();
  const { guideId } = useParams();

  const { data: guide } = useQuery(['guide', guideId], () => useGetGuideById(guideId), {
    staleTime: Infinity
  });

  const { blocks, isFetching } = useGetBlocksByGuide(guideId);

  const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

  return (
    <MainLayout back={isIframe ? null : '/'} title="Unavailability" topTitle={guide?.first_name}>
      {!isFetching && !blocks?.length ? (
        <Typography level="body-sm" fontStyle="italic">
          No blocked dates
        </Typography>
      ) : (
        <Box>
          <Typography level="h5">Blocks:</Typography>
          {blocks?.length ? (
            <Box sx={{ marginTop: '10px' }}>
              {blocks.map(({ startAt, endAt, type, trip, createdAt, comment }, i) => {
                return (
                  <Box key={i} sx={{ padding: '5px', alignItems: 'center', gap: '5px' }}>
                    <Typography level="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      {formatDate(startAt)} <NavigateNextIcon /> {formatDate(endAt)}{' '}
                      {type === 'trip' ? trip.i18n[locale].title : 'All trips'}
                    </Typography>
                    <Typography level="body-sm">
                      Created at: {formatDate(createdAt)}{' '}
                      {comment && <Chip size="sm">{comment}</Chip>}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </Box>
      )}
    </MainLayout>
  );
}
