import { Box, Button, DialogActions, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';

import SharedModal from '../SharedModal';
import { makeVersionCurrent } from '../../lib/mails';

function MakeCurrentVersion() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [confirm, setConfirm] = useState(false);

  const makeCurrent = async () => {
    const { mail_name, _id } = await toast.promise(makeVersionCurrent(id), {
      loading: 'Making current...',
      success: 'Version made current!',
      error: 'An error occurred'
    });
    await queryClient.invalidateQueries([`mail-${_id}`]);
    await queryClient.invalidateQueries([`mail-versions-${mail_name}`]);
    setConfirm(false);
  };

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Button onClick={() => setConfirm(true)}>Make this version current</Button>
      {confirm && (
        <SharedModal isOpen={confirm} close={() => setConfirm(false)}>
          <Box sx={{ marginTop: '20px' }}>
            <Typography>Are you sure you want to make this version the current one?</Typography>
            <DialogActions>
              <Button color="primary" onClick={makeCurrent}>
                Confirm
              </Button>
              <Button variant="plain" color="neutral" onClick={() => setConfirm(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </SharedModal>
      )}
    </Box>
  );
}

export default MakeCurrentVersion;
