import { Box, Button } from '@mui/joy';
import { Link } from 'react-router-dom';

import useConfirmModal from '../../hooks/useConfirmModal';

const ControlButtons = ({ loading, handleConfirm, to }) => {
  const { open, modal } = useConfirmModal({ loading, handleConfirm });
  return (
    <Box sx={{ display: 'flex', gap: '5px', justifyContent: 'end' }}>
      <Button disabled={loading} component={Link} color="neutral" to={to}>
        Edit
      </Button>
      <Button disabled={loading} onClick={open}>
        {loading ? 'Loading...' : 'Approve'}
      </Button>
      {modal}
    </Box>
  );
};

export default ControlButtons;
