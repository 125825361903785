/* eslint-disable react/button-has-type */
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  Typography
} from '@mui/joy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';

import { ArrowLeft, ArrowRight } from '@mui/icons-material';

import MainLayout from '../../components/Layouts/MainLayout';
import useGetLandingPages from '../../lib/landing-pages';
import Loading from '../../components/Loading/Loading';
import LocaleSelector from '../../components/LandingPage/LocaleSelector';
import debounce from '../../utils/debounce';
import { DEFAULT_LOCALE } from '../../constants/defaults';

import { useLocale } from '../../hooks/useLocale';

import LandingPageBulk from './LandingPageBulk';

const SITE = import.meta.env.VITE_SITE;

export default function LandingPages() {
  const defaultPage = new URLSearchParams(window.location.search).get('page') || 1;
  const defaultSearch = new URLSearchParams(window.location.search).get('search') || '';

  const [openBulkDrawer, setOpenBulkDrawer] = useState(false);
  const [bulkPage, setBulkPage] = useState([]);
  const [page, setPage] = useState(Number(defaultPage));
  const [search, setSearch] = useState(
    defaultSearch ? `?s=${defaultSearch}&locale=${DEFAULT_LOCALE}` : ''
  );

  const [locale] = useLocale();

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['allLandingPages'],
    () => useGetLandingPages({ page: page - 1, search, locale }),
    {
      enabled: locale !== null
    }
  );

  useEffect(() => {
    if (!locale) return;
    refetch();
  }, [page, search, locale]);

  if (!locale) return null;

  if (isLoading) {
    return (
      <MainLayout title="Landing Pages">
        <Loading />
      </MainLayout>
    );
  }

  const handleSetNewPage = (newPage) => {
    window.scrollTo(0, 0);
    window.history.pushState({}, '', `/landing-pages?page=${newPage}&locale=${locale}`);
    setPage(newPage);
  };

  const searchLandingPage = debounce((e) => {
    const searchTerm = e.target.value;
    if (!searchTerm) {
      window.history.pushState({}, '', `/landing-pages?page=${page}&locale=${locale}`);
      setSearch('');
      return;
    }
    window.history.pushState(
      {},
      '',
      `/landing-pages?page=${page}&search=${searchTerm}&locale=${locale}`
    );
    setSearch(`?s=${searchTerm}&locale=${locale}`);
  }, 500);

  return (
    <MainLayout
      title="Landing Pages"
      options={
        <Button onClick={() => setOpenBulkDrawer(true)}>
          Open Bulk {bulkPage.length ? `(${bulkPage.length})` : null}
        </Button>
      }>
      <LandingPageBulk open={openBulkDrawer} setOpen={setOpenBulkDrawer} bulkPage={bulkPage} />
      <LocaleSelector />
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          justifyContent: 'center',
          marginBottom: '16px',
          marginTop: '16px'
        }}>
        <Input
          autoFocus
          placeholder="Hiking in Slovenia"
          defaultValue={defaultSearch}
          onChange={searchLandingPage}
        />
      </Box>
      {isRefetching ? (
        <Loading />
      ) : (
        <>
          <List>
            {data.data.map((landingPage) => {
              const landingPageData = landingPage.i18n[locale];
              if (!landingPageData) return null;
              return (
                <Fragment key={landingPage._id}>
                  <ListItem
                    startAction={
                      landingPageData.seo.link && (
                        <IconButton
                          size="sm"
                          variant="plain"
                          component="a"
                          href={landingPageData.seo.link.replace(
                            'https://www.explore-share.com',
                            SITE
                          )}
                          target="_blank">
                          <OpenInNewIcon />
                        </IconButton>
                      )
                    }>
                    <ListItemButton
                      component={Link}
                      to={`/landing-pages/${landingPage._id}?locale=${locale}`}
                      state={{ landingPage }}>
                      <img
                        alt=""
                        src={`${landingPage.header}?w=180&h=180`}
                        height="50px"
                        width="50px"
                        loading="lazy"
                        style={{ borderRadius: '4px', objectFit: 'cover', marginRight: '5px' }}
                      />
                      <ListItemContent>
                        {landingPageData.title ? (
                          <Typography>{landingPageData.title}</Typography>
                        ) : (
                          <Typography>Untitled</Typography>
                        )}
                        <Typography level="body-sm">
                          Updated at: {dayjs(landingPage.updatedAt).format('MM/DD/YYYY, HH:mm')}
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListDivider />
                </Fragment>
              );
            })}
          </List>
          {!defaultSearch && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                justifyContent: 'center',
                background: 'white',
                padding: '10px 0px'
              }}>
              <Button
                size="sm"
                variant="outlined"
                disabled={page <= 1}
                onClick={() => handleSetNewPage(page - 1)}>
                <ArrowLeft />
              </Button>
              <Typography>
                {page} / {data.metadata.totalPages}
              </Typography>
              <Button size="sm" variant="outlined" onClick={() => handleSetNewPage(page + 1)}>
                <ArrowRight />
              </Button>
            </Box>
          )}
        </>
      )}
    </MainLayout>
  );
}
