import { func, bool } from 'prop-types';
import { Button } from '@mui/joy';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const Star = ({ onClick, filled }) => {
  return (
    <Button size="sm" variant="plain" color="neutral" onClick={onClick}>
      {filled ? <StarIcon sx={{ fill: '#f8c904' }} /> : <StarBorderIcon />}
    </Button>
  );
};

Star.propTypes = {
  onClick: func.isRequired,
  filled: bool
};
Star.defaultProps = {
  filled: false
};
export default Star;
